import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { REDUX_FORM_NAME } from 'react-admin';
import { change } from 'redux-form';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import { t } from 'i18next';
import Typography from '@material-ui/core/Typography';
import WarningAlert from '@material-ui/icons/Warning';

import { GET_MATERIALS, GET_UNITS } from "../../customQueries";
import apolloClient from '../../initApollo';
import Ingredients from './Ingredients';
import Nutrients from './Nutrients';
import Moisture from './Moisture';
import Energy from './Energy';
import { DailyDose } from './DailyDose';
import AdditionalSettings from './AdditionalSettings';
import ChipWithAvatar from '../fields/ChipWithAvatar';

import { withStyles } from '@material-ui/core';

const styles = {
  div: {
    display: 'flex'
  },
  redText: {
    color: 'red'
  },
  rightDiv: {
    float: 'right'
  }
};

class RecipeSimpleFormIterator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      allMaterials: [],
      allUnits: [],
      nutrients: [],
      moistureDetails: [],
      energy: {},
      changed: false
    };

    // we need a unique id for each field for a proper enter/exit animation
    // but redux-form doesn't provide one (cf https://github.com/erikras/redux-form/issues/2735)
    // so we keep an internal map between the field position and an autoincrement id
    this.nextId = props.fields.length
      ? props.fields.length
      : props.defaultValue
        ? props.defaultValue.length
        : 0;

    // We check whether we have a defaultValue (which must be an array) before checking
    // the fields prop which will always be empty for a new record.
    // Without it, our ids wouldn't match the default value and we would get key warnings
    // on the CssTransition element inside our render method
    this.ids = this.nextId > 0 ? Array.from(Array(this.nextId).keys()) : [];
  }

  componentDidMount() {
    apolloClient.query({ query: GET_MATERIALS }).then(response => {
      if (response.loading) return;
      this.setState({ allMaterials: response.data.materials })
    });

    apolloClient.query({ query: GET_UNITS }).then(response => {
      if (response.loading) return;
      this.setState({ allUnits: response.data.units })
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.props.materialQuantities) {
      return;
    }

    const prevFields = [...prevProps.materialQuantities];
    const currentFields = [...this.props.materialQuantities];

    let isChanged = !isEqual(prevFields, currentFields);

    if (isChanged) {
      let newFields = currentFields.map((field) => {
        return this.calculateDetails(field);
      });

      this.props.change(REDUX_FORM_NAME, 'materialQuantities', newFields);
      this.calculateTotal(currentFields);
    }
  }

  removeField = index => () => {
    const { fields } = this.props;
    this.ids.splice(index, 1);
    fields.remove(index);
  };

  addField = () => {
    const { fields } = this.props;
    this.ids.push(this.nextId++);
    fields.push({});
  };

  calculateDetails(field) {
    // we need both of these for calculations
    if (field.materialId && field.unitId) {

      // get raw objects for Material and Unit, we will need values from there for calculations
      let material = this.state.allMaterials.filter(material => material.id === field.materialId);
      let chosenMaterial = material.pop();

      let unit = this.state.allUnits.filter(unit => unit.id === field.unitId);
      let chosenUnit = unit.pop();

      let perKg = 1 / chosenUnit.coefficientPerKilogram;
      field.percentPerKg = field.quantity * perKg * 100;

      field.moisture = chosenMaterial.moisture;

      let price = field.quantity * perKg * chosenMaterial.price;
      field.price = Number(price.toFixed(2));

      return field;
    }

    if (field.materialId && !field.unitId) {
      let material = this.state.allMaterials.filter(material => material.id === field.materialId);
      let chosenMaterial = material.pop();

      return {
        ...field,
        moisture: chosenMaterial.moisture
      };
    }

    return {};
  }

  calculateTotal = (rows) => {
    let totalPrice = 0;

    rows.forEach((row) => {
      if (row.price) {
        totalPrice = totalPrice + row.price;
        totalPrice = Number(totalPrice.toFixed(2));
      }
    });

    this.props.change(REDUX_FORM_NAME, 'totalPrice', totalPrice);
  };

  nutrientsCallback = (nutrients) => {
    this.setState({ nutrients });

    if (nutrients) {
      this.setState({ changed: true });
    }
  };

  moistureCallback = (moistureDetails) => {
    if (this.state.nutrients.length !== 0 && moistureDetails.length !== 0) {
      this.setState({ moistureDetails, changed: true });
    }
  };

  energyCallback = (energy) => {
    this.setState({ energy, changed: false });
  };

  render() {
    const { allMaterials, allUnits, nutrients, changed, energy } = this.state;
    const { classes, data, ...rest } = this.props;
    const { fields, materialQuantities, record, change, order, animalType, formNutrients } = rest;
    const { animal } = order;

    return fields ? (
      <div>
        {this.props.showWarningMessage && <Typography variant="subheading" className={classes.redText}><WarningAlert /> {t('warning_message_recipe')}</Typography>}
        <div className={classes.rightDiv}>
          {this.props.record.id && animal && rest.user && rest.user === 'Admin' ? <ChipWithAvatar record={animal} blankTarget={true} /> : null}
        </div>
        <Ingredients
          ids={this.ids}
          removeField={this.removeField}
          addField={this.addField}
          {...rest}
        />
        <div className={classes.div}>
          <Nutrients
            fields={materialQuantities}
            materials={allMaterials}
            nutrients={record.nutrients || (data && data.nutrients ? data.nutrients : null)}
            change={change}
            units={allUnits}
            callback={this.nutrientsCallback.bind(this)}
          />
          <div>
            <Moisture
              allMaterials={allMaterials}
              allUnits={allUnits}
              moistureDetails={record.moistureDetails || (data && data.moistureDetails ? data.moistureDetails : null)}
              materials={materialQuantities}
              change={change}
              callback={this.moistureCallback.bind(this)}
              user={rest.user}
            />
            <Energy
              energy={record.energy || (data && data.energy ? data.energy : null)}
              animalType={animalType || animal.animalType}
              nutrients={nutrients}
              materials={materialQuantities}
              changed={changed}
              change={change}
              callback={this.energyCallback.bind(this)}
              user={rest.user}
            />
            {animal && animal.animalType === 'Dog' &&
              <DailyDose
                energy={Object.keys(energy).length !== 0 ? energy : (data && data.energy ? data.energy : null)}
                adjustment={record.dailyDoseAdjustment}
                {...rest}
              />
            }
            {formNutrients.length > 0 && <AdditionalSettings user={rest.user} />}
          </div>
        </div>
      </div>
    ) : null;
  }
}

RecipeSimpleFormIterator.defaultProps = {
  disableAdd: false,
  disableRemove: false,
};

RecipeSimpleFormIterator.propTypes = {
  defaultValue: PropTypes.any,
  basePath: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  fields: PropTypes.object,
  meta: PropTypes.object,
  record: PropTypes.object,
  source: PropTypes.string,
  resource: PropTypes.string,
  disableAdd: PropTypes.bool,
  disableRemove: PropTypes.bool,
};

const mapFormStateToProps = state => {
  let materialQuantities = [];
  let formNutrients = [];
  let totalPrice = 0;
  let form = state.form[REDUX_FORM_NAME];
  if (form) {
    materialQuantities = (form.values && form.values.materialQuantities) ? form.values.materialQuantities : materialQuantities;
    formNutrients = (form.values && form.values.nutrients) ? form.values.nutrients : formNutrients;
    totalPrice = (form.values && form.values.totalPrice) ? form.values.totalPrice : totalPrice;
  }

  return { materialQuantities, totalPrice, formNutrients };
};

export default connect(mapFormStateToProps, { change })(withStyles(styles)(RecipeSimpleFormIterator));
