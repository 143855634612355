import React from 'react';
import { Edit } from 'react-admin';

import { t } from 'i18next';

import { RecipeForm } from './RecipeForm';
import EditFormTitle from '../EditFormTitle';
import { CustomEditToolbar } from '../CustomEditToolbar';
import { GET_RECIPE } from '../../customQueries';
import apolloClient from '../../initApollo';

class RecipeEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      order: null,
      recipeFormName: t('recipe'),
    };

    this.initializeRecipeForm(this.props.id);
  };

  initializeRecipeForm = id => {
    apolloClient.query({ query: GET_RECIPE, variables: { id }, fetchPolicy: 'no-cache' })
      .then(response => {
        if (response.loading) return;

        const order = response.data.recipe.order;
        const animal = order.animal;
        let recipeFormName = '';
        let name = '';
        let type = order.animalType.toLowerCase();
        const breed = order.breed;
        let breedName = '';
        if (breed) {
          breedName = breed.breedNameBg;
        }

        if (animal) {
          name = animal.name;
          type = animal.animalType.toLowerCase();
          breedName = animal.breed.breedNameBg;
          recipeFormName = t('recipes.title', { name, type: t(type), breed: breedName });
        } else {
          recipeFormName = t('recipes.title_clinic_order', { type: t(type), detail: breedName || order.sizeClass, ageGroup: order.ageGroupName });
        }

        this.setState({
          order,
          recipeFormName
        });
      });
  };

  render = () => {
    const { recipeFormName, order } = this.state;
    if (!order) return null;

    return (
      <Edit {...this.props} title={<EditFormTitle formName={recipeFormName} />}>
        <RecipeForm toolbar={<CustomEditToolbar />} order={order} user={this.props.options.currentUser}/>
      </Edit>
    )
  };
}

export { RecipeEdit };
