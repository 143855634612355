import { Pets } from '@material-ui/icons';

import { AnimalList } from '../components/Animal/AnimalList';
import AnimalShow from '../components/Animal/AnimalShow';
import AnimalCreate from '../components/Animal/AnimalCreate';
import AnimalEdit from '../components/Animal/AnimalEdit';

export const Animals = {
  icon: Pets,
  list: AnimalList,
  show: AnimalShow,
  create: AnimalCreate,
  edit: AnimalEdit
};