import React from 'react';
import { ShowController, ShowView, TextField, TabbedShowLayout, RichTextField, Tab, NumberField, BooleanField, ImageField, Labeled } from 'react-admin';
import { t } from 'i18next';

import EditFormTitle from '../EditFormTitle';
import TranslationField from '../fields/TranslationField';

export const BreedShow = props => (
  <ShowController {...props}>
    {controllerProps =>
      <ShowView {...props} {...controllerProps} title={<EditFormTitle formName={t('breed')} />}>
      <TabbedShowLayout>
        <Tab label={t("summary_tab")}>
          <TranslationField source="animalType" label={t('animal_type')}/>
          <TextField source="breedNameBg" label={t('name_bg')}/>
          <TextField source="breedNameEn" label={t('name_en')}/>
          <TextField source="sizeClass" label={t('size_class')}/>
          <BooleanField source="supportedByOptimus" label={t('breeds.supported_by_optimus')}/>
          {props.options.currentUser.role === 'Admin' ? <NumberField source="priceCoefficient" label={t('breeds.price_coefficient')} options={{ maximumFractionDigits: 12 }}/> : null}
        </Tab>
        {controllerProps.record && controllerProps.record.animalType  === 'Dog' && props.options.currentUser.role === 'Admin' ?
          <Tab label={t("short_encyclopedia")}>
            <RichTextField source="shortEncyclopedia" label={t('short_encyclopedia')}/>
          </Tab>
        : null}
        {controllerProps.record && controllerProps.record.animalType  === 'Dog' ?
          <Tab label={t("encyclopedia")}>
            <RichTextField source="encyclopedia" label={t('encyclopedia')}/>
          </Tab>
        : null}
        <Tab label={t('profile_picture')}>
          {controllerProps.record && controllerProps.record.animalType  === 'Dog' ?
            <Labeled label={t('age_group.puppy')}>
              <ImageField source="picturePuppy"/>
            </Labeled>
          :
            <Labeled label={t('age_group.kitten')}>
              <ImageField source="pictureKitten"/>
            </Labeled>
          }
          <Labeled label={t('age_group.junior')}>
            <ImageField source="pictureJunior"/>
          </Labeled>
          {controllerProps.record && controllerProps.record.animalType  === 'Dog' ?
            <Labeled label={t('age_group.adult')}>
              <ImageField source="pictureAdult"/>
            </Labeled>
          :
            <Labeled label={t('age_group.prime')}>
              <ImageField source="picturePrime"/>
            </Labeled>
          }
          <Labeled label={t('age_group.mature')}>
            <ImageField source="pictureMature"/>
          </Labeled>
          <Labeled label={t('age_group.senior')}>
            <ImageField source="pictureSenior"/>
          </Labeled>
          <Labeled label={t('age_group.geriatric')}>
            <ImageField source="pictureGeriatric"/>
          </Labeled>

          <Labeled label={t('age_group.youngadult')}>
            <ImageField source="pictureYoungAdult"/>
          </Labeled>

          <Labeled label={t('age_group.matureadult')}>
            <ImageField source="pictureMatureAdult"/>
          </Labeled>
        </Tab>
      </TabbedShowLayout>
      </ShowView>
    }
  </ShowController>
);
