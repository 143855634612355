import React from 'react';
import { Create } from 'react-admin';
import { t } from 'i18next';
import CustomCreateToolbar from '../CustomCreateToolbar';
import { MaterialForm } from './MaterialForm';

export const MaterialCreate = props => (
  <Create {...props} title={t("material_details.title_create")}>
    <MaterialForm toolbar={<CustomCreateToolbar user={props.options.currentUser.role}/>}/>
  </Create>
);
