import React from 'react';
import { Create } from 'react-admin';
import { t } from 'i18next';

import CustomCreateToolbar from '../CustomCreateToolbar';
import { ConsultingFeePercentageVetForm } from './ConsultingFeePercentageVetForm';

export const ConsultingFeePercentageVetCreate = props => (
  <Create {...props} title={t('consulting_fee_details.consulting_fee_percentage_create_vet')}>
    <ConsultingFeePercentageVetForm toolbar={<CustomCreateToolbar user={props.options.currentUser.role}/>}/>
  </Create>
);