import React from 'react';
import { Create } from 'react-admin';
import { t } from 'i18next';

import CustomCreateToolbar from '../CustomCreateToolbar';
import { EmailTemplateForm } from './EmailTemplateForm';

export const EmailTemplateCreate = props => (
  <Create {...props} title={t('email_templates.title_create')}>
    <EmailTemplateForm toolbar={<CustomCreateToolbar user={props.options.currentUser.role}/>}/>
  </Create>
);