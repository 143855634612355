import React from 'react';
import { Create } from 'react-admin';
import { t } from 'i18next';

import CustomCreateToolbar from '../CustomCreateToolbar';
import { AgeGroupDogForm } from './AgeGroupDogForm';

export const AgeGroupDogCreate = props => (
  <Create {...props} title={t('age_group.dog_title_create')}>
    <AgeGroupDogForm toolbar={<CustomCreateToolbar user={props.options.currentUser.role}/>}/>
  </Create>
);