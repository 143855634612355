import React from 'react';
import {t} from 'i18next';
import {
  Create,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  FormDataConsumer,
  NumberInput,
  RadioButtonGroupInput,
  ArrayInput,
  SimpleFormIterator,
  LongTextInput,
  TextInput,
} from 'react-admin';
import {withStyles} from '@material-ui/core';
import {Chip} from '@material-ui/core';

import Typography from '@material-ui/core/Typography';
import {SearchableSelect} from './../SearchableSelect';

import CustomCreateToolbar from '../CustomCreateToolbar';
import {isRequired} from '../../utils/validation';
import {isNaturalNumber} from '../../utils/validation/index';
import {calculateAnimalDetails} from '../calculateAnimalDetails';
import {GET_CLINIC_ID_OF_VET, GET_OWNER_ADDRESS_DATA} from '../../customQueries';
import apolloClient from '../../initApollo';
import LocationSelect from "../LocationSelect";

const validateRequired = [isRequired()];
const validateNumber = [isRequired(), isNaturalNumber];

const styles = theme => ({
  animalField: {
    minWidth: 180
  }
});

class NormalOrderForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      breedCoefficient: null,
      priceByWeightGeneral: null,
      priceByWeight: null,
      priceByWeightVIP: null,
      clinic: null,
      animalDetails: null,
      ownerDetails: { id: null}
    }
  }

  componentDidMount() {
    const {user} = this.props;
    this._isMounted = true;

    if (user.role === 'Vet') {
      let vetId = user.systemId;

      apolloClient.query({query: GET_CLINIC_ID_OF_VET, variables: {vetId}, fetchPolicy: 'no-cache'})
        .then(response => {
          if (response.loading) return;

          this.setState({clinic: response.data.vet.clinic.id});
        })
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onFormChanged = event => {

    if (event.animal && event.animal.id && !event.ageGroup) {
      const animalId = event.animal.id;

      calculateAnimalDetails(animalId).then(animalDetails => {
        event.animalType = animalDetails.type;
        event.breed = {id: animalDetails.breedId};
        event.sizeClass = animalDetails.sizeClass;
        event.ageGroup = animalDetails.ageGroup;
        event.ageGroupName = animalDetails.ageGroupName;

        if (this._isMounted) {
          this.setState({
            breedCoefficient: animalDetails.breedCoefficient,
            priceByWeightGeneral: animalDetails.priceByWeightGeneral,
            priceByWeight: animalDetails.priceByWeight,
            priceByWeightVIP: animalDetails.priceByWeightVIP,
            animalDetails,
          })
        }
      })
    }

    const ownerId = event.owner && event.owner.id

    if (ownerId && ownerId !== this.state.ownerDetails.id) {
      apolloClient.query({query: GET_OWNER_ADDRESS_DATA, variables: {ownerId}, fetchPolicy: 'no-cache'})
        .then(response => {
          if (response.loading) return;
          event.ownerName = response.data.owner.name;
          event.city = response.data.owner.city;
          event.district = response.data.owner.district;
          event.street = response.data.owner.street;
          event.streetNumber = response.data.owner.streetNumber;
          event.buildingNumber = response.data.owner.buildingNumber;
          event.entrance = response.data.owner.entrance;
          event.floor = response.data.owner.floor;
          event.apartmentNumber = response.data.owner.apartmentNumber;
          event.postcode = response.data.owner.postcode;
          event.phoneNumber = response.data.owner.phoneNumber;
          this.setState({ownerDetails: {id: ownerId} });

        })
    }
  };

  getChoices = orderType => {
    switch (orderType) {
      case 'VIP':
        return this.props.convertPairsToString(this.state.priceByWeightVIP);
      case 'ByBreed':
        return this.props.calculatePairs(this.state.priceByWeight, this.state.breedCoefficient);
      default:
        return this.props.convertPairsToString(this.state.priceByWeightGeneral);
    }
  };

  render() {
    const {
      classes,
      formData,
      generateWeightPriceChoice,
      dispatch,
      convertPairsToString,
      calculatePairs,
      status,
      user,
      ...rest
    } = this.props;
    const previousState = this.props.location.state || null;
    let redirect = previousState && previousState.data ? `/Owner/${previousState.data.owner.id}/show/2` : 'list';

    if (previousState && previousState.prevPath && previousState.prevPath === '#/Order') {
      redirect = 'list';
    }


    return (
      <Create
        {...rest}
        title={t('order_details.title_create')}>
        <SimpleForm
          toolbar={<CustomCreateToolbar user={user.role}/>}
          redirect={redirect}
          defaultValue={{status: {id: status.id}, dateCreated: new Date()}}
          onChange={this.onFormChanged}>
          <React.Fragment>
            <div>
              <SelectInput
                source="orderType"
                label={t('order_details.order_type')}
                choices={[
                  {id: 'Normal', name: t('order_details.normal')},
                  {id: 'ByBreed', name: t('order_details.by_breed')},
                  {id: 'VIP', name: t('order_details.VIP')},
                ]}
                onChange={() => {
                  formData.weight = null;
                  formData.price = null;
                  formData.key = null;
                  formData.weightPricePair = null;
                }}
                validate={validateRequired}
              />
            </div>
            <div>
              <ReferenceInput
                label={t('clinic_name')}
                reference="Clinic"
                source="clinic.id"
                validate={validateRequired}
                disabled={!!previousState || user.role === 'Vet' || user.role === 'Clinic'}
                defaultValue={previousState ? previousState.data.clinic.id : user.role === 'Vet' ? this.state.clinic : user.role === 'Clinic' ? user.systemId : undefined}
                onChange={() => {
                  formData.vet = null;
                  formData.owner = null;
                  formData.animal = null;
                  formData.weight = null;
                  formData.price = null;
                  formData.key = null;
                  formData.weightPricePair = null;
                }}
                {...rest}
              >
                <SearchableSelect obj="Clinic"/>
              </ReferenceInput>
            </div>

            <div>
              <ReferenceInput
                key={formData.clinic ? formData.clinic.id : null}
                label={t('vet')}
                reference="Vet"
                source="vet.id"
                validate={validateRequired}
                onChange={() => {
                  formData.owner = null;
                  formData.animal = null;
                  formData.weight = null;
                  formData.price = null;
                  formData.key = null;
                  formData.weightPricePair = null;
                }}
                filter={{clinic: (formData.clinic ? formData.clinic : null)}}
                disabled={!!previousState || user.role === 'Vet'}
                defaultValue={previousState ? previousState.data.vet.id : user.role === 'Vet' ? user.systemId : undefined}
                {...rest}
              >
                <SearchableSelect obj="Vet" filter={{clinic: (formData.clinic || null)}} optionText="name"/>
              </ReferenceInput>
            </div>

            <div>
              <ReferenceInput
                key={formData.vet ? formData.vet.id : null}
                label={t('owner')}
                reference="Owner"
                source="owner.id"
                validate={validateRequired}
                onChange={(x, value, oldValue) => {
                  if (value !== oldValue) {
                    formData.animal = null;
                    formData.weight = null;
                    formData.price = null;
                    formData.key = null;
                    formData.weightPricePair = null;
                  }
                }}
                filter={{vet: (formData.vet && formData.vet.id !== undefined ? formData.vet : {'id': null})}}
                disabled={!!previousState}
                defaultValue={previousState ? previousState.data.owner.id : undefined}
                {...rest}
              >
                <SearchableSelect
                  obj="Owner"
                  filter={{vet: (formData.vet || null)}}
                />
              </ReferenceInput>
            </div>

            <div>
              <ReferenceInput
                key={formData.owner ? formData.owner.id : null}
                label={t('animal')}
                reference="Animal"
                source="animal.id"
                validate={validateRequired}
                onChange={(x, value, oldValue) => {
                  if (value !== oldValue) {
                    formData.ageGroup = null;
                    formData.weight = null;
                    formData.price = null;
                    formData.key = null;
                    formData.weightPricePair = null;
                  }
                }}
                // filter={{ owner: (formData.owner && formData.owner.id !== undefined ? formData.owner : null) }}
                filter={{owner: (formData.owner && formData.owner.id !== undefined ? formData.owner : {'id': null})}}
                disabled={!!previousState}
                defaultValue={previousState ? previousState.data.animal.id : undefined}
                // classes={{ input: classes.animalField }}
                {...rest}
              >
                {/*<SelectInput optionText="name" />*/}
                <SearchableSelect obj="Animal" filter={{owner: (formData.owner || null)}}/>
              </ReferenceInput>
            </div>
            {formData.animal && this.state.animalDetails ?
              <div>
                <Chip label={'Възраст: ' + this.state.animalDetails.ageInMonths + ' м.'}/>
                <Chip label={'Възр. група: ' + this.state.animalDetails.ageGroupName}/>
                <Chip label={'Клас размери: ' + this.state.animalDetails.sizeClass}/>
              </div>
              : null}

            <div>
              <ReferenceInput
                style={{width: 256}}
                label={t('taste')}
                reference='Taste'
                source='taste.id'
                {...rest}
              >
                <SearchableSelect obj="Taste"/>
              </ReferenceInput>
            </div>

            <div>
              <TextInput source="testerCode" label={t('order_details.tester_code')}/>
            </div>

            <div>
              <TextInput source="ownerName" label={t('owner_name')}/>
            </div>

            <div>
              <ReferenceInput
                label={t('city')}
                reference="City"
                source="city.id"
                perPage={30}
                filter={{mainProvinceCity: true}}
                sort={{field: 'name', order: 'ASC'}}
              >
                <LocationSelect/>
              </ReferenceInput>
            </div>

            <div>
              <TextInput source="district" label={t('district')}/>
            </div>
            <div>
              <TextInput source="street" label={t('street')}/>
            </div>
            <div>
              <TextInput source="streetNumber" label={t('street_number')}/>
            </div>
            <div>
              <TextInput source="buildingNumber" label={t('building_number')}/>
            </div>
            <div>
              <TextInput source="entrance" label={t('entrance')}/>
            </div>
            <div>
              <TextInput source="floor" label={t('floor')}/>
            </div>
            <div>
              <TextInput source="apartmentNumber" label={t('apartment_number')}/>
            </div>
            <div>
              <TextInput source="postcode" label={t('postcode')}/>
            </div>
            <div>
              <TextInput source="phoneNumber" label={t('phone_number')}/>
            </div>


            {formData.animal && formData.orderType ?
              <div>
                <RadioButtonGroupInput
                  label={t('age_group.price_weight_pair')}
                  source='key'
                  choices={this.getChoices(formData.orderType)}
                  optionValue='key'
                  optionText={generateWeightPriceChoice}
                  translateChoice={false}
                  validate={validateRequired}
                  onChange={(x, value) => {
                    formData.weightPricePair = this.getChoices(formData.orderType).filter(choice => choice.key === value).pop();
                    formData.weight = formData.weightPricePair.weight;
                    formData.price = formData.quantity ? formData.quantity * formData.weightPricePair.weight * formData.weightPricePair.price : null;
                  }}
                />
              </div>
              : null}
            <div>
              <NumberInput
                source="quantity"
                label={t('order_details.quantity')}
                validate={validateNumber}
                defaultValue={1}
                inputProps={{min: 1}}
                onChange={(x, value) => {
                  formData.price = formData.weightPricePair ? value * formData.weightPricePair.weight * formData.weightPricePair.price : null;
                }}
              />
            </div>
            <ArrayInput source="comments" label={t('comments')}>
              <SimpleFormIterator>
                <FormDataConsumer>
                  {({id, getSource}) => {
                    const index = id.match(/\d+/) ? id.match(/\d+/)[0] : 0;
                    return (
                      <LongTextInput
                        source={getSource('comment')}
                        label={t('comment')}
                        validate={isRequired()}
                        resettable
                        onChange={() => formData.comments[index].user = user.role}
                      />
                    );
                  }}
                </FormDataConsumer>
              </SimpleFormIterator>
            </ArrayInput>
            {formData.price ?
              <React.Fragment>
                <Typography
                  variant="headline">{t('order_details.price')}: {formData.price.toFixed(2)} {t('lv')}</Typography>
              </React.Fragment>
              : null}
          </React.Fragment>
        </SimpleForm>
      </Create>
    )
  }
}

export default withStyles(styles)(NormalOrderForm);
