import React, { Fragment } from 'react';
import {
  List,
  Datagrid,
  SelectField,
  NumberField,
  SelectInput,
  Filter
} from 'react-admin';
import { t } from 'i18next';

import ExchangeWeightBySizeClass from '../ExchangeWeight/ExchangeWeightBySizeClass';
import { CustomBulkActions } from '../CustomBulkActions';
import { CustomListActions } from '../CustomListActions';

export const SizeClassFilter = props => {
  return (
    <Filter {...props}>
      <SelectInput
        source='sizeClass'
        label={t('size_class')}
        choices={[
          { id: 'Mini', name: t('size_classes.mini') },
          { id: 'Small', name: t('size_classes.small') },
          { id: 'Medium', name: t('size_classes.medium') },
          { id: 'Large', name: t('size_classes.large') },
          { id: 'Giant', name: t('size_classes.giant') }
        ]}
        alwaysOn
      />
    </Filter>
  );
};

export const EnergyRequirementDogList = props => (
  <Fragment>
    <List
      bulkActionButtons={<CustomBulkActions />}
      actions={<CustomListActions />}
      title={t('energy.metabolic_energy')}
      filters={<SizeClassFilter/>}
      {...props}
    >
      <Datagrid rowClick='edit'>
        <SelectField
          source='sizeClass'
          label={t('size_class')}
          choices={[
            { id: 'Mini', name: t('size_classes.mini') },
            { id: 'Small', name: t('size_classes.small') },
            { id: 'Medium', name: t('size_classes.medium') },
            { id: 'Large', name: t('size_classes.large') },
            { id: 'Giant', name: t('size_classes.giant') }
          ]}
        />
        <NumberField source='energy' label={t('energy_requirement_dog.energy')}/>
        <NumberField source='months' label={t('age_in_months')}/>
        <NumberField source='days' label={t('energy_requirement_dog.days')}/>
      </Datagrid>
    </List>
    <ExchangeWeightBySizeClass {...props}/>
  </Fragment>
);
