import React, { cloneElement, Component } from 'react';
import PropTypes from 'prop-types';
import { isRequired, withDefaultValue } from 'ra-core';
import { FieldArray } from 'redux-form';
import FormControl from '@material-ui/core/FormControl';

const sanitizeRestProps = ({
  alwaysOn,
  basePath,
  component,
  defaultValue,
  formClassName,
  initializeForm,
  input,
  isRequired,
  limitChoicesToValue,
  locale,
  meta,
  options,
  optionText,
  optionValue,
  record,
  resource,
  allowEmpty,
  source,
  textAlign,
  translate,
  translateChoice,
  ...rest
}) => rest;

export class RecipeArrayInput extends Component {
  renderFieldArray = fieldProps => {
    const { children, record, resource, source } = this.props;
    return cloneElement(children, {
      ...fieldProps,
      record,
      resource,
      source,
    });
  };

  render() {
    const {
      className,
      defaultValue,
      source,
      validate,
      ...rest
    } = this.props;

    return (
      <FormControl
        fullWidth
        margin="normal"
        className={className}
        {...sanitizeRestProps(rest)}
      >
        <FieldArray
          name={source}
          defaultValue={defaultValue}
          component={this.renderFieldArray}
          validate={validate}
          isRequired={isRequired(validate)}
        />
      </FormControl>
    );
  }
}

RecipeArrayInput.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  defaultValue: PropTypes.any,
  isRequired: PropTypes.bool,
  resource: PropTypes.string,
  source: PropTypes.string,
  record: PropTypes.object,
  options: PropTypes.object,
  validate: PropTypes.func,
};

RecipeArrayInput.defaultProps = {
  options: {},
  fullWidth: true,
};

export default withDefaultValue(RecipeArrayInput);
