import apolloClient from '../initApollo';
import {
  GET_ORDERS_BY_CLINIC_AND_WEEK,
  GET_ORDERS_BY_VET_AND_WEEK,
  GET_CONSULTING_FEE_VET_PERCENT,
  GET_CONSULTING_FEE_CLINIC_PERCENT
} from '../customQueries';
import { getFirstDayOfWeek } from '../utils/Helper';

const getPercentageByTurnover = (currentPercentages, weeklyTurnover) => {
  let percentageByTurnover = null;

  for (const data of currentPercentages) {
    if (data.weeklyTurnoverTo >= weeklyTurnover) {
      percentageByTurnover = data;
      break;
    }
  }

  if (!percentageByTurnover) {
    percentageByTurnover = currentPercentages[currentPercentages.length - 1];
  }

  return percentageByTurnover;
}

export const calculateConsultingFeeDetails = data => {
  if (!data || !data.clinic || !data.clinic.id) return;

  return getClinicWeeklyTurnover(data.clinic.id).then(currentWeeklyTurnoverClinic => {
    let consultingFeeData = {};

    let weeklyTurnoverClinic = currentWeeklyTurnoverClinic + data.price;
    consultingFeeData.weeklyTurnoverClinic = weeklyTurnoverClinic;

    return getClinicConsultingFeePercentage(data.clinic.id).then(currentClinicPercentages => {
      consultingFeeData.percentagesClinic = currentClinicPercentages;
      let clinicPercentageByTurnover = getPercentageByTurnover(currentClinicPercentages, weeklyTurnoverClinic);

      if (clinicPercentageByTurnover) {
        let currentClinicPercentage = 1 + (clinicPercentageByTurnover.consultingFeePercentage / 100);
        consultingFeeData.currentConsultingFeeClinic = data.price - (data.price / currentClinicPercentage);
        consultingFeeData.consultingFeeClinic = weeklyTurnoverClinic - (weeklyTurnoverClinic / currentClinicPercentage);
        consultingFeeData.consultingPercentageClinic = clinicPercentageByTurnover.consultingFeePercentage;

        if (data.vet && data.vet.id) {
          return getVetWeeklyTurnover(data.vet.id).then(currentWeeklyTurnoverVet => {
            let weeklyTurnoverVet = currentWeeklyTurnoverVet + data.price;
            consultingFeeData.weeklyTurnoverVet = weeklyTurnoverVet;

            return getVetConsultingFeePercentage(data.vet.id).then(currentVetPercentages => {
              consultingFeeData.percentagesVet = currentVetPercentages;
              let vetPercentageByTurnover = getPercentageByTurnover(currentVetPercentages, weeklyTurnoverVet);

              if (vetPercentageByTurnover) {
                let consultingFeeVetByClinic = weeklyTurnoverVet - (weeklyTurnoverVet / currentClinicPercentage);
                let currentConsultingFeeVetByClinic = data.price - (data.price / currentClinicPercentage);
                let currentVetPercentage = vetPercentageByTurnover.consultingFeePercentage / 100;
                consultingFeeData.currentConsultingFeeVet = currentConsultingFeeVetByClinic * currentVetPercentage;
                consultingFeeData.consultingFeeVet = consultingFeeVetByClinic * currentVetPercentage;
                consultingFeeData.consultingPercentageVet = vetPercentageByTurnover.consultingFeePercentage;

                return consultingFeeData;
              } else return consultingFeeData;
            });
          });
        } else return consultingFeeData;
      } else return consultingFeeData;
    })
  })
};

export const getVetConsultingFeePercentage = (vetId) => {
  return apolloClient.query({ query: GET_CONSULTING_FEE_VET_PERCENT, variables: { vetId }, fetchPolicy: 'no-cache' })
    .then(response => {
      if (response.loading) return;

      return response.data.consultingFeePercentageVets;
    });
};

export const getClinicConsultingFeePercentage = (clinicId) => {
  return apolloClient.query({ query: GET_CONSULTING_FEE_CLINIC_PERCENT, variables: { clinicId }, fetchPolicy: 'no-cache' })
    .then(response => {
      if (response.loading) return;

      return response.data.consultingFeePercentageClinics;
    });
};

export const getVetWeeklyTurnover = (vetId) => {
  return apolloClient.query({ query: GET_ORDERS_BY_VET_AND_WEEK, variables: { vetId, startOfWeek: getFirstDayOfWeek(new Date()) }, fetchPolicy: 'no-cache' })
    .then(response => {
      if (response.loading) return;

      let vetWeeklyTurnover = 0;
      let vetOrders = response.data.orders;
      vetOrders && vetOrders.forEach(vetOrder => {
        vetWeeklyTurnover += vetOrder.price;
      });

      return vetWeeklyTurnover;
    });
};

export const getClinicWeeklyTurnover = (clinicId) => {
  return apolloClient.query({ query: GET_ORDERS_BY_CLINIC_AND_WEEK, variables: { clinicId, startOfWeek: getFirstDayOfWeek(new Date()) }, fetchPolicy: 'no-cache' })
    .then(response => {
      if (response.loading) return;

      let clinicWeeklyTurnover = 0;
      let clinicOrders = response.data.orders;
      clinicOrders && clinicOrders.forEach(clinicOrder => {
        clinicWeeklyTurnover += clinicOrder.price;
      });

      return clinicWeeklyTurnover;
    });
};