import React, { Component } from 'react';
import { Create } from 'react-admin';

import { t } from 'i18next';
import CustomCreateToolbar from '../CustomCreateToolbar';
import { ProductionManagerForm } from './ProductionManagerForm';

class ProductionManagerCreate extends Component {

  render() {
    const { history, options } = this.props;

    return (
      <Create {...this.props} title={t('production_managers.title_create')}>
        <ProductionManagerForm toolbar={<CustomCreateToolbar history={history} user={options.currentUser.role} />} redirect="list" />
      </Create>
    )
  }
}

export default ProductionManagerCreate;