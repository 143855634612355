import React from 'react';
import {
    Filter,
    TextInput,
    ReferenceInput,
    SelectInput,
    FormDataConsumer
} from 'react-admin';
import {t} from 'i18next';
import {connect} from 'react-redux';
import {changeListParams} from 'ra-core';
import NumberBetweenInput from '../NumberBetweenInput';
import CustomDateInput from '../fields/CustomDateInput';
import BreedSelect from '../BreedSelect';

class AnimalFilter extends React.Component {

    componentWillUnmount() {
        this.props.resetAllFilters()
    }

    render() {
        const {resetAllFilters, ...rest} = this.props;

        return (
            <Filter {...rest}>
                <TextInput label={t("name")} source="name_contains" alwaysOn/>
                <TextInput label={t("chip_number")} source="chipNumber_contains"/>
                <TextInput label={t("tattoo_number")} source="tattooNumber_contains"/>

                {rest.user.role !== 'Owner' ? <TextInput label={t("owner")} source="owner.name_contains"/> : null}
                <FormDataConsumer source='breed.id' label={t('breed')}>
                    {({formData, ...rest}) => (
                        <ReferenceInput
                            key={this.props.filterValues && this.props.filterValues.animalType ? this.props.filterValues.animalType : ''}
                            resource='Breed'
                            label={t('breed')}
                            source="breed.id"
                            reference="Breed"
                            sort={{field: 'breedNameBg', order: 'ASC'}}
                        >
                            <BreedSelect
                                animalType={this.props.filterValues && this.props.filterValues.animalType ? this.props.filterValues.animalType : null}/>
                        </ReferenceInput>
                    )}
                </FormDataConsumer>
                <SelectInput
                    source="animalType"
                    label={t('animal_type')}
                    choices={[
                        {id: 'Dog', name: t('dog')},
                        {id: 'Cat', name: t('cat')},
                    ]}
                />
                <CustomDateInput source="birthDate" label={t('birth_date')} maxDate={new Date()}/>
                <NumberBetweenInput
                    label={t('weight')}
                    source="weight"
                    setFilters={rest.setFilters || null}
                    filterValues={this.props.filterValues || null}/>
            </Filter>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        resetAllFilters: () => dispatch(changeListParams('Animal', {filter: {}})),
    }
}

const ConnectedAnimalFilter = connect(undefined, mapDispatchToProps)(AnimalFilter);
export {ConnectedAnimalFilter as AnimalFilter};
