import React from 'react';
import { Create } from 'react-admin';
import { t } from 'i18next';
import CustomCreateToolbar from '../CustomCreateToolbar';
import { ClinicForm } from './ClinicForm';

export const ClinicCreate = props => (
  <Create {...props} redirect="list" title={t("clinics.title_create")}>
    <ClinicForm toolbar={<CustomCreateToolbar history={props.history} user={props.options.currentUser.role}/>}/>
  </Create>
);