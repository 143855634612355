import React from 'react';
import { t } from 'i18next';
import { Edit } from 'react-admin';
import EditFormTitle from '../EditFormTitle';
import { ProductGroupForm } from './ProductGroupForm';
import { CustomEditToolbar } from '../CustomEditToolbar';

export const ProductGroupEdit = props => (
  <Edit {...props} title={<EditFormTitle formName={t('product_group')} />}>
    <ProductGroupForm toolbar={<CustomEditToolbar user={props.options.currentUser.role}/>} />
  </Edit>
);
