import { SpeakerNotes } from '@material-ui/icons';
import { NutrientGroupCreate } from '../components/NutrientGroup/NutrientGroupCreate';
import { NutrientGroupEdit } from '../components/NutrientGroup/NutrientGroupEdit';
import { NutrientGroupList } from '../components/NutrientGroup/NutrientGroupList';

export const NutrientGroups = {
  icon: SpeakerNotes,
  create: NutrientGroupCreate,
  edit: NutrientGroupEdit,
  list: NutrientGroupList,
};
