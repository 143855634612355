import { EnergyRequirementDogList } from '../components/EnergyRequirementDog/EnergyRequirementDogList';
import { EnergyRequirementDogCreate } from '../components/EnergyRequirementDog/EnergyRequirementDogCreate';
import { EnergyRequirementDogEdit } from '../components/EnergyRequirementDog/EnergyRequirementDogEdit';
import { EnergyRequirementDogShow } from '../components/EnergyRequirementDog/EnergyRequirementDogShow';

export const EnergyRequirementDog = {
  list: EnergyRequirementDogList,
  create: EnergyRequirementDogCreate,
  edit: EnergyRequirementDogEdit,
  show: EnergyRequirementDogShow
};