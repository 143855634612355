import React from 'react';
import { Create } from 'react-admin';
import { t } from 'i18next';

import CustomCreateToolbar from '../CustomCreateToolbar';
import { StatusForm } from './StatusForm';

export const StatusCreate = props => (
  <Create {...props} title={t('statuses.title_create')}>
    <StatusForm toolbar={<CustomCreateToolbar user={props.options.currentUser.role}/>}/>
  </Create>
);