import React from 'react';
import { Toolbar } from 'react-admin';
import GenerateLabelButtons from '../components/Label/GenerateLabelButtons';

export const LabelSettingsToolbar = (props) => {
  const { hiddenNutrients, ...rest } = props;

  return (
    <Toolbar {...rest}>
      <GenerateLabelButtons {...props}/>
    </Toolbar>
  );
};
