import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import apolloClient from '../../initApollo';
import { GET_STATUS_NAME_BY_ID } from '../../customQueries';

const styles = theme => createStyles({
  link: {
    color: theme.palette.primary.main,
  },
});

class StatusNameTypography extends Component {
  constructor() {
    super();

    this.state = {
      status: ''
    }
  }

  componentDidMount() {
    const { record } = this.props;

    apolloClient.query({ query: GET_STATUS_NAME_BY_ID, variables: { id: record.status.id } })
      .then(response => {
        if(response.loading) return;

        this.setState({ status: response.data.status.nameBg })
      })
  }

  render() {
    const { classes, user } = this.props;

    return <Typography className={user === 'Admin' || user === 'ProductionManager' ? classes.link : null}>{this.state.status}</Typography>
  }
}

StatusNameTypography.propTypes = {
  user: PropTypes.string,
  classes: PropTypes.object,
  allowEmpty: PropTypes.bool,
  basePath: PropTypes.string,
  className: PropTypes.string,
  record: PropTypes.object,
  resource: PropTypes.string,
  translateChoice: PropTypes.bool
};

export default withStyles(styles)(StatusNameTypography);