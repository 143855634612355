import React, { Fragment } from 'react';
import classNames from 'classnames';
import Dropzone from 'react-dropzone';
import apolloClient from '../initApollo';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { Labeled, REDUX_FORM_NAME } from 'react-admin';
import DeleteFileButtonWithConfirmation from './DeleteFileButtonWithConfirmation';
import { DELETE_IMAGE, UPDATE_NODE_WITH_IMAGE, UPLOAD_FILE_TO_S3 } from '../customQueries';
import { t } from 'i18next';
import { customCrudCreate } from './customCrudCreate';

const styles = {
  dropZone: {
    background: '#efefef',
    cursor: 'pointer',
    padding: '1rem',
    marginTop: 15,
    textAlign: 'center',
    color: '#999',
  },
  preview: {
    margin: '0.5rem',
    'max-height': '10rem',
    display: 'block',
  },
  root: { width: '100%' },
  errorWrapper: { marginTop: '1em' },
};

class CustomImageInput extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      imageUrl: props.formData[props.source] || props.record[props.source]
    }
  }

  uploadFile = (acceptedFile, fileContent) => {
    const { resource, source, formData } = this.props;

    apolloClient.mutate({
      mutation: UPLOAD_FILE_TO_S3,
      variables: { fileName: acceptedFile.name, fileSize: acceptedFile.size, fileType: acceptedFile.type, fileContent }
    }).then(response => {
      if (response.loading) return;

      const { url } = response.data.uploadToS3;

      if(url !== '' && resource === 'Animal') {
        const variables = {id: this.props.record.id, imagePath: url};
        this.updateRelatedNode(UPDATE_NODE_WITH_IMAGE(resource, source), variables)
      } else {
        formData[source] = url;
        this.setState({imageUrl: url})
      }
    });
  };

  onDrop = acceptedFiles => {
    acceptedFiles.forEach((acceptedFile) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        this.uploadFile(acceptedFile, btoa(reader.result));
      };
      reader.readAsBinaryString(acceptedFile);
    });
  };

  updateRelatedNode = (mutation, variables) => {
    const { source, formData, createHistory, basePath, user } = this.props;

    apolloClient.mutate({mutation, variables}).then(response => {
      if (response.loading) return;

      formData[source] = variables.imagePath;
      this.setState({imageUrl: variables.imagePath});

      const history = {
        user,
        date: new Date(),
        animal: variables.id,
        data: {
          [source]: {
            oldValue: null,
            newValue: variables.imagePath
          }
        }
      };

      createHistory('AnimalHistory', history, basePath, null);
    });
  };

  deleteImage = ({resource, source, record}) => {
    const { formData, createHistory, basePath, user } = this.props;

    formData[source] = null;
    this.setState({imageUrl: null});

    if(record) {
      apolloClient.mutate({mutation: DELETE_IMAGE(resource, source), variables: {id: record.id}})
        .then(response => {
          if (response.loading) return;

          if (resource === 'Animal') {
            const history = {
              user,
              date: new Date(),
              animal: record.id,
              data: {
                [source]: {
                  oldValue: record[source],
                  newValue: null
                }
              }
            };

            createHistory('AnimalHistory', history, basePath, null);
          }
        });
    }
  };

  render() {
    const {
      accept,
      classes = {},
      disableClick,
      id,
      maxSize,
      minSize,
      options = {},
      label,
      source,
      resource,
      record,
      formData,
      isVisible
    } = this.props;

    if (formData && formData[source]) {
      return (
        <div className={isVisible !== undefined && !isVisible ? "hidden" : "visible"}>
          <Labeled label={label} >
            <Fragment>
              <img id={this.state.imageUrl} src={this.state.imageUrl} alt={label} className={classes.preview}/>
              <DeleteFileButtonWithConfirmation
                source={source}
                resource={resource}
                record={record}
                callback={this.deleteImage.bind(this)}
                label={t('delete_image_button.delete_image')}
                message={t('delete_image_confirmation.confirm_message')}
              />
            </Fragment>
          </Labeled>
        </div>
      )
    }

    return (
      <div className={isVisible !== undefined && !isVisible ? "hidden" : "visible"}>
        <Labeled label={label} >
          <Dropzone
            onDrop={this.onDrop}
            accept={accept}
            disableClick={disableClick}
            maxSize={maxSize}
            minSize={minSize}
            multiple={false}
            className={classes.dropZone}
            {...options}
            inputProps={{ id, ...options.inputProps }}
          >
            {({getRootProps, getInputProps, isDragActive, rejectedFiles}) => {
              const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;
              const acceptedFileTypes = accept.split(',');
              const hasProperFileType = rejectedFiles.length > 0 && acceptedFileTypes.includes(rejectedFiles[0].type);

              return (
              <div>
                <div
                  style={styles.dropZone}
                  {...getRootProps()}
                  className={classNames('dropzone', {'dropzone--isActive': isDragActive})}
                >
                  <input {...getInputProps()} />
                  {
                    isDragActive ?
                    <p>{t('image_input.drop_image')}</p> :
                    <p>{t('image_input.drop_image_or_click')}</p>
                  }
                </div>
                {isFileTooLarge && (
                  <div className={classes.errorWrapper}>
                    <p className="required-field-error">{t('image_input.too_large')}</p>
                  </div>
                )}
                {rejectedFiles.length && !hasProperFileType ? (
                  <div className={classes.errorWrapper}>
                    <p className="required-field-error">{t('image_input.not_accepted')}</p>
                  </div>
                ) : null}
              </div>
              )
            }}
          </Dropzone>
        </Labeled>
      </div>
    );
  }
}

CustomImageInput.propTypes = {
  source: PropTypes.string,
  classes: PropTypes.object,
  label: PropTypes.string,
  record: PropTypes.object,
  resource: PropTypes.string.isRequired,
  id: PropTypes.string,
  accept: PropTypes.string,
  maxSize: PropTypes.number
};

const mapFormStateToProps = state => {
  let formData = null;
  let form = state.form[REDUX_FORM_NAME];
  if (form) {
    formData = form.values;
  }

  return { formData };
};

export default compose(
  connect(mapFormStateToProps, { createHistory: customCrudCreate }),
  withStyles(styles)
)(CustomImageInput);
