import React from 'react';
import { List, Datagrid, TextField } from 'react-admin';
import { t } from 'i18next';

import { CustomListActions } from '../CustomListActions';

export const StatusList = props => (
  <List {...props} bulkActionButtons={false} actions={<CustomListActions/>} title={t('statuses.title_list')}>
    <Datagrid rowClick='edit'>
      <TextField source='nameBg' label={t('name_bg')} />
      <TextField source='nameEn' label={t('name_en')} />
    </Datagrid>
  </List>
);
