import React, { Component, Fragment } from 'react';
import { t } from 'i18next';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {
  GET_ALL_OWNERS,
  GET_ALL_STATUSES,
  GET_ALL_TASTES, GET_ALL_VETS,
  GET_HISTORY,
  GET_ORDER_HISTORY_RECORDS,
  GET_ALL_ANIMALS,
  GET_ALL_CLINICS,
  GET_ALL_BREEDS
} from '../../customQueries';
import apolloClient from '../../initApollo';

import { translateProp, getObjectProperty, formatDateTime } from '../../utils/Helper';
import { NotesDatagrid } from './Notes';

class OrderHistory extends Component {
  ageGroups = ['Puppy', 'Kitten', 'Junior', 'Adult', 'Prime', 'Mature', 'Senior', 'Geriatric', 'YoungAdult', 'MatureAdult'];
  constructor(props){
    super(props);

    this.state = {
      records: [],
      statuses: [],
      tastes: [],
      owners: [],
      vets: [],
      animals: [],
      breeds: [],
      clinics: [],
      createdOrder: null
    };
  }

  componentWillMount() {
    let key = `Order-${this.props.record.id}`;

    const createdOrder = apolloClient.query({ query: GET_HISTORY, variables: { key } }).then(response => {
      if (response.loading) return;

      let records = response.data.histories;
      let createdOrder = records.filter(order => isEmpty(order.oldData)).pop();

      this.setState({ createdOrder })
    });

    const statuses = apolloClient.query({ query: GET_ALL_STATUSES }).then(response => {
      if (response.loading) return;
      this.setState({ statuses: response.data.statuses })
    });

    const tastes = apolloClient.query({ query: GET_ALL_TASTES }).then(response => {
      if (response.loading) return;
      this.setState({ tastes: response.data.tastes })
    });

    const owners = apolloClient.query({ query: GET_ALL_OWNERS }).then(response => {
      if (response.loading) return;
      this.setState({ owners: response.data.owners })
    });

    const vets = apolloClient.query({ query: GET_ALL_VETS }).then(response => {
      if (response.loading) return;
      this.setState({ vets: response.data.vets })
    });

    const animals = apolloClient.query({ query: GET_ALL_ANIMALS }).then(response => {
      if (response.loading) return;
      this.setState({ animals: response.data.animals })
    });

    const breeds = apolloClient.query({ query: GET_ALL_BREEDS }).then(response => {
      if (response.loading) return;
      this.setState({ breeds: response.data.breeds })
    });

    const clinics = apolloClient.query({ query: GET_ALL_CLINICS }).then(response => {
      if (response.loading) return;
      this.setState({ clinics: response.data.clinics })
    });

    Promise.all([createdOrder, statuses, tastes, owners, vets, animals, breeds, clinics]).then(() => {
      this.formatHistory(this.props.record.id)
    });
  }

  formatHistory = orderId => {
    apolloClient.query({query: GET_ORDER_HISTORY_RECORDS, variables: { orderId }, fetchPolicy: 'no-cache'}).then(response => {
      let historyRecords = response.data.orderHistories;
      let data = [];

      historyRecords.forEach(record => {
        Object.entries(record.data).forEach(([property, values]) => {
          let oldValue = values.oldValue;
          let newValue = values.newValue;

          if(oldValue !== null && typeof oldValue === 'object') {
            // for some properties we want to show name or something else instead of id
            // to be sure we display the current name we need to make a query to get this property
            oldValue = this.getValue(property, oldValue);
          }

          if(newValue !== null && typeof newValue === 'object') {
            // for some properties we want to show name or something else instead of id
            // to be sure we display the current name we need to make a query to get this property
            newValue = this.getValue(property, newValue);
          }

          data.push({
            date: formatDateTime(record.date),
            user: record.user,
            property: property,
            oldValue: oldValue,
            newValue: newValue
          });
        })
      });

      this.setState({ records: data })
    });
  };

  getValue = (key, value) => {
    switch(key) {
      case 'status':
        return getObjectProperty(value, this.state.statuses, 'nameBg');
      case 'taste':
        return getObjectProperty(value, this.state.tastes, 'name');
      default:
        return value;
    }
  };

  getJSONValues = (items, property) => {
    if(items && items.length > 0) {
      return <NotesDatagrid data={items} textCellName={t(translateProp(`order_details.${property}`))} property={property}/>;
    }

    return t('null');
  };

  getCreatedOrderData = () => {
    const { createdOrder, tastes, statuses, owners, vets, clinics, animals, breeds } = this.state;
    if(!createdOrder || !vets || !owners || !clinics || !animals || !breeds) return;

    let data = this.state.createdOrder.newData;

    let taste = data.taste && data.taste.id ? getObjectProperty(data.taste, tastes, 'name') : null;
    let status = data.status && data.status.id ? getObjectProperty(data.status, statuses, 'nameBg') : null;
    let owner = data.owner && data.owner.id ? getObjectProperty(data.owner, owners, 'name') : null;
    let vet = data.vet && data.vet.id ? getObjectProperty(data.vet, vets, 'name') : null;
    let clinic = data.clinic && data.clinic.id ? getObjectProperty(data.clinic, clinics, 'name') : null;
    let animal = data.animal && data.animal.id ? getObjectProperty(data.animal, animals, 'name') : null;
    let breed = data.breed && data.breed.id ? getObjectProperty(data.breed, breeds, 'breedNameBg') : null;

    let animalType = data.animalType ? t(`${data.animalType.toLowerCase()}`) : null;
    let sizeClass = data.sizeClass ? t(`size_classes.${data.sizeClass.toLowerCase()}`) : null;

    let ageGroup = data.ageGroupName && this.ageGroups.includes(data.ageGroupName) ? t(`age_group.${data.ageGroupName.toLowerCase()}`) : null;

    let translatedOrderType = translateProp(this.state.createdOrder.newData.orderType);
    let orderType = data.orderType === 'VIP' ? 'VIP' : translatedOrderType.substring(1, translatedOrderType.length);

   return { data, taste, status, owner, vet, clinic, animal, breed, animalType, ageGroup, sizeClass, orderType };
  };

  render() {
    const createdOrderData = this.getCreatedOrderData();

    return (
      <Fragment>
        { createdOrderData &&
          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
              <Typography>
                {t('order_details.created_order_message_history', {user: this.state.createdOrder.user})} {formatDateTime(this.state.createdOrder.date)} {t('order_details.with_following_data')}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                {t('number')}: {createdOrderData.data.idNumber}<br/>
                {t('status')}: {createdOrderData.status || null}<br/>
                {t('weight')}: {createdOrderData.data.weight}<br/>
                {t('quantity')}: {createdOrderData.data.quantity}<br/>
                {t('price')}: {createdOrderData.data.price}<br/>
                {t('order_details.order_type')}: {t(`order_details.${createdOrderData.orderType}`)}<br/>
                {createdOrderData.owner ? `${t('owner')}: ${createdOrderData.owner || null}` : null}
                {createdOrderData.owner ? <br/> : null}
                {t('vet')}: {createdOrderData.vet || null}<br/>
                {t('clinic_name')}: {createdOrderData.clinic || null}<br/>
                {createdOrderData.animal ? `${t('animal')}: ${createdOrderData.animal || null}` : null}
                {createdOrderData.animal ? <br/> : null}
                {t('breed')}: {createdOrderData.breed || null}<br/>
                {t('pet_type')}: {createdOrderData.animalType || null}<br/>
                {t('age_group.age_group')}: {createdOrderData.ageGroup || null}<br/>
                {t('sizeClass')}: {createdOrderData.sizeClass || null}<br/>
                {createdOrderData.taste ? `${t('taste')}: ${createdOrderData.taste || null}` : null}<br/>
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        }
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('property')}</TableCell>
              <TableCell>{t('old_value')}</TableCell>
              <TableCell>{t('new_value')}</TableCell>
              <TableCell>{t('date_of_change')}</TableCell>
              <TableCell>{t('user')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              Object.entries(this.state.records).map(([key, value]) => {
                const translated_property = translateProp(value.property);
                const oldValue = typeof value.oldValue === 'object' ? this.getJSONValues(value.oldValue, value.property.substring(0, value.property.length-1)) : t(`${value.oldValue}`);
                const newValue = typeof value.newValue === 'object' ? this.getJSONValues(value.newValue, value.property.substring(0, value.property.length-1)) : t(`${value.newValue}`);
                return (
                  <TableRow key={key}>
                    <TableCell>{t(`${translated_property}`)}</TableCell>
                    <TableCell>{oldValue}</TableCell>
                    <TableCell>{newValue}</TableCell>
                    <TableCell>{value.date}</TableCell>
                    <TableCell>{t(`${value.user.toLowerCase()}`)}</TableCell>
                  </TableRow>
                );
              })
            }
          </TableBody>
        </Table>
      </Fragment>
    );
  }
}

export default OrderHistory;
