const translations = {
  translation: {
    'title': 'OptimusWeb',
    'orders': 'Orders',
    'summary_tab': 'Summary',
    'data_tab': 'Data',
    'name': 'Name',
    'code_column': 'Code',
    'phone_number': 'Phone number',
    'city': 'City',
    'postcode': 'Postcode',
    'email': 'Email',
    'materials': 'Materials',
    'material': 'Material',
    'quantity': 'Quantity',
    'unit': 'Unit',
    'clinic_name': 'Clinic',
    'required_field': 'Required',
    'require_geo_latitude': 'Must be a valid latitude coordinate',
    'require_geo_longitude': 'Must be a valid longitude coordinate',
    'natural_number': 'Must be a valid natural number',
    'loading': 'Loading',
    'ingredients': 'Label texts',
    'owner': 'Owner',
    'municipality': 'Municipality',
    'province': 'Province',
    'order': 'Order',
    'nutrient': 'Nutrient',
    'product_group': 'Product group',
    'nutrient_group': 'Nutrient Group',
    'nutrient_element': 'Nutrient element',
    'files': 'Files',
    'city_search_input_field': 'Select location...',
    'search_input_field': 'Search...',
    'vet': 'Vet',
    'recipe': 'Recipe',
    'status': 'Status',
    'date_created': 'Date created',
    'animal_type': 'Animal type',
    'weight': 'Weight',
    'weight_from': 'Weight from',
    'weight_to': 'Weight to',
    'moisture': 'Moisture',
    'gender': 'Gender',
    'chip_number': 'Microchip number',
    'tattoo_number': 'Tattoo number',
    'dog': 'Dog',
    'cat': 'Cat',
    'male': 'Male',
    'female': 'Female',
    'yes': 'Yes',
    'no': 'No',
    'castrated': 'Castrated',
    'comment': 'Comment',
    'municipality_select': 'Mun.',
    'province_select': 'Prov.',
    'village_select': 'village',
    'city_select': 'city',
    'location_type': 'Location type',
    'no_results_found': 'No results found',
    'loading_select': 'Loading...',
    'addresses_tab': 'Addresses',
    'consulting_fee': 'Consulting fee',
    'history': 'History',
    'breed': 'Breed',
    'breed_father': 'Breed of father',
    'breed_mother': 'Breed of mother',
    'birth_date': 'Birth date',
    'profile_picture': 'Photo',
    'property': 'Property',
    'old_value': 'Old value',
    'new_value': 'New value',
    'date_of_change': 'Date of change',
    'user': 'User',
    'age': 'Age',
    'years': 'y',
    'years_without_dot': 'y',
    'months': 'm',
    'months_without_dot': 'm',
    'and': 'and',
    'select': 'Select',
    'material_confirmation_message': 'The sum of nutrients and moisture percentages is not 100.',
    'null': '-',
    'general_section': 'General',
    'digestive_system_condition_section': 'Digestive system condition',
    'activity_section': 'Activity',
    'feeding_section': 'Feeding',
    'breeding_section': 'Breeding',
    'withers_height': 'Withers height',
    'coat_type': 'Coat type',
    'hair_structure': 'Hair structure',
    'coat_color': 'Coat color',
    'skin_condition': 'Skin condition',
    'teeth': 'Teeth',
    'gums': 'Gums',
    'tongue_and_mucous_membranes': 'Tongue and mucous membranes',
    'esophagus': 'Esophagus',
    'stomach': 'Stomach',
    'intestines': 'Intestines',
    'excrement_consistency': 'Excrement consistency',
    'solid_and_friable': 'Solid and friable',
    'solid_and_shaped': 'Solid and shaped',
    'shaped': 'Shaped',
    'soft_and_shaped': 'Soft and shaped',
    'soft_and_unshaped': 'Soft and unshaped',
    'liquid': 'Liquid',
    'living_place': 'Living place',
    'home_inside': 'Home (inside)',
    'home_outside': 'Home (outside)',
    'daily_walk_duration': 'Daily walk duration (min.)',
    'walk_intensity': 'Walk intensity',
    'low_intensity': 'Low',
    'medium_intensity': 'Medium',
    'high_intensity': 'High',
    'hunt': 'Hunt',
    'underground': 'Underground',
    'birds': 'Birds',
    'chasing': 'Chasing',
    'sport': 'Sport',
    'IPO': 'IPO',
    'agility': 'Agility',
    'other': 'Other',
    'other_sport': 'Other sport',
    'work_usage': 'Work usage',
    'work_type': 'Work type',
    'food_brand': 'Food brand',
    'homemade_food': 'Homemade food',
    'appetite': 'Appetite',
    'low_appetite': 'Low',
    'medium_appetite': 'Normal',
    'high_appetite': 'High',
    'preferred_taste': 'Preferred taste',
    'suspected_food_allergy': 'Suspected food allergy',
    'identified_food_allergy': 'Identified food allergy',
    'joints_and_ties_condition': 'Joints and ties condition',
    'breeding': 'Breeding',
    'fertilization_date': 'Fertilization date',
    'giving_birth_date': 'Giving birth date',
    'size_class': 'Size class',
    'price': 'Price',
    'percent_per_kg': '%/kg',
    'add_button': 'Add',
    'copy_recipe': 'Copy existing recipe',
    'copy': 'Copy',
    'continue': 'Continue anyway',
    'copy_recipe_dialog_message': 'Unsaved data from the current recipe will be lost',
    'warning_message_recipe': 'The profile of the pet was edited after creation of the order.',
    'lv': 'lv',
    'kg': 'kg',
    'g': 'g',
    'true': 'Yes',
    'false': 'No',
    'from': 'from',
    'to': 'To',
    'none': '-',
    'number': 'Number',
    'age_in_months': 'Age in months',
    'name_bg': 'Name in Bulgarian',
    'name_en': 'Name in English',
    'active': 'Active',
    'animal': 'Animal',
    'address': 'Address',
    'taste': 'Taste',
    'comments': 'Comments',
    'vet_notes': 'Vet notes',
    'owner_notes': 'Owner notes',
    'notes': 'Notes',
    'encyclopedia': 'Encyclopedia',
    'short_encyclopedia': 'Short Encyclopedia',
    'ui_in_mg': 'UI in one milligram',
    'unique_constraint_message': 'This email is already in use',
    'logout': 'Logout',
    'admin': 'Admin',
    'productionmanager': 'Production manager',
    'clinic': 'Clinic manager',
    'food_price': 'Food price',
    'normal_food': 'Normal food',
    'food_by_breed': 'Food by breed',
    'price_in_lev_currency': 'Price /lv',
    'with_recipe': 'With recipe',
    'without_recipe': 'Without recipe',
    'pet_type': 'Animal type',
    'dogs': 'dogs',
    'cats': 'cats',
    'street': 'Street',
    'street_number': 'Street number',
    'building_number': 'Building number',
    'district': 'District',
    'entrance': 'Entrance',
    'floor': 'Floor',
    'apartment_number': 'Apartment number',
    'notifications': 'Receive Email',
    'incorrect_password': 'Incorrect username or password',
    'change_password_success': 'Successfully changed password',
    'incorrect_cognito_password': 'The password should contain at least 8 characters and must include at least one uppercase letter, lowercase letter, number and special character',
    'main_menu': {
      'owners': 'Owners',
      'animals': 'Animals',
      'clinics': 'Clinics',
      'vets': 'Vets',
      'countries': 'Countries',
      'cities': 'Cities',
      'municipalities': 'Municipalities',
      'provinces': 'Provinces',
      'nutrient_elements': 'Nutrient Elements',
      'nutrient_groups': 'Nutrient Groups',
      'nutrients': 'Nutrients',
      'product_groups': 'Product Groups',
      'units': 'Units',
      'recipes': 'Recipes',
      'breeds': 'Breeds',
      'size_class_mix': 'Size class mix',
      'age_group_dog': 'Age groups dogs',
      'age_group_cat': 'Age groups cats',
      'taste': 'Taste food',
      'statistics': 'Statistics',
      'dashboard': 'Dashboard',
      'settings': 'Settings',
      'admins': 'Admins',
      'production_managers': 'Production managers'
    },
    'owners': {
      'title_list': 'Оwners List',
      'title_create': 'Create Owner',
      'pets': 'Pets',
      'inactive_owner_confirm': 'This owners is inactive, do you want to continue?',
      'owner_has_pet_pop_up': 'Owner that has a pet can\'t be deleted.'
    },
    'animals': {
      'title_list': 'Animals List',
      'title_create': 'Create animal',
    },
    'clinics': {
      'title_list': 'Clinics List',
      'title_create': 'Create clinic',
      'latitude': 'Географска ширина',
      'longitude': 'Географска дължина',
      'location': 'Местоположение',
      'active': 'Active',
      'has_vets_pop_up': 'Clinic with vets can\'t be deleted.',
      'inactive_clinic_pop_up': 'The chosen clinic is inactive. Do you want to continue?'
    },
    'vets': {
      'title_list': 'Vets List',
      'title_create': 'Create Vet',
      'statistics': 'Statistics',
      'vet_has_order_pop_up': 'Vet that has an orders can\'t be deleted.',
      'inactive_vet_pop_up': 'The chosen vet is inactive. Do you want to continue?'
    },
    'countries': {
      'title_list': 'Countries List',
      'title_create': 'Create country',
      'iso_code_column': 'ISO code',
      'country': 'Country'
    },
    'cities': {
      'title_list': 'Cities List',
      'title_create': 'Create city',
    },
    'municipalities': {
      'title_list': 'Municipalities List',
      'title_create': 'Create municipality',
    },
    'provinces': {
      'title_list': 'Provinces List',
      'title_create': 'Create province',
    },
    'nutrient_elements': {
      'title_list': 'Nutrient elements List',
      'title_create': 'Create nutrient element',
    },
    'nutrient_groups': {
      'title_list': 'Nutrient Groups List',
      'title_create': 'Create Nutrient Group',
    },
    'nutrients': {
      'title_list': 'Nutrients List',
      'title_create': 'Create Nutrient',
    },
    'product_groups': {
      'title_list': 'Product Groups List',
      'title_create': 'Create product group',
    },
    'units': {
      'title_list': 'Units List',
      'title_create': 'Create Unit',
      'coefficient_per_kilogram_column': 'Coefficient per Kilogram',
    },
    'ingredient_details': {
      'title_list': 'Label texts list',
      'title_create': 'Create label text',
      'human_usable': 'Is human usable',
    },
    'material_details': {
      'title_list': 'Materials List',
      'title_create': 'Create Material',
      'moisture_percent': 'Moisture, %',
      'price_unit': 'Price, lv/kg',
      'nutrient_content': 'Nutrient contains',
      'percent': '%',
      'mg': 'mg/kg',
      'μg': 'μg/kg',
      'UI': 'UI/kg',
      'ppm': 'ppm',
      'mix': 'Mix',
    },
    'recipes': {
      'title': 'Recipe for {{name}} ({{type}} {{breed}})',
      'title_clinic_order': 'Recipe for {{type}}, {{detail}}, {{ageGroup}}',
      'title_create': 'Create recipe',
      'high_quality_animal_protein': 'High quality animal protein',
      'weight_per_bowl': 'Volume weight',
      'best_before': 'Best before',
      'additional_settings': 'Additional settings'
    },
    'daily_dose': {
      'title': 'Daily food dose',
      'dose_value': 'Daily dose in grams',
      'days_will_last': 'Days the food will be enough for',
      'adjustment_coefficient': 'Adjustment coefficient',
    },
    'breeds': {
      'title_list': 'Pet breeds list',
      'title_create': 'Create pet breed',
      'price_coefficient': 'Coefficient',
      'supported_by_optimus': 'Supported by Optimus',
      'mix_breed': 'Mix',
    },
    'size_class_mix': {
      'title': 'Size class mix',
      'title_list': 'Size class mix List',
      'title_create': 'Create size class mix',
      'mini_kg': 'Mini /max kg',
      'small_kg': 'Small /max kg',
      'medium_kg': 'Medium /max kg',
      'large_kg': 'Large /max kg',
      'giant_kg': 'Giant /max kg',
    },
    'age_group': {
      'age_group': 'Age group',
      'dog_title_create': 'Add age group for dogs',
      'dog_title': 'Age group for dogs',
      'dog_title_list': 'Age groups dogs List',
      'cat_title_create': 'Add age group for cats',
      'cat_title': 'Age group for cats',
      'cat_title_list': 'Age groups cats List',
      'puppy': 'Puppy',
      'kitten': 'Kitten',
      'junior': 'Junior',
      'adult': 'Adult',
      'prime': 'Prime',
      'mature': 'Mature',
      'senior': 'Senior',
      'geriatric': 'Geriatric',
      'youngadult': 'Young Adult',
      'matureadult': 'Mature Adult',
      'price_by_weight': 'Price by weight kg-lv',
      'price_weight_pair': 'Price by weight',
      'price_by_weight_VIP': 'Price by weight VIP kg-lv',
      'weight': 'Weight /kg',
      'price': 'Price /lv for kg'
    },
    'size_classes': {
      'mini': 'Mini',
      'small': 'Small',
      'medium': 'Medium',
      'large': 'Large',
      'giant': 'Giant',
    },
    'consulting_fee_details': {
      'consulting_fee_percentage_vet': 'Consulting fee percentage - Vet',
      'consulting_fee_percentage_clinic': 'Consulting fee percentage - Clinic',
      'consulting_fee_percentage_create_vet': 'Add consulting fee percentage - Vet',
      'consulting_fee_percentage_create_clinic': 'Add consulting fee percentage - Clinic',
      'weekly_turnover_to': 'Weekly turnover to /lv',
      'consulting_fee_percentage': 'Percentage',
      'week_number': 'Week number',
      'week': 'Week',
      'weekly_turnover': 'Weekly turnover (BGN)',
      'weekly_turnover_clinic': 'Weekly turnover of clinic (BGN)',
      'consulting_fee': 'Consulting fee (BGN)',
      'consulting_fee_clinic': 'Clinics\' consulting fee',
    },
    'order_details': {
      'title_list': 'Orders List',
      'title_create': 'Create order',
      'order_type': 'Order type',
      'VIP': 'VIP',
      'normal': 'Normal',
      'by_breed': 'By breed',
      'quantity': 'Quantity',
      'price': 'Total price',
      'add_comment': 'Add comment',
      'comment': 'Comment',
      'note': 'Note',
      'owner_note': 'Owner note',
      'vet_note': 'Vet note',
      'add_note': 'Add note',
      'add_owner_note': 'Add owner note',
      'add_vet_note': 'Add vet note',
      'created_order_message_history': 'This order was created from user {{user}} on',
      'with_following_data': 'with the following data:',
      'tab_order': 'Standard order',
      'tab_clinic_order': 'Clinic order',
      'unfinished_order_title': 'Unfinished order',
      'unfinished_order_message': 'Your order is not finished. Entered data will be lost. Do you want to proceed?'
    },
    'country_import': {
      'import_country_data_message': 'Import provinces, municipalities and cities.',
      'upload_button': 'Upload',
      'delete_button': 'Delete All Cities',
    },
    'delete_confirmation': {
      'dialog_title': 'Confirmation',
      'confirm_message_sg': 'Are you sure you want to delete this item?',
      'confirm_message_pl': 'Are you sure you want to delete these items?',
    },
    'delete_image_confirmation': {
      'confirm_message': 'Are you sure you want to delete this image?',
    },
    'delete_image_button': {
      'delete_image': 'Delete image',
    },
    'delete_file_confirmation': {
      'confirm_message': 'Are you sure you want to delete this file?',
    },
    'delete_file_button': {
      'delete_file': 'Delete file',
    },
    'file_input': {
      'drop_files': 'Drop files here.',
      'drop_files_or_click': 'Drop files here, or click to select files to upload.'
    },
    'image_input': {
      'drop_image': 'Drop images here.',
      'drop_image_or_click': 'Drop images here, or click to select images to upload',
      'too_large': 'File is too large.',
      'not_accepted': 'File type not accepted.',
    },
    'moisture_details': {
      'total_moisture': 'Total moisture',
      'target_moisture': 'Target moisture',
      'moisture_to_remove': 'Moisture to remove',
      'total_dry_matter': 'Total dry matter',
      'target_dry_matter': 'Target dry matter',
    },
    'energy': {
      'metabolic_energy': 'Metabolic energy',
      'first_method': 'First method',
      'second_method': 'Second method',
      'subtitle_message': 'Please select value for the label.',
      'calculated': 'calculated',
    },
    'label_settings': {
      'label': 'Label',
      'generate_label_button': 'Generate label',
      'button_link_front': 'Front label',
      'button_link_back': 'Back label',
      'confirm_overwrite_message': 'Labels for this order already exist, please confirm that new ones are to be generated',
      'confirm_overwrite_label': 'Confirm',
      'typical_analysis': 'Typical analysis',
      'vitamins_and_minerals': 'Vitamins and minerals',
      'digital_label': 'Digital Label',
      'tastes_like': 'Tastes like',
      'daily_dose': 'Daily dose',
      'weight': 'Weight',
      'cup': 'Cup',
      'weight_in_grams': 'Weight in grams',
      'optimus_cup': 'OPTIMUS cup',
      'optimus_cups': 'OPTIMUS cups',
      'moisture': 'Moisture [%] max'
    },
    'repeat_order': {
      'button_title': 'Repeat order',
      'repeat_order': 'Repeat order',
      'dialog_message': 'Please check that all your animal data is still up to date',
      'short_label': 'Repeat'
    },
    'tastes': {
      'title_list': 'Tastes List',
      'title_create': 'Create taste',
      'picture': 'Picture',
      'picture_required': 'Picture is required'
    },
    'energy_requirement_dog': {
      'title_create': 'Create metabolic energy',
      'energy': 'Energy',
      'days': 'Days',
    },
    'exchange_weight': {
      'live_weight_to': 'Live weight to /kg',
      'exchange_weight': 'Exchange weight',
      'exchange_weight_kg': 'Exchange weight /kg',
      'title_create': 'Create exchange weight'
    },
    'food_running_out': {
      'food_running_out': 'Food running out',
      'running_out_date': 'Date',
      'seen': 'Seen'
    },
    'statuses': {
      'title_list': 'Statuses List',
      'title_create': 'Create status',
      'statuses': 'Statuses',
    },
    'statuses_workflow': {
      'workflow': 'Workflow',
      'create_title': 'Create workflow',
      'status_from': 'From status',
      'status_to': 'To status',
      'send_emails_to': 'Send email to ',
      'admin': 'Admin',
      'production_manager': 'Production Manager',
      'clinic': 'Clinic',
      'vet': 'Vet',
      'owner': 'Owner',
      'role': 'Role',
      'template': 'Template'
    },
    'email_templates': {
      'templates': 'Email templates',
      'template': 'Email template',
      'title_create': 'Create email template'
    },
    'statistics': {
      'title': 'Statistics',
      'total_owners': 'Total owners',
      'total_orders': 'Total orders',
      'total_vets': 'Total vets',
      'total_revenue': 'Total revenue',
      'orders_by_breed': 'Total orders by breed',
      'new_clients_current_mt': 'New owners this month',
      'orders_last_mt': 'Orders last month',
      'vet_orders': 'Clinic orders',
      'revenue_last_mt': 'Revenue last month',
      'total_ingredient_value': 'Total ingredient value',
      'orders_by_animal_type': 'Total orders dogs / cats',
      'revenue_by_animal_type': 'Revenue dogs / cats',
      'total_production': 'Total production',
      'orders_new_repeat': 'Orders new / repeat',
      'rev_by_size_class': 'Revenue by size class',
      'this_month': 'this month',
      'based_on_last_mt': 'based on previous month',
      'based_on_avg': 'based on average',
      'in_kg': 'in kg',
      'based_on_time_filter': 'based on time filter',
      'no_data': 'No data'
    },
    'profile': {
      'title': 'Profile',
      'change_password': 'Change password',
      'old_password': 'Old password',
      'new_password': 'New password',
      'repeat_new_password': 'Repeat new password',
      'save': 'Save',
      'user_info': 'Details'
    },
    'admins': {
      'title_create': 'Create admin',
      'title_list': 'Admins list'
    },
    'production_managers': {
      'title_create': 'Create production manager',
      'title_list': 'Production managers list'
    }
  }
};

export default translations;
