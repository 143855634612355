import React from 'react';
import { t } from 'i18next';
import TextField from '@material-ui/core/TextField';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';

const Notes = props => {
  return props.record.notes ? <NotesDatagrid data={props.record.notes} textCellName={t('order_details.note')} property='note'/> : null;
};

const OwnerNotes = props => {
  return props.record.ownerNotes ? <NotesDatagrid data={props.record.ownerNotes} textCellName={t('order_details.owner_note')} property='ownerNote'/> : null;
};

const VetNotes = props => {
  return props.record.vetNotes ? <NotesDatagrid data={props.record.vetNotes} textCellName={t('order_details.vet_note')} property='vetNote'/> : null;
};

export const NotesDatagrid = ({data, textCellName, property}) => {
  return data && (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{t('user')}</TableCell>
          <TableCell>{textCellName}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          data.map((details, index) => {
            return (
              <TableRow key={index}>
                <TableCell>{t(`${details.user.toLowerCase()}`)}</TableCell>
                <TableCell><TextField fullWidth={true} InputProps={{multiline: true, disableUnderline: true, readOnly: true}} value={details[property]}/></TableCell>
              </TableRow>
            );
          })
        }
      </TableBody>
    </Table>
  );
};

export { Notes, OwnerNotes, VetNotes };