import { Gesture } from '@material-ui/icons';
import { MaterialCreate } from '../components/Material/MaterialCreate';
import { MaterialEdit } from '../components/Material/MaterialEdit';
import { MaterialList } from '../components/Material/MaterialList';
import { MaterialShow } from '../components/Material/MaterialShow';

export const Materials = {
  icon: Gesture,
  create: MaterialCreate,
  edit: MaterialEdit,
  list: MaterialList,
  show: MaterialShow,
};
