import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  SelectField,
  BooleanField
} from 'react-admin';
import { t } from 'i18next';

import { CustomBulkActions } from '../CustomBulkActions';
import { CustomListActions } from '../CustomListActions';
import { CityFilter } from './CityFilter';

export const CityList = props => (
  <List
      filters={<CityFilter />}
      bulkActionButtons={<CustomBulkActions />}
    actions={<CustomListActions />}
    title={t('cities.title_list')}
    {...props}
  >
    <Datagrid rowClick="show">
      <TextField source="name" label={t('name')} />
      <ReferenceField label={t('municipality')} reference="Municipality" source="municipality.id" allowEmpty={true} sortable={false}>
        <TextField source="name"/>
      </ReferenceField>
      <SelectField
        label={t('location_type')}
        source="type"
        optionValue="id"
        choices={[
          { id: 'City', name: t('city_select') },
          { id: 'Village', name: t('village_select') },
        ]}
      />
      <BooleanField label={t("city_is_main_province")} source="mainProvinceCity"/>
    </Datagrid>
  </List>
);
