import React, { Component, Fragment } from 'react';
import { Toolbar, SaveButton, Button, Link } from 'react-admin';
import { t } from 'i18next';
import CheckCircle from '@material-ui/icons/CheckCircle';
import ContentCopy from '@material-ui/icons/ContentCopy';
import { withStyles } from '@material-ui/core';

import ChipWithAvatar from '../fields/ChipWithAvatar';
import ConfirmationDialog from '../ConfirmationDialog';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';

const styles = {
  toolbar: {
    display: 'flex'
  },
  button: {
    marginLeft: '20px'
  },
  buttonLink: {
    marginLeft: '20px',
    color: 'white'
  }
};

class RecipeToolbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDialog: false
    }
  }

  handleCopyClick = () => {
    this.setState({ showDialog: true });
  };

  handleCloseClick = () => {
    this.setState({ showDialog: false });
  };

  handleSaveClick = () => {
    const { handleSubmit, createResource, redirect, push, basePath, resource } = this.props;

    return handleSubmit(values => {
      let newValues = Object.assign({}, values);
      let mq = newValues.materialQuantities;
      let newMQ = [];

      for (const i in mq) {
        if (Object.entries(mq[i]).length > 0 && mq[i].constructor === Object) {
          newMQ.push(mq[i]);
        }
      }

      newValues.materialQuantities = newMQ;
      createResource(resource, newValues, basePath, null);
      push(redirect)
    });
  }

  render() {
    const { createResource, materialQuantities, filters, classes, handleSubmitWithRedirect, push, ...props } = this.props;

    return (
      <Toolbar {...props} className={classes.toolbar}>
        <SaveButton
          handleSubmitWithRedirect={this.handleSaveClick}
          {...props}
        />
        <Fragment>
          {materialQuantities ?
            <Button
              variant="contained"
              size="medium"
              label={t('copy_recipe')}
              className={classes.button}
              onClick={this.handleCopyClick}
            >
              <ContentCopy />
            </Button>
            : <Button
              variant="contained"
              size="medium"
              label={t('copy_recipe')}
              className={classes.buttonLink}
              component={Link}
              to={{
                pathname: '/copyRecipe',
                state: {
                  filters,
                  order: props.record.orderId,
                  createResource: createResource
                }
              }}
            >
              <ContentCopy />
            </Button>
          }
          <ConfirmationDialog
            isOpen={this.state.showDialog}
            message={t('copy_recipe_dialog_message')}
            label={t('continue')}
            icon={<CheckCircle />}
            handleClose={this.handleCloseClick}
            cancelLabel='ra.action.cancel'
            buttonLink={true}
            pathname="/copyRecipe"
            state={{
              filters,
              order: props.record.orderId,
              createResource: createResource
            }}
          />
          {props.animal ? <ChipWithAvatar record={props.animal} blankTarget={true} /> : null}
        </Fragment>
      </Toolbar>
    );
  }
}

const styledRecipeToolbar = withStyles(styles)(RecipeToolbar);
export default connect(undefined, { push })(styledRecipeToolbar);
