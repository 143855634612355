import React from 'react';
import { List, Datagrid, NumberField } from 'react-admin';
import { t } from 'i18next';

import { CustomBulkActions } from '../CustomBulkActions';
import { CustomListActions } from '../CustomListActions';

export const SizeClassMixList = props => (
  <List
    sort={{ field: 'months', order: 'ASC' }}
    bulkActionButtons={<CustomBulkActions />}
    actions={<CustomListActions />}
    title={t('size_class_mix.title_list')}
    {...props}
  >
    <Datagrid rowClick="show">
      <NumberField source="months" label={t('age_in_months')}/>
      <NumberField source="mini" label={t('size_class_mix.mini_kg')}/>
      <NumberField source="small" label={t('size_class_mix.small_kg')}/>
      <NumberField source="medium" label={t('size_class_mix.medium_kg')}/>
      <NumberField source="large" label={t('size_class_mix.large_kg')}/>
      <NumberField source="giant" label={t('size_class_mix.giant_kg')}/>
    </Datagrid>
  </List>
);
