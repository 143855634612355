import React, { Component, Fragment } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Button } from 'react-admin';
import IconCancel from '@material-ui/icons/Cancel';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { PercentToUnit } from '../PercentToUnit';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  alignRight: {
    textAlign: 'right',
    paddingRight: '48px !important'
  }
});

class RecipeShowDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      animalType: null
    }
  }

  getAnimalDetails = (details) => {
    let animalType = null;
    return Object.entries(details).map(([animalKey, animalKeyValue]) => {
      switch (animalKey) {
        case 'animalType':
          animalType = animalKeyValue;
          return animalKeyValue ? <h4 key={animalKey}>{t('animal_type')}: {t(`${animalKeyValue.toLowerCase()}`)}</h4> : null;
        case 'breed':
          return animalKeyValue ? <h4 key={animalKey}>{t('breed')}: {animalKeyValue.breedNameBg}</h4> : null;
        case 'sizeClass':
          return animalKeyValue ? <h4 key={animalKey}>{t('size_class')}: {t(`size_classes.${animalKeyValue.toLowerCase()}`)}</h4> : null;
        case 'ageGroup':
          return animalKeyValue ? this.getAgeGroup(animalKey, animalType, animalKeyValue) : null;
        default:
          return null;
      }
    });
  };

  getAgeGroup = (animalKey, animalType, value) => {
    if (animalType === 'Dog') {
      let group = this.props.ageGroupDogs.filter(group => group.id === value).pop();
      return <h4 key={animalKey}>{t('age_group.age_group')}: {group.ageGroup}</h4>;
    } else if (animalType === 'Cat') {
      let group = this.props.ageGroupCats.filter(group => group.id === value).pop();
      return <h4 key={animalKey}>{t('age_group.age_group')}: {group.ageGroup}</h4>;
    }
  };

  getMaterials = (key, materials) => {
    const { allMaterials, units } = this.props;

    return (
      <Table key={key}>
        <TableHead>
          <TableRow>
            <TableCell>{t('material')}</TableCell>
            <TableCell>{t('quantity')}</TableCell>
            <TableCell>{t('unit')}</TableCell>
            <TableCell>{t('percent_per_kg')}</TableCell>
            <TableCell>{t('material_details.moisture_percent')}</TableCell>
            <TableCell>{t('material_details.price_unit')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {materials.map((material, index) => (
            <TableRow key={index}>
              <TableCell>{material.materialId ? allMaterials.filter(element => element.id === material.materialId).pop().name : ''}</TableCell>
              <TableCell>{material.quantity}</TableCell>
              <TableCell>{material.unitId ? units.filter(unit => unit.id === material.unitId).pop().name : ''}</TableCell>
              <TableCell>{material.percentPerKg ? `${material.percentPerKg} ${t('material_details.percent')}` : ''}</TableCell>
              <TableCell>{material.moisture}</TableCell>
              <TableCell>{material.price ? `${material.price} ${t('lv')}` : ''}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  getMoistureDetails = (key, moistureDetails) => {
    const { classes } = this.props;

    return (
      <Fragment key={key}>
        <h4>{t('moisture')}</h4>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>{t('moisture_details.target_moisture')}</TableCell>
              <TableCell className={classes.alignRight}>{moistureDetails.targetMoisture} g</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('moisture_details.total_moisture')}</TableCell>
              <TableCell className={classes.alignRight}>{moistureDetails.totalMoisture} g</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('moisture_details.moisture_to_remove')}</TableCell>
              <TableCell className={classes.alignRight}>{moistureDetails.moistureToRemove} g</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('moisture_details.total_dry_matter')}</TableCell>
              <TableCell className={classes.alignRight}>{moistureDetails.totalDryMatter} g</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('moisture_details.target_dry_matter')}</TableCell>
              <TableCell className={classes.alignRight}>{moistureDetails.targetDryMatter} g</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Fragment>
    );
  };

  getNutrients = (key, nutrients) => {
    const { units, classes } = this.props;

    return (
      <Fragment key={key}>
        <h4>{t('main_menu.nutrients')}</h4>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('nutrient')}</TableCell>
              <TableCell className={classes.alignRight}>{t('quantity')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {nutrients.map((nutrient, index) => (
              <Fragment key={index}>
                <TableRow>
                  <TableCell>{nutrient.name}</TableCell>
                  <TableCell className={classes.alignRight}>
                    <PercentToUnit percent={nutrient.percent} units={units} />
                  </TableCell>
                </TableRow>
                {nutrient.nutrientGroups.map((nutrientGroup, idx2) => (
                  <Fragment key={idx2}>
                    <TableRow>
                      <TableCell>-> {nutrientGroup.name}</TableCell>
                      <TableCell className={classes.alignRight}>
                        <PercentToUnit percent={nutrientGroup.percent} units={units} />
                      </TableCell>
                    </TableRow>
                    {nutrientGroup.nutrientElements.map((nutrientElement, idx3) => (
                      <TableRow key={idx3}>
                        <TableCell>-> -> {nutrientElement.name}</TableCell>
                        <TableCell className={classes.alignRight}>
                          <PercentToUnit percent={nutrientElement.percent} units={units} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </Fragment>
                ))}
              </Fragment>
            ))}
          </TableBody>
        </Table>
      </Fragment>
    )
  };

  getMetabolicEnergy = (key, energy) => {
    return (
      <Fragment key={key}>
        <h4>{t('energy.metabolic_energy')}</h4>
        <Table>
          <TableBody>
            <TableRow><TableCell>{t('energy.first_method')}</TableCell></TableRow>
            <TableRow><TableCell>kcal МЕ = {energy.first.kcal.value}</TableCell></TableRow>
            <TableRow><TableCell>kJ МЕ = {energy.first.kJ.value}</TableCell></TableRow>
            <TableRow><TableCell>{t('energy.second_method')}</TableCell></TableRow>
            <TableRow><TableCell>kcal МЕ = {energy.second.kcal.value}</TableCell></TableRow>
            <TableRow><TableCell>kJ МЕ = {energy.second.kJ.value}</TableCell></TableRow>
          </TableBody>
        </Table>
      </Fragment>
    );
  };

  render() {
    const { isOpen, handleClose, cancelLabel, recipeData } = this.props;

    return (
      <Dialog open={isOpen} fullWidth={true} maxWidth='md'>
        <DialogContent>
          {recipeData ? Object.entries(recipeData).map(([key, value]) => {
            switch (key) {
              case 'order':
                return value ? this.getAnimalDetails(value) : null;
              case 'materialQuantities':
                return value ? this.getMaterials(key, value) : null;
              case 'moistureDetails':
                return value ? this.getMoistureDetails(key, value) : null;
              case 'nutrients':
                return value ? this.getNutrients(key, value) : null;
              case 'energy':
                return value ? this.getMetabolicEnergy(key, value) : null;
              case 'totalPrice':
                return value ? <h4 key={key}>{t('price')}: {value} {t('lv')}</h4> : null;
              case '__typename':
              case 'id':
              case 'hiddenNutrients':
              default:
                return null;
            }
          }) : null}
        </DialogContent>
        <DialogActions>
          <Button label={cancelLabel} onClick={handleClose}><IconCancel /></Button>}
        </DialogActions>
      </Dialog>
    );
  }
}

RecipeShowDialog.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  cancelLabel: PropTypes.string,
  recipeData: PropTypes.object,
  ageGroupDogs: PropTypes.array,
  ageGroupCats: PropTypes.array,
  allMaterials: PropTypes.array,
  units: PropTypes.array
};

RecipeShowDialog.defaultProps = {
  isOpen: false
};

export default withStyles(styles)(RecipeShowDialog);
