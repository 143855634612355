import React from 'react';
import { Resource } from 'react-admin';
import { t } from 'i18next';

import { Animals } from '../pages/Animals';
import { Owners } from '../pages/Owners';
import { Clinics } from '../pages/Clinics';
import { Vets } from '../pages/Vets';
import { Countries } from '../pages/Countries';
import { Materials } from '../pages/Materials';
import { Orders } from '../pages/Orders';
import { Cities } from '../pages/Cities';
import { Municipalities } from '../pages/Municipalities';
import { Provinces } from '../pages/Provinces';
import { NutrientElements } from '../pages/NutrientElements';
import { NutrientGroups } from '../pages/NutrientGroups';
import { Nutrients } from '../pages/Nutrients';
import { ProductGroups } from '../pages/ProductGroups';
import { Units } from '../pages/Units';
import { Ingredients } from '../pages/Ingredients';
import { Recipes } from '../pages/Recipes';
import { Breeds } from '../pages/Breeds';
import { SizeClassMix } from '../pages/SizeClassMix';
import { AgeGroupDog } from '../pages/AgeGroupDog';
import { AgeGroupCat } from '../pages/AgeGroupCat';
import { ConsultingFeePercentageVet } from '../pages/ConsultingFeePercentageVet';
import { ConsultingFeePercentageClinic } from '../pages/ConsultingFeePercentageClinic';
import { Tastes } from '../pages/Tastes';
import { ExchangeWeight } from '../pages/ExchangeWeight';
import { EnergyRequirementDog } from '../pages/EnergyRequirementDog';
import { FoodRunningOutNotifications } from '../pages/FoodRunningOutNotifications';
import { Statuses } from '../pages/Statuses';
import { StatusesWorkflow } from '../pages/StatusesWorkflow';
import { EmailTemplates } from '../pages/EmailTemplates';
import { Statistics } from '../pages/Statistics';
import { Admins } from '../pages/Admins';
import { ProductionManagers } from '../pages/ProductionManagers';

export const ResourceMapperByRole = currentUser => {
  switch (currentUser.role) {
    case 'Owner':
      return [
        <Resource name="Owner" options={{ label: t('main_menu.owners'), currentUser }} {...Owners} create={false} />,
        <Resource name="Animal" options={{ label: t('main_menu.animals'), currentUser }} {...Animals} />,
        <Resource name="Breed" options={{ label: t('main_menu.breeds'), currentUser }} {...Breeds} create={false} edit={false} />,
        <Resource name="Order" options={{ label: t('orders'), currentUser }} {...Orders} />,
        <Resource name="Clinic" />,
        <Resource name="Vet" />,
        <Resource name="AgeGroupDog" />,
        <Resource name="AgeGroupCat" />,
        <Resource name="SizeClassMix" />,
        <Resource name="Taste" />,
        <Resource name="Status" />,
        <Resource name="City" />,
        <Resource name="Country" />,
        <Resource name="ConsultingFeePercentageVet" />,
        <Resource name="ConsultingFeePercentageClinic" />,
        <Resource name="File" />,
        <Resource name="OrderHistory" />,
        <Resource name="OwnerHistory" />,
        <Resource name="AnimalHistory" />,
        <Resource name="History" />
      ];
    case 'Vet':
      return [
        <Resource name="Vet" options={{ label: t('main_menu.vets'), currentUser }} {...Vets} create={false} />,
        <Resource name="Owner" options={{ label: t('main_menu.owners'), currentUser }} {...Owners} edit={false} />,
        <Resource name="Animal" options={{ label: t('main_menu.animals'), currentUser }} {...Animals} />,
        <Resource name="Breed" options={{ label: t('main_menu.breeds'), currentUser }} {...Breeds} create={false} edit={false} />,
        <Resource name="Order" options={{ label: t('orders'), currentUser }} {...Orders} />,
        <Resource name="Clinic" />,
        <Resource name="AgeGroupDog" />,
        <Resource name="AgeGroupCat" />,
        <Resource name="SizeClassMix" />,
        <Resource name="Taste" />,
        <Resource name="Status" />,
        <Resource name="City" />,
        <Resource name="Country" />,
        <Resource name="ConsultingFeePercentageVet" />,
        <Resource name="ConsultingFeePercentageClinic" />,
        <Resource name="File" />,
        <Resource name="OrderHistory" />,
        <Resource name="OwnerHistory" />,
        <Resource name="AnimalHistory" />,
        <Resource name="History" />
      ];
    case 'Clinic':
      return [
        <Resource name="Clinic" options={{ label: t('main_menu.clinics'), currentUser }} {...Clinics} create={false} />,
        <Resource name="Vet" options={{ label: t('main_menu.vets'), currentUser }} {...Vets} create={false} />,
        <Resource name="Owner" options={{ label: t('main_menu.owners'), currentUser }} {...Owners} edit={false} />,
        <Resource name="Animal" options={{ label: t('main_menu.animals'), currentUser }} {...Animals} />,
        <Resource name="Breed" options={{ label: t('main_menu.breeds'), currentUser }} {...Breeds} create={false} edit={false} />,
        <Resource name="Order" options={{ label: t('orders'), currentUser }} {...Orders} />,
        <Resource name="ConsultingFeePercentageVet" options={{ currentUser }} {...ConsultingFeePercentageVet} />,
        <Resource name="ConsultingFeePercentageClinic" />,
        <Resource name="AgeGroupDog" />,
        <Resource name="AgeGroupCat" />,
        <Resource name="SizeClassMix" />,
        <Resource name="Taste" />,
        <Resource name="Status" />,
        <Resource name="City" />,
        <Resource name="Country" />,
        <Resource name="File" />,
        <Resource name="OrderHistory" />,
        <Resource name="OwnerHistory" />,
        <Resource name="AnimalHistory" />,
        <Resource name="History" />
      ];
    case 'ProductionManager':
      return [
        <Resource name="Order" options={{ label: t('orders'), currentUser }} {...Orders} create={false} />,
        <Resource name="Recipe" options={{ label: t('main_menu.recipes'), currentUser }} {...Recipes} create={false} />,
        <Resource name="Clinic" />,
        <Resource name="Vet" />,
        <Resource name="Owner" />,
        <Resource name="Animal" />,
        <Resource name="Breed" />,
        <Resource name="Material" />,
        <Resource name="Unit" />,
        <Resource name="ProductGroup" />,
        <Resource name="EnergyRequirementDog" />,
        <Resource name="ExchangeWeightMini" />,
        <Resource name="ExchangeWeightSmall" />,
        <Resource name="ExchangeWeightMedium" />,
        <Resource name="ExchangeWeightLarge" />,
        <Resource name="ExchangeWeightGiant" />,
        <Resource name="Taste" />,
        <Resource name="Status" />,
        <Resource name="City" />,
        <Resource name="OrderHistory" />,
        <Resource name="History" />
      ];
    case 'Admin':
      return [
        <Resource name="Statistics" options={{ label: t('main_menu.statistics') }} {...Statistics} />,
        <Resource name="Clinic" options={{ label: t('main_menu.clinics'), currentUser }} {...Clinics} />,
        <Resource name="Vet" options={{ label: t('main_menu.vets'), currentUser }} {...Vets} />,
        <Resource name="Owner" options={{ label: t('main_menu.owners'), currentUser }} {...Owners} />,
        <Resource name="Animal" options={{ label: t('main_menu.animals'), currentUser }} {...Animals} />,
        <Resource name="Breed" options={{ label: t('main_menu.breeds'), currentUser }} {...Breeds} />,
        <Resource name="Order" options={{ label: t('orders'), currentUser }} {...Orders} />,
        <Resource name="Material" options={{ label: t('materials'), currentUser }} {...Materials} />,
        <Resource name="NutrientGroup" options={{ label: t('main_menu.nutrient_groups'), currentUser }} {...NutrientGroups} />,
        <Resource name="Nutrient" options={{ label: t('main_menu.nutrients'), currentUser }} {...Nutrients} />,
        <Resource name="NutrientElement" options={{ label: t('main_menu.nutrient_elements'), currentUser }} {...NutrientElements} />,
        <Resource name="Unit" options={{ label: t('main_menu.units'), currentUser }} {...Units} />,
        <Resource name="ProductGroup" options={{ label: t('main_menu.product_groups'), currentUser }} {...ProductGroups} />,
        <Resource name="Ingredient" options={{ label: t('ingredients'), currentUser }} {...Ingredients} />,
        <Resource name="SizeClassMix" options={{ label: t('main_menu.size_class_mix'), currentUser }} {...SizeClassMix} />,
        <Resource name="AgeGroupDog" options={{ label: t('main_menu.age_group_dog'), currentUser }} {...AgeGroupDog} />,
        <Resource name="AgeGroupCat" options={{ label: t('main_menu.age_group_cat'), currentUser }} {...AgeGroupCat} />,
        <Resource name="EnergyRequirementDog" options={{ label: t('energy.metabolic_energy'), currentUser }} {...EnergyRequirementDog} />,
        <Resource name="ExchangeWeightMini" options={{ currentUser }} {...ExchangeWeight} />,
        <Resource name="ExchangeWeightSmall" options={{ currentUser }} {...ExchangeWeight} />,
        <Resource name="ExchangeWeightMedium" options={{ currentUser }} {...ExchangeWeight} />,
        <Resource name="ExchangeWeightLarge" options={{ currentUser }} {...ExchangeWeight} />,
        <Resource name="ExchangeWeightGiant" options={{ currentUser }} {...ExchangeWeight} />,
        <Resource name="Taste" options={{ label: t('main_menu.taste'), currentUser }} {...Tastes} />,
        <Resource name="Status" options={{ label: t('statuses.statuses'), currentUser }} {...Statuses} />,
        <Resource name="EmailTemplate" options={{ label: t('email_templates.templates'), currentUser }} {...EmailTemplates} />,
        <Resource name="StatusWorkflow" options={{ label: t('statuses_workflow.workflow'), currentUser }} {...StatusesWorkflow} />,
        <Resource name="City" options={{ label: t('main_menu.cities'), currentUser }} {...Cities} />,
        <Resource name="Municipality" options={{ label: t('main_menu.municipalities'), currentUser }} {...Municipalities} />,
        <Resource name="Province" options={{ label: t('main_menu.provinces'), currentUser }} {...Provinces} />,
        <Resource name="Country" options={{ label: t('main_menu.countries'), currentUser }} {...Countries} />,
        <Resource name="Recipe" options={{ label: t('main_menu.recipes'), currentUser }} {...Recipes} />,
        <Resource name="ConsultingFeePercentageVet" options={{ currentUser }} {...ConsultingFeePercentageVet} />,
        <Resource name="ConsultingFeePercentageClinic" options={{ currentUser }} {...ConsultingFeePercentageClinic} />,
        <Resource name="FoodRunningOutNotification" options={{ currentUser }} {...FoodRunningOutNotifications} />,
        <Resource name="Admin" options={{ label: t('main_menu.admins'), currentUser }} {...Admins} />,
        <Resource name="ProductionManager" options={{ label: t('main_menu.production_managers'), currentUser }} {...ProductionManagers} />,
        <Resource name="File" />,
        <Resource name="OrderHistory" />,
        <Resource name="OwnerHistory" />,
        <Resource name="AnimalHistory" />,
        <Resource name="History" />,
      ];
    default:
      throw Error('The role is not valid!')
  }
};