import React, { Fragment } from 'react';
import { TabbedForm, FormTab, TextInput, SelectInput, NumberInput, BooleanInput } from 'react-admin';
import { t } from 'i18next';
import RichTextInput from 'ra-input-rich-text';
import CustomImageInput from '../CustomImageInput';

import { isRequired } from '../../utils/validation';
const validateRequired = [isRequired()];

class BreedForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      animalType: (props.record && props.record.animalType) || null
    }
  }

  render(){
    return (
      <Fragment>
        <TabbedForm {...this.props} redirect='list'>
          <FormTab label={t('summary_tab')}>
            <SelectInput
              source="animalType"
              label={t('animal_type')}
              choices={[
                { id: 'Dog', name: t('dog') },
                { id: 'Cat', name: t('cat') }
              ]}
              validate={validateRequired}
              onChange={(event, animalType) => {
                this.setState({animalType})
              }}
            />
            <TextInput source="breedNameBg" label={t('name_bg')} validate={validateRequired}/>
            <TextInput source="breedNameEn" label={t('name_en')} validate={validateRequired}/>
            <SelectInput
              source="sizeClass"
              label={t('size_class')}
              choices={[
                { id: 'Mini', name: t('size_classes.mini') },
                { id: 'Small', name: t('size_classes.small') },
                { id: 'Medium', name: t('size_classes.medium') },
                { id: 'Large', name: t('size_classes.large') },
                { id: 'Giant', name: t('size_classes.giant') }
              ]}
              validate={validateRequired}
            />
            <BooleanInput source="supportedByOptimus" label={t('breeds.supported_by_optimus')}/>
            <NumberInput source="priceCoefficient" label={t('breeds.price_coefficient')}/>
          </FormTab>
          {this.state.animalType === "Dog" ?
            <FormTab label={t('short_encyclopedia')}>
              <RichTextInput source="shortEncyclopedia" label={t('short_encyclopedia')}/>
            </FormTab>
            : null}
          {this.state.animalType === "Dog" ?
            <FormTab label={t('encyclopedia')}>
              <RichTextInput source="encyclopedia" label={t('encyclopedia')}/>
            </FormTab>
            : null}
          <FormTab label={t('profile_picture')}>
            <CustomImageInput
              source="picturePuppy"
              label={t('age_group.puppy')}
              accept='image/jpeg, image/png'
              maxSize={20 * (2 ** 20)} //20MB in bytes
              isVisible={this.state.animalType === "Dog"}
            />
            <CustomImageInput
              source="pictureKitten"
              label={t('age_group.kitten')}
              accept='image/jpeg, image/png'
              maxSize={20 * (2 ** 20)} //20MB in bytes
              isVisible={this.state.animalType === "Cat"}
            />
            <CustomImageInput
              source="pictureJunior"
              label={t('age_group.junior')}
              accept='image/jpeg, image/png'
              maxSize={20 * (2 ** 20)} //20MB in bytes
            />
            <CustomImageInput
              source="pictureAdult"
              label={t('age_group.adult')}
              accept='image/jpeg, image/png'
              maxSize={20 * (2 ** 20)} //20MB in bytes
              isVisible={this.state.animalType === "Dog"}
            />
            <CustomImageInput
              source="picturePrime"
              label={t('age_group.prime')}
              accept='image/jpeg, image/png'
              maxSize={20 * (2 ** 20)} //20MB in bytes
              isVisible={this.state.animalType === "Cat"}
            />
            <CustomImageInput
              source="pictureMature"
              label={t('age_group.mature')}
              accept='image/jpeg, image/png'
              maxSize={20 * (2 ** 20)} //20MB in bytes
            />
            <CustomImageInput
              source="pictureSenior"
              label={t('age_group.senior')}
              accept='image/jpeg, image/png'
              maxSize={20 * (2 ** 20)} //20MB in bytes
            />
            <CustomImageInput
              source="pictureGeriatric"
              label={t('age_group.geriatric')}
              accept='image/jpeg, image/png'
              maxSize={20 * (2 ** 20)} //20MB in bytes
            />

            <CustomImageInput
              source="pictureYoungAdult"
              label={t('age_group.youngadult')}
              accept='image/jpeg, image/png'
              maxSize={20 * (2 ** 20)} //20MB in bytes
            />
            <CustomImageInput
              source="pictureMatureAdult"
              label={t('age_group.matureadult')}
              accept='image/jpeg, image/png'
              maxSize={20 * (2 ** 20)} //20MB in bytes
            />

          </FormTab>
        </TabbedForm>
      </Fragment>
    )
  }
};

export default BreedForm;
