import React from 'react';
import { Edit } from 'react-admin';
import { t } from 'i18next';

import EditFormTitle from '../EditFormTitle';
import { CustomEditToolbar } from '../CustomEditToolbar';
import { StatusForm } from './StatusForm';

export const StatusEdit = props => (
  <Edit {...props} title={<EditFormTitle formName={t('status')} />}>
    <StatusForm toolbar={<CustomEditToolbar/>}/>
  </Edit>
);