import React, { Fragment } from 'react';
import { Create, Button } from 'react-admin';
import CustomCreateToolbar from '../CustomCreateToolbar';
import { parse } from 'query-string';
import { t } from 'i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';

import { RecipeForm } from './RecipeForm';
import { GET_COUNT_OF_ANIMAL_CHANGES_AFTER_DATE, GET_ANIMAL_AND_DATE_OF_ORDER, GET_ORDER } from '../../customQueries';
import apolloClient from '../../initApollo';

class RecipeCreate extends React.Component {
  order = { orderId: null };

  constructor(props) {
    super(props);

    this.state = {
      order: null,
      recipeFormName: t('recipes.title_create'),
      showWarningMessage: false,
      showDialogAlert: true
    };

    this.order = parse(props.location.search);
  };

  componentDidMount() {
    apolloClient.query({ query: GET_ANIMAL_AND_DATE_OF_ORDER, variables: { id: this.order.orderId } })
      .then(response => {
        if (response.loading) return;

        let animal;
        let date = response.data.order.dateCreated;

        if (response.data.order.animal) {
          animal = response.data.order.animal.id;
        }

        if (animal) {
          apolloClient.query({ query: GET_COUNT_OF_ANIMAL_CHANGES_AFTER_DATE, variables: { animal, date } })
            .then(response => {
              if (response.loading) return;

              //filter is not applied to aggregate function so we use count of nodes which are returned
              let changes = response.data.animalHistories.length;
              if (changes > 0) this.setState({showWarningMessage: true});
            });
        }
      });

    this.initializeRecipeForm(this.order.orderId);
  }

  initializeRecipeForm = id => {
    apolloClient.query({ query: GET_ORDER, variables: { id }, fetchPolicy: 'no-cache' })
      .then(response => {
        if (response.loading) return;

        const order = response.data.order;
        const animal = order.animal;
        let name = '';
        let type = '';
        let breed = '';
        let ageGroup = '';
        let detail = '';
        let recipeFormName = '';
        if (animal) {
          name = animal.name || '';
          type = t(animal.animalType.toLowerCase());
          breed = animal.breed.breedNameBg;
          recipeFormName = t('recipes.title', { name, type, breed });
        } else {
          type = t(order.animalType.toLowerCase());
          detail = order.breed ? order.breed.breedNameBg : order.sizeClass;
          ageGroup = order.ageGroupName;
          recipeFormName = t('recipes.title_clinic_order', { type, detail, ageGroup });
        }

        this.setState({
          order,
          recipeFormName,
          type: type.toLowerCase(),
        });
      });
  };

  render = () => {
    const { order, recipeFormName, showWarningMessage, showDialogAlert } = this.state;
    const { options, location } = this.props;
    if (!order) return null;

    const { orderId, animal, animalType } = order;

    return (
      <Fragment>
        {showWarningMessage && !location.state &&
          <Dialog open={showDialogAlert}>
            <DialogContent><Typography variant="subheading">{t('warning_message_recipe')}</Typography></DialogContent>
            <DialogActions><Button onClick={() => this.setState({ showDialogAlert: false })} label='OK' /></DialogActions>
          </Dialog>
        }
        <Create {...this.props} title={recipeFormName}>
          <RecipeForm
            toolbar={<CustomCreateToolbar animal={animal} user={options.currentUser.role} />}
            defaultValue={{ orderId }}
            animalType={animalType}
            showWarningMessage={showWarningMessage}
            order={order}
            user={options.currentUser}
            data={location.state}
          />
        </Create>
      </Fragment>
    );
  }
}

export default RecipeCreate;
