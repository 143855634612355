import { LocalHospital } from '@material-ui/icons';
import { ClinicList } from '../components/Clinic/ClinicList';
import { ClinicShow } from '../components/Clinic/ClinicShow';
import { ClinicCreate } from '../components/Clinic/ClinicCreate';
import { ClinicEdit } from '../components/Clinic/ClinicEdit';

export const Clinics = {
  icon: LocalHospital,
  list: ClinicList,
  show: ClinicShow,
  create: ClinicCreate,
  edit: ClinicEdit
};