import React from 'react';
import { Edit } from 'react-admin';
import { t } from 'i18next';

import EditFormTitle from '../EditFormTitle';
import { CustomEditToolbar } from '../CustomEditToolbar';
import { StatusesWorkflowForm } from './StatusesWorkflowForm';

export const StatusesWorkflowEdit = props => (
  <Edit {...props} title={<EditFormTitle formName={t('statuses_workflow.workflow')} />}>
    <StatusesWorkflowForm toolbar={<CustomEditToolbar user={props.options.currentUser.role}/>}/>
  </Edit>
);