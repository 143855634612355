import React from 'react';
import { Edit } from 'react-admin';
import { t } from 'i18next';

import EditFormTitle from '../EditFormTitle';
import { CustomEditToolbar } from '../CustomEditToolbar';
import { EnergyRequirementDogForm } from './EnergyRequirementDogForm';

export const EnergyRequirementDogEdit = props => (
  <Edit {...props} title={<EditFormTitle formName={t('energy.metabolic_energy')} />}>
    <EnergyRequirementDogForm toolbar={<CustomEditToolbar user={props.options.currentUser.role} />} />
  </Edit>
);
