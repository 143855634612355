import { VetList } from '../components/Vet/VetList';
import VetShow from '../components/Vet/VetShow';
import { VetCreate } from '../components/Vet/VetCreate';
import { VetEdit } from '../components/Vet/VetEdit';

export const Vets = {
  list: VetList,
  show: VetShow,
  create: VetCreate,
  edit: VetEdit
};
