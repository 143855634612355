import { Face } from '@material-ui/icons';
import OwnerCreate from '../components/Owner/OwnerCreate';
import OwnerEdit from '../components/Owner/OwnerEdit';
import { OwnerList } from '../components/Owner/OwnerList';
import { OwnerShow } from '../components/Owner/OwnerShow';

export const Owners = {
  icon: Face,
  list: OwnerList,
  edit: OwnerEdit,
  show: OwnerShow,
  create: OwnerCreate
};
