import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SaveButton, REDUX_FORM_NAME } from 'react-admin';
import { isEqual, isEmpty } from 'lodash';
import { customCrudCreate } from './customCrudCreate';
import { customCrudUpdate } from './customCrudUpdate';
import { push } from 'react-router-redux';
import { showNotification } from 'ra-core';
import { t } from 'i18next';
import { UPDATE_OWNER_NODE, UPDATE_VET_NODE, UPDATE_CLINIC_NODE, TRIGGER_ORDER_STATUS_CHANGE, UPDATE_ADMIN_OR_MANAGER_NODE } from '../customQueries';
import apolloClient from '../initApollo';

class CustomSaveButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ownerEmail: null,
      vetEmail: null,
      clinicEmail: null,
      productionManagerEmail: 'production.manager@dnhsoft.com',
      adminEmail: 'admin@dnhsoft.com',
      allStatuses: []
    }
  }

  getChangedData = (resource, record, formData) => {
    //do it only for Animal type
    if (resource !== 'Animal' && resource !== 'Owner' && resource !== 'Order') return;

    //if there are no changes between record from the db and the field of the form return
    if (JSON.stringify(record) === JSON.stringify(formData)) return;
    let data = {};

    for (let key in record) {
      if (['animalHistories', 'animalHistoriesIds', 'filesIds', 'files', 'profilePicture'].includes(key)) {
        continue;
      }

      if (resource === 'Order' && ['comments', 'notes', 'ownerNotes', 'vetNotes'].includes(key)) {
        continue;
      }

      if (Array.isArray(record[key])) {
        if (record[key].length === 0) {
          continue;
        }
      }

      if (record[key]) {
        if (typeof record[key] === 'object') {
          if (!isEqual(record[key], formData[key])) {
            data[key] = {
              oldValue: record[key] || null,
              newValue: formData[key] || null
            }
          }
        } else if (record[key] !== formData[key]) {
          data[key] = {
            oldValue: record[key] || null,
            newValue: formData[key] || null
          };
        }
      } else if (formData[key]) {
        let values = [];
        if (typeof formData[key] !== 'object' || formData[key] instanceof Date) {
          values.push(formData[key])
        } else {
          Object.entries(formData[key]).forEach(([key, value]) => {
            if (value) {
              values.push(value);
            }
          });
        }

        if (!isEmpty(values)) {
          data[key] = {
            oldValue: null,
            newValue: formData[key] || null
          };
        }
      }
    }

    return data;
  };

  handleClick = () => {
    const { handleSubmit, updateResource, createHistory, record, basePath, resource, redirect, push, user, statusFrom, statusTo } = this.props;
    const roles = ['Owner', 'Vet', 'Clinic', 'ProductionManager', 'Admin'];

    return handleSubmit(values => {
      const data = this.getChangedData(resource, record, values);

      if (roles && roles.includes(resource)) {
        this.updateNode(resource, values, record, data, createHistory, basePath, user);
      } else {
        let newValues = Object.assign({}, values);
        // Clear empty fields when saving a recipe
        if (resource === 'Recipe') {
          let mq = values.materialQuantities;
          let newMQ = [];

          for (const i in mq) {
            if (mq[i].materialId && mq[i].quantity && mq[i].unitId) {
              newMQ.push(mq[i]);
            }
          }

          newValues.materialQuantities = newMQ;
        }
        updateResource(newValues, record, resource, basePath, data, createHistory, redirect, user);
      }
      //TODO: redirect only on success of recipe edit
      //redirect on success of recipe edit returns `Invariant Violation: Maximum update depth exceeded.`
      if (resource === 'Recipe') push(redirect);

      ////// TODO: uncomment this code when we need to send emails from mailchimp
      if (resource && resource === 'Order' && statusFrom && statusTo && statusFrom !== statusTo) {
        const orderId = record.id;
        const orderNumber = record.idNumber;
        const orderLink = `${window.location.origin}/#/Order/${orderId}/show`;
        apolloClient.query({ query: TRIGGER_ORDER_STATUS_CHANGE, variables: { statusFrom, statusTo, orderId, orderNumber, orderLink }, fetchPolicy: 'no-cache' });
      }
    });
  };

  updateNode = (resource, values, record, data, createHistory, basePath, user) => {
    apolloClient.mutate({ mutation: this.getQueryName(resource), variables: this.getData(resource, values, record) })
      .then(async response => {
        if (response.loading) return;

        if (resource === 'Owner') {
          const history = {
            user,
            date: new Date(),
            owner: record.id,
            data
          };

          createHistory('OwnerHistory', history, basePath, null);
        }

        this.props.history.push(`/${resource}`);
      })
      .catch(error => {
        const graphQLError = error.graphQLErrors ? error.graphQLErrors.pop() : null;

        if (graphQLError) {
          graphQLError.code === 3010 ? this.props.showNotification(t('unique_constraint_message'), 'warning') : this.props.showNotification(graphQLError.message, 'warning');
        } else {
          throw Error(error);
        }
      });
  };

  getQueryName = resource => {
    switch (resource) {
      case 'Owner':
        return UPDATE_OWNER_NODE;
      case 'Vet':
        return UPDATE_VET_NODE;
      case 'Clinic':
        return UPDATE_CLINIC_NODE;
      case 'Admin':
      case 'ProductionManager':
        return UPDATE_ADMIN_OR_MANAGER_NODE(resource);
      default:
        throw Error('Resource not found!');
    }
  };

  getData = (resource, form, record) => {
    switch (resource) {
      case 'Owner':
        return this.getOwnerData(form, record);
      case 'Vet':
        return this.getVetData(form, record);
      case 'Clinic':
        return this.getClinicData(form, record);
      case 'Admin':
      case 'ProductionManager':
        return this.getAdminOrManagerData(form, record);
      default:
        throw Error('Resource not found!');
    }
  };

  getOwnerData = (form, record) => {
    return {
      id: record.id,
      active: form.active !== null ? form.active : record.active,
      email: form.email || record.email,
      name: form.name || record.name,
      city: form.city.id || record.city.id,
      street: form.street,
      streetNumber: form.streetNumber,
      buildingNumber: form.buildingNumber,
      district: form.district,
      entrance: form.entrance,
      floor: form.floor,
      apartmentNumber: form.apartmentNumber,
      country: form.countryId || record.countryId,
      postcode: form.postcode || record.postcode,
      phoneNumber: form.phoneNumber || record.phoneNumber,
      vet: form.vet.id || record.vet.id
    }
  };

  getVetData = (form, record) => {
    return {
      id: record.id,
      active: form.active !== null ? form.active : record.active,
      email: form.email || record.email,
      name: form.name || record.name,
      phoneNumber: form.phoneNumber || record.phoneNumber,
      postcode: form.postcode || record.postcode,
      street: form.street,
      streetNumber: form.streetNumber,
      buildingNumber: form.buildingNumber,
      district: form.district,
      entrance: form.entrance,
      floor: form.floor,
      apartmentNumber: form.apartmentNumber,
      city: form.city.id || record.city.id,
      clinic: form.clinic.id || record.clinic.id
    }
  };

  getClinicData = (form, record) => {
    return {
      id: record.id,
      active: form.active !== null ? form.active : record.active,
      email: form.email || record.email,
      name: form.name || record.name,
      lat: form.lat,
      lng: form.lng,
      phoneNumber: form.phoneNumber || record.phoneNumber,
      postcode: form.postcode || record.postcode,
      street: form.street,
      streetNumber: form.streetNumber,
      buildingNumber: form.buildingNumber,
      district: form.district,
      entrance: form.entrance,
      floor: form.floor,
      apartmentNumber: form.apartmentNumber,
      city: form.city.id || record.city.id
    }
  };

  getAdminOrManagerData = (form, record) => {
    return {
      id: record.id,
      active: form.active !== null ? form.active : record.active,
      notifications: form.notifications !== null ? form.notifications : record.notifications,
      email: form.email || record.email,
      name: form.name || record.name,
      phoneNumber: form.phoneNumber || record.phoneNumber,
    }
  }

  render() {
    const { handleSubmitWithRedirect, updateResource, createHistory, push, statusFrom, statusTo, ...props } = this.props;

    return (
      <SaveButton
        handleSubmitWithRedirect={this.handleClick}
        {...props}
      />
    );
  }
}

const mapFormStateToProps = state => {
  let statusFrom = null;
  let statusTo = null;
  let form = state.form[REDUX_FORM_NAME];
  if (form) {
    statusFrom = form.initial && form.initial.status ? form.initial.status.id : statusFrom;
    statusTo = form.values && form.values.status ? form.values.status.id : statusTo;
  }

  return { statusFrom, statusTo };
};

export default connect(mapFormStateToProps, { push, showNotification, updateResource: customCrudUpdate, createHistory: customCrudCreate })(CustomSaveButton);
