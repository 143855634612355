import React from 'react';
import {Button, FunctionField} from 'react-admin';
import {t} from "i18next";
import apolloClient from "../initApollo";
import {RESEND_USER_TEMP_PASSWORD} from "../customQueries";
import EmailIcon from '@material-ui/icons/Email';
import {Tooltip} from '@material-ui/core';


export const ResendTempEmailButton = (props) => {
  return (

    <FunctionField
      {...props}
      render={({email, active}) => {
        return (
          <Button
            label={''}
            onClick={async e => {

              e.stopPropagation();

              const response = await apolloClient.mutate({
                mutation: RESEND_USER_TEMP_PASSWORD,
                variables: {email}
              });

              const success = response.data.resendUserTempPassword.success;
              const message = response.data.resendUserTempPassword.message;

              if (success) {
                window.alert('Писмото е изпратено успешно.');
              } else {
                window.alert('Не е изпратено писмо. Резултат от Амазон: ' + message);
              }

            }}
            disabled={!active}
          >
            <Tooltip title={t('send_new_temp_email')}>
              <EmailIcon/>
            </Tooltip>
          </Button>
        );
      }}
    />

  );
};