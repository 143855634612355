import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { formatDate } from '../../utils/Helper';

const DateField = ({ record, source }) => {
  if (!record) return null;

  const value = record[source];
  if (value === null) return null;

  const date = value instanceof Date ? value : new Date(value);
  const dateString = (!(date instanceof Date) || isNaN(date)) ? null : formatDate(date);

  return <Typography>{dateString}</Typography>;
};

DateField.propTypes = {
  addLabel: PropTypes.bool,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  resource: PropTypes.string,
  label: PropTypes.string,
  basePath: PropTypes.string
};

DateField.defaultProps = {
  addLabel: true,
};

export default DateField;