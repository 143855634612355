import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import { Title } from 'react-admin';
import Papa from 'papaparse';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import { GET_ALL_PROVINCES, GET_ALL_MUNICIPALITIES, GET_ALL_CITIES, GET_BG_COUNTRY } from '../customQueries';
import { chunkArrayInGroups, capitalizeFirstLetter } from '../utils/Helper';
import { t } from 'i18next';
import gql from 'graphql-tag';
import apolloClient from '../initApollo';

class CountryImportData extends Component {
  mainProvinceCity = false;
  importCountryData = category => {
    this.getCsvData(category, this.updateData)
  };

  getCsvData = (categoryName, funcName) => {
    let csvFilePath = require(`../countryImportData/${categoryName}.csv`);

    Papa.parse(csvFilePath, {
      header: true,
      download: true,
      skipEmptyLines: true,
      complete: result => funcName(result, categoryName)
    });
  }

  deleteCities = () =>
    apolloClient.query({ query: GET_ALL_CITIES }).then(
      response => {
        const cities = response.data.cities;
        const cityChunks = chunkArrayInGroups(cities, 250);

        let offset = 0;
        cityChunks.map(cityChunk => {
          this.deleteAllEntries(cityChunk, 'city', this.deleteCitySubQuery, offset);
          return offset += 5000;
        });
      }
    );

  handleDeleteCities = () =>{
    let timeOffset = 0;
    // for (let i = 0; i < 5; i++) {
      setTimeout(() => this.deleteCities(), timeOffset);
      timeOffset += 5000;
    // }
  };

  deleteAllEntries = (data, categoryName, subQuery, offset) => {
    setTimeout(() => {
      const deleteQuery = this.getDeleteQuery(data, categoryName, subQuery);

      console.log('Deleting Elements');
      apolloClient.mutate({ mutation: deleteQuery }).then(response => {
        console.log(response);
      });

    }, 0 + offset);
  };

  getDeleteQuery = (data, categoryName, subQueryBuilder) => {
    let queryData = '';
    categoryName = capitalizeFirstLetter(categoryName);

    queryData = data.reduce((queryData, { id }, index) => {

      queryData += subQueryBuilder(index, categoryName, id);
      return queryData;

    }, queryData);

    return gql`mutation {${queryData}}`;
  };

  getUpperLocationByCode = (upperLocationCode, upperLocation) => {
    return upperLocation.filter(({ code }) => { return code === upperLocationCode });
  };

  buildGQLQuery = (data, upperLocations, categoryName, upperLocationName, subQueryBuilder, countryId = null) => {
    let queryData = '';
    categoryName = capitalizeFirstLetter(categoryName);

    queryData = data.reduce((queryData, {code, name, type}, index) => {

      const upperLocationCode = upperLocationName === 'province' ? code.slice(0, 3) : code;

      let id = null;
      if (categoryName !== 'Province') {
        const upperLoc = this.getUpperLocationByCode(upperLocationCode, upperLocations)[0];
        id = upperLoc.id;
      }

      if (categoryName === 'City') {
        type = (type === 'гр') ? 'City' : 'Village';
        let mainProvince = upperLocations.filter(municipality => municipality.province.name === name && type === 'City');
        this.mainProvinceCity = mainProvince.length !== 0;
      }
      queryData += subQueryBuilder(index, categoryName, name, code, upperLocationName, id, type, countryId, this.mainProvinceCity);
      return queryData;

    }, queryData, upperLocations);

    return gql`mutation {${queryData}}`;
  };

  createProvinceSubQuery = (index, categoryName, name, code) => {
    return `
      m${index}: create${categoryName}(data: {name: "${name}", code: "${code}"}) {
        id
        name
        code
      }
    `;
  };

  createMunicipalitySubQuery = (index, categoryName, name, code, upperLocationName, id) => {
    return `
      m${index}: create${categoryName}(data: {name: "${name}", code: "${code}", ${upperLocationName}: { connect: { id: "${id}" }}}) {
        id
        name
        code
        ${upperLocationName} {
          id
        }
      }
    `;
  };

  createCitySubQuery = (index, categoryName, name, code, upperLocationName, id, type, countryId, mainProvinceCity) => {
    return `
      m${index}: create${categoryName}(data: {name: "${name}", type: ${type}, mainProvinceCity: ${mainProvinceCity} ${upperLocationName}: { connect: { id: "${id}" }}, country: { connect: { id: "${countryId}"}}}) {
        id
        name
        type
        ${upperLocationName} {
          id
        }
        country {
          id
        }
      }
    `;
  };

  deleteCitySubQuery = (index, categoryName, id) => {
    return `
      m${index}: delete${categoryName}(where: {id: "${id}"}) {
        id
      }
    `;
  };

  updateData = ({ data }, categoryName) => {
    if (categoryName === 'province') {
      const importProvincesQuery = this.buildGQLQuery(data, null, categoryName, '', this.createProvinceSubQuery);

      apolloClient.mutate({ mutation: importProvincesQuery }).then(response => {
        console.log(response);
      });
    } else if (categoryName === 'municipality') {
      this.importMunicipalities(data, categoryName);

    } else if (categoryName === 'city') {
      data = chunkArrayInGroups(data, 250);

      apolloClient.query({ query: GET_BG_COUNTRY }).then(response => {
        const country = response.data.countries.pop();
        const countryId = country ? country.id : '';

        let offset = 0;
        data.map(chunk => {
          this.importCities(chunk, categoryName, offset, countryId);
          return offset += 5000;
        });
      });
    }
  };

  importMunicipalities = (data, categoryName) => {
    apolloClient.query({ query: GET_ALL_PROVINCES }).then(
      response => {
        const provinces = response.data.provinces;
        const importMunicipalitiesQuery = this.buildGQLQuery(data, provinces, categoryName, 'province', this.createMunicipalitySubQuery);

        console.log('Importing in GraphQL server');
        apolloClient.mutate({ mutation: importMunicipalitiesQuery }).then(response => {
          console.log(response);
        });
      }
    );
  };

  importCities = (data, categoryName, offset, countryId) => {
    setTimeout(() =>
      apolloClient.query({ query: GET_ALL_MUNICIPALITIES }).then(
        response => {
          const municipalities = response.data.municipalities;
          const importCitiesQuery = this.buildGQLQuery(data, municipalities, categoryName, 'municipality', this.createCitySubQuery, countryId);

          console.log('Importing in GraphQL server');
          apolloClient.mutate({ mutation: importCitiesQuery }).then(response => {
            console.log(response);
          });
        }
      ), 0 + offset);
  };

  render() {
    return (
      <Card>
        <Title title={t('title')} />
        <CardContent>{t('country_import.import_country_data_message')}</CardContent>
        <CardActions>
          <Button variant='contained' color='primary' onClick={() => this.importCountryData('province')} style={{margin: '10px'}}>
            {t('main_menu.provinces')} <CloudUploadIcon style={{marginLeft: '5px'}} />
          </Button>
          <Button variant='contained' color='primary' onClick={() => this.importCountryData('municipality')} style={{margin: '10px'}}>
            {t('main_menu.municipalities')} <CloudUploadIcon style={{marginLeft: '5px'}} />
          </Button>
          <Button variant='contained' color='primary' onClick={() => this.importCountryData('city')} style={{margin: '10px'}}>
            {t('main_menu.cities')} <CloudUploadIcon style={{marginLeft: '5px'}} />
          </Button>
          <Button variant='contained' color='primary' onClick={this.handleDeleteCities} style={{margin: '10px'}}>
            {t('country_import.delete_button')} <DeleteIcon/>
          </Button>
        </CardActions>
      </Card>
    );
  }
}

export default CountryImportData;