import React, { Component } from 'react';
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { t } from 'i18next';
import { REDUX_FORM_NAME, BooleanInput } from 'react-admin';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core';
import { validateAlwaysFail } from '../../utils/validation';

const alwaysFail = [validateAlwaysFail()];

const styles = ({
  subtitle: {
    color: 'red'
  },
  hiddenSubTitle: {
    display: 'none'
  }
});

class Energy extends Component {
  labelEnergy = {'methods': ['first', 'second'], 'units': ['kcal', 'kJ'] };

  multipliers = {
    grossEnergy: {
      kcal: {
        proteins: 4,
        carbohydrates: 4,
        fats: {
          dog: 9,
          cat: 8.5
        }
      },
      kJ: {
        proteins: 16.7,
        carbohydrates: 16.7,
        fats: {
          dog: 37.6,
          cat: 35.6
        }
      }
    },
    digestibility: {
      total: {
        dog: 91.2,
        cat: 87.9
      },
      coefficientDryMatter: {
        dog: 1.43,
        cat: 0.88
      }
    },
    metabolicEnergy: {
      kcal: {
        dog: 1.04,
        cat: 0.77
      },
      kJ: {
        dog: 4.35,
        cat: 3.22
      }
    }
  };

  constructor(props){
    super(props);

    this.state = {
      selected: false,
      energy: props.energy || [],
    };
  }

  componentDidMount() {
    Object.values(this.state.energy).forEach(method => {
      Object.values(method).forEach(unit => {
        if(unit.selected) {
          this.setState({ selected: true });
        }
      })
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.changed) {
      this.setState({ selected: false });
      this.calculateEnergy();
    }
  }

  calculateEnergy = () => {
    const { animalType, nutrients } = this.props;
    let { proteinsPercent, carbohydratesPercent, fatsPercent, fibersPercent } = this.getNutrientsPercent(nutrients);

    let first = this.calculateFirstMethod(proteinsPercent, carbohydratesPercent, fatsPercent, fibersPercent, animalType.toLowerCase());
    let second = this.calculateSecondMethod(proteinsPercent, carbohydratesPercent, fatsPercent, animalType.toLowerCase());

    this.setState({ energy: { first, second } }, () => this.storeData() );

    this.props.callback(this.state.energy);
  };

  calculateFirstMethod = (proteinsPercent, carbohydratesPercent, fatsPercent, fibersPercent, animalType) => {
    if(!fibersPercent) return { kcal: { value: 0, selected: false }, kJ: { value: 0, selected: false } };
    let grossEnergy = this.calculateGrossEnergy(proteinsPercent, carbohydratesPercent, fatsPercent, fibersPercent);
    let digestibility = this.calculateDigestibility(fibersPercent, animalType);
    let digestibleEnergy = this.calculateDigestibleEnergy(grossEnergy, digestibility);

    let kcal = digestibleEnergy.kcal - (this.multipliers.metabolicEnergy.kcal[animalType] * proteinsPercent);
    let kJ = digestibleEnergy.kJ - (this.multipliers.metabolicEnergy.kJ[animalType] * proteinsPercent);

    kcal *= 10;
    kJ *= 10;

    return { kcal: { value: parseInt(kcal), selected: false }, kJ: { value: parseInt(kJ), selected: false } };
  };

  getNutrientsPercent = (nutrients) => {
    let proteinsPercent = 0;
    let carbohydratesPercent = 0;
    let fatsPercent = 0;
    let fibersPercent = 0;

    nutrients.forEach(nutrient => {
      if(nutrient.name === 'Протеини') {
        proteinsPercent = nutrient.percent || 0;
      }

      if(nutrient.name === 'Въглехидрати') {
        carbohydratesPercent = nutrient.percent || 0;
        if(nutrient.nutrientGroups && nutrient.nutrientGroups.length !== 0) {
          nutrient.nutrientGroups.forEach(group => {
            if(group.name === 'Фибри') {
              fibersPercent = group.percent || 0;
            }
          })
        }
      }

      if(nutrient.name === 'Мазнини') {
        fatsPercent = nutrient.percent || 0;
      }
    });

    return { proteinsPercent, carbohydratesPercent, fatsPercent, fibersPercent };
  };

  calculateGrossEnergy = (proteinsPercent, carbohydratesPercent, fatsPercent, fibersPercent) =>{
    let kcal = 5.7 * proteinsPercent + 9.4 * fatsPercent + 4.1 * (carbohydratesPercent + fibersPercent);
    let kJ = 23.85 * proteinsPercent + 39.33 * fatsPercent + 17.5 * (carbohydratesPercent + fibersPercent);

    return { kcal, kJ };
  };

  calculateDigestibility = (fibersPercent, animalType) => {
    const { materials } = this.props;

    let totalMoisture = 0;
    materials.forEach(material => {
      if(!material.materialId || !material.quantity || !material.unitId) return { kcal: { value: 0, selected: false }, kJ: { value: 0, selected: false } };
      totalMoisture += material.moisture;
    });

    let dryMatter = 100 - (totalMoisture / materials.length);
    let fibersInDryMatter = (fibersPercent / dryMatter) * 100;

    return this.multipliers.digestibility.total[animalType] - (this.multipliers.digestibility.coefficientDryMatter[animalType] * fibersInDryMatter);
  };

  calculateDigestibleEnergy = (grossEnergy, digestibility) => {
    let kcal = (grossEnergy.kcal * digestibility) / 100;
    let kJ = (grossEnergy.kJ * digestibility) / 100;

    return { kcal, kJ };
  };

  calculateSecondMethod = (proteinsPercent, carbohydratesPercent, fatsPercent, animalType) => {
    let proteinsKcal = proteinsPercent * this.multipliers.grossEnergy.kcal.proteins;
    let carbohydratesKcal = carbohydratesPercent * this.multipliers.grossEnergy.kcal.carbohydrates;
    let fatsKcal = fatsPercent * this.multipliers.grossEnergy.kcal.fats[animalType];

    let proteinsKJ = proteinsPercent * this.multipliers.grossEnergy.kJ.proteins;
    let carbohydratesKJ = carbohydratesPercent * this.multipliers.grossEnergy.kJ.carbohydrates;
    let fatsKJ = fatsPercent * this.multipliers.grossEnergy.kJ.fats[animalType];

    let kcal = proteinsKcal + carbohydratesKcal + fatsKcal;
    let kJ = proteinsKJ + carbohydratesKJ + fatsKJ;

    kcal *= 10;
    kJ *= 10;

    return { kcal: { value: parseInt(kcal), selected: false }, kJ: { value: parseInt(kJ), selected: false } };
  };

  storeData = () => {
    this.props.change(REDUX_FORM_NAME, 'energy', this.state.energy);
  };

  handleChange = (e) => {
    let id = e.target.id;
    let method = id.split('.')[0];
    let unit = id.split('.')[1];

    let value = e.target.checked;

    let energy = this.state.energy;
    energy[method][unit].selected = value;

    let selected = false;
    if(value) {
      selected = true;

      this.labelEnergy.methods.forEach(labelMethod => {
        if (method !== labelMethod) {
          this.labelEnergy.units.forEach(labelUnit => {
            energy[labelMethod][labelUnit].selected = null
          })
        } else {
          this.labelEnergy.units.forEach(labelUnit => {
            if (unit !== labelUnit) {
              energy[method][labelUnit].selected = null
            }
          })
        }
      });
    }

    this.setState({ energy, selected }, () => this.storeData());
    this.props.callback(this.state.energy);
  };

  render() {
    const { classes, user } = this.props;
    const { selected, energy } = this.state;

    return this.state.energy.length !== 0 && (
      <div>
        <h1>{t('energy.metabolic_energy')}</h1>
        <Typography variant="body2" gutterBottom className={selected ? classes.hiddenSubTitle : classes.subtitle}>
          {t('energy.subtitle_message')}
        </Typography>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>{t('energy.first_method')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <BooleanInput
                  id='first.kcal'
                  source="energy.first.kcal.selected"
                  label=""
                  validate={selected ? null : alwaysFail}
                  options={{
                    checked: energy.first.kcal.selected || false,
                    onChange: this.handleChange,
                    disabled: user && user !== 'Admin'
                  }}
                />
              </TableCell>
              <TableCell>kcal МЕ = {energy.first.kcal.value}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <BooleanInput
                  id='first.kJ'
                  source="energy.first.kJ.selected"
                  label=""
                  validate={selected ? null : alwaysFail}
                  options={{
                    checked: energy.first.kJ.selected || false,
                    onChange: this.handleChange,
                    disabled: user && user !== 'Admin'
                  }}
                />
              </TableCell>
              <TableCell>kJ МЕ = {energy.first.kJ.value}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('energy.second_method')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <BooleanInput
                  id='second.kcal'
                  source="energy.second.kcal.selected"
                  label=""
                  validate={selected ? null : alwaysFail}
                  options={{
                    checked: energy.second.kcal.selected || false,
                    onChange: this.handleChange,
                    disabled: user && user !== 'Admin'
                  }}
                />
              </TableCell>
              <TableCell>kcal МЕ = {energy.second.kcal.value}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <BooleanInput
                  id='second.kJ'
                  source="energy.second.kJ.selected"
                  label=""
                  validate={selected ? null : alwaysFail}
                  options={{
                    checked: energy.second.kJ.selected || false,
                    onChange: this.handleChange,
                    disabled: user && user !== 'Admin'
                  }}
                />
              </TableCell>
              <TableCell>kJ МЕ = {energy.second.kJ.value}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    )
  }
}

Energy.propTypes = {
  animalType: PropTypes.string,
  nutrients: PropTypes.oneOfType([PropTypes.array,  PropTypes.object]),
  materials: PropTypes.array,
  change: PropTypes.func,
  energy: PropTypes.object,
  changed: PropTypes.bool,
  callback: PropTypes.func
};

export default withStyles(styles)(Energy);