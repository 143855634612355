import React from 'react';
import { SimpleForm, TextInput } from 'react-admin';
import { t } from 'i18next';

import { isRequired } from '../../utils/validation';

const validateRequired = [isRequired()];

export const StatusForm = props => (
  <SimpleForm {...props} redirect='list'>
    <TextInput source='nameBg' label={t('name_bg')} validate={validateRequired}/>
    <TextInput source='nameEn' label={t('name_en')} validate={validateRequired}/>
  </SimpleForm>
);