import React from 'react';
import { CardActions, CreateButton } from 'react-admin';

export const CustomListActions = ({
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    user
}) => (
    <CardActions>
        {bulkActions && React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context : 'button',
        })}
        { user ? isCreateAllowed(resource, user.role) ? <CreateButton basePath={basePath} /> : null : <CreateButton basePath={basePath}/>}
    </CardActions>
);

const isCreateAllowed = (resource, role) => {
  return !(((resource === 'Owner' || resource === 'Order') && role === 'Owner') || (resource === 'Breed' && role !== 'Admin') || (resource === 'Vet' && role === 'Vet') || ((resource === 'Vet' || resource === 'Clinic') && role === 'Clinic') || role === 'ProductionManager' );
};