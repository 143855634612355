import React, {Component} from 'react';
import {Button, REDUX_FORM_NAME} from 'react-admin';
import ContentSave from '@material-ui/icons/Save';
import {showNotification} from 'ra-core';
import {connect} from 'react-redux';
import {customCrudCreate} from './customCrudCreate';
import {getCurrentWeekNumber, getFirstDayOfWeek, getTodayTimeRange} from '../utils/Helper';
import {isEqual, clone} from 'lodash';
import {calculateConsultingFeeDetails} from './calculateConsultingFeeDetails';
import {GET_ORDER_DATA, GET_CLINIC_ORDER_DATA, GET_TODAYS_ORDERS} from './../customQueries';
import apolloClient from './../initApollo';

const orderTypes = {
  Normal: 'G',
  ByBreed: 'BR',
  VIP: 'VIP'
};

const animalTypes = {
  Cat: 'C',
  Dog: 'D'
};

class OrderSaveButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      consultingFeeDetails: null
    }
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState, x) {
    const prevData = clone(prevProps.formData);
    const currentData = clone(this.props.formData);

    let isChanged = !isEqual(prevData, currentData);

    if (isChanged && currentData && currentData.clinic && currentData.clinic.id && currentData.price) {
      calculateConsultingFeeDetails(currentData).then(consultingFeeDetails => {
        if (this._isMounted) this.setState({consultingFeeDetails})
      });
    }
  }

  getOrderId = (ownerId, animalId, vetId, orderType, animalType) => {
    return apolloClient.query({query: GET_ORDER_DATA, variables: {ownerId, animalId, vetId}, fetchPolicy: 'no-cache'})
      .then(async response => {
        if (response.loading) return;

        let idNumber = await this.getIdNumber(orderType, animalType);

        return idNumber;
      });
  };

  getClinicOrderId = (clinicId, vetId, orderType, animalType) => {
    return apolloClient.query({query: GET_CLINIC_ORDER_DATA, variables: {clinicId, vetId}, fetchPolicy: 'no-cache'})
      .then(async response => {
        if (response.loading) return;

        let idNumber = await this.getIdNumber(orderType, animalType);

        return idNumber;
      });
  };

  getIdNumber = (orderType, animalType) => {
    const {minTime, maxTime, yFormat, mFormat, dFormat} = getTodayTimeRange();
    let orderTypeString = orderTypes[orderType];
    let animalTypeString = animalTypes[animalType];

    return apolloClient.query({
      query: GET_TODAYS_ORDERS,
      variables: {minTime, maxTime},
      fetchPolicy: 'no-cache'
    }).then(response => {
      if (response.loading) return;

      let dailyOrdersNumber = ("00" + ((response.data.orders.length + 1).toString())).slice(-3);
      return `${orderTypeString}${animalTypeString}${yFormat}${mFormat}${dFormat}${dailyOrdersNumber}`;
    })
  };

  handleClick = () => {
    const {
      invalid,
      showNotification,
      handleSubmitWithRedirect,
      redirect,
      resource,
      createResource,
      basePath,
      formData
    } = this.props;

    if (invalid) {
      showNotification('ra.message.invalid_form', 'warning');
      handleSubmitWithRedirect(redirect)();
    } else {
      const handleGetOrderIdResult = orderId => {
        const {consultingFeeDetails} = this.state;
        const data = {
          idNumber: orderId,
          ownerName: formData.ownerName,
          city: formData.city,
          street: formData.street,
          streetNumber: formData.streetNumber,
          buildingNumber: formData.buildingNumber,
          district: formData.district,
          entrance: formData.entrance,
          floor: formData.floor,
          apartmentNumber: formData.apartmentNumber,
          postcode: formData.postcode,
          phoneNumber: formData.phoneNumber,
          clinic: formData.clinic,
          vet: formData.vet,
          owner: formData.owner,
          animal: formData.animal,
          animalType: formData.animalType,
          ageGroup: formData.ageGroup,
          ageGroupName: formData.ageGroupName,
          breed: formData.breed,
          dateCreated: formData.dateCreated,
          orderType: formData.orderType,
          price: parseFloat(formData.price.toFixed(2)),
          quantity: formData.quantity,
          sizeClass: formData.sizeClass,
          status: formData.status,
          weight: formData.weight,
          weightPricePair: formData.weightPricePair,
          taste: formData.taste,
          testerCode: formData.testerCode,
          comments: formData.comments,
          weekNumber: getCurrentWeekNumber(),
          startOfWeek: getFirstDayOfWeek(new Date()),
          percentagesVet: consultingFeeDetails ? consultingFeeDetails.percentagesVet : null,
          percentagesClinic: consultingFeeDetails ? consultingFeeDetails.percentagesClinic : null,
          weeklyTurnoverVet: consultingFeeDetails ? consultingFeeDetails.weeklyTurnoverVet : null,
          weeklyTurnoverClinic: consultingFeeDetails ? consultingFeeDetails.weeklyTurnoverClinic : null,
          consultingFeeVet: consultingFeeDetails ? consultingFeeDetails.consultingFeeVet : null,
          currentConsultingFeeVet: consultingFeeDetails ? consultingFeeDetails.currentConsultingFeeVet : null,
          consultingFeeClinic: consultingFeeDetails ? consultingFeeDetails.consultingFeeClinic : null,
          currentConsultingFeeClinic: consultingFeeDetails ? consultingFeeDetails.currentConsultingFeeClinic : null,
          consultingPercentageClinic: consultingFeeDetails ? consultingFeeDetails.consultingPercentageClinic : null,
          consultingPercentageVet: consultingFeeDetails ? consultingFeeDetails.consultingPercentageVet : null,
        };
        // Handles breed = null when submiting order with type normal
        // May be this should be handled on the server side
        if (!data.breed) {
          data.breed = {}
        }

        createResource(resource, data, basePath, null, redirect);
      }

      if (formData.owner && formData.owner.id) {
        this.getOrderId(formData.owner.id, formData.animal.id, formData.vet.id, formData.orderType, formData.animalType)
          .then(handleGetOrderIdResult);
      } else {
        this.getClinicOrderId(formData.clinic.id, formData.vet.id, formData.orderType, formData.animalType)
          .then(handleGetOrderIdResult)
      }
    }
  };

  render() {
    return (
      <Button
        label='ra.action.save'
        onClick={this.handleClick}
        color='primary'
        variant='raised'
        size="medium"
      >
        <ContentSave/>
      </Button>
    );
  }
}

const mapFormStateToProps = state => {
  let values = null;
  let form = state.form[REDUX_FORM_NAME];
  if (form) {
    values = form.values || null
  }

  return {formData: values};
};

export default connect(mapFormStateToProps, {showNotification, createResource: customCrudCreate})(OrderSaveButton);
