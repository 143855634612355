import React from 'react';
import { List, Datagrid, TextField, FunctionField } from 'react-admin';
import { t } from 'i18next';
import { withStyles } from '@material-ui/core/styles';

import { CustomBulkActions } from '../CustomBulkActions';
import { CustomListActions } from '../CustomListActions';

const styles = {
  image: {
    width: '50px',
    height: 'auto'
  }
};

const TasteListComponent = props => (
  <List {...props} bulkActionButtons={<CustomBulkActions />} actions={<CustomListActions />} title={t('tastes.title_list')}>
    <Datagrid rowClick='edit'>
      <TextField source='name' label={t('name')} />
      <FunctionField label={t('tastes.picture')} render={(record) => record.picture ? <img src={record.picture} alt={record.name} className={props.classes.image}/> : null} />
    </Datagrid>
  </List>
);

export const TasteList = withStyles(styles)(TasteListComponent);
