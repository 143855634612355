import { EmailTemplateCreate } from '../components/EmailTemplate/EmailTemplateCreate';
import { EmailTemplateList } from '../components/EmailTemplate/EmailTemplateList';
import { EmailTemplateEdit } from '../components/EmailTemplate/EmailTemplateEdit';

import { Email } from '@material-ui/icons';

export const EmailTemplates = {
  list: EmailTemplateList,
  create: EmailTemplateCreate,
  edit: EmailTemplateEdit,
  icon: Email
};