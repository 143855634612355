import React from 'react';
import { List, Datagrid, TextField } from 'react-admin';
import { t } from 'i18next';

import { CustomListActions } from '../CustomListActions';
import { CustomBulkActions } from '../CustomBulkActions';

export const EmailTemplateList = props => (
  <List {...props} bulkActionButtons={<CustomBulkActions/>} actions={<CustomListActions/>} title={t('email_templates.templates')}>
    <Datagrid rowClick='edit'>
      <TextField source='name' label={t('name')} />
    </Datagrid>
  </List>
);
