import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  ReferenceField,
  Filter,
  TextInput
} from 'react-admin';
import { t } from 'i18next';

import { CustomBulkActions } from '../CustomBulkActions';
import { CustomListActions } from '../CustomListActions';

const IngredientFilter = props => (
  <Filter {...props}>
    <TextInput label={t('name')} source='name_contains' alwaysOn/>
  </Filter>
);

export const IngredientList = props => (
  <List
    {...props}
    bulkActionButtons={<CustomBulkActions />}
    actions={<CustomListActions />}
    title={t('ingredient_details.title_list')}
    filters={<IngredientFilter/>}
  >
    <Datagrid rowClick='edit'>
      <TextField source='name' label={t('name')}/>
      <BooleanField source='isHumanUsable' label={t('ingredient_details.human_usable')} />
      <ReferenceField label={t('product_group')} reference='ProductGroup' source='productGroup.id' allowEmpty={true} sortable={false}>
        <TextField source='name'/>
      </ReferenceField>
    </Datagrid>
  </List>
);
