import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { withStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
};

export const LoadingIndicator = ({ classes = {} }) => (
  <div className={classes.root}>
    <div>{t('loading')}</div>
    <div><CircularProgress /></div>
  </div>
);

LoadingIndicator.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(LoadingIndicator);