import gql from 'graphql-tag';

export const GET_MATERIALS = gql`
  query {
    materials {
      id
      moisture
      name
      price
      nutrients
      ingredients {
        productGroup {
          id
          name
        }
      }
    }
  }
`;

export const GET_UNITS = gql`
  query {
    units {
      id
      name
      coefficientPerKilogram
    }
  }
`;

export const GET_ALL_PROVINCES = gql`
  query {
    provinces {
      id
      name
      code
    }
  }
`;

export const GET_ALL_MUNICIPALITIES = gql`
  query {
    municipalities {
      id
      name
      code
      province {
        name
      }
    }
  }
`;

export const GET_ALL_CITIES = gql`
  query {
    cities {
      id
      name
    }
  }
`;

export const GET_BG_COUNTRY = gql`
  query {
    countries(where: { isoCode: "BG" }) {
      id
    }
  }
`;

export const GET_ORDER = gql`
  query($id: ID!) {
    order(where: { id: $id }) {
      id
      idNumber
      dateCreated
      animalType
      breed { id breedNameBg }
      sizeClass
      ageGroup
      ageGroupName
      weight
      quantity
      dateCreated
      animal {
        id
        name
        animalType
        birthDate
        breed {
          id
          breedNameBg
          sizeClass
        }
        profilePicture
        weight
      }
      taste {
        name
        picture
      }
      recipe {
        id
        frontLabelUrl
        backLabelUrl
        digitalLabelId
      }
    }
  }
`;

export const UPLOAD_FILE_TO_S3 = gql`
  mutation($fileName: String!, $fileSize: Int!, $fileType: String!, $fileContent: Json) {
    uploadToS3(fileName: $fileName, fileSize: $fileSize, fileType: $fileType, fileContent: $fileContent) {
      url
    }
  }
`;

export const STORE_FILE = gql`
  mutation($name: String!, $url: String!, $size: Int!, $type: String!, $animalId: ID!, $dateCreated: DateTime!) {
    createFile(data: { name: $name, url: $url, size: $size, type: $type, animal: { connect: { id: $animalId } }, dateCreated: $dateCreated }) {
      id
      url
      dateCreated
      animal {
        id
      }
      name
    }
  }
`;

export const DELETE_FILES_OF_ANIMAL = (nodeName) => gql`
  mutation($id: ID!, $filesIds: [ID!]) {
    update${nodeName}(data: { files: { deleteMany: { id_in: $filesIds } } } where: { id: $id }) {
      id
      files {
        id
      }
    }
  }
`;

export const UPDATE_NODE_WITH_IMAGE = (nodeName, fieldName) => gql`
  mutation($id: ID!, $imagePath: String) {
    update${nodeName}(data: { ${fieldName}: $imagePath } where: { id: $id }) {
      id
      ${fieldName}
    }
  }
`;

export const DELETE_IMAGE = (nodeName, fieldName) => gql`
  mutation($id: ID!) {
    update${nodeName}(data: { ${fieldName}: null } where: { id: $id }) {
      id
      ${fieldName}
    }
  }
`;

export const GET_FILTERED_CITIES = gql`
  query($name: String) {
    cities(where: { name_contains: $name }) {
      id
      name
      type
      municipality {
        name
        province {
          name
        }
      }
    }
  }
`;

export const GET_NUTRIENT_INFO = gql`
  query($nutrientIds: [ID!], $nutrientGroupIds: [ID!], $nutrientElementIds: [ID!]) {
    filterNutrients(nutrientIds: $nutrientIds, nutrientGroupIds: $nutrientGroupIds, nutrientElementIds: $nutrientElementIds)
  }
`;

export const GET_ALL_OWNERS = gql`
  query($name: String) {
    owners(where: { name_contains: $name }) {
      id
      name
      email
    }
  }
`;

export const GET_ALL_ACTIVE_OWNERS = gql`
  query($name: String) {
    owners(where: { name_contains: $name, active: true }) {
      id
      name
      email
    }
  }
`;

export const GET_ALL_BREEDS = gql`
  query {
    breeds {
      id
      breedNameBg
    }
  }
`;

export const GET_ANIMAL_HISTORY_RECORDS = gql`
  query($animalId: String!) {
    animalHistories(where: { animal: $animalId }, orderBy: date_DESC) {
      id
      user
      date
      data
    }
  }
`;

export const GET_FILTERED_MATERIALS = gql`
  query($name: String) {
    materials(where: { name_contains: $name }) {
      id
      moisture
      name
      price
      nutrients
      ingredients {
        productGroup {
          id
          name
        }
      }
    }
  }
`;

export const GET_FILTERED_BREEDS = gql`
  query($name: String) {
    breeds(where: { breedNameBg_contains: $name, supportedByOptimus: true }) {
      id
      breedNameBg
    }
  }
`;

export const GET_FILTERED_BREEDS_BY_TYPE = gql`
  query($name: String, $animalType: AnimalType!) {
    breeds(where: { breedNameBg_contains: $name, animalType: $animalType, supportedByOptimus: true }) {
      id
      breedNameBg
    }
  }
`;

export const GET_FILTERED_BREEDS_BY_TYPE_WITHOUT_MIX = gql`
  query getFilteredBreeds($name: String, $animalType: AnimalType!, $mixedBreed: String) {
    breeds(where: { breedNameBg_contains: $name, breedNameBg_not_contains: $mixedBreed, animalType: $animalType, supportedByOptimus: true }) {
      id
      breedNameBg
    }
  }
`;

export const GET_PRODUCT_GROUPS = gql`
  query {
    productGroups {
      id
      name
    }
  }
`;

export const SUBSCRIBE_TO_CREATE = (resourceName, nodes) => gql`
  subscription {
    ${resourceName}(where: { mutation_in: [CREATED] }) {
      ${nodes}
    }
  }
`;

export const GET_CONSULTING_FEE_VET_PERCENT = gql`
  query($vetId: ID!) {
    consultingFeePercentageVets(where: { vet: { id: $vetId } }, orderBy: weeklyTurnoverTo_ASC) {
      id
      vet { id }
      clinic { id }
      weeklyTurnoverTo
      consultingFeePercentage
    }
  }
`;

export const GET_CONSULTING_FEE_CLINIC_PERCENT = gql`
  query($clinicId: ID!) {
    consultingFeePercentageClinics(where: { clinic: { id: $clinicId } }, orderBy: weeklyTurnoverTo_ASC) {
      id
      clinic { id }
      weeklyTurnoverTo
      consultingFeePercentage
    }
  }
`;

export const GET_RECIPE = gql`
  query($id: ID!) {
    recipe(where: { id: $id }) {
      order {
        animalType
        orderType
        breed {
          breedNameBg
          breedNameEn
          pictureJunior
          pictureAdult
          pictureGeriatric
          pictureKitten
          pictureMature
          picturePrime
          picturePuppy
          pictureSenior
          pictureYoungAdult
          pictureMatureAdult
        }
        sizeClass
        ageGroup
        ageGroupName
        idNumber
        weight
        quantity
        dateCreated
        animal {
          id
          name
          animalType
          birthDate
          breed {
            id
            breedNameBg
            sizeClass
            breedNameEn
          }
          profilePicture
          weight
        }
        taste {
          name
          picture
        }
      }
      id
      materialQuantities
      moistureDetails
      nutrients
      energy
      totalPrice
      hiddenNutrients
      highQualityAnimalProtein
      weightPerBowl
      bestBefore
      dailyDose
      frontLabelUrl
      backLabelUrl
      digitalLabelId
    }
  }
`;

export const SAVE_RECIPE_FIELDS_FOR_LABEL = gql`
  mutation($id: ID!, $frontLabelUrl: String, $backLabelUrl: String, $digitalLabelId: String, $labelContent: Json, $nutrients: Json) {
    updateRecipe(data: { frontLabelUrl: $frontLabelUrl, backLabelUrl: $backLabelUrl, digitalLabelId: $digitalLabelId, labelContent: $labelContent, nutrients: $nutrients } where: { id: $id }) {
      id
      frontLabelUrl
      backLabelUrl
      digitalLabelId
      labelContent
      nutrients
    }
  }
`;

export const GET_ADDITIONAL_DATA_FOR_LABEL = gql`
  query {
    materials {
      id
      name
      ingredients {
        id
        name
        isHumanUsable
        productGroup {
          name
        }
      }
    }
    units {
      id
      name
      coefficientPerKilogram
    }
  }
`;

export const GET_ANIMAL_INFO = gql`
  query($animalId: ID!) {
    animal(where: { id: $animalId }) {
      id
      animalType
      appetite
      birthDate
      breed { id breedNameBg breedNameEn sizeClass priceCoefficient }
      breedFather { id breedNameBg }
      breedMother { id breedNameBg }
      breeding
      castrated
      chipNumber
      coatType
      coatColor
      comment
      dailyWalkDuration
      esophagus
      excrementConsistency
      fertilizationDate
      files { id name url}
      foodBrand
      gender
      givingBirthDate
      gums
      hairStructure
      homemadeFood
      hunt
      identifiedFoodAllergy
      intestines
      jointsAndTiesCondition
      livingPlace
      name
      otherSport
      owner { id name }
      preferredTaste
      profilePicture
      skinCondition
      sport
      stomach
      suspectedFoodAllergy
      tattooNumber
      teeth
      tongueAndMucousMembranes
      walkIntensity
      weight
      withersHeight
      workType
      workUsage
    }
  }
`;

export const SAVE_HIDDEN_RECIPE_ITEMS = gql`
  mutation($id: ID!, $hiddenNutrients: Json) {
    updateRecipe(data: { hiddenNutrients: $hiddenNutrients } where: { id: $id }) {
      id
      hiddenNutrients
    }
  }
`;

export const GET_ALL_AGE_GROUPS = gql`
  query($sizeClass: SizeClass) {
    ageGroupCats(orderBy: months_ASC) {
      months
      ageGroup
      id
    }
    ageGroupDogs(where: { sizeClass: $sizeClass } orderBy: months_ASC) {
      months
      ageGroup
      sizeClass
      id
    }
  }
`;

export const GET_AGE_GROUP_CAT = gql`
  query($months: Int!) {
    ageGroupCats(where: { months_gte: $months }, orderBy: months_ASC, first: 1) {
      months
      ageGroup
      id
      priceByWeightGeneral
      priceByWeight
      priceByWeightVIP
    }
  }
`;

export const GET_AGE_GROUP_DOG = gql`
  query($months: Int!, $sizeClass: SizeClass!) {
    ageGroupDogs(where: { months_gte: $months, sizeClass: $sizeClass }, orderBy: months_ASC, first: 1) {
      months
      ageGroup
      id
      priceByWeightGeneral
      priceByWeight
      priceByWeightVIP
    }
  }
`;

export const GET_SIZE_CLASS_MIX = gql`
  query($months: Int!) {
    sizeClassMixes(where: { months: $months }) {
      mini
      small
      medium
      large
      giant
    }
  }
`;

export const GET_ORDER_DETAILS = gql`
  query($id: ID!) {
    order(where: { id: $id }) {
      animalType
      breed { id }
      sizeClass
      ageGroup
      animal {
        id
      }
    }
  }
`;

export const GET_LABEL_CONTENT = gql`
  query($digitalLabelId: String) {
    recipe(where: { digitalLabelId: $digitalLabelId }) {
      labelContent
      dailyDose
      order {
        animal {
          name
        }
        weight
      }
    }
  }
`;

export const GET_ORDERS_BY_VET_AND_WEEK = gql`
  query($vetId: ID!, $startOfWeek: DateTime!) {
    orders(where: { vet: { id: $vetId }, startOfWeek: $startOfWeek }) {
      id
      idNumber
      price
    }
  }
`;

export const GET_ORDERS_BY_CLINIC_AND_WEEK = gql`
  query($clinicId: ID!, $startOfWeek: DateTime!) {
    orders(where: { clinic: { id: $clinicId }, startOfWeek: $startOfWeek }) {
      id
      idNumber
      price
    }
  }
`;

export const GET_ORDERS_BY_CLINIC = gql`
  query($clinicId: ID!) {
    orders(where: { clinic: { id: $clinicId } }, orderBy: dateCreated_DESC) {
      weekNumber
      startOfWeek
      price
      weeklyTurnoverClinic
      consultingFeeClinic
      currentConsultingFeeClinic
      consultingFeeVet
      currentConsultingFeeVet
      consultingPercentageClinic
      consultingPercentageVet
      idNumber
      id
      vet {
        id
        name
      }
    }
  }
`;

export const GET_ORDERS_BY_VET = gql`
  query($vetId: ID!) {
    orders(where: { vet: { id: $vetId } }, orderBy: dateCreated_DESC) {
      weekNumber
      startOfWeek
      price
      weeklyTurnoverVet
      consultingFeeVet
      currentConsultingFeeVet
      consultingPercentageClinic
      consultingPercentageVet
      idNumber
      id
    }
  }
`;

export const GET_ORDERS_BY_VET_USER = gql`
    query($vetId: ID!) {
        orders(where: { vet: { id: $vetId } }, orderBy: dateCreated_DESC) {
            weekNumber
            startOfWeek
            price
            weeklyTurnoverVet
            consultingFeeVet
            currentConsultingFeeVet
            consultingPercentageVet
            idNumber
            id
        }
    }
`;


export const GET_ORDERS_BY_WEEK = gql`
  query($startOfWeek: DateTime!) {
    orders(where: { startOfWeek: $startOfWeek }, orderBy: dateCreated_DESC) {
      weekNumber
      startOfWeek
      price
      weeklyTurnoverClinic
      consultingFeeClinic
      currentConsultingFeeClinic
      idNumber
      id
      vet {
        id
        name
      }
      clinic {
        id
        name
      }
    }
  }
`;

export const GET_ALL_EXCHANGE_WEIGHT = (sizeClass) => gql`
  query {
    exchangeWeight${sizeClass}s {
      id
      liveWeightTo
      exchangeWeight
    }
  }
`;

export const GET_EXCHANGE_WEIGHT_BY_WEIGHT = (sizeClass) => gql`
  query($weight: Float!) {
    exchangeWeight${sizeClass}s(where: { liveWeightTo_gte: $weight } orderBy: liveWeightTo_ASC, first: 1) {
      exchangeWeight
    }
  }
`;

export const GET_ENERGY_REQUIREMENT_DOG = gql`
  query($months: Int!, $days: Int!, $sizeClass: SizeClass!){
    energyRequirementDogs(where: {AND: [{OR: [{months_gt: $months}, {AND: [{months: $months}, {days_gte: $days}]}]}, {sizeClass: $sizeClass}]}, orderBy: months_ASC, first: 1){
      energy
    }
  }
`;

export const GET_ORDER_DATA = gql`
  query($ownerId: ID!, $animalId: ID!, $vetId: ID!) {
    owner(where: { id: $ownerId }) {
      name
    }
    animal(where: { id: $animalId }) {
      name
    }
    vet(where: { id: $vetId }) {
      name
    }
  }
`;

export const GET_CLINIC_ORDER_DATA = gql`
  query($clinicId: ID!, $vetId: ID!) {
    clinic(where: { id: $clinicId }) {
      name
    }
    vet(where: { id: $vetId }) {
      name
    }
  }
`;

export const GET_COUNT_OF_ANIMAL_CHANGES_AFTER_DATE = gql`
  query($animal: String!, $date: DateTime!) {
    animalHistories(where: { animal: $animal, date_gt: $date }) {
      id
    }
  }
`;

export const GET_ANIMAL_AND_DATE_OF_ORDER = gql`
  query($id: ID!) {
    order(where: { id: $id }) {
      id
      dateCreated
      animal {
        id
      }
    }
  }
`;

export const UPDATE_ORDER_HASRECIPE = gql`
  mutation($id: ID!, $hasRecipe: String!) {
    updateOrder(data: { hasRecipe: $hasRecipe }, where: { id: $id }) {
      id
      hasRecipe
    }
  }
`;

export const GET_OWNER_HISTORY_RECORDS = gql`
  query($ownerId: String!) {
    ownerHistories(where: { owner: $ownerId }, orderBy: date_DESC) {
      id
      user
      date
      data
    }
  }
`;

export const GET_ALL_VETS = gql`
  query($name: String) {
    vets(where: { name_contains: $name }) {
      id
      name
      email
    }
  }
`;

export const GET_ALL_ACTIVE_VETS = gql`
  query($name: String) {
    vets(where: { name_contains: $name, active: true }) {
      id
      name
      email
    }
  }
`;

export const TRIGGER_ORDER_STATUS_CHANGE = gql`
  query($statusFrom: ID!, $statusTo: ID!, $orderId: ID!, $orderNumber: String!, $orderLink: String!) {
    triggerOrderStatusChange(statusFrom: $statusFrom, statusTo: $statusTo, orderId: $orderId, orderNumber: $orderNumber, orderLink: $orderLink)
  }
`;

export const GET_ALL_STATUSES = gql`
  query {
    statuses {
      id
      nameBg
    }
  }
`;

export const GET_ORDER_HISTORY_RECORDS = gql`
  query($orderId: String!) {
    orderHistories(where: { order: $orderId }, orderBy: date_DESC) {
      id
      user
      date
      data
    }
  }
`;

export const GET_ALL_TASTES = gql`
  query {
    tastes {
      id
      name
    }
  }
`;

export const GET_HISTORY = gql`
  query($key: String!) {
    histories(where: { key: $key }) {
      id
      date
      oldData
      newData
      user
    }
  }
`;

export const GET_ALL_ANIMALS = gql`
  query {
    animals {
      id
      name
    }
  }
`;

export const GET_ALL_ANIMALS_WITH_NAME = gql`
  query($name: String) {
    animals(where: { name_contains: $name }) {
      id
      name
    }
  }
`;

export const GET_ALL_CLINICS = gql`
  query($name: String) {
    clinics(where: { name_contains: $name }) {
      id
      name
      email
    }
  }
`;

export const GET_ALL_ACTIVE_CLINICS = gql`
  query($name: String) {
    clinics(where: { name_contains: $name, active: true }) {
      id
      name
      email
    }
  }
`;

export const GET_RECIPE_LABEL_LINKS = gql`
  query($id: ID!) {
    recipe(where: { id: $id }) {
      frontLabelUrl
      backLabelUrl
      digitalLabelId
    }
  }
`;

export const GET_RECIPE_LABEL_LINKS_BY_ORDER = gql`
  query($id: ID!) {
    orders(where: { id: $id }) {
      recipe {
        id
        frontLabelUrl
        backLabelUrl
        digitalLabelId
      }
    }
  }
`;

export const GET_UI_VALUES = gql`
  query {
    nutrientGroups(where: { uiValue_not: null }) {
      id
      uiValue
    }
    nutrientElements(where: { uiValue_not: null }) {
      id
      uiValue
    }
  }
`;

export const GET_CITIES_BY_IDS = gql`
  query($ids: [ID!]) {
    cities(where: { id_in: $ids }) {
      id
      name
    }
  }
`;

export const GET_BREEDS_BY_ANIMAL_TYPE_WITH_RECIPES = gql`
  query($name: String, $animalType: AnimalType!, $recipe: ID) {
    breeds(where: { breedNameBg_contains: $name, orders_some: { recipe: { id_not: $recipe } }, animalType: $animalType, supportedByOptimus: true }) {
      id
      breedNameBg
    }
  }
`;

export const GET_NORMAL_PACKAGE_DETAILS_DOG = gql`
  query($id: ID!, $sizeClass: SizeClass) {
    ageGroupDogs(where: { id: $id, sizeClass: $sizeClass }) {
      priceByWeightGeneral
    }
  }
`;

export const GET_NORMAL_PACKAGE_DETAILS_CAT = gql`
  query($id: ID!) {
    ageGroupCats(where: { id: $id }) {
      priceByWeightGeneral
    }
  }
`;


export const GET_BREED_PACKAGE_DETAILS_DOG = gql`
  query($id: ID!, $sizeClass: SizeClass) {
    ageGroupDogs(where: { id: $id, sizeClass: $sizeClass }) {
      priceByWeight
    }
  }
`;

export const GET_BREED_PACKAGE_DETAILS_CAT = gql`
  query($id: ID!) {
    ageGroupCats(where: { id: $id }) {
      priceByWeight
    }
  }
`;

export const GET_SIZE_CLASS_AND_COEFFICIENT_BY_BREED = gql`
  query($id: ID!) {
    breeds(where: { id: $id }) {
      sizeClass
      priceCoefficient
    }
  }
`;

export const GET_ALL_VETS_BY_CLINIC = gql`
  query($name: String, $clinicId: ID) {
    vets(where: { name_contains: $name, clinic: { id: $clinicId } }) {
      id
      name
    }
  }
`;

export const GET_ALL_VETS_BY_CLINIC_EMAIL = gql`
  query($name: String, $clinic: String) {
    vets(where: { name_contains: $name, clinic: { email: $clinic } }) {
      id
      name
    }
  }
`;

export const GET_ALL_OWNERS_BY_VET = gql`
  query($name: String, $vetId: ID) {
    owners(where: { name_contains: $name, vet: { id: $vetId } }) {
      id
      name
    }
  }
`;

export const GET_ALL_OWNERS_BY_VET_EMAIL = gql`
  query($name: String, $vet: String) {
    owners(where: { name_contains: $name, vet: { email: $vet } }) {
      id
      name
    }
  }
`;

export const GET_ALL_OWNERS_BY_CLINIC = gql`
  query($name: String, $clinic: String) {
    owners(where: { name_contains: $name, vet: { clinic: { email: $clinic } } }) {
      id
      name
    }
  }
`;


export const GET_ALL_ANIMALS_BY_OWNER = gql`
  query($name: String, $ownerId: ID) {
    animals(where: { name_contains: $name, owner: { id: $ownerId } }) {
      id
      name
    }
  }
`;


export const GET_TOTAL_NODES_COUNT = node => gql`
  query {
    entities: ${node} {
      id
    }
  }
`;

export const GET_TOTAL_ORDERS_BY_BREED = gql`
  query($minDate: DateTime!, $maxDate: DateTime!) {
    allOrders: orders(where: { orderType: ByBreed }) {
      id
    }

    firstOrderDate: orders(first: 1, orderBy: dateCreated_ASC, where: { orderType: ByBreed }) {
      dateCreated
    }

    ordersCurMonth: orders(where: { orderType: ByBreed, dateCreated_lte: $maxDate, dateCreated_gte: $minDate }) {
      id
    }
  }
`;

export const GET_TOTAL_DATA = gql`
  query($minDate: DateTime!, $maxDate: DateTime!, $type: String) {
    historyData: histories(where: { key_starts_with: $type, date_lte: $maxDate, date_gte: $minDate }) {
      id
      oldData
    }

    firstDate: histories(first:1, orderBy: date_ASC, where: { key_starts_with: $type }) {
      date
    }
  }
`;

export const GET_TOTAL_ORDERS_DATA = gql`
  query {
    orders(orderBy: dateCreated_ASC) {
      dateCreated
    }
  }
`;

export const GET_TOTAL_REVENUE = gql`
  query($minDate: DateTime!, $maxDate: DateTime!) {
    allPrices: orders {
      price
    }

    firstOrderDate: orders(first: 1, orderBy: dateCreated_ASC) {
      dateCreated
    }

    allPricesCurMonth: orders(where: { dateCreated_lte: $maxDate, dateCreated_gte: $minDate }) {
      price
    }
  }
`;

export const GET_TOTAL_NEW_CLIENTS = gql`
  query($minDateCur: DateTime!, $maxDateCur: DateTime!, $minDatePrev: DateTime!, $maxDatePrev: DateTime!) {
    allCurMonth: histories(where: { key_starts_with: "Owner", date_lte: $maxDateCur, date_gte: $minDateCur }) {
      id
      oldData
    }

    allPrevMonth: histories(where: { key_starts_with: "Owner", date_lte: $maxDatePrev, date_gte: $minDatePrev }) {
      id
      oldData
    }
  }
`;

export const GET_TOTAL_ORDERS_PREV_AND_CUR_MONTH = gql`
  query($minDatePrev: DateTime!, $maxDatePrev: DateTime!, $minDateCur: DateTime!, $maxDateCur: DateTime!) {
    prevMonthOrderCount: orders(where: { dateCreated_lte: $maxDatePrev, dateCreated_gte: $minDatePrev }) {
      id
    }

    curMonthOrderCount: orders(where: { dateCreated_lte: $maxDateCur, dateCreated_gte: $minDateCur }) {
      id
    }
  }
`;

export const GET_TOTAL_CLINIC_ORDERS = gql`
  query($minDate: DateTime!, $maxDate: DateTime!) {
    totalOrders: orders(where: { owner: null }) {
      id
    }

    firstOrderDate: orders(first: 1, orderBy: dateCreated_ASC) {
      dateCreated
    }

    totalOrdersCurMonth: orders(where: { owner: null, dateCreated_lte: $maxDate, dateCreated_gte: $minDate }) {
      id
    }
  }
`;

export const GET_TOTAL_REVENUE_PREV_AND_CUR_MONTH = gql`
  query($minDatePrev: DateTime!, $maxDatePrev: DateTime!, $minDateCur: DateTime!, $maxDateCur: DateTime!) {
    prevMonthPrices: orders(where: { dateCreated_lte: $maxDatePrev, dateCreated_gte: $minDatePrev }) {
      price
    }

    curMonthPrices: orders(where: { dateCreated_lte: $maxDateCur, dateCreated_gte: $minDateCur }) {
      price
    }
  }
`;

export const GET_ORDER_COUNTS = gql`
  query($minDateCur: DateTime!, $maxDateCur: DateTime!, $minDatePrev: DateTime!, $maxDatePrev: DateTime!) {
    allDogOrders: orders(where: { animalType: Dog }) {
      id
    }

    allCatOrders: orders(where: { animalType: Cat }) {
      id
    }

    allDogOrdersCurMonth: orders(where: { animalType: Dog, dateCreated_lte: $maxDateCur, dateCreated_gte: $minDateCur }) {
      id
    }

    allCatOrdersCurMonth: orders(where: { animalType: Cat, dateCreated_lte: $maxDateCur, dateCreated_gte: $minDateCur }) {
      id
    }

    allDogOrdersPrevMonth: orders(where: { animalType: Dog, dateCreated_lte: $maxDatePrev, dateCreated_gte: $minDatePrev }) {
      id
    }

    allCatOrdersPrevMonth: orders(where: { animalType: Cat, dateCreated_lte: $maxDatePrev, dateCreated_gte: $minDatePrev }) {
      id
    }
  }
`;

export const GET_ANIMAL_DATA = gql`
  query {
    orders {
      price
      animalType
      dateCreated
      sizeClass
    }
  }
`;

export const GET_PRODUCTION = gql`
  query($minDate: DateTime!, $maxDate: DateTime!) {
    orders(where: { dateCreated_lte: $maxDate, dateCreated_gte: $minDate }) {
      weight
      quantity
    }
  }
`;

export const GET_STATUS_NAME_BY_ID = gql`
  query($id: ID!) {
    status(where: { id: $id }) {
      nameBg
    }
  }
`;

export const GET_STATUS_ID_BY_NAME = gql`
  query($name: String!) {
    statuses(where: { nameEn_contains: $name }) {
      id
    }
  }
`;

export const GET_SIZE_CLASS_BY_BREED = gql`
  query($id: ID!) {
    breeds(where: { id: $id }) {
      sizeClass
    }
  }
`;

export const GET_AGE_GROUP_NAME_BY_ANIMAL_TYPE = (animalType) => gql`
  query($id: ID!) {
    ageGroups: ageGroup${animalType}(where: { id: $id }) {
      ageGroup
    }
  }
`;

export const GET_MATERIAL_QUANTITY = gql`
  query($minDate: DateTime!, $maxDate: DateTime!) {
    orders(where: { dateCreated_lte: $maxDate, dateCreated_gte: $minDate }) {
      recipe {
        materialQuantities
      }
    }

    units {
      id
      coefficientPerKilogram
    }
  }
`;

export const CREATE_OWNER_NODE = gql`
  mutation($active: Boolean, $email: String!, $name: String, $city: ID, $street: String, $streetNumber: String, $buildingNumber: String, $district: String, $entrance: String, $floor: String, $apartmentNumber: String, $country: ID, $postcode: String, $phoneNumber: String, $vet: ID) {
    createOwner(data: { active: $active, email: $email, name: $name, city: { connect: { id: $city } }, street: $street, streetNumber: $streetNumber, buildingNumber: $buildingNumber, district: $district, entrance: $entrance, floor: $floor, apartmentNumber: $apartmentNumber, country: { connect: { id: $country } }, postcode: $postcode, phoneNumber: $phoneNumber, vet: { connect: { id: $vet } } }) {
      id
    }
  }
`;

export const CREATE_VET_NODE = gql`
  mutation($active: Boolean, $email: String!, $name: String, $phoneNumber: String, $postcode: String, $street: String, $streetNumber: String, $buildingNumber: String, $district: String, $entrance: String, $floor: String, $apartmentNumber: String, $city: ID, $clinic: ID) {
    createVet(data: { active: $active, email: $email, name: $name, phoneNumber: $phoneNumber, postcode: $postcode, street: $street, streetNumber: $streetNumber, buildingNumber: $buildingNumber, district: $district, entrance: $entrance, floor: $floor, apartmentNumber: $apartmentNumber, city: { connect: { id: $city } }, clinic: { connect: { id: $clinic } } }) {
      id
    }
  }
`;

export const CREATE_CLINIC_NODE = gql`
  mutation($active: Boolean, $email: String, $name: String!, $lat: String, $lng: String, $phoneNumber: String!, $postcode: String!, $street: String, $streetNumber: String, $buildingNumber: String, $district: String, $entrance: String, $floor: String, $apartmentNumber: String, $city: ID) {
    createClinic(data: { active: $active, email: $email, name: $name, lat: $lat, lng: $lng, phoneNumber: $phoneNumber, postcode: $postcode, street: $street, streetNumber: $streetNumber, buildingNumber: $buildingNumber, district: $district, entrance: $entrance, floor: $floor, apartmentNumber: $apartmentNumber, city: { connect: { id: $city } } }) {
      id
    }
  }
`;

export const CREATE_ADMIN_OR_MANAGER_NODE = (node) => gql`
mutation($active: Boolean, $email: String!, $name: String!, $phoneNumber: String!, $notifications: Boolean) {
  create${node}(data: { active: $active, notifications: $notifications, email: $email, name: $name, phoneNumber: $phoneNumber }) {
    id
  }
}
`;

export const UPDATE_OWNER_NODE = gql`
  mutation($id: ID!, $active: Boolean, $email: String!, $name: String, $city: ID, $street: String, $streetNumber: String, $buildingNumber: String, $district: String, $entrance: String, $floor: String, $apartmentNumber: String, $country: ID, $postcode: String, $phoneNumber: String, $vet: ID) {
    updateOwner(where: { id: $id } data: { active: $active, email: $email, name: $name, city: { connect: { id: $city } }, street: $street, streetNumber: $streetNumber, buildingNumber: $buildingNumber, district: $district, entrance: $entrance, floor: $floor, apartmentNumber: $apartmentNumber, country: { connect: { id: $country } }, postcode: $postcode, phoneNumber: $phoneNumber, vet: { connect: { id: $vet } } }) {
      id
    }
  }
`;

export const UPDATE_VET_NODE = gql`
  mutation($id: ID!, $active: Boolean, $email: String!, $name: String, $phoneNumber: String, $postcode: String, $street: String, $streetNumber: String, $buildingNumber: String, $district: String, $entrance: String, $floor: String, $apartmentNumber: String, $city: ID, $clinic: ID) {
    updateVet(where: { id: $id } data: { active: $active, email: $email, name: $name, phoneNumber: $phoneNumber, postcode: $postcode, street: $street, streetNumber: $streetNumber, buildingNumber: $buildingNumber, district: $district, entrance: $entrance, floor: $floor, apartmentNumber: $apartmentNumber, city: { connect: { id: $city } }, clinic: { connect: { id: $clinic } } }) {
      id
    }
  }
`;

export const UPDATE_CLINIC_NODE = gql`
  mutation($id: ID!, $active: Boolean, $email: String, $name: String!, $lat: String, $lng: String, $phoneNumber: String!, $postcode: String!, $street: String, $streetNumber: String, $buildingNumber: String, $district: String, $entrance: String, $floor: String, $apartmentNumber: String, $city: ID) {
    updateClinic(where: { id: $id } data: { active: $active, email: $email, name: $name, lat: $lat, lng: $lng, phoneNumber: $phoneNumber, postcode: $postcode, street: $street, streetNumber: $streetNumber, buildingNumber: $buildingNumber, district: $district, entrance: $entrance, floor: $floor, apartmentNumber: $apartmentNumber, city: { connect: { id: $city } } }) {
      id
    }
  }
`;

export const UPDATE_ADMIN_OR_MANAGER_NODE = (node) => gql`
  mutation($id: ID!, $active: Boolean, $email: String!, $name: String!, $phoneNumber: String!, $notifications: Boolean) {
    update${node}(where: { id: $id } data: { active: $active, notifications: $notifications, email: $email, name: $name, phoneNumber: $phoneNumber }) {
      id
    }
  }
`;

export const GET_CURRENT_USER = gql`
  query {
    currentUser {
      role
      email
    }  
  }
`;


export const GET_OWNER_ADDRESS_DATA = gql`
    query($ownerId: ID!) {
        owner(where: { id: $ownerId }) {
            name
            district
            city {
                id
            }
            street
            streetNumber
            buildingNumber
            entrance
            floor
            apartmentNumber
            postcode
            phoneNumber
        }
    }
`;

export const GET_CLINIC_ID_OF_VET = gql`
  query($vetId: ID!) {
    vet(where: { id: $vetId }) {
      clinic {
        id
      }
    }
  }
`;

export const GET_MIX_BREED_ID = gql`
  query {
    breeds(where: { breedNameEn_contains: "Mix" }) {
      id
    }
  }
`;

export const GET_TODAYS_ORDERS = gql`
  query($minTime: DateTime, $maxTime: DateTime) {
    orders(where: { dateCreated_lte: $maxTime, dateCreated_gte: $minTime }) {
      id
    }
  }
`;

export const GET_CURRENT_USER_INFO = (node, fields) => gql`
  query($email: String!) {
    ${node}(where: { email: $email }) {
      ${fields}
    }
  }
`;

export const CHANGE_COGNITO_USER_PASSWORD = gql`
  mutation($prevPass: String!, $newPass: String!) {
    changeCognitoUserPassword(prevPass: $prevPass, newPass: $newPass) {
      success
      message
    }  
  }
`;

export const RESEND_USER_TEMP_PASSWORD = gql`
    mutation($email: String!) {
        resendUserTempPassword(email: $email) {
            success
            message
        }
    }
`;
