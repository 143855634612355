/**
 * HELPER FUNCTIONS
 */

/**
 *
 * @param arr
 * @param size
 * @returns {Array}
 */
export const chunkArrayInGroups = (arr, size) => {

  const chunks = [];

  for (let i = 0; i < arr.length; i += size) {
    chunks.push(arr.slice(i, i + size));
  }

  return chunks;
};

/**
 *
 * @param str
 * @returns {string}
 */
export const capitalizeFirstLetter = str => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

/**
 * Moves an array element to the specified position
 *
 * @param arr
 * @param element
 * @param newIndex
 * @returns {*}
 */
export const changeElementIndex = (arr, element, newIndex) => {

  if (Array.isArray(arr) && arr.length === 0) {
    return arr;
  }

  const index = arr.indexOf(element);

  const newArr = [...arr.slice(0, index), ...arr.slice(index + 1)];

  if (newIndex === 0) {
    return [element, ...newArr];
  } else if (newIndex === arr.length) {
    return [...newArr, element]
  } else {
    return [...newArr.slice(0, newIndex), element, ...newArr.slice(newIndex)];
  }
};

export const getWeekNumber = date => {
  // We have to compare against the first monday of the year not the 01/01
  // 60*60*24*1000 = 86400000
  // 'onejan_next_monday_time' reffers to the miliseconds of the next monday after 01/01

  let day_miliseconds = 86400000,
    onejan = new Date(date.getFullYear(), 0, 1, 0, 0, 0),
    onejan_day = (onejan.getDay() === 0) ? 7 : onejan.getDay(),
    days_for_next_monday = (8 - onejan_day),
    onejan_next_monday_time = onejan.getTime() + (days_for_next_monday * day_miliseconds),
    // If one jan is not a monday, get the first monday of the year
    first_monday_year_time = (onejan_day > 1) ? onejan_next_monday_time : onejan.getTime(),
    this_date = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0),// This at 00:00:00
    this_time = this_date.getTime(),
    days_from_first_monday = Math.round(((this_time - first_monday_year_time) / day_miliseconds));

  // let first_monday_year = new Date(first_monday_year_time);

  // We add 1 to "days_from_first_monday" because if "days_from_first_monday" is *7,
  // then 7/7 = 1, and as we are 7 days from first monday,
  // we should be in week number 2 instead of week number 1 (7/7=1)
  // We consider week number as 52 when "days_from_first_monday" is lower than 0,
  // that means the actual week started before the first monday so that means we are on the firsts
  // days of the year (ex: we are on Friday 01/01, then "days_from_first_monday"=-3,
  // so friday 01/01 is part of week number 52 from past year)
  // "days_from_first_monday<=364" because (364+1)/7 == 52, if we are on day 365, then (365+1)/7 >= 52 (Math.ceil(366/7)=53) and thats wrong

  return (days_from_first_monday >= 0 && days_from_first_monday < 364) ? Math.ceil((days_from_first_monday + 1) / 7) : 52;
}


export const getCurrentWeekNumber = () => {
  return getWeekNumber(new Date());
  // let today = new Date();
  // let firstDateOfYear = new Date(today.getFullYear(), 0, 1);
  // return Math.ceil((((today - firstDateOfYear) / 86400000) + firstDateOfYear.getDay() + 1) / 7);
};

export const formatDate = value => {
  const date = new Date(value);
  const pad = '00';
  const yyyy = date.getFullYear().toString();
  const MM = (date.getMonth() + 1).toString();
  const dd = date.getDate().toString();

  return `${(pad + dd).slice(-2)}/${(pad + MM).slice(-2)}/${yyyy}`;
};

export const translateProp = prop => {
  return prop.replace(/([A-Z])/g, (x) => {
    return "_" + x.toLowerCase()
  })
};


export const translatePropValue = (prop, value) => {
  let suffix = (prop === 'appetite' && value !== 'None') ? '_appetite' : (prop === 'walkIntensity' && value !== 'None') ? '_intensity' : '';
  value = value.replace(/([A-Z])/g, (x, y, index) => {
    return index !== 0 ? "_" + x.toLowerCase() : x.toLowerCase()
  });

  return value + suffix;
};

export const calculateAge = (birthDate, date) => {
  let birth = new Date(birthDate);
  let change = new Date(date);

  let year = change.getFullYear();
  let month = change.getMonth();
  let day = change.getDate();

  let birthYear = birth.getFullYear();
  let birthMonth = birth.getMonth();
  let birthDay = birth.getDate();

  let years = year - birthYear;
  let months = month - birthMonth;
  let days = day - birthDay;

  if (months < 0) {
    years = years - 1;
    months = (12 - birthMonth) + month;
  }

  if (days < 0) {
    days = 0;

    if (months === 0) {
      years = years >= 1 ? years - 1 : years;
      months = 11;
    } else if (months >= 1) {
      months = months - 1;
    }
  }

  return {years, months, days};
};

export const getFirstDayOfWeek = date => {
  const dayOfWeek = date.getDay();

  // dayIndex is 0 for Sunday and 1 for Monday
  // const diff = dayOfWeek >= dayIndex ? dayOfWeek - dayIndex : 6 - dayOfWeek;
  const diff = dayOfWeek >= 1 ? dayOfWeek - 1 : 6 - dayOfWeek;

  const firstDayOfWeek = new Date(date);
  firstDayOfWeek.setDate(date.getDate() - diff);
  firstDayOfWeek.setHours(0, 0, 0, 0);

  return firstDayOfWeek
};

export const getCurrentAgeInMonths = birthDate => {
  const {years, months} = calculateAge(birthDate, new Date());

  return (years * 12) + months;
};

export const getAgeInMonthsAndDays = (birthDate, date) => {
  const {years, months, days} = calculateAge(birthDate, date);

  return {
    months: (years * 12) + months,
    days: days,
  };
};

export const getObjectProperty = (value, items, property) => {
  const item = items.filter(item => item.id === value.id).pop();

  if (item && item[property]) {
    return item[property];
  }

  return null;
};

export const formatDateTime = value => {
  const date = formatDate(value);
  const datetime = new Date(value);
  const pad = '00';
  const HH = datetime.getHours().toString();
  const ii = datetime.getMinutes().toString();
  const ss = datetime.getSeconds().toString();

  return `${date} ${HH}:${(pad + ii).slice(-2)}:${(pad + ss).slice(-2)}`;
};

export const getTodayTimeRange = () => {
  let date = new Date();
  let year = date.getFullYear();
  let month = date.getMonth();
  let day = date.getDate();

  let minTime = new Date(year, month, day);
  minTime.setHours(0, 0, 0, 0);
  minTime = minTime.toISOString();
  let maxTime = new Date(year, month, day);
  maxTime.setHours(23, 59, 59, 999);
  maxTime = maxTime.toISOString();

  let yFormat = year.toString().substr(-2);
  let mFormat = ("0" + (month + 1)).slice(-2);
  let dFormat = ("0" + day).slice(-2);

  return {minTime, maxTime, yFormat, mFormat, dFormat}
};