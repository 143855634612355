import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import {
  Grid,
  Button,
  Table,
  withStyles,
} from '@material-ui/core';

import IngredientsHeader from './IngredientsHeader';
import IngredientsBody from './IngredientsBody';

const styles = theme => ({
  action: {
    marginTop: theme.spacing.unit * 2,
  },
});

function Ingredients(props) {
  const {
    meta: { error, submitFailed },
    disableAdd,
    classes,
    addField,
  } = props;

  return (
    <Grid item xs={8}>
      <h1>{t('materials')}</h1>
      {submitFailed && error && <span>{error}</span>}
      <Table padding='default'>
        <IngredientsHeader {...props} />
        <IngredientsBody {...props} />
      </Table>
      {!disableAdd && (
        <Button className={classes.action}
          size="small"
          variant="contained"
          color="primary"
          onClick={addField}
        >
          {t('add_button')}
        </Button>
      )}
    </Grid>
  )
}

Ingredients.propTypes = {
  classes: PropTypes.object,
  meta: PropTypes.object,
  disableAdd: PropTypes.bool,
  addField: PropTypes.func,
  updateAdditionalInformation: PropTypes.func,
};

export default withStyles(styles)(Ingredients);