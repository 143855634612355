import { Gavel } from '@material-ui/icons';

import { UnitCreate } from '../components/Unit/UnitCreate';
import { UnitList } from '../components/Unit/UnitList';
import { UnitEdit } from '../components/Unit/UnitEdit';

export const Units = {
  icon: Gavel,
  list: UnitList,
  create: UnitCreate,
  edit: UnitEdit
};
