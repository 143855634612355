import React, {Component} from 'react';

import QrReader from 'react-qr-reader'

class ScanDigitalLabel extends Component {

  dogImageUrl
  handleScan = data => {
    if (data) {
      // this.setState({
      //   result: data
      // })
      this.props.history.push(`/DigitalLabel/${data}`);

    }
  }

  handleError = err => {
    console.error(err)
  }

  render() {
    return (
      <div>
        <QrReader
          delay={300}
          onError={this.handleError}
          onScan={this.handleScan}
          style={{width: '100%'}}
        />
        {/*<p>{this.state.result}</p>*/}
      </div>
    )
  }
}

export default ScanDigitalLabel;
