import React, { Component } from 'react';
import { Create } from 'react-admin';

import { t } from 'i18next';
import CustomCreateToolbar from '../CustomCreateToolbar';
import { AdminForm } from './AdminForm';
class AdminCreate extends Component {

  render() {
    const { history, options } = this.props;

    return (
      <Create {...this.props} title={t('admins.title_create')}>
        <AdminForm toolbar={<CustomCreateToolbar history={history} user={options.currentUser.role} />} redirect="list" />
      </Create>
    )
  }
}

export default AdminCreate;