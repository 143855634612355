import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { t } from 'i18next';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';

import { calculateAnimalDetails } from '../calculateAnimalDetails';

const FoodPriceTable = ({heading, prices, classes}) => (
  <Grid item xs={6}>
    <Paper className={classes.textEl}>
      <h3>{heading}</h3>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.textEl}>{t('age_group.weight')}</TableCell>
            <TableCell className={classes.textEl}>{t('price_in_lev_currency')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {prices.map((pair, index) => {
            return (
              <TableRow key={index}>
                <TableCell className={classes.textEl}>{pair.weight}</TableCell>
                <TableCell className={classes.textEl}>{pair.price.toFixed(2)}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Paper>
  </Grid>
);

class AnimalFoodPrices extends Component {
  constructor(props) {
    super(props);

    this.state = {
      animalDetails: null,
      normalPrices: null,
      breedPrices: null
    }
  }

  componentDidMount() {
    const { record } = this.props;
    calculateAnimalDetails(record.id).then(animalDetails => this.setState({ animalDetails }, () => this.calculatePrices()));
  }

  calculatePrices = () => {
    const { priceByWeightGeneral, priceByWeight, breedCoefficient } = this.state.animalDetails;

    if (priceByWeight) {
      const normalPrices = this.calculateNormalPrice(priceByWeightGeneral);
      const breedPrices = this.calculatePriceByBreed(priceByWeight, breedCoefficient);

      this.setState({ normalPrices, breedPrices })
    }
  };

  calculateNormalPrice = prices => {
    let normalPrices = [];
    prices.forEach(pair => {
      const normalPrice = pair.price * pair.weight;
      const normalPricePair = { weight: pair.weight, price: normalPrice };

      normalPrices.push(normalPricePair);
    });

    return normalPrices;
  };

  calculatePriceByBreed = (prices, breedCoefficient) => {
    let breedPrices = [];
    prices.forEach(pair => {
      const breedPrice = (breedCoefficient || 1) * pair.price * pair.weight;
      const breedPricePair = { weight: pair.weight, price: breedPrice };

      breedPrices.push(breedPricePair);
    });

    return breedPrices;
  };

  render() {
    const { classes } = this.props;
    const { normalPrices, breedPrices } = this.state;

    return (
      normalPrices && breedPrices &&
      <Grid container spacing={8}>
        <FoodPriceTable heading={t('normal_food')} prices={normalPrices} classes={classes}/>
        <FoodPriceTable heading={t('food_by_breed')} prices={breedPrices} classes={classes}/>
      </Grid>
    );
  }
}

export default withStyles({ textEl: { textAlign: 'center' }})(AnimalFoodPrices);