import { FormatListNumbered } from '@material-ui/icons';

import OrderList from '../components/Order/OrderList';
import OrderShow from '../components/Order/OrderShow';
import OrderCreate from '../components/Order/OrderCreate';
import OrderEdit from '../components/Order/OrderEdit';

export const Orders = {
  icon: FormatListNumbered,
  list: OrderList,
  show: OrderShow,
  create: OrderCreate,
  edit: OrderEdit
};