import React from 'react';
import { Edit } from 'react-admin';
import { t } from 'i18next';

import EditFormTitle from '../EditFormTitle';
import { CustomEditToolbar } from '../CustomEditToolbar';
import { ExchangeWeightForm } from './ExchangeWeightForm';

export const ExchangeWeightEdit = props => (
  <Edit {...props} title={<EditFormTitle formName={t('exchange_weight.exchange_weight')} />}>
    <ExchangeWeightForm toolbar={<CustomEditToolbar user={props.options.currentUser.role}/>}/>
  </Edit>
);
