import React, { Fragment, Component } from 'react';
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  FunctionField,
  EditButton,
  Button,
  Link,
  SelectField,
  NumberField
} from 'react-admin';
import { t } from 'i18next';
import ListIcon from '@material-ui/icons/List';
import ContentAdd from '@material-ui/icons/Add';
import Label from '@material-ui/icons/Label';

import { OrderFilter } from './OrderFilter';
import { CustomListActions } from '../CustomListActions';
import ChipWithAvatar from '../fields/ChipWithAvatar';
import DateField from '../fields/CustomDateField';
// import AnimalTypeAvatar from '../fields/AnimalTypeAvatar';
import StatusNameTypography from '../fields/StatusNameTypography';
import apolloClient from '../../initApollo';
import { GET_STATUS_ID_BY_NAME } from '../../customQueries';
import CopyOrderButton from '../CopyOrderButton';

class OrderList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      statusInProgress: ''
    }
  }

  componentDidMount() {
    apolloClient.query({ query: GET_STATUS_ID_BY_NAME, variables: { name: process.env.REACT_APP_STATUS_IN_PROGRESS_ID }, fetchPolicy: 'no-cache' })
      .then(response => {
        if (response.loading) return;
        this.setState({ statusInProgress: response.data.statuses.pop() || '' })
      });
  }

  filterByRole = ({ role, email }) => {
    switch (role) {
      case 'Owner':
        return { owner: { email } };
      case 'ProductionManager':
        return { status: { id: this.state.statusInProgress.id } };
      case 'Vet':
        return { vet: { email } };
      case 'Clinic':
        return { clinic: { email } };
      default:
        return {}
    }
  };

  render() {
    const { options } = this.props;
    return ((options.currentUser.role !== 'ProductionManager' || (options.currentUser.role === 'ProductionManager' && this.state.statusInProgress !== '')) &&
      <List
        filters={<OrderFilter user={options.currentUser} />}
        sort={{ field: "dateCreated", order: "DESC" }}
        bulkActionButtons={false}
        actions={<CustomListActions user={options.currentUser} />}
        title={t('order_details.title_list')}
        filter={this.filterByRole(options.currentUser)}
        {...this.props}
      >
        <Datagrid rowClick="show">
          <TextField source="idNumber" label={t('number')} />
          <DateField source='dateCreated' label={t('date_created')} />
          <ReferenceField label={t('clinic_name')} reference="Clinic" source="clinic.id" allowEmpty={true} sortable={false} linkType={options.currentUser.role === 'Admin' ? 'show' : false}>
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField label={t('vet')} reference="Vet" source="vet.id" allowEmpty={true} sortable={false} linkType={options.currentUser.role === 'Admin' ? 'show' : false}>
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField label={t('owner')} reference="Owner" source="owner.id" allowEmpty={true} sortable={false} linkType={options.currentUser.role === 'Admin' ? 'show' : false}>
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField label={t('animal')} reference="Animal" source="animal.id" allowEmpty={true} sortable={false} linkType={options.currentUser.role === 'Admin' ? 'show' : false}>
            <ChipWithAvatar />
          </ReferenceField>
          <SelectField
            label={t('age_group.age_group_shortened')}
            source="ageGroupName"
            choices={[
              { id: 'Puppy', name: t('age_group.puppy') },
              { id: 'Kitten', name: t('age_group.kitten') },
              { id: 'Junior', name: t('age_group.junior') },
              { id: 'Adult', name: t('age_group.adult') },
              { id: 'Prime', name: t('age_group.prime') },
              { id: 'Mature', name: t('age_group.mature') },
              { id: 'Senior', name: t('age_group.senior') },
              { id: 'YoungAdult', name: t('age_group.youngadult') },
              { id: 'MatureAdult', name: t('age_group.matureadult') },
              { id: 'Geriatric', name: t('age_group.geriatric') }
            ]}
          />
          <SelectField
            label={t('size_class_shortened')}
            source="sizeClass"
            choices={[
              { id: 'Mini', name: t('size_classes.mini') },
              { id: 'Small', name: t('size_classes.small') },
              { id: 'Medium', name: t('size_classes.medium') },
              { id: 'Large', name: t('size_classes.large') },
              { id: 'Giant', name: t('size_classes.giant') }
            ]}
          />
          <ReferenceField label={t('status')} reference='Order' source='id' allowEmpty={true} sortable={false} linkType={options.currentUser.role === 'Admin' || options.currentUser.role === 'ProductionManager' ? 'edit' : false}>
            <StatusNameTypography user={options.currentUser.role} />
          </ReferenceField>
          <NumberField source="quantity" label={t('order_details.quantity_shortened')} />
          <TextField source="weight" label={t('age_group.weight')} />
          <NumberField source="price" label={t('order_details.price')} />
          <SelectField
            label={t('order_details.order_type')}
            source="orderType"
            choices={[
              { id: 'Normal', name: t('order_details.normal') },
              { id: 'ByBreed', name: t('order_details.by_breed') },
              { id: 'VIP', name: t('order_details.VIP') },
            ]}
          />
          {options.currentUser.role !== 'Admin' && options.currentUser.role !== 'ProductionManager' ? null :
            <FunctionField
              label={t('recipe')}
              sortable={false}
              render={({ recipe, id }) => {
                return recipe ?
                  <Fragment>
                    <EditButton
                      label={t('recipe')}
                      icon={<ListIcon />}
                      basePath="Recipe"
                      record={recipe}
                      onClick={e => e.stopPropagation()}
                    />
                    <Button
                      component={Link}
                      to={{ pathname: `/Recipe/labelSettings/${recipe.id}` }}
                      label={t('label_settings.label')}
                      onClick={e => e.stopPropagation()}>
                      <Label />
                    </Button>
                  </Fragment> : options.currentUser.role === 'Admin' ?
                    (
                      <Button
                        component={Link}
                        to={{ pathname: '/Recipe/create', search: `?orderId=${id}` }}
                        label={t('recipe_create')}
                        onClick={e => e.stopPropagation()}>
                        <ContentAdd />
                      </Button>
                    ) : null;
              }}
            />
          }
          {options.currentUser.role === 'Owner' &&
            <FunctionField
              label={t('repeat_order.button_title')}
              sortable={false}
              render={(params) => (
                <CopyOrderButton
                  record={params}
                  label={t('repeat_order.short_label')}
                  shouldStopPropagation={true}
                />
              )} />
          }
        </Datagrid>
      </List>
    )
  }
}

export default OrderList;
