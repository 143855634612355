import React from 'react';
import {
  List,
  Datagrid,
  TextField,
} from 'react-admin';
import { t } from 'i18next';

import { CustomBulkActions } from '../CustomBulkActions';
import { CustomListActions } from '../CustomListActions';

export const CountryList = props => (
  <List {...props}
        bulkActionButtons={<CustomBulkActions />}
        actions={<CustomListActions />}
        title={t("countries.title_list")}
  >
    <Datagrid rowClick="edit">
      <TextField source="name" label={t("name")} />
      <TextField source="isoCode" label={t("countries.iso_code_column")} />
    </Datagrid>
  </List>
);
