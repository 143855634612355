import React, { Fragment } from 'react';
import {
  TabbedForm,
  TextInput,
  ReferenceInput,
  FormTab,
  BooleanInput,
  email,
  ReferenceArrayField,
  Datagrid,
  TextField,
  Button,
  Link,
  FunctionField
} from 'react-admin';
import { t } from 'i18next';
import ContentAdd from '@material-ui/icons/Add';
import { SearchableSelect } from './../SearchableSelect';

import { isRequired } from '../../utils/validation';

import LocationSelect from '../LocationSelect';

const validateRequired = [isRequired()];
const validateEmail = [isRequired(), email()];

export const VetForm = props => (
  <Fragment>
    <TabbedForm {...props} redirect="list">
      <FormTab label={t('summary_tab')}>
        <TextInput source="name" label={t("name")} validate={validateRequired} />
        { props.record.id ?
          props.user.role === 'Admin' ? <BooleanInput source="active" label={t('active')}/> : null
        : <BooleanInput source="active" label={t('active')}/>}
        <TextInput source="email" label={t('email')} validate={validateEmail} />
        <TextInput source="phoneNumber" label={t('phone_number')} validate={validateRequired} />
        <ReferenceInput label={t("clinic_name")} reference="Clinic" source="clinic.id" validate={validateRequired}>
          <SearchableSelect obj="Clinic" disabled={props.record.id && props.user.role !== 'Admin'}/>
        </ReferenceInput>
      </FormTab>
      <FormTab label={t('addresses_tab')}>
        <ReferenceInput
          label={t('city')}
          reference="City"
          source="city.id"
          validate={validateRequired}
          perPage={30}
          filter={{mainProvinceCity: true}}
          sort={{ field: 'name', order: 'ASC' }}
        >
          {
            props.record.id ?
              <LocationSelect parentId={props.record.id} parentIdName='vetsIds'/>
              :<LocationSelect/>
          }
        </ReferenceInput>
        <TextInput source='street' label={t('street')}/>
        <TextInput source='streetNumber' label={t('street_number')}/>
        <TextInput source='buildingNumber' label={t('building_number')}/>
        <TextInput source='district' label={t('district')}/>
        <TextInput source='entrance' label={t('entrance')}/>
        <TextInput source='floor' label={t('floor')}/>
        <TextInput source='apartmentNumber' label={t('apartment_number')}/>
        <TextInput source="postcode" label={t('postcode')} validate={validateRequired}/>
      </FormTab>
        {
          props.record.id && (props.user.role === 'Admin' || props.user.role === 'Clinic') ?
            <FormTab label={t("consulting_fee")}>
              <ReferenceArrayField label={t("consulting_fee_details.consulting_fee_percentage_vet")} reference="ConsultingFeePercentageVet" source="consultingFeePercentageVetsIds">
                <Datagrid rowClick='edit'>
                  <TextField source="weeklyTurnoverTo" label={t("consulting_fee_details.weekly_turnover_to")} sortable={false}/>
                  <TextField source="consultingFeePercentage" label={t("consulting_fee_details.consulting_fee_percentage")} sortable={false}/>
                </Datagrid>
              </ReferenceArrayField>
              <FunctionField
                label={t('consulting_fee_details.consulting_fee_percentage_create_vet')}
                render={() => {
                  return <Button component={Link} to={{pathname: '/ConsultingFeePercentageVet/create', search: `?vetId=${props.record.id}&clinicId=${props.record.clinic.id}`}} label='ra.action.create'><ContentAdd/></Button>;
                }}
              />
            </FormTab>
          : null
        }
    </TabbedForm>
  </Fragment>
);
