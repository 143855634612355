import React from 'react';
import { Toolbar } from 'react-admin';
import DeleteButtonWithConfirmation from '../components/DeleteButtonWithConfirmation';
import CustomSaveButton from './CustomSaveButton';
import MaterialSaveButton from './MaterialSaveButton';

export const CustomEditToolbar = (props) => {
  return (<Toolbar {...props} style={{ justifyContent: 'space-between' }}>
    { props.resource === 'Material' ? <MaterialSaveButton {...props}/> : <CustomSaveButton {...props}/> }
    { (props.resource === 'ConsultingFeePercentageVet' && props.user === 'Clinic') || (props.resource !== 'FoodRunningOutNotification' && props.resource !== 'Status' && (props.record.email === undefined || props.record.email !== props.useremail) && props.user === 'Admin') ? <DeleteButtonWithConfirmation {...props}/> : null}
  </Toolbar>);
};