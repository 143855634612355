import React, { Fragment } from 'react';
import { Link, Button } from 'react-admin';
import { t } from 'i18next';
import ContentAdd from '@material-ui/icons/Add';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';

export const ExchangeWeightList = props => {
  return props.data && (
    <Fragment>
      <Button
        component={Link}
        to={{pathname: `/${props.resource}/create`}}
        label='ra.action.create'
        onClick={e => e.stopPropagation()}
      >
        <ContentAdd/>
      </Button>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('exchange_weight.live_weight_to')}</TableCell>
            <TableCell>{t('exchange_weight.exchange_weight_kg')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            Object.entries(props.data).map(([index, record]) => {
              return (
                <TableRow key={index} hover={true} onClick={() => props.history && props.history.push(`/${props.resource}/${record.id}`)}>
                  <TableCell>{record.liveWeightTo}</TableCell>
                  <TableCell>{record.exchangeWeight}</TableCell>
                </TableRow>
              );
            })
          }
        </TableBody>
      </Table>
    </Fragment>
  );
};