import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  BooleanField
} from 'react-admin';

import { t } from 'i18next';

import { VetFilter } from './VetFilter';
import { CustomListActions } from '../CustomListActions';
import {ResendTempEmailButton} from "../ResendTempEmailButton";

const filterByRole = ({role, email}) => {
  if (role === 'Vet') {
    return {email};
  } else if (role === 'Clinic') {
    return {clinic: {email}}
  }

  return {};
};

export const VetList = props => (
  <List
    filters={<VetFilter user={props.options.currentUser}/>}
    bulkActionButtons={false}
    actions={props.options.currentUser.role !== 'Vet' ? <CustomListActions user={props.options.currentUser}/> : null}
    title={t("vets.title_list")}
    filter={filterByRole(props.options.currentUser)}
    {...props}
  >
    <Datagrid rowClick="show">
      <TextField source="name" label={t("name")} />
      <BooleanField source="active" label={t('active')}/>
      <TextField source="email" label={t('email')} />
      <TextField source="phoneNumber" label={t('phone_number')} />
      <ReferenceField label={t("city")} reference="City" source="city.id" allowEmpty={true} sortable={false} linkType={false}>
        <TextField source="name"/>
      </ReferenceField>
      <TextField source='street' label={t('street')} />
      <ReferenceField label={t("clinic_name")} reference="Clinic" source="clinic.id" allowEmpty={true} linkType={props.options.currentUser.role === 'Vet' ? false : 'show'} sortable={false}>
        <TextField source="name"/>
      </ReferenceField>
      <ResendTempEmailButton
        email={props.email}
        active={props.active}
      />
    </Datagrid>
  </List>
);
