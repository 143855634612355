import React, { Component, Fragment } from 'react';
import { Button } from 'react-admin'
import CheckCircle from '@material-ui/icons/CheckCircle';
import apolloClient from '../initApollo';
import { t } from 'i18next';
import { withStyles } from '@material-ui/core/styles';

import { GET_ANIMAL_INFO } from '../customQueries';
import ConfirmationDialog from './ConfirmationDialog';
import { formatDate, translateProp, translatePropValue } from '../utils/Helper';
import { calculateAnimalDetails } from './calculateAnimalDetails';
import { calculateConsultingFeeDetails } from './calculateConsultingFeeDetails';

const styles = {
  image: {
    width: 'auto',
    height: '100px'
  }
};

class CopyOrderButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDialog: false,
      animalData: [],
      animalDetails: [],
      consultingFeeDetails: []
    }
  }

  componentDidMount() {
    const { record } = this.props;

    if (record && record.animal && record.animal.id) {
      apolloClient.query({
        query: GET_ANIMAL_INFO,
        variables: { animalId: record.animal.id }
      }).then(response => {
        if (response.loading) return;

        this.setState({ animalData: response.data.animal })
      });
    }
  }

  handleClick = (e) => {
    const { record, shouldStopPropagation } = this.props;

    if (shouldStopPropagation) {
      e.stopPropagation()
    }

    this.setState({ showDialog: true });
    calculateAnimalDetails(record.animal.id).then(animalDetails => this.setState({ animalDetails }));
    calculateConsultingFeeDetails(record).then(consultingFeeDetails => this.setState({ consultingFeeDetails }))
  };

  handleClose = () => { this.setState({ showDialog: false }) };

  getStyledValue = (prop, value) => {
    const { classes } = this.props;

    switch (prop) {
      case 'birthDate':
      case 'fertilizationDate':
      case 'givingBirthDate':
        return formatDate(value);
      case 'breed':
      case 'breedMother':
      case 'breedFather':
        return value.breedNameBg;
      case 'owner':
        return value.name;
      case 'profilePicture':
        return <img src={value} alt={translateProp(prop)} className={classes.image} />;
      case 'files':
        return value.length !== 0 ? value.map(file => { return <Fragment key={file.id}><a href={file.url}>{file.name}</a><br /></Fragment> }) : t('null');
      default:
        return typeof value === 'string' ? t(`${translatePropValue(prop, value)}`) : t(`${value}`);
    }
  };

  render() {
    return (this.state.animalData && this.state.animalData.length !== 0 &&
      <Fragment>
        <Button label={this.props.label || t('repeat_order.button_title')} variant="contained" onClick={this.handleClick} />
        <ConfirmationDialog
          isOpen={this.state.showDialog}
          message={t('repeat_order.dialog_message')}
          label={t('repeat_order.repeat_order')}
          cancelLabel='ra.action.cancel'
          handleClose={this.handleClose}
          title={false}
          icon={<CheckCircle />}
          animalEditTitle='ra.action.edit'
          animalContent={this.state.animalData}
          animalProp={translateProp}
          animalPropValue={this.getStyledValue}
          buttonLink={true}
          pathname="/Order/create"
          state={{ data: this.props.record }}
        />
      </Fragment>
    )
  }
}

export default (withStyles(styles)(CopyOrderButton));