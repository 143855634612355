import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';

import { SizeClassMixList } from '../components/SizeClassMix/SizeClassMixList';
import { SizeClassMixShow } from '../components/SizeClassMix/SizeClassMixShow';
import { SizeClassMixCreate } from '../components/SizeClassMix/SizeClassMixCreate';
import { SizeClassMixEdit } from '../components/SizeClassMix/SizeClassMixEdit';

export const SizeClassMix = {
  icon: () => <FontAwesomeIcon style={{marginLeft: '9px', marginRight: '9px'}} icon={faEllipsisV}/>,
  list: SizeClassMixList,
  show: SizeClassMixShow,
  create: SizeClassMixCreate,
  edit: SizeClassMixEdit
};