import React from 'react';
import {
  Show,
  Datagrid,
  TextField,
  NumberField,
  BooleanField,
  ReferenceField,
  ReferenceArrayField,
  TabbedShowLayout,
  Tab,
  ShowButton,
} from 'react-admin';

import { t } from 'i18next';

import CopyOrderButton from '../CopyOrderButton';
import CustomDateField from '../fields/CustomDateField';
import EditFormTitle from '../EditFormTitle';
import OwnerHistory from './OwnerHistory';
import ChipWithAvatar from '../fields/ChipWithAvatar';
import AnimalTypeAvatar from "../fields/AnimalTypeAvatar";

export const OwnerShow = props => (
  <Show {...props} title={<EditFormTitle formName={t('owner')} />}>
    <TabbedShowLayout redirect="list">

      <Tab label={t('summary_tab')}>
        <TextField source="name" label={t('name')}/>
        <BooleanField source="active" label={t('active')}/>
        <TextField source="phoneNumber" label={t('phone_number')}/>
        <TextField source="email" label={t('email')}/>
        <ReferenceField label={t('vet')} reference="Vet" source="vet.id" allowEmpty={true} linkType={props.options.currentUser.role === 'Owner' ? false : 'show'}>
          <TextField source="name"/>
        </ReferenceField>
      </Tab>

      <Tab label={t('addresses_tab')}>
        <ReferenceField label={t('city')} reference="City" source="city.id" linkType={false} allowEmpty={true}>
          <TextField source="name"/>
        </ReferenceField>
        <TextField source='street' label={t('street')}/>
        <TextField source='streetNumber' label={t('street_number')}/>
        <TextField source='buildingNumber' label={t('building_number')}/>
        <TextField source='district' label={t('district')}/>
        <TextField source='entrance' label={t('entrance')}/>
        <TextField source='floor' label={t('floor')}/>
        <TextField source='apartmentNumber' label={t('apartment_number')}/>
        <TextField label={t('postcode')} source="postcode"/>
      </Tab>

      <Tab label={t('orders')}>
        <ReferenceArrayField addLabel={false} reference="Order" source="ordersIds">
          <Datagrid>
            <TextField source="idNumber" label={t('number')} sortable={false}/>
            <ReferenceField label={t('animal')} reference='Animal' source='animal.id' allowEmpty={true} sortable={false} linkType='show'>
              <ChipWithAvatar/>
            </ReferenceField>
            <CustomDateField source="dateCreated" label={t('date_created')} sortable={false}/>
            <ReferenceField label={t('status')} reference='Status' source='status.id' allowEmpty={true} sortable={false} linkType={false}>
              <TextField source='nameBg'/>
            </ReferenceField>
            <ShowButton/>
            <CopyOrderButton/>
          </Datagrid>
        </ReferenceArrayField>
      </Tab>

      <Tab label={t('owners.pets')}>
        <ReferenceArrayField label={t('owners.pets')} reference="Animal" source="animalsIds">
          <Datagrid rowClick="show">
            <TextField source="name" label={t('name')} sortable={false}/>
            <AnimalTypeAvatar source='animalType' label={t('animal_type')} sortable={false}/>
            <ReferenceField label={t('breed')} reference='Breed' source='breed.id' allowEmpty={true} sortable={false} linkType={false}>
              <TextField source='breedNameBg'/>
            </ReferenceField>
            <NumberField source="weight" label={t('weight')} sortable={false}/>
          </Datagrid>
        </ReferenceArrayField>
      </Tab>

      <Tab label={t('history')}>
        <OwnerHistory/>
      </Tab>

    </TabbedShowLayout>
  </Show>
);
