import React from 'react';
import { Create } from 'react-admin';
import { t } from 'i18next';

import CustomCreateToolbar from '../CustomCreateToolbar';
import { EnergyRequirementDogForm } from './EnergyRequirementDogForm';

export const EnergyRequirementDogCreate = props => (
  <Create {...props} redirect='list' title={t('energy_requirement_dog.title_create')}>
    <EnergyRequirementDogForm toolbar={<CustomCreateToolbar user={props.options.currentUser.role}/>}/>
  </Create>
);
