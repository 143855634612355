import React, { Component } from 'react';
import {
  Create,
  TextInput,
  TabbedForm,
  FormTab,
  ReferenceInput,
  BooleanInput,
  email,
} from 'react-admin';

import { t } from 'i18next';
import CustomCreateToolbar from '../CustomCreateToolbar';
import LocationSelect from '../LocationSelect';
import { SearchableSelect } from './../SearchableSelect';
import apolloClient from '../../initApollo';
import { GET_BG_COUNTRY } from '../../customQueries';
import { isRequired } from '../../utils/validation';

const validateRequired = [isRequired()];
const validateEmail = [isRequired(), email()];

const filterVetByRole = user => {
  if (user.role === 'Vet') {
    return { email: user.email };
  } else if (user.role === 'Clinic') {
    return { clinic: { email: user.email } };
  }

  return null;
};

class OwnerCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      country: ''
    }
  }

  componentDidMount() {
    apolloClient.query({ query: GET_BG_COUNTRY, fetchPolicy: 'no-cache' })
      .then(response => {
        if (response.loading) return;
        this.setState({country: response.data.countries.pop() || ''})
      });
  }

  render () {
    const { history, options } = this.props;
    const { country } = this.state;

    return ( country !== '' &&
      <Create {...this.props} title={t('owners.title_create')} className="gridWithSearchableFilter">
        <TabbedForm toolbar={<CustomCreateToolbar history={history} user={options.currentUser.role}/>} redirect="list" defaultValue={{countryId: country.id }}>
          <FormTab label={t('summary_tab')}>
            <TextInput source="name" label={t('name')} validate={validateRequired}/>
            <BooleanInput source="active" label={t('active')}/>
            <TextInput source="phoneNumber" label={t('phone_number')}/>
            <TextInput source="email" label={t('email')} validate={validateEmail}/>
            <ReferenceInput
              label={t('vet')}
              reference="Vet"
              source="vet.id"
              validate={validateRequired}
            >
            <SearchableSelect
              obj="Vet"
              defaultValue={options.currentUser.role === 'Vet' ? options.currentUser.systemId : undefined}
              disabled={options.currentUser.role === 'Vet'}
              filter={filterVetByRole(options.currentUser)}
            />
            </ReferenceInput>
          </FormTab>
          <FormTab label={t('addresses_tab')} basepath="addresses">
            <ReferenceInput
              label={t('city')}
              reference="City"
              source="city.id"
              validate={validateRequired}
              perPage={30}
              filter={{mainProvinceCity: true}}
              sort={{field: 'name', order: 'ASC'}}
            >
              <LocationSelect/>
            </ReferenceInput>
            <TextInput source='street' label={t('street')}/>
            <TextInput source='streetNumber' label={t('street_number')}/>
            <TextInput source='buildingNumber' label={t('building_number')}/>
            <TextInput source='district' label={t('district')}/>
            <TextInput source='entrance' label={t('entrance')}/>
            <TextInput source='floor' label={t('floor')}/>
            <TextInput source='apartmentNumber' label={t('apartment_number')}/>
            <TextInput source="postcode" label={t('postcode')} validate={validateRequired}/>
          </FormTab>
        </TabbedForm>
      </Create>
    )
  }
}

export default OwnerCreate;