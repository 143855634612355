import React from 'react';
import { t } from 'i18next';
import { Create } from 'react-admin';
import { ProductGroupForm } from './ProductGroupForm';
import CustomCreateToolbar from '../CustomCreateToolbar';

export const ProductGroupCreate = props => (
  <Create {...props} title={t('product_groups.title_create')}>
    <ProductGroupForm toolbar={<CustomCreateToolbar user={props.options.currentUser.role}/>}/>
  </Create>
);
