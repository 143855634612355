import React, {Component} from 'react';
import Dashboard from './Dashboard';
import {Admin} from 'react-admin';
// import {Title} from 'react-admin';
import buildOpenCrudProvider from 'ra-data-prisma2';
import customRoutes from './customRoutes';
import SideMenu from './components/SideMenu';

import client from './initApollo';
import {ApolloProvider} from 'react-apollo';
import i18n from './locales/initialization';
import bgMessages from './locales/bg/ReactTranslations';
import {AuthUtils} from './utils/AuthUtils';

import './App.css';
import authProvider from './utils/AuthProvider';
import {CustomLayout} from './components/CustomLayout';
import {GET_ALL_CLINICS, GET_ALL_OWNERS, GET_ALL_VETS, GET_CURRENT_USER} from './customQueries';
import {ResourceMapperByRole} from './utils/ResourceMapper';
import LoadingIndicator from './components/LoadingIndicator';

const messages = {
  bg: bgMessages,
};

const i18nProvider = locale => messages[locale];

class App extends Component {
  constructor() {
    super();
    this.state = {
      dataProvider: null,
      currentUser: null,
      owners: [],
      vets: [],
      clinics: []
    };

    i18n.dir();//todo: added to pass build
  }

  componentDidMount() {
    buildOpenCrudProvider({client})
      .then(dataProvider => this.setState({dataProvider}));

    const owners = client.query({query: GET_ALL_OWNERS}).then(response => {
      if (response.loading) return;
      this.setState({owners: response.data.owners})
    });

    const vets = client.query({query: GET_ALL_VETS}).then(response => {
      if (response.loading) return;
      this.setState({vets: response.data.vets})
    });

    const clinics = client.query({query: GET_ALL_CLINICS}).then(response => {
      if (response.loading) return;
      this.setState({clinics: response.data.clinics})
    });

    Promise.all([owners, vets, clinics]).then(() => {
      this.getCurrentUser();
    });
  }

  getCurrentUser = () => {
    client.query({query: GET_CURRENT_USER}).then(response => {
      if (response.loading) return;
      window.currentUser = response.data.currentUser;
      window.currentUser.systemId = this.getSystemIdForRole(response.data.currentUser);
      this.setState({currentUser: response.data.currentUser});
    });
  };

  getSystemIdForRole = user => {
    switch (user.role) {
      case 'Owner':
        const owner = this.state.owners.filter(owner => owner.email === user.email).pop();
        return owner ? owner.id : null;
      case 'Vet':
        const vet = this.state.vets.filter(vet => vet.email === user.email).pop();
        return vet ? vet.id : null;
      case 'Clinic':
        const clinic = this.state.clinics.filter(clinic => clinic.email === user.email).pop();
        return clinic ? clinic.id : null;
      default:
        return null;
    }
  };

  render() {
    const {dataProvider} = this.state;

    if (!dataProvider) {
      return <LoadingIndicator/>;
    }

    return (
      <ApolloProvider client={client}>
        <AuthUtils>
          <Admin appLayout={CustomLayout}
                 menu={SideMenu}
                 dataProvider={dataProvider}
                 authProvider={authProvider}
                 dashboard={window.currentUser && window.currentUser.role === 'Admin' ? Dashboard : null}
                 customRoutes={customRoutes}
                 locale="bg"
                 i18nProvider={i18nProvider}>
            {/*<Title title="g3tools Admin" />*/}
            {currentUser => ResourceMapperByRole(window.currentUser)}
          </Admin>
        </AuthUtils>
      </ApolloProvider>
    );
  }
}

export default App;
