import React from 'react';
import { t } from 'i18next';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';

const styles = {
  fullHeightCard: {
    height: "100%",
  },
}

class StatisticsCard extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      primary: '',
      secondary: [],
      primaryArr: []
    }
  }

  componentDidMount() {
    const { getData, dateFrom, dateTo } = this.props;
    if (getData) {
      if (dateFrom && dateTo) {
        getData(dateFrom, dateTo).then(data => this.setValues(data));
      } else {
        getData().then(data => this.setValues(data));
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { getData, dateFrom, dateTo } = this.props;
    const prevDateFrom = prevProps.dateFrom;
    const prevDateTo = prevProps.dateTo;

    if (dateFrom !== prevDateFrom || dateTo !== prevDateTo) {
      getData(dateFrom, dateTo).then(data => this.setValues(data));
    }
  }

  setValues = (data) => {
    let primaryValue = '';
    if (typeof data.primaryValue !== 'undefined') {
      primaryValue = data.primaryValue.toString();
    }

    this.setState({
      primary: primaryValue,
      secondary: data.secondaryValue,
      primaryArr: data.primaryArr || []
    });
  }

  render() {
    const { title, classes } = this.props;
    const { primary, secondary, primaryArr } = this.state;

    const noData = (!primary || primary.length === 0) && (!primaryArr || primaryArr.length === 0) && secondary.length === 0;

    return (
      <Card className={classes.fullHeightCard}>
        <CardContent>
          <Typography gutterBottom align='center' variant="headline" component="h1">
            {title}
          </Typography>
          <Typography gutterBottom align='center' variant="display1" component="h2" color='secondary'>
            {primary}
          </Typography>
          {(primaryArr && primaryArr.length > 0) &&
            primaryArr.map((text, i) => (
              <Typography key={i} gutterBottom align='center' variant='subheading' component="h6" color='secondary'>
                {text}
              </Typography>
            ))
          }
          {secondary.map((text, i) => (
            <Typography key={i} align='center' component="p">
              {text}
            </Typography>
          ))}
          {noData &&
            <Typography gutterBottom align='center' variant="display1" component="h2" color='secondary'>
              {t('statistics.no_data')}
            </Typography>
          }
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(styles)(StatisticsCard);