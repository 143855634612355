import apolloClient from '../initApollo';
import { t } from 'i18next';
import {
  GET_ANIMAL_INFO,
  GET_AGE_GROUP_DOG,
  GET_AGE_GROUP_CAT,
  GET_SIZE_CLASS_MIX,
  GET_ALL_AGE_GROUPS,
  GET_NORMAL_PACKAGE_DETAILS_DOG,
  GET_NORMAL_PACKAGE_DETAILS_CAT,
  GET_BREED_PACKAGE_DETAILS_DOG,
  GET_BREED_PACKAGE_DETAILS_CAT,
  GET_SIZE_CLASS_AND_COEFFICIENT_BY_BREED,
} from '../customQueries';
import { getCurrentAgeInMonths } from '../utils/Helper';

export const calculateAnimalDetails = animalId => {
  return apolloClient.query({ query: GET_ANIMAL_INFO, variables: { animalId }, fetchPolicy: 'no-cache' })
    .then(response => {
      if (response.loading) return;

      const animalData = {};
      const animal = response.data.animal;
      const weight = animal.weight;
      const breed = animal.breed;
      const breedId = breed.id;
      const breedNameEn = breed.breedNameEn;
      const breedCoefficient = breed.priceCoefficient;
      const type = animal.animalType;
      const sizeClass = breed.sizeClass;
      const ageInMonths = getCurrentAgeInMonths(animal.birthDate);

      animalData.type = type;
      animalData.breed = breed;
      animalData.breedId = breedId;
      animalData.sizeClass = sizeClass;
      animalData.breedCoefficient = breedCoefficient;
      animalData.ageInMonths = ageInMonths;

      //calculate sizeClass for breed Mix
      if (breedNameEn === process.env.REACT_APP_BREED_MIX_ID) {

        if (ageInMonths < 2) return animalData;

        return calculateMixSizeClass(ageInMonths, weight).then(mixSizeClass => {

          const sizeClass = mixSizeClass ? mixSizeClass.charAt(0).toUpperCase() + mixSizeClass.slice(1) : null;
          animalData.sizeClass = sizeClass;

          return getAgeGroupDog(ageInMonths, sizeClass).then(ageGroup => {
            animalData.ageGroup = ageGroup ? ageGroup.id : null;
            animalData.ageGroupName = ageGroup ? ageGroup.ageGroup : null;
            animalData.priceByWeightGeneral = ageGroup ? ageGroup.priceByWeightGeneral : null;
            animalData.priceByWeight = ageGroup ? ageGroup.priceByWeight : null;
            animalData.priceByWeightVIP = ageGroup ? ageGroup.priceByWeightVIP : null;

            return animalData;
          });
        });

      } else if (type === 'Cat') {

        return getAgeGroupCat(ageInMonths).then(ageGroup => {
          animalData.ageGroup = ageGroup ? ageGroup.id : null;
          animalData.ageGroupName = ageGroup ? ageGroup.ageGroup : null;
          animalData.priceByWeightGeneral = ageGroup ? ageGroup.priceByWeightGeneral : null;
          animalData.priceByWeight = ageGroup ? ageGroup.priceByWeight : null;
          animalData.priceByWeightVIP = ageGroup ? ageGroup.priceByWeightVIP : null;

          return animalData;
        });
      } else if (type === 'Dog') {
        return getAgeGroupDog(ageInMonths, sizeClass).then(ageGroup => {
          animalData.ageGroup = ageGroup ? ageGroup.id : null;
          animalData.ageGroupName = ageGroup ? ageGroup.ageGroup : null;
          animalData.priceByWeightGeneral = ageGroup ? ageGroup.priceByWeightGeneral : null;
          animalData.priceByWeight = ageGroup ? ageGroup.priceByWeight : null;
          animalData.priceByWeightVIP = ageGroup ? ageGroup.priceByWeightVIP : null;

          return animalData;
        });
      }
    });
};

export const getNormalPackageDetails = (animalType, sizeClass, id) => {
  const query = animalType === 'Dog' ? GET_NORMAL_PACKAGE_DETAILS_DOG : GET_NORMAL_PACKAGE_DETAILS_CAT;
  let group = animalType === 'Dog' ? 'ageGroupDogs' : 'ageGroupCats';

  return apolloClient.query({ query, variables: { sizeClass, id }, fetchPolicy: 'no-cache' })
    .then(resp => {
      if (resp.loading) return;

      return resp.data[group].pop();
    });
}

const getNormalPackageDetailsPriceRecord = (animalType, sizeClass, id) => {
  const query = animalType === 'Dog' ? GET_BREED_PACKAGE_DETAILS_DOG : GET_BREED_PACKAGE_DETAILS_CAT;
  let group = animalType === 'Dog' ? 'ageGroupDogs' : 'ageGroupCats';

  return apolloClient.query({ query, variables: { sizeClass, id }, fetchPolicy: 'no-cache' })
    .then(resp => {
      if (resp.loading) return;

      return resp.data[group].pop();
    });
}

export const getBreedPackageDetails = (animalType, breed, ageGroup) => {
  return apolloClient.query({ query: GET_SIZE_CLASS_AND_COEFFICIENT_BY_BREED, variables: { id: breed.id }, fetchPolicy: 'no-cache' })
    .then(resp => {
      if (resp.loading) return;

      return resp.data.breeds.pop();
    })
    .then(data => {
      let sizeClass = data.sizeClass;
      let priceCoefficient = data.priceCoefficient;

      return getNormalPackageDetailsPriceRecord(animalType, sizeClass, ageGroup)
        .then(res => {
          return { priceByWeight: res.priceByWeight, priceCoefficient, sizeClass }
        });
    })
}

export const getAgeGroupDog = (months, sizeClass) => {

  return apolloClient.query({ query: GET_AGE_GROUP_DOG, variables: { months, sizeClass }, fetchPolicy: 'no-cache' })
    .then(response => {
      if (response.loading) return;

      return response.data.ageGroupDogs.pop();
    });
};

export const getAgeGroupCat = (months) => {
  return apolloClient.query({ query: GET_AGE_GROUP_CAT, variables: { months }, fetchPolicy: 'no-cache' })
    .then(response => {
      if (response.loading) return;

      return response.data.ageGroupCats.pop();
    });
};

export const calculateMixSizeClass = (age, weight) => {
  if (age > 24) age = 24;
  return apolloClient.query({ query: GET_SIZE_CLASS_MIX, variables: { months: age }, fetchPolicy: 'no-cache' })
    .then(response => {
      if (response.loading) return;

      let currentValue = 0;
      let mixSizeClass = '';
      let params = response.data.sizeClassMixes.pop();
      Object.entries(params).forEach(([sizeClass, sizeClassWeight]) => {
        if (sizeClass === '__typename') return;
        if (sizeClassWeight >= weight) {
          if (currentValue === 0) {
            currentValue = sizeClassWeight;
            mixSizeClass = sizeClass;
          } else {
            if (currentValue > sizeClassWeight) {
              currentValue = sizeClassWeight;
              mixSizeClass = sizeClass;
            }
          }
        }
      });

      return mixSizeClass;
    });
};

export const getAgeGroupWithMonths = animal => {
  let { breed: { sizeClass, breedNameEn } } = animal;
  const { birthDate, animalType, weight } = animal;
  const ageInMonths = getCurrentAgeInMonths(birthDate);

  if (breedNameEn === process.env.REACT_APP_BREED_MIX_ID) {
    return calculateMixSizeClass(ageInMonths, weight).then(mixSizeClass => {
      sizeClass = mixSizeClass ? mixSizeClass.charAt(0).toUpperCase() + mixSizeClass.slice(1) : null;

      return getAgeGroupDog(ageInMonths, sizeClass).then(ageGroup => {
        return createAgeGroupWithMonths(ageGroup, animalType, sizeClass);
      });
    });
  } else if (animalType === 'Cat') {

    return getAgeGroupCat(ageInMonths).then(ageGroup => {
      return createAgeGroupWithMonths(ageGroup, animalType);
    });
  } else if (animalType === 'Dog') {

    return getAgeGroupDog(ageInMonths, sizeClass).then(ageGroup => {
      return createAgeGroupWithMonths(ageGroup, animalType, sizeClass);
    });
  }
};

export const createAgeGroupWithMonths = (ageGroup, animalType, sizeClass = null) => {
  return apolloClient.query({ query: GET_ALL_AGE_GROUPS, variables: { sizeClass } })
    .then(response => {
      if (response.loading) return;

      const { ageGroupDogs, ageGroupCats } = response.data;
      let result = {
        name: 'Age group',
        monthsFrom: null,
        monthsTo: null,
      };

      if (animalType === 'Dog') {
        ageGroupDogs.forEach((item, key) => {
          if (ageGroup.id === item.id) {
            let monthsFrom = ageGroupDogs[key - 1] ? ageGroupDogs[key - 1].months : null;
            result.name = item.ageGroup;
            result.monthsFrom = monthsFrom ? calculateAgePeriod(monthsFrom) : null;
            result.monthsTo = calculateAgePeriod(item.months)
          }
        });
      } else if (animalType === 'Cat') {
        ageGroupCats.forEach((item, key) => {
          if (ageGroup.id === item.id) {
            let monthsFrom = ageGroupCats[key - 1] ? ageGroupCats[key - 1].months : null;
            result.name = item.ageGroup;
            result.monthsFrom = monthsFrom ? calculateAgePeriod(monthsFrom) : null;
            result.monthsTo = calculateAgePeriod(item.months)
          }
        });
      }

      return result;
    });
};

const calculateAgePeriod = months => {
  let years = Math.floor(months / 12);
  let yearsString = years > 0 ? years + t('years_without_dot') : '';
  let ageMonths = months % 12;
  let monthsString = ageMonths > 0 ? ageMonths + t('months_without_dot') : '';

  if (monthsString !== '' && yearsString !== '') {
      return `${yearsString} ${monthsString}`;
  }

  if (monthsString === '') {
      return yearsString;
  }

  if (yearsString === '') {
    return monthsString;
  }
};
