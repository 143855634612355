import { LocationCity } from '@material-ui/icons';
import { CityList } from '../components/City/CityList';
import { CityShow } from '../components/City/CityShow';
import { CityCreate } from '../components/City/CityCreate';
import { CityEdit } from '../components/City/CityEdit';

export const Cities = {
  icon: LocationCity,
  list: CityList,
  show: CityShow,
  create: CityCreate,
  edit: CityEdit
};
