import React, { Component, cloneElement, Fragment } from 'react';
import { t } from 'i18next';
import CustomSearchableAsyncSelect from './CustomSearchableAsyncSelect';
import apolloClient from '../initApollo';
import { GET_FILTERED_BREEDS, GET_FILTERED_BREEDS_BY_TYPE, GET_BREEDS_BY_ANIMAL_TYPE_WITH_RECIPES, GET_FILTERED_BREEDS_BY_TYPE_WITHOUT_MIX } from "../customQueries";
import { isEmpty } from 'lodash';
import { addField } from 'ra-core';
import { isRequired } from '../utils/validation';
const validateRequired = [isRequired()];

class BreedSelect extends Component {

  constructor(props) {
    super(props);

    this.state = {
      animalType: props.animalType || null,
      initialOptions: [],
      inputValue: null,
      isClearable: true
    };
  }

  componentDidMount() {
    this.props.breedCopyOrderFilter
      ? this.getFilteredBreedsWithRecipes()
      : this.getFilteredBreeds();

    this.mixedBreed = t('breeds.mix_breed');
  }

  getFilteredBreedsWithRecipes = () => {
    const { animalType } = this.state;
    const variables = { animalType, recipe: null };

    return apolloClient.query({ query: GET_BREEDS_BY_ANIMAL_TYPE_WITH_RECIPES, variables })
      .then(response => {
        if (response.loading) return;
        this.setState({
          initialOptions: response.data.breeds,
          isClearable: false,
        });
        return response.data.breeds;
      });
  };

  getFilteredBreeds = (inputValue) => {
    const { animalType } = this.state;
    const { containsMix } = this.props;
    const variables = { name: inputValue, animalType, mixedBreed: this.mixedBreed };
    let query = animalType ? GET_FILTERED_BREEDS_BY_TYPE : GET_FILTERED_BREEDS;

    if (animalType && animalType === 'Dog' && !containsMix) {
      query = GET_FILTERED_BREEDS_BY_TYPE_WITHOUT_MIX;
    }

    return apolloClient.query({ query, variables })
      .then(response => {
        if (response.loading) return;
        this.setState({
          initialOptions: response.data.breeds,
          inputValue
        });
        return response.data.breeds;
      });
  };

  getInitialValue = (value, breeds) => {
    let initialValue = null;
    breeds.forEach(breed => {
      if (breed.id === value) {
        initialValue = breed;
      }
    });

    return initialValue;
  };

  render() {
    const { choices, input, label, source, data, isRequired, onchange, meta, disabled } = this.props;
    const { initialOptions } = this.state;
    const initialValue = isEmpty(input.value) ? null : this.getInitialValue(input.value, choices);

    if ((!initialOptions || initialOptions.length === 0) && isEmpty(this.state.inputValue)) return null;

    const hasValue = input.value !== '' && input.value !== undefined && input.value !== null;
    const hasError = !hasValue && meta.submitFailed;

    return (
      cloneElement(
        <Fragment>
          <CustomSearchableAsyncSelect
            isClearable={this.state.isClearable}
            initialValue={initialValue}
            initialOptions={initialOptions}
            getOptionLabel={(option) => option.breedNameBg}
            getOptionValue={(option) => option.id}
            getFilteredData={this.getFilteredBreeds}
            placeholder={`${label} ${isRequired ? '*' : ''}`}
            validate={isRequired ? validateRequired : null}
            name={input.breedNameBg}
            source={source}
            onChange={onchange}
            disabled={disabled}
            styles={
              {
                placeholder: (base) => ({
                  ...base,
                  color: hasError ? 'rgba(244, 67, 54, 1)' : 'rgba(0, 0, 0, 0.54)'
                }),
                option: (provided, state) => ({
                  ...provided,
                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                  cursor: 'pointer'
                }),
                menuList: (base) => ({
                  ...base,
                  cursor: 'pointer',
                  maxHeight: 120
                }),
                menu: (base) => ({
                  ...base,
                  maxWidth: 256,
                }),
                valueContainer: (base) => ({
                  ...base,
                  padding: 0,
                  width: 180
                }),
                dropdownIndicator: (base) => ({
                  ...base,
                  cursor: 'pointer'
                }),
                clearIndicator: (base) => ({
                  ...base,
                  cursor: 'pointer'
                }),
                control: (base, state) => {
                  const border = '1px solid rgba(0, 0, 0, 0.42)';
                  const borderHover = '2px solid rgba(0, 0, 0, 0.87)';
                  const borderFocus = '2px solid #303f9f';

                  return {
                    ...base,
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    outline: 0,
                    border: 0,
                    boxShadow: 0,
                    borderRadius: 0,
                    borderBottom: state.isFocused ? borderFocus : border,
                    marginTop: 32,
                    marginBottom: 8,
                    maxWidth: 256,
                    ':hover': {
                      border: state.isFocused ? 0 : 0,
                      borderBottom: state.isFocused ? borderFocus : borderHover,
                      cursor: 'text'
                    }
                  }
                }
              }
            }
            data={data}
            obj={this.props.name || "Breed"}
          />
        </Fragment>
      )
    )
  };
}

BreedSelect.defaultProps = {
  containsMix: true
};

export default addField(BreedSelect);
