import React, { Fragment, Component } from 'react';
import {
  withStyles,
  List,
  ListItem,
  Typography,
  Switch,
  Select,
  MenuItem
} from '@material-ui/core';
import { percentToUnitObject, compareUnitCoefficients } from '../PercentToUnit';
import { isEmpty } from 'lodash';
import { t } from 'i18next';

const styles = theme => ({
  title: {
    margin: 2 * theme.spacing.unit,
  },
  container: {
    width: '50%',
    margin: theme.spacing.unit,
  },
  list: {
    marginLeft: 4 * theme.spacing.unit,
    transition: '0.1s',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.02)',
    },
  },
  listItem: {
    transition: '0.1s',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.03)',
    },
    padding: theme.spacing.unit / 2,
  },
  valueField: {
    marginLeft: 'auto',
    marginRight: theme.spacing.unit,
  },
  unitSelect: {
    marginLeft: '0.5rem',
  },
});

class NutrientPickerComponent extends Component {
  constructor(props) {
    super(props);

    var unitSelection = {};

    this.state = { unitSelection }
  }

  generateUnitSelection = (nutrients) => {
    const { allUnits } = this.props;

    if (!nutrients || isEmpty(allUnits)) return {};

    var unitSelection = {};

    nutrients.forEach(nutrient => {
      const {
        percent,
        uiSubtotal = 0,
        uiValue,
        noPercent,
        withPercent,
        unit: nutrientUnit = null,
        nutrientGroups,
        nutrientElements,
      } = nutrient;

      if (uiSubtotal > 0) {
        unitSelection[nutrient.id] = percentToUnitObject(percent, allUnits, nutrientUnit, uiValue, uiSubtotal, null, noPercent, withPercent);
      } else {
        unitSelection[nutrient.id] = percentToUnitObject(percent, allUnits, nutrientUnit, uiValue, uiSubtotal);
      }

      unitSelection = { ...unitSelection, ...this.generateUnitSelection(nutrientGroups || nutrientElements, allUnits) };
    });

    return unitSelection;
  };

  selectUnitAction = (selectedUnit, nutrient, units, percent) => {
    var unitValue = null;
    var { unitSelection } = this.state;
    unitSelection[nutrient.id].unit = selectedUnit;
    nutrient.unit = selectedUnit;

    if (selectedUnit === 'UI' && nutrient.uiValue) {
      let percentObject = percentToUnitObject(percent, units, null, nutrient.uiValue, nutrient.uiSubtotal, null, nutrient.noPercent, nutrient.withPercent);
      unitValue = percentObject.value;
    } else {
      let percentObject = percentToUnitObject(percent, units, selectedUnit, nutrient.uiValue, nutrient.uiSubtotal, null, nutrient.noPercent, nutrient.withPercent);
      unitValue = percentObject.value;
    }

    if (unitValue) {
      unitSelection[nutrient.id].value = unitValue;
      nutrient.value = unitValue;
    }

    this.setState({ unitSelection });
  };

  generateUnitsMenu = unsortedUnits => {
    const sortedUnits = unsortedUnits.sort(compareUnitCoefficients);

    const unitsMenu = sortedUnits.map(unit =>
      <MenuItem value={unit.name} key={unit.id}>[{unit.name}]</MenuItem>
    );
    unitsMenu.unshift(<MenuItem value='%' key='%'>[%]</MenuItem>);

    return unitsMenu;
  };

  nutrientList = props => {
    const { nutrients, hiddenNutrients, switchAction, allUnits, nested = false, classes } = props;
    const { unitSelection } = this.state;

    if (!nutrients || allUnits.length <= 0) return null;

    if (isEmpty(unitSelection)) {
      const unitSelection = this.generateUnitSelection(nutrients, allUnits);
      this.setState({ unitSelection });

      return null;
    };

    return (
      <List disablePadding className={nested ? classes.list : null}>
        {
          nutrients.map(nutrient => {
            const {
              id: nutrientId,
              name,
              percent,
              nutrientGroups,
              nutrientElements,
            } = nutrient;
            const checked = hiddenNutrients.indexOf(nutrientId) < 0;
            const { unit = null, value = null } = unitSelection[nutrient.id];

            return (
              <Fragment key={nutrientId}>
                <ListItem className={classes.listItem}>
                  <Switch
                    id={nutrientId}
                    checked={checked}
                    onClick={switchAction}
                    disabled={this.props.disabled}
                    color='primary'
                  />
                  <Typography component='span'>{name}</Typography>
                  {
                    unit && nutrient.name !== "Витамини" ?
                      <Select value={unit} onChange={e => this.selectUnitAction(e.target.value, nutrient, allUnits, percent)} className={classes.unitSelect} disableUnderline>
                        {this.generateUnitsMenu(allUnits)}
                      </Select>
                      : null
                  }
                  { nutrient.name !== "Витамини" && <Typography component='span' className={classes.valueField}> {value} </Typography> }
                </ListItem>
                {
                  this.nutrientList({
                    nutrients: nutrientGroups || nutrientElements,
                    hiddenNutrients,
                    switchAction,
                    allUnits,
                    nested: true,
                    classes,
                  })
                }
              </Fragment>
            );
          })
        }
      </List>
    );
  };

  render() {
    const { classes: { container, title } } = this.props;

    return (
      <div className={container}>
        <Typography component='span' variant='headline' className={title}>
          {t('main_menu.nutrients')}
        </Typography>
        {this.nutrientList(this.props)}
      </div>
    );
  }
}

export const NutrientPicker = withStyles(styles)(NutrientPickerComponent);
