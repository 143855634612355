import React from 'react';
import classNames from 'classnames';
import Dropzone from 'react-dropzone';
import apolloClient from '../initApollo';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { refreshView as refreshViewAction } from 'ra-core';
import { STORE_FILE, UPLOAD_FILE_TO_S3 } from '../customQueries';
import { t } from 'i18next';
import { customCrudCreate } from './customCrudCreate';
import { REDUX_FORM_NAME } from 'react-admin';

const styles = {
  dropZone: {
    background: '#efefef',
    cursor: 'pointer',
    padding: '1rem',
    marginTop: 15,
    textAlign: 'center',
    color: '#999',
  },
  root: { width: '100%' },
  errorWrapper: { marginTop: '1em' },
};

class CustomFileInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      files: []
    }
  }

  uploadFile = (acceptedFile, fileContent) => {
    const { createHistory, basePath, refreshView, options } = this.props;

    apolloClient.mutate({
      mutation: UPLOAD_FILE_TO_S3,
      variables: {fileName: acceptedFile.name, fileSize: acceptedFile.size, fileType: acceptedFile.type, fileContent}
    }).then(response => {
      if (response.loading) return;

      const { url } = response.data.uploadToS3;

      if(url !== '') {
        apolloClient.mutate({
          mutation: STORE_FILE,
          variables: { name: acceptedFile.name, size: acceptedFile.size, type: acceptedFile.type, animalId: this.props.record.id, dateCreated: new Date(), url }
        })
          .then(async response => {
            if (response.loading) return;

            let file = response.data.createFile;
            const history = {
              user: options.currentUser.role,
              date: new Date(),
              animal: file.animal.id,
              data: {
                files: {
                  oldValue: null,
                  newValue: { id: file.id, name: file.name, url: file.url }
                }
              }
            };

            createHistory('AnimalHistory', history, basePath, null);

            this.props.formData.files.push({id: file.id});
            this.props.formData.filesIds.push(file.id);
            refreshView()
          });
      }
    });
  };

  onDrop = acceptedFiles => {
    acceptedFiles.forEach(acceptedFile => {
      const reader = new FileReader();
      reader.onloadend = () => {
        this.uploadFile(acceptedFile, btoa(reader.result));
      };
      reader.readAsBinaryString(acceptedFile);
    });
  };

  render() {
    const {
      accept,
      classes = {},
      disableClick,
      id,
      maxSize,
      minSize,
      multiple,
      options = {},
    } = this.props;

    return (
      <React.Fragment>
        <Dropzone
          onDrop={this.onDrop}
          accept={accept}
          disableClick={disableClick}
          maxSize={maxSize}
          minSize={minSize}
          multiple={multiple}
          className={classes.dropZone}
          {...options}
          inputProps={{ id, ...options.inputProps }}
        >
          {({getRootProps, getInputProps, isDragActive, rejectedFiles}) => {
            const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;
            const acceptedFileTypes = accept.split(',');
            const hasProperFileType = rejectedFiles.length > 0 && acceptedFileTypes.includes(rejectedFiles[0].type);

            return (
            <div>
              <div
                style={styles.dropZone}
                {...getRootProps()}
                className={classNames('dropzone', {'dropzone--isActive': isDragActive})}
              >
                <input {...getInputProps()} />
                {
                  isDragActive ?
                    <p>{t('file_input.drop_files')}</p> :
                    <p>{t('file_input.drop_files_or_click')}</p>
                }
              </div>
              {isFileTooLarge && (
                <div className={classes.errorWrapper}>
                  <p className="required-field-error">{t('image_input.too_large')}</p>
                </div>
              )}
              {rejectedFiles.length && !hasProperFileType ? (
                <div className={classes.errorWrapper}>
                  <p className="required-field-error">{t('image_input.not_accepted')}</p>
                </div>
              ) : null}
            </div>
            )
          }}
        </Dropzone>
      </React.Fragment>
    );
  }
}

const mapFormStateToProps = state => {
  let formData = null;
  let form = state.form[REDUX_FORM_NAME];
  if (form) {
    formData = form.values || null;
  }

  return { formData };
};

export default compose(
  connect(mapFormStateToProps, { refreshView: refreshViewAction, createHistory: customCrudCreate }),
  withStyles(styles)
)(CustomFileInput);