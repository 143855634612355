import i18n from 'i18next';
import bgTranslations from './bg/CustomTranslations'
import enTranslations from './en/CustomTranslations'

i18n.init({
    lng: 'bg',
    resources: {
        bg: bgTranslations,
        en: enTranslations
    }
});

export default i18n;