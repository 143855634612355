const translations = {
  translation: {
    'title': 'OptimusWeb',
    'orders': 'Поръчки',
    'summary_tab': 'Лични данни',
    'data_tab': 'Данни',
    'name': 'Име',
    'code_column': 'Код',
    'phone_number': 'Телефонен номер',
    'city': 'Населено място',
    'city_is_main_province': 'Главен град',
    'postcode': 'Пощенски код',
    'email': 'Електронна поща',
    'materials': 'Суровини',
    'material': 'Суровина',
    'quantity': 'Количество',
    'unit': 'Мерна единица',
    'clinic_name': 'Клиника',
    'required_field': 'Задължително поле',
    'require_geo_latitude': 'Въведете валидна географска ширина',
    'require_geo_longitude': 'Въведете валидна географска дължина',
    'natural_number': 'Въведете валидно естествено число',
    'loading': 'Зареждане',
    'ingredients': 'Текстове за етикет',
    'owner': 'Собственик',
    'municipality': 'Община',
    'province': 'Област',
    'order': 'Поръчка',
    'nutrient': 'Хранително вещество',
    'product_group': 'Група продукти',
    'nutrient_group': 'Група хранителни вещества',
    'nutrient_element': 'Хранителен елемент',
    'files': 'Файлове',
    'city_search_input_field': 'Населено място...',
    'search_input_field': 'Търсене...',
    'vet': 'Ветеринарен лекар',
    'recipe': 'Рецепта',
    'recipe_create': 'Създай рецепта',
    'status': 'Статус',
    'date_created': 'Дата на създаване',
    'animal_type': 'Вид',
    'weight': 'Тегло',
    'weight_from': 'Тегло от',
    'weight_to': 'Тегло до',
    'moisture': 'Влага',
    'gender': 'Пол',
    'chip_number': 'Микрочип номер',
    'tattoo_number': 'Татуировъчен номер',
    'dog': 'Куче',
    'cat': 'Котка',
    'male': 'Мъжки',
    'female': 'Женски',
    'yes': 'Да',
    'no': 'Не',
    'castrated': 'Кастрирано',
    'comment': 'Коментар',
    'municipality_select': 'Общ.',
    'province_select': 'Обл.',
    'village_select': 'село',
    'city_select': 'град',
    'location_type': 'Тип населено място',
    'no_results_found': 'Няма намерени резултати',
    'loading_select': 'Зарежда...',
    'addresses_tab': 'Адреси',
    'consulting_fee': 'Консултантско възнаграждение',
    'history': 'История',
    'breed': 'Порода',
    'breed_father': 'Порода баща',
    'breed_mother': 'Порода майка',
    'birth_date': 'Рождена дата',
    'profile_picture': 'Снимка',
    'property': 'Атрибут',
    'old_value': 'Стара стойност',
    'new_value': 'Нова стойност',
    'date_of_change': 'Дата на промяна',
    'user': 'Потребител',
    'age': 'Възраст',
    'years': 'г.',
    'years_without_dot': 'г',
    'months': 'м.',
    'months_without_dot': 'м',
    'and': 'и',
    'select': 'Избери',
    'material_confirmation_message': 'Сборът на хранителните вещества и влагата не е 100%.',
    'null': '-',
    'general_section': 'Общи',
    'digestive_system_condition_section': 'Състояние на храносмилателната система',
    'activity_section': 'Активност',
    'feeding_section': 'Хранене',
    'breeding_section': 'Разплод',
    'withers_height': 'Височина при холката',
    'coat_type': 'Вид космена покривка',
    'hair_structure': 'Структура на косъма',
    'coat_color': 'Цвят на космената покривка',
    'skin_condition': 'Състояние на кожата',
    'teeth': 'Зъби',
    'gums': 'Венци',
    'tongue_and_mucous_membranes': 'Език и лигавици',
    'esophagus': 'Хранопровод',
    'stomach': 'Стомах',
    'intestines': 'Черва',
    'excrement_consistency': 'Консистенция на екскрементите',
    'solid_and_friable': 'Твърди ронливи',
    'solid_and_shaped': 'Твърди оформени',
    'shaped': 'Оформени',
    'soft_and_shaped': 'Меки оформени',
    'soft_and_unshaped': 'Меки неоформени',
    'liquid': 'Течни',
    'living_place': 'Място на гледане',
    'home_inside': 'Домашно, в помещение',
    'home_outside': 'Домашно, в двор',
    'daily_walk_duration': 'Обща продължителност на разходката дневно (мин.)',
    'walk_intensity': 'Интензивност',
    'low_intensity': 'Ниска',
    'medium_intensity': 'Средна',
    'high_intensity': 'Висока',
    'hunt': 'Лов',
    'underground': 'Подземен',
    'birds': 'Птичи',
    'chasing': 'Гонене',
    'sport': 'Спорт',
    'IPO': 'IPO',
    'agility': 'Аджилити',
    'other': 'Друг вид',
    'other_sport': 'Друг вид спорт',
    'work_usage': 'Служебно ползване',
    'work_type': 'Начин на служебно ползване',
    'food_brand': 'Марка и вид готова храна',
    'homemade_food': 'Каква домашно приготвена храна',
    'appetite': 'Апетит',
    'low_appetite': 'Нисък',
    'medium_appetite': 'Нормален',
    'high_appetite': 'Висок',
    'preferred_taste': 'Предпочитан/и вкус/ове',
    'suspected_food_allergy': 'Предполагаема хранителна алергия',
    'identified_food_allergy': 'Установена хранителна алергия',
    'joints_and_ties_condition': 'Състояние на ставите и връзките',
    'breeding': 'Разплодно животно',
    'fertilization_date': 'Дата на заплождане',
    'giving_birth_date': 'Дата на последно раждане',
    'size_class': 'Клас размери',
    'size_class_shortened': 'Клас разм.',
    'price': 'Цена',
    'percent_per_kg': '%/кг',
    'add_button': 'Добави',
    'copy_recipe': 'Копирай предишна рецепта',
    'copy': 'Копирай',
    'continue': 'Продължи',
    'copy_recipe_dialog_message': 'Данните от текущата рецепта ще бъдат изтрити.',
    'warning_message_recipe': 'Профилът на домашния любимец беше редактиран след създаване на поръчката.',
    'lv': 'лв',
    'kg': 'кг',
    'g': 'г',
    'true': 'Да',
    'false': 'Не',
    'from': 'от',
    'to': 'до',
    'none': '-',
    'number': 'Номер',
    'age_in_months': 'Възраст в месеци',
    'name_bg': 'Име на български',
    'name_en': 'Име на английски',
    'active': 'Активен',
    'animal': 'Домашен любимец',
    'address': 'Адрес',
    'taste': 'С преобладаващ вкус на',
    'comments': 'Коментари',
    'notes': 'Вътрешни бележки',
    'owner_notes': 'Бележки за собственика',
    'vet_notes': 'Бележки за ветеринарния лекар',
    'encyclopedia': 'Енциклопедия',
    'short_encyclopedia': 'Съкратена Енциклопедия',
    'ui_in_mg': 'UI в един милиграм',
    'unique_constraint_message': 'В системата има регистриран потребител с този email адрес. Моля, попълнете нов email адрес.',
    'logout': 'Изход',
    'admin': 'Сътрудник',
    'productionmanager': 'Отговорник производство',
    'clinic': 'Мениджър клиника',
    'food_price': 'Цена на храна',
    'normal_food': 'Обща храна',
    'food_by_breed': 'Породна храна',
    'price_in_lev_currency': 'Цена /лв',
    'with_recipe': 'С рецепта',
    'without_recipe': 'Без рецепта',
    'pet_type': 'Вид домашен любимец',
    'dogs': 'кучета',
    'cats': 'котки',
    'street': 'Улица',
    'street_number': 'Номер на улица',
    'building_number': 'Номер на блок',
    'district': 'Квартал',
    'owner_name': 'Име на собственика',
    'entrance': 'Вход',
    'floor': 'Етаж',
    'apartment_number': 'Апартамент',
    'notifications': 'Получаване на имейл',
    'incorrect_password': 'Грешно въведена парола',
    'change_password_success': 'Успешно променена парола',
    'incorrect_cognito_password': 'Паролата трябва да е поне 8 символа и да съдържа цифри, големи букви, малки букви и специални знаци',
    'send_new_temp_email': 'Изпращане на ново писмо с временна парола (ако потребителят още не е дефинирал собствена)',
    'scan_digital_label': 'Сканирай QR код за електронен етикет',
    'main_menu': {
      'owners': 'Собственици',
      'animals': 'Домашни любимци',
      'clinics': 'Клиники',
      'vets': 'Ветеринарни лекари',
      'countries': 'Държави',
      'cities': 'Населени места',
      'municipalities': 'Общини',
      'provinces': 'Области',
      'nutrient_elements': 'Хранителни елементи',
      'nutrient_groups': 'Групи хр. вещества',
      'nutrients': 'Хранителни вещества',
      'product_groups': 'Групи продукти',
      'units': 'Мерни единици',
      'recipes': 'Рецепти',
      'breeds': 'Породи',
      'size_class_mix': 'Класове кучета без порода',
      'age_group_dog': 'Възрастови групи-кучета',
      'age_group_cat': 'Възрастови групи-котки',
      'taste': 'С преобладаващ вкус',
      'statistics': 'Статистики',
      'dashboard': 'Начало',
      'settings': 'Настройки',
      'admins': 'Сътрудници',
      'production_managers': 'Отговорници производство'
    },
    'owners': {
      'title_list': 'Списък собственици',
      'title_create': 'Добави собственик',
      'pets': 'Домашни любимци',
      'inactive_owner_confirm': 'Този собственик е неактивен, искате ли да продължите?',
      'owner_has_pet_pop_up': 'Собственик с домашен любимец не може да бъде изтрит от системата.'
    },
    'animals': {
      'title_list': 'Списък домашни любимци',
      'title_create': 'Добави домашен любимец',
    },
    'clinics': {
      'title_list': 'Списък клиники',
      'title_create': 'Добави клиника',
      'latitude': 'Географска ширина',
      'longitude': 'Географска дължина',
      'location': 'Местоположение',
      'active': 'Активна',
      'has_vets_pop_up': 'Клиника с ветеринарни лекари не може да бъде изтрита от системата.',
      'inactive_clinic_pop_up': 'Избраната от вас клиника е неактивна. Желаете ли да направите така поръчката?'
    },
    'vets': {
      'title_list': 'Списък ветеринарни лекари',
      'title_create': 'Добави ветеринарен лекар',
      'statistics': 'Статистика',
      'vet_has_order_pop_up': 'Ветеринарен лекар с поръчки не може да бъде изтрит от системата.',
      'inactive_vet_pop_up': 'Избраният от вас ветеринарен лекар е неактивен. Желаете ли да направите така поръчката?',
      'owners': 'Собственици към този ветеринар'
    },
    'countries': {
      'title_list': 'Списък държави',
      'title_create': 'Добави държава',
      'iso_code_column': 'ISO код',
      'country': 'Държава'
    },
    'cities': {
      'title_list': 'Списък населени места',
      'title_create': 'Добави населено място',
    },
    'municipalities': {
      'title_list': 'Списък общини',
      'title_create': 'Добави община',
    },
    'provinces': {
      'title_list': 'Списък области',
      'title_create': 'Добави област',
    },
    'nutrient_elements': {
      'title_list': 'Списък хранителни елементи',
      'title_create': 'Добави хранителен елемент',
    },
    'nutrient_groups': {
      'title_list': 'Списък групи хранителни вещества',
      'title_create': 'Добави група хранителни вещества',
    },
    'nutrients': {
      'title_list': 'Списък хранителни вещества',
      'title_create': 'Добави хранително вещество',
    },
    'product_groups': {
      'title_list': 'Списък групи продукти',
      'title_create': 'Добави група продукти',
    },
    'units': {
      'title_list': 'Списък мерни единици',
      'title_create': 'Добави мерна единица',
      'coefficient_per_kilogram_column': 'Коефициент спрямо килограм',
    },
    'ingredient_details': {
      'title_list': 'Списък текстове за етикет',
      'title_create': 'Добави текст за етикет',
      'human_usable': 'Годно за консумация от хора',
    },
    'material_details': {
      'title_list': 'Списък суровини',
      'title_create': 'Добави суровина',
      'moisture_percent': 'Влага, %',
      'price_unit': 'Цена, лв/кг',
      'nutrient_content': 'Съдържание на хранителното вещество',
      'percent': '%',
      'mg': 'mg/kg',
      'μg': 'μg/kg',
      'UI': 'UI/kg',
      'ppm': 'ppm',
      'mix': 'Микс',
    },
    'recipes': {
      'title': 'Рецепта за {{name}} ({{type}} {{breed}})',
      'title_label_settings': 'Етикет за {{orderNumber}} - {{name}} ({{type}} {{breed}} )',
      'title_clinic_order': 'Рецепта за {{type}}, {{detail}}, {{ageGroup}}',
      'title_create': 'Създаване на рецепта',
      'high_quality_animal_protein': 'Висококачествен животински протеин',
      'weight_per_bowl': 'Обемно тегло',
      'best_before': 'Най-добро преди',
      'additional_settings': 'Допълнителни настройки'
    },
    'daily_dose': {
      'title': 'Дневна доза храна',
      'dose_value': 'Дневна доза в грамове',
      'days_will_last': 'Дни, за които е достатъчна',
      'adjustment_coefficient': 'Коефициент за настройка',
    },
    'breeds': {
      'title_list': 'Списък породи домашни любимци',
      'title_create': 'Добави порода домашен любимец',
      'price_coefficient': 'Коефициент',
      'supported_by_optimus': 'Поддържана от Оптимус',
      'mix_breed': 'Микс',
    },
    'size_class_mix': {
      'title': 'Клас размер без порода',
      'title_list': 'Списък класове размери без порода',
      'title_create': 'Добави клас размер без порода',
      'mini_kg': 'Mini /max kg',
      'small_kg': 'Small /max kg',
      'medium_kg': 'Medium /max kg',
      'large_kg': 'Large /max kg',
      'giant_kg': 'Giant /max kg',
    },
    'age_group': {
      'age_group': 'Възрастова група',
      'age_group_shortened': 'Възр. група',
      'dog_title_create': 'Добави възрастова група за кучета',
      'dog_title': 'Възрастова група за кучета',
      'dog_title_list': 'Списък възрастови групи за кучета',
      'cat_title_create': 'Добави възрастова група за котки',
      'cat_title': 'Възрастова група за котки',
      'cat_title_list': 'Списък възрастови групи за котки',
      'puppy': 'Puppy',
      'kitten': 'Kitten',
      'junior': 'Junior',
      'adult': 'Adult',
      'prime': 'Prime',
      'mature': 'Mature',
      'senior': 'Senior',
      'geriatric': 'Geriatric',
      'youngadult': 'Young Adult',
      'matureadult': 'Mature Adult',
      'price_by_weight': 'Цена и разфасовки за ПОРОДНА формула кг-лв',
      'price_weight_pair': 'Цена и разфасовки',
      'price_by_weight_VIP': 'Цена и разфасовки за VIP кг-лв',
      'price_by_weight_general': 'Цена и разфасовки за ОБЩА формула кг-лв',
      'weight': 'Разф./кг',
      'price': 'Цена /лв за кг.'
    },
    'size_classes': {
      'mini': 'Mini',
      'small': 'Small',
      'medium': 'Medium',
      'large': 'Large',
      'giant': 'Giant',
    },
    'consulting_fee_details': {
      'consulting_fee_percentage_vet': 'Процент консултантско възнаграждение за ветеринарен лекар',
      'consulting_fee_percentage_clinic': 'Процент консултантско възнаграждение за клиника',
      'consulting_fee_percentage_create_vet': 'Добави процент консултантско възнаграждение за ветеринарен лекар',
      'consulting_fee_percentage_create_clinic': 'Добави процент консултантско възнаграждение за клиника',
      'weekly_turnover_to': 'Седмичен оборот до /лв',
      'consulting_fee_percentage': 'Процент',
      'week_number': 'Седмица',
      'week': 'Седмица',
      'weekly_turnover': 'Седмичен оборот (лв.)',
      'weekly_turnover_clinic': 'Седмичен оборот на клиниката (лв.)',
      'consulting_fee': 'Консултантско възнаграждение (лв.)',
      'consulting_fee_clinic': 'Консултантско възнаграждение на клиниката (лв.)',
      'consulting_fee_clinic_partial': 'Консултантско възнаграждение на клиниката за ветеринаря (лв.)',
      'consulting_fee_clinic_percentage': 'Консултантско възнаграждение на клиниката (%)',
    },
    'order_details': {
      'title_list': 'Списък поръчки',
      'title_create': 'Създаване на поръчка',
      'order_type': 'Вид храна',
      'VIP': 'VIP',
      'normal': 'Обща',
      'by_breed': 'Породна',
      'quantity': 'Брой разфасовки',
      'quantity_shortened': 'Бр. Разф.',
      'tester_code': 'Код на тестер',
      'price': 'Обща сума',
      'add_comment': 'Добави коментар',
      'comment': 'Коментар',
      'note': 'Вътрешна бележка',
      'owner_note': 'Бележка за собственика',
      'vet_note': 'Бележка за ветеринарния лекар',
      'add_note': 'Добави вътрешна бележка',
      'add_owner_note': 'Добави бележка за собственика',
      'add_vet_note': 'Добави бележка за ветеринарния лекар',
      'created_order_message_history': 'Поръчката е създадена от потребител {{user}} на',
      'with_following_data': 'с първоначални данни:',
      'tab_order': 'Обща поръчка',
      'tab_clinic_order': 'Поръчка за клиниката',
      'unfinished_order_title': 'Незавършена поръчка',
      'unfinished_order_message': 'Поръчката е незавършена. Въведените данни ще бъдат изтрити. Искате ли да продължите?'
    },
    'country_import': {
      'import_country_data_message': 'Зареждане на области, общини и градове в системата',
      'upload_button': 'Прикачи файл',
      'delete_button': 'Изтрий всички градове',
    },
    'delete_confirmation': {
      'dialog_title': 'Изтриване',
      'confirm_message_sg': 'Желаете ли да изтриете този запис?',
      'confirm_message_pl': 'Желаете ли да изтриете тези записи?',
    },
    'delete_image_confirmation': {
      'confirm_message': 'Желаете ли да изтриете това изображение?',
    },
    'delete_image_button': {
      'delete_image': 'Изтрий изображение',
    },
    'delete_file_confirmation': {
      'confirm_message': 'Желаете ли да изтриете този файл?',
    },
    'delete_file_button': {
      'delete_file': 'Изтрий файл',
    },
    'file_input': {
      'drop_files': 'Поставете файловете тук.',
      'drop_files_or_click': 'Поставете файловете тук или натиснете полето за да изберете файлове за качване'
    },
    'image_input': {
      'drop_image': 'Поставете изображението тук.',
      'drop_image_or_click': 'Поставете изображението тук или натиснете полето за да изберете изображение за качване',
      'too_large': 'Файлът е прекалено голям.',
      'not_accepted': 'Този тип файл не се поддържа.',
    },
    'moisture_details': {
      'total_moisture': 'Влага на всички суровини',
      'target_moisture': 'Целева влага',
      'moisture_to_remove': 'За премахване',
      'total_dry_matter': 'Общо количество сухо вещество',
      'target_dry_matter': 'Целево количество сухо вещество',
    },
    'energy': {
      'metabolic_energy': 'Метаболитна енергия',
      'first_method': 'Първи метод',
      'second_method': 'Втори метод',
      'subtitle_message': 'Моля изберете стойност на метаболитната енергия за етикета.',
      'calculated': 'калкулирана',
    },
    'label_settings': {
      'label': 'Етикет',
      'label_admin': 'Администриране на етикет',
      'generate_label_button': 'Генерирай етикет',
      'button_link_front': 'Преден етикет',
      'button_link_back': 'Заден етикет',
      'confirm_overwrite_message': 'Има вече съществуващи етикети за тази поръчка, моля потвърдете генерирането на нови',
      'confirm_overwrite_label': 'Потвърди',
      'typical_analysis': 'Типичен анализ',
      'vitamins_and_minerals': 'Витамини и минерали',
      'digital_label': 'Електронен етикет',
      'tastes_like': 'С преобладаващ вкус',
      'daily_dose': 'Дневна дажба',
      'weight': 'Разфасовка',
      'cup': 'Купичка',
      'weight_in_grams': 'Грамаж',
      'optimus_cup': 'мерителна купичка OPTIMUS',
      'optimus_cups': 'мерителни купички OPTIMUS',
      'moisture': 'Влага [%] max',
      'label_1star_explanation': '* продуктите са в променливо съотношение',
      'label_2stars_explanation': '** продукти предназначени за консумация от хора',
      'indicative_doses_notes': 'Тези количества са индикативни. В зависимост от условията на отглеждане и активността на домашния любимец, той може да се нуждае от по-малко или повече храна, за да бъде в отлично здраве.',
      'must_have_water': 'Домашният любимец трябва винаги да има на разположение прясна питейна вода!',
    },
    'repeat_order': {
      'button_title': 'Повтори поръчка',
      'repeat_order': 'Направи поръчка',
      'dialog_message': 'Моля проверете дали всички данни за животното ви още са актуални.',
      'short_label': 'Повтори'
    },
    'tastes': {
      'title_list': 'Списък вкусове',
      'title_create': 'Създай вкус на храна',
      'picture': 'Картинка',
      'picture_required': 'Картинка е задължително поле'
    },
    'energy_requirement_dog': {
      'title_create': 'Създаване на метаболитна енергия',
      'energy': 'Енергия',
      'days': 'Дни',
    },
    'exchange_weight': {
      'live_weight_to': 'Живо тегло до /кг',
      'exchange_weight': 'Обменно тегло',
      'exchange_weight_kg': 'Обменно тегло /кг',
      'title_create': 'Добави обменно тегло'
    },
    'food_running_out': {
      'food_running_out': 'Свършваща храна',
      'running_out_date': 'Дата',
      'seen': 'Видяно'
    },
    'statuses': {
      'title_list': 'Списък статуси',
      'title_create': 'Добави статус',
      'statuses': 'Статуси',
    },
    'statuses_workflow': {
      'workflow': 'Workflow',
      'create_title': 'Добави Workflow',
      'status_from': 'От статус',
      'status_to': 'Към статус',
      'send_emails_to': 'Изпрати имейл до',
      'admin': 'Admin',
      'production_manager': 'Отговорник производство',
      'clinic': 'Клиника',
      'vet': 'Ветеринарен лекар',
      'owner': 'Собственик',
      'role': 'Роля',
      'template': 'Шаблон',
    },
    'email_templates': {
      'templates': 'Email шаблони',
      'template': 'Email шаблон',
      'title_create': 'Добави email шаблон'
    },
    'statistics': {
      'title': 'Статистики',
      'total_owners': 'Общо Собственици до момента',
      'total_orders': 'Общо поръчки',
      'total_vets': 'Общо Ветеринарни лекари',
      'total_revenue': 'Общо приходи',
      'orders_by_breed': 'Общо Породни поръчки',
      'new_clients_current_mt': 'Нови собственици този месец',
      'orders_last_mt': 'Поръчки предходен месец',
      'vet_orders': 'Поръчки от ветеринари',
      'revenue_last_mt': 'Приходи предходен месец',
      'total_ingredient_value': 'Общо количество използвани суровини',
      'orders_by_animal_type': 'Общо поръчки за кучета / котки (брой)',
      'revenue_by_animal_type': 'Приходи от поръчки за кучета / котки',
      'total_production': 'Общо произведена храна в кг',
      'orders_new_repeat': 'Поръчки нови/ повторени',
      'rev_by_size_class': 'Приходи по size class ',
      'this_month': 'този месец',
      'based_on_last_mt': 'спрямо предходен месец',
      'based_on_avg': 'спрямо средно месечно',
      'in_kg': 'в кг.',
      'based_on_time_filter': 'спрямо избран период',
      'no_data': 'Няма данни'
    },
    'profile': {
      'title': 'Профил',
      'change_password': 'Смяна на парола',
      'old_password': 'Стара парола',
      'new_password': 'Нова парола',
      'repeat_new_password': 'Повтори нова парола',
      'save': 'Запази',
      'user_info': 'Детайли'
    },
    'admins': {
      'title_create': 'Добави сътрудник',
      'title_list': 'Списък сътрудници'
    },
    'production_managers': {
      'title_create': 'Добави отговорник',
      'title_list': 'Списък отговорници'
    }
  }
};

export default translations;
