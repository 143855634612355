import React, { Component } from 'react';
import { Toolbar, SaveButton } from 'react-admin';
import apolloClient from '../initApollo';
import {
  SUBSCRIBE_TO_CREATE,
  GET_ORDER_DETAILS
} from '../customQueries';
import { REDUX_FORM_NAME } from 'react-admin';
import { customCrudCreate } from './customCrudCreate';
import { connect } from 'react-redux';
import MaterialSaveButton from './MaterialSaveButton';
import OrderSaveButton from './OrderSaveButton';
import RecipeToolbar from './Recipe/RecipeToolbar';
import CustomCreateButton from './CustomCreateButton';

const NodesMapper = {
  'Owner': 'id active address email name phoneNumber postcode city { id } country { id } vet { id }',
  'Animal': 'id animalType birthDate castrated chipNumber comment gender name profilePicture tattooNumber weight breed { id } breedMother { id } breedFather { id } owner { id }',
  'Clinic': 'id active email lat lng name phoneNumber postcode street city { id }',
  'City': 'id mainProvinceCity name type country { id } municipality { id }',
  'Vet': 'id active address email name phoneNumber postcode city { id } clinic { id }',
  'Country': 'id isoCode name',
  'Municipality': 'id code name province { id }',
  'Province': 'id code name',
  'Nutrient': 'id name',
  'NutrientElement': 'id name uiValue nutrientGroup { id }',
  'NutrientGroup': 'id name uiValue nutrient { id }',
  'ProductGroup': 'id name',
  'Unit': 'id name coefficientPerKilogram',
  'Order': 'id idNumber dateCreated status { id } currentConsultingFeeClinic consultingFeeClinic currentConsultingFeeVet consultingFeeVet percentagesVet percentagesClinic startOfWeek weekNumber weeklyTurnoverClinic weeklyTurnoverVet price quantity weight weightPricePair orderType hasRecipe animalType breed { id } sizeClass ageGroup ageGroupName animal { id } taste { id } clinic { id } owner { id } recipe { id } vet { id }',
  'Ingredient': 'id name isHumanUsable productGroup { id }',
  'Material': 'id name moisture price nutrients ingredients { id }',
  'Recipe': 'id name bestBefore weightPerBowl totalKcal totalPrice nutrients moistureDetails energy previousRecipeId materialQuantities labelContent frontLabelUrl backLabelUrl digitalLabelId dailyDose dailyDoseAdjustment dailyDosesInPackage hiddenNutrients highQualityAnimalProtein order { id }',
  'Breed': 'id animalType breedNameBg breedNameEn sizeClass priceCoefficient supportedByOptimus encyclopedia shortEncyclopedia picturePuppy pictureJunior pictureAdult pictureMature pictureSenior pictureGeriatric pictureKitten picturePrime pictureYoungAdult pictureMatureAdult',
  'SizeClassMix': 'id months mini small medium large giant',
  'AgeGroupDog': 'id months sizeClass ageGroup priceByWeight priceByWeightVIP',
  'AgeGroupCat': 'id months ageGroup priceByWeight priceByWeightVIP',
  'ConsultingFeePercentageVet': 'id consultingFeePercentage weeklyTurnoverTo vet { id } clinic { id }',
  'ConsultingFeePercentageClinic': 'id consultingFeePercentage weeklyTurnoverTo clinic { id }',
  'Taste': 'id name picture',
  'EnergyRequirementDog': 'id sizeClass energy months days',
  'ExchangeWeightMini': 'id liveWeightTo exchangeWeight',
  'ExchangeWeightSmall': 'id liveWeightTo exchangeWeight',
  'ExchangeWeightMedium': 'id liveWeightTo exchangeWeight',
  'ExchangeWeightLarge': 'id liveWeightTo exchangeWeight',
  'ExchangeWeightGiant': 'id liveWeightTo exchangeWeight',
  'Status': 'id nameBg nameEn',
  'StatusWorkflow': 'id statusFrom { id } statusTo { id } emailTo',
  'EmailTemplate': 'id name',
  'ProductionManager': 'id name active notifications phoneNumber email',
  'Admin': 'id name active notifications phoneNumber email'
};

class CustomCreateToolbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filters: null
    }
  }

  componentDidMount() {
    const { resource, basePath, createResource, user } = this.props;

    const nodes = `node { ${NodesMapper[resource]} }`;

    const resourceToSubscribe = resource.charAt(0).toLowerCase() + resource.slice(1);

    const subscription = apolloClient.subscribe({ query: SUBSCRIBE_TO_CREATE(resourceToSubscribe, nodes) }).subscribe({
      async next(result) {
        const resourceData = result.data[resourceToSubscribe].node;

        const history = {
          user,
          date: new Date(),
          key: `${resource}-${resourceData.id}`,
          oldData: {},
          newData: resourceData
        };

        createResource('History', history, basePath, null);

        subscription.unsubscribe();
      }
    });

    //get orders filters for coping a recipe
    if (resource === 'Recipe') {
      apolloClient.query({ query: GET_ORDER_DETAILS, variables: { id: this.props.record.orderId }, fetchPolicy: 'no-cache' })
        .then(response => {
          if (response.loading) return;

          const animalType = response.data.order.animalType;
          this.setState({
            filters: {
              animalType,
              recipe: { id_not: null }
            }
          });
        });
    }
  }

  render() {
    const { createResource, materialQuantities, ...props } = this.props;
    const roles = ['Owner', 'Vet' , 'Clinic', 'ProductionManager', 'Admin'];

    return (
      props.resource === 'Recipe' ?
        <RecipeToolbar {...this.props} filters={this.state.filters} />
        : <Toolbar {...props} style={{ justifyContent: 'space-between' }}>
          {
            props.resource === 'Material' ?
              <MaterialSaveButton />
              : props.resource === 'Order' ?
                <OrderSaveButton {...props} />
                : roles.includes(props.resource) ?
                  <CustomCreateButton {...this.props} />
                  : <SaveButton />
          }
        </Toolbar>
    );
  }
}

const mapFormStateToProps = state => {
  return { materialQuantities: state.form[REDUX_FORM_NAME] ? !!(state.form[REDUX_FORM_NAME].values && state.form[REDUX_FORM_NAME].values.materialQuantities) : false };
};

export default connect(mapFormStateToProps, { createResource: customCrudCreate })(CustomCreateToolbar);
