import React, {Fragment} from 'react';
import {SimpleForm} from 'react-admin';
import {remove} from 'lodash';
import {SAVE_HIDDEN_RECIPE_ITEMS, GET_UNITS} from '../../customQueries';
import apolloClient from '../../initApollo';
import {NutrientPicker} from './NutrientPicker';

class LabelSettingsForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      saving: false,
      allUnits: [],
    };
  }

  componentDidMount() {
    const user = window.currentUser;

    if (user) {
      this.setState({user});
    }

    apolloClient.query({query: GET_UNITS}).then(response => {
      if (response.loading) return;
      this.setState({allUnits: response.data.units})
    });
  }

  handleNutrientSwitchClick = e => {
    const {id, checked} = e.target;
    const {saving} = this.state;
    let {hiddenNutrients} = this.props;

    if (saving) return;

    if (checked) {
      remove(hiddenNutrients, nutrientId => nutrientId === id);
    } else {
      hiddenNutrients.push(e.target.id);
    }

    this.saveLabelSettings(hiddenNutrients);
  };

  saveLabelSettings = (hiddenNutrients) => {
    this.setState({saving: true});

    const {record: {id}, updateHiddenNutrientsState} = this.props;

    apolloClient.mutate({
      mutation: SAVE_HIDDEN_RECIPE_ITEMS,
      variables: {id, hiddenNutrients}
    }).then(response => {
      if (response.loading) return;

      updateHiddenNutrientsState(hiddenNutrients);

      this.setState({
        saving: false,
      });

      return response;
    });
  };

  render = () => {
    const {
      staticContext,
      basePath,
      callback,
      record,
      classes,
      hiddenNutrients,
      updateHiddenNutrientsState,
      ...rest
    } = this.props;
    const {allUnits} = this.state;
    const {user} = this.state;

    return (
      <Fragment>
        <NutrientPicker
          nutrients={record.nutrients}
          hiddenNutrients={hiddenNutrients}
          switchAction={this.handleNutrientSwitchClick.bind(this)}
          allUnits={allUnits}
          disabled={user && (user.role === 'ProductionManager')}
        />
        <SimpleForm record={record} {...rest}>
        </SimpleForm>
      </Fragment>
    );
  };
}

export {LabelSettingsForm};
