import React from 'react';
import {
  ShowController,
  ShowView,
  TabbedShowLayout,
  Tab,
  TextField,
  ReferenceField,
  ReferenceArrayField,
  Datagrid,
  ReferenceManyField,
  BooleanField,
} from 'react-admin';

import {
  Divider,
} from '@material-ui/core';

import {t} from 'i18next';

import EditFormTitle from '../EditFormTitle';
import {MapField} from '../MapField.js';
import ClinicConsultingFee from './ClinicConsultingFee';
import ChipWithAvatar from '../fields/ChipWithAvatar';
// import AnimalTypeAvatar from '../fields/AnimalTypeAvatar';
import CustomDateField from '../fields/CustomDateField';

export const ClinicShow = props => (
  <ShowController {...props}>
    {controllerProps =>
      <ShowView {...props} {...controllerProps} title={<EditFormTitle formName={t('clinic_name')}/>}>
        <TabbedShowLayout>
          <Tab label={t("summary_tab")}>
            <TextField source="name" label={t("name")}/>
            <BooleanField source="active" label={t('clinics.active')}/>
            <TextField source="phoneNumber" label={t('phone_number')}/>
            <TextField source="email" label={t('email')}/>
          </Tab>
          <Tab label={t("addresses_tab")}>
            <ReferenceField label={t("city")} reference="City" source="city.id" allowEmpty={true} linkType={false}>
              <TextField source="name"/>
            </ReferenceField>
            <TextField source='street' label={t('street')}/>
            <TextField source='streetNumber' label={t('street_number')}/>
            <TextField source='buildingNumber' label={t('building_number')}/>
            <TextField source='district' label={t('district')}/>
            <TextField source='entrance' label={t('entrance')}/>
            <TextField source='floor' label={t('floor')}/>
            <TextField source='apartmentNumber' label={t('apartment_number')}/>
            <TextField label={t('postcode')} source="postcode"/>
            <TextField source="postcode" label={t('postcode')}/>
            <MapField label={t("clinics.location")} addLabel={true}/>
          </Tab>

          <Tab label={t("orders")}>
            <ReferenceArrayField addLabel={false} reference="Order" source="ordersIds">
              <Datagrid rowClick="show">
                <ReferenceField label={t("vet")} reference="Vet" source="vet.id" allowEmpty={true} sortable={false}
                                linkType="show">
                  <TextField source="name"/>
                </ReferenceField>
                <TextField source="idNumber" label={t("number")} sortable={false}/>
                <ReferenceField label={t("animal")} reference="Animal" source="animal.id" allowEmpty={true}
                                sortable={false} linkType="show">
                  <ChipWithAvatar/>
                </ReferenceField>
                <CustomDateField source="dateCreated" label={t('date_created')} sortable={false}/>
                <ReferenceField label={t('status')} reference='Status' source='status.id' allowEmpty={true}
                                sortable={false} linkType={false}>
                  <TextField source='nameBg'/>
                </ReferenceField>
              </Datagrid>
            </ReferenceArrayField>
          </Tab>

          <Tab label={t("main_menu.vets")}>
            <ReferenceArrayField addLabel={false} reference="Vet" source="vetsIds">
              <Datagrid rowClick="show">
                <TextField source='name' label={t("name")} sortable={false}/>
                <TextField source='email' label={t("email")} sortable={false}/>
              </Datagrid>
            </ReferenceArrayField>
          </Tab>

          <Tab label={t("consulting_fee")}>

            <ReferenceArrayField label={t("consulting_fee_details.consulting_fee_percentage_clinic")}
                                 reference="ConsultingFeePercentageClinic" source="consultingFeePercentageClinicsIds">
              <Datagrid>
                <TextField source="weeklyTurnoverTo" label={t("consulting_fee_details.weekly_turnover_to")}
                           sortable={false}/>
                <TextField source="consultingFeePercentage"
                           label={t("consulting_fee_details.consulting_fee_percentage")} sortable={false}/>
              </Datagrid>
            </ReferenceArrayField>

            <Divider/>

            <ReferenceManyField label={t('consulting_fee_details.consulting_fee_percentage_vet')}
                                reference="ConsultingFeePercentageVet" target="clinic">
              <Datagrid>
                <ReferenceField label={t('vet')} reference="Vet" source="vet.id" allowEmpty={true} linkType="show"
                                sortable={false}>
                  <TextField source="name"/>
                </ReferenceField>
                <TextField source="weeklyTurnoverTo" label={t('consulting_fee_details.weekly_turnover_to')}
                           sortable={false}/>
                <TextField source="consultingFeePercentage"
                           label={t('consulting_fee_details.consulting_fee_percentage')} sortable={false}/>
              </Datagrid>
            </ReferenceManyField>

            <Divider/>

            <ClinicConsultingFee/>

          </Tab>

        </TabbedShowLayout>
      </ShowView>
    }
  </ShowController>
);
