import React, { Component, Fragment } from 'react';
import { Button, REDUX_FORM_NAME } from 'react-admin';
import ContentSave from '@material-ui/icons/Save';
import ConfirmationDialog from './ConfirmationDialog';
import { showNotification } from 'ra-core';
import { connect } from 'react-redux';
import { t } from 'i18next';
import { isEmpty } from 'lodash';
import { customCrudCreate } from './customCrudCreate';
import { customCrudUpdate } from './customCrudUpdate';

class MaterialSaveButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDialog: false
    }
  }

  handleClick = () => {
    const {
      invalid,
      showNotification,
      handleSubmitWithRedirect,
      redirect,
      moisture,
      nutrients,
      record,
      updateResource,
      values,
      resource,
      createHistory,
      basePath
    } = this.props;

    if (invalid) {
      showNotification('ra.message.invalid_form', 'warning');
      handleSubmitWithRedirect(redirect)();
    } else {
      let totalPercent = moisture;

      if (nutrients && nutrients.length !== 0) {
        let nutrientsWithPercent = nutrients.filter(nutrient => !isEmpty(nutrient) && nutrient.percent);

        nutrientsWithPercent.forEach(nutrient => {
          totalPercent += nutrient.percent;
        });
      }

      if (totalPercent !== 100) {
        this.setState({ showDialog: true })
      } else {

        if (record && record.id) {
          updateResource(values, record, resource, basePath, {}, createHistory, redirect, null);
        } else {
          handleSubmitWithRedirect(redirect)();
        }
      }
    }
  };

  handleCloseClick = () => {
    this.setState({ showDialog: false });
  };

  handleSave = () => {
    const {
      record,
      updateResource,
      values,
      resource,
      basePath,
      createHistory,
      redirect,
      handleSubmitWithRedirect
    } = this.props;

    if (record && record.id) {
      updateResource(values, record, resource, basePath, {}, createHistory, redirect, null);
    } else {
      handleSubmitWithRedirect(redirect)();
    }
  };

  render() {
    return (
      <Fragment>
        <Button
          label='ra.action.save'
          onClick={this.handleClick}
          color='primary'
          variant='raised'
          size="medium"
        >
          <ContentSave />
        </Button>
        <ConfirmationDialog
          isOpen={this.state.showDialog}
          message={t('material_confirmation_message')}
          handleAction={this.handleSave}
          label='ra.action.save'
          icon={<ContentSave />}
          handleClose={this.handleCloseClick}
          cancelLabel='ra.action.back'
          title={false}
        />
      </Fragment>
    );
  }
}

const mapFormStateToProps = state => {
  let nutrients = null;
  let moisture = null;
  let values = null;
  let form = state.form[REDUX_FORM_NAME];
  if (form) {
    moisture = form.values ? form.values.moisture : null;
    nutrients = form.values ? form.values.nutrients : null;
    values = form.values || null;
  }

  return { moisture, nutrients, values };
};

export default connect(mapFormStateToProps, { showNotification, updateResource: customCrudUpdate, createHistory: customCrudCreate })(MaterialSaveButton);