import React from 'react';
import Card from '@material-ui/core/Card';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {t} from 'i18next';
import {withStyles} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import Person from '@material-ui/icons/Person';
import LocationCity from '@material-ui/icons/LocationCity';
import Room from '@material-ui/icons/Room';
import LocalPhone from '@material-ui/icons/LocalPhone';
import Email from '@material-ui/icons/Email';
import AccountCircle from '@material-ui/icons/AccountCircle';
import apolloClient from '../initApollo';
import {showNotification} from 'ra-core';
import {connect} from 'react-redux';
import {GET_CURRENT_USER_INFO, CHANGE_COGNITO_USER_PASSWORD} from '../customQueries';
import {isValidPassword} from '../utils/validation/index';

const styles = theme => ({
  saveButton: {
    marginTop: 16,
  },
  iconInvalid: {
    color: 'red',
    marginLeft: 16,
  },
  root: {
    marginTop: 16
  }
});

const ListItemComponent = ({primaryText, icon}) => (
  <ListItem disableGutters>
    <ListItemIcon>{icon}</ListItemIcon>
    <ListItemText inset primary={primaryText}/>
  </ListItem>
);

const TextFieldComponent = ({label, onChange, value, required, valid}) => (
  <TextField
    label={label}
    type="password"
    margin="normal"
    required={true}
    onChange={(e) => onChange(e.target.value)}
    value={value}
    error={required || !valid}
    helperText={required ? t('required_field') : !valid ? t('incorrect_cognito_password') : null}
  />
);

class Profile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isValid: true,
      userInfo: null,
      wrongPass: false,
      prevPass: '',
      newPass: '',
      repeatedPass: '',
      requiredPrevPass: false,
      requiredNewPass: false,
      requiredRepeatedPass: false,
      validPrevCognitoPass: true,
      validNewCognitoPass: true
    }
  }

  componentDidMount() {
    const user = window.currentUser;
    console.log(user)
    if (user) {
      this.setUserInfo(user.email, user.role);
    }
  }

  setUserInfo = async (email, role) => {
    if (!email || !role) {
      return null;
    }

    let user = {
      email
    };
    let queryParam;

    switch (role) {
      case 'Admin':
        user.role = role.slice('Admin');
        break;
      case 'ProductionManager':
        user.role = role.slice('Production Manager');
        break;
      default:
        user.role = role;
        queryParam = role.toLowerCase();
        break;
    }

    if (!queryParam) {
      return this.setState({userInfo: user});
    }

    let fields = 'name city{name} phoneNumber street streetNumber';
    // queryParam === 'clinic'
    //   ? fields = fields.concat('street')
    //   : fields = fields.concat('street');

    const query = GET_CURRENT_USER_INFO(queryParam, fields);

    await apolloClient.query({query, variables: {email}, fetchPolicy: 'no-cache'}).then(response => {
      if (response.loading) return this.setState({userInfo: user});

      user = {
        ...user,
        name: response.data[queryParam].name,
        city: response.data[queryParam].city.name,
        street: response.data[queryParam].street,
        streetNumber: response.data[queryParam].streetNumber,
        phoneNumber: response.data[queryParam].phoneNumber
      }

      this.setState({userInfo: user});
    });
  }

  changePassword = async () => {
    const {prevPass, newPass, repeatedPass} = this.state;

    if (!isValidPassword(prevPass)) this.setState({validPrevCognitoPass: false});
    if (!isValidPassword(newPass) || !isValidPassword(repeatedPass)) this.setState({validNewCognitoPass: false});

    if (!isValidPassword(prevPass) || !isValidPassword(newPass) || !isValidPassword(repeatedPass)) return;

    const response = await apolloClient.mutate({
      mutation: CHANGE_COGNITO_USER_PASSWORD,
      variables: {prevPass, newPass}
    });
    const responseSuccess = response.data.changeCognitoUserPassword.success;
    const responseMessage = response.data.changeCognitoUserPassword.message;

    const translations = {
      'Successfully changed password': t('change_password_success'),
      'Incorrect username or password.': t('incorrect_password')
    };

    if (responseSuccess) {
      this.setState({
        prevPass: '',
        newPass: '',
        repeatedPass: '',
        wrongPass: false
      })
    } else this.setState({wrongPass: true});

    this.props.showNotification(translations[responseMessage] || responseMessage, responseSuccess ? 'info' : 'warning');
  }

  handleSubmit = () => {
    const {prevPass, newPass, repeatedPass} = this.state;

    if (prevPass.length === 0) this.setState({requiredPrevPass: true});
    if (newPass.length === 0) this.setState({requiredNewPass: true});
    if (repeatedPass.length === 0) this.setState({requiredRepeatedPass: true});

    if (prevPass.length === 0 || newPass.length === 0 || repeatedPass.length === 0) return;

    if (newPass !== repeatedPass) {
      return this.setState({isValid: false});
    }

    return this.changePassword();
  }

  translateRole = (role) => {
    role = role.toLowerCase().replace(' ', '');
    return t(role);
  }

  renderUserInfo = () => {
    const {userInfo} = this.state;

    if (!userInfo) {
      return null;
    }

    return (
      <List
        subheader={
          <ListSubheader
            disableGutters
            disableSticky
            component="div">
            {t('profile.user_info')}
          </ListSubheader>}
      >
        {userInfo.name && <ListItemComponent primaryText={userInfo.name} icon={<Person/>}/>}
        {userInfo.city && <ListItemComponent primaryText={userInfo.city} icon={<LocationCity/>}/>}
        {userInfo.street &&
        <ListItemComponent primaryText={userInfo.street + ' ' + userInfo.streetNumber} icon={<Room/>}/>}
        {userInfo.phoneNumber && <ListItemComponent primaryText={userInfo.phoneNumber} icon={<LocalPhone/>}/>}
        {userInfo.email && <ListItemComponent primaryText={userInfo.email} icon={<Email/>}/>}
        {userInfo.role && <ListItemComponent primaryText={this.translateRole(userInfo.role)} icon={<AccountCircle/>}/>}
      </List>
    );
  }

  render() {
    const {classes} = this.props;
    const {
      isValid,
      wrongPass,
      prevPass,
      newPass,
      repeatedPass,
      requiredPrevPass,
      requiredNewPass,
      requiredRepeatedPass,
      validPrevCognitoPass,
      validNewCognitoPass
    } = this.state;

    return (
      <Card>
        <Toolbar>
          <Typography variant="title" color="inherit">
            {t('profile.title')}
          </Typography>
        </Toolbar>
        <CardContent>
          {this.renderUserInfo()}
          <FormControl component="div" classes={{root: classes.root}}>
            <FormLabel component="legend">{t('profile.change_password')}</FormLabel>
            <FormGroup>
              <form>
                <div>
                  <TextFieldComponent
                    label={t('profile.old_password')}
                    required={requiredPrevPass}
                    valid={validPrevCognitoPass}
                    value={prevPass}
                    onChange={(prevPass) => this.setState({
                      prevPass,
                      requiredPrevPass: false,
                      wrongPass: false,
                      validPrevCognitoPass: true
                    })}
                  />
                  {wrongPass && <Close className={classes.iconInvalid}/>}
                </div>
                <div>
                  <TextFieldComponent
                    label={t('profile.new_password')}
                    required={requiredNewPass}
                    valid={validNewCognitoPass}
                    value={newPass}
                    onChange={(newPass) => this.setState({
                      newPass,
                      requiredNewPass: false,
                      isValid: true,
                      validNewCognitoPass: true
                    })}
                  />
                  {isValid === false && <Close className={classes.iconInvalid}/>}
                </div>
                <div>
                  <TextFieldComponent
                    label={t('profile.repeat_new_password')}
                    required={requiredRepeatedPass}
                    valid={validNewCognitoPass}
                    value={repeatedPass}
                    onChange={(repeatedPass) => this.setState({
                      repeatedPass,
                      requiredRepeatedPass: false,
                      isValid: true,
                      validNewCognitoPass: true
                    })}
                  />
                  {isValid === false && <Close className={classes.iconInvalid}/>}
                </div>
              </form>
            </FormGroup>
            <Button variant="raised" color="primary" margin='dense' className={classes.saveButton}
                    onClick={this.handleSubmit}>
              {t('profile.save')}
            </Button>
          </FormControl>
        </CardContent>
      </Card>
    );
  }
}

export default connect(undefined, {showNotification})(withStyles(styles)(Profile));