import React from 'react';
import { SimpleForm, NumberInput } from 'react-admin';
import { t } from 'i18next';

import { isRequired } from '../../utils/validation';

const validateRequired = [isRequired()];

export const ExchangeWeightForm = props => (
  <SimpleForm {...props} redirect='/EnergyRequirementDog'>
    <NumberInput source='liveWeightTo' validate={validateRequired} label={t('exchange_weight.live_weight_to')}/>
    <NumberInput source='exchangeWeight' validate={validateRequired} label={t('exchange_weight.exchange_weight_kg')}/>
  </SimpleForm>
);