import React from 'react';
import { t } from 'i18next';
import {
  List,
  Datagrid,
  TextField,
} from 'react-admin';
import { CustomBulkActions } from '../CustomBulkActions';
import { CustomListActions } from '../CustomListActions';

export const ProvinceList = props => (
  <List {...props} title={t("provinces.title_list")} bulkActionButtons={<CustomBulkActions />} actions={<CustomListActions />}>
    <Datagrid rowClick="edit">
      <TextField label={t("name")} source="name"/>
      <TextField label={t("code_column")} source="code"/>
    </Datagrid>
  </List>
);
