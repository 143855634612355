import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCat, faDog } from '@fortawesome/free-solid-svg-icons';

const AnimalTypeAvatar = ({ record }) => (
  record && record.animal ? null : <Avatar style={{width: 30, height: 30, color: 'grey'}}><FontAwesomeIcon icon={record.animalType === 'Dog' ? faDog : faCat}/></Avatar>
);

AnimalTypeAvatar.propTypes = {
  record: PropTypes.object ,
  source: PropTypes.string,
  label: PropTypes.string,
  sortable: PropTypes.bool,
  resource: PropTypes.string,
  basePath: PropTypes.string
};

export default AnimalTypeAvatar;