import React from 'react';
import { Create } from 'react-admin';
import CustomCreateToolbar from '../CustomCreateToolbar';
import { t } from 'i18next';

import { VetForm } from './VetForm';

export const VetCreate = props => (
  <Create {...props} redirect="list" title={t("vets.title_create")} className="gridWithSearchableFilter">
    <VetForm toolbar={<CustomCreateToolbar history={props.history} user={props.options.currentUser.role}/>}/>
  </Create>
);
