import React, { Component } from 'react';
import { REDUX_FORM_NAME } from 'react-admin';
import { connect } from 'react-redux';
import { t } from 'i18next';
import Card from '@material-ui/core/Card';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { reset } from 'redux-form';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import NormalOrderForm from './NormalOrderForm';
import ClinicOrderForm from './ClinicOrderForm';
import apolloClient from '../../initApollo';
import { GET_STATUS_ID_BY_NAME } from '../../customQueries';

class OrderCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 0,
      openPopup: false,
      status: ''
    }
  }

  componentDidMount() {
    apolloClient.query({ query: GET_STATUS_ID_BY_NAME, variables: { name: process.env.REACT_APP_STATUS_NEW_ID }, fetchPolicy: 'no-cache' })
      .then(response => {
        if (response.loading) return;
        this.setState({status: response.data.statuses.pop() || ''})
      });
  }

  handleChangeTab = (event) => {
    this.props.dispatch(reset(REDUX_FORM_NAME));
    let value = this.state.activeTab === 0 ? 1 : 0;
    this.setState({ activeTab: value, openPopup: false });
  }

  handleOpenPopup = (event, value) => {
    const role = this.props.options.currentUser.role;
    const { animalType, ageGroup, animal, clinic, orderType, owner, price, vet, quantity, taste, testerCode } = this.props.formData;

    let details = animalType || ageGroup || animal || orderType || price || quantity !== 1 || taste || testerCode || owner;

    if((role === 'Admin' && (details || clinic || vet)) || (role === 'Clinic' && (details || vet)) || (role === 'Vet' && details)) {
      return this.setState({ openPopup: true });
    }

    this.setState({ activeTab: value, openPopup: false });
  }

  handleStay = () => {
    this.setState({ openPopup: false });
  }

  generateWeightPriceChoice = choice => {
    return `${choice.weight} ${t('kg')} х ${choice.price} ${t('lv')}`;
  }

  convertPairsToString = pairs => {
    let pairsToString = [];
    if (pairs) {
      pairs.forEach(pair => {
        let pairPrice = pair.price.toFixed(2);
        pairsToString.push({ weight: pair.weight.toString(), price: pairPrice.toString(), key: pair.key.toString() });
      })
    }

    return pairsToString;
  };

  calculatePairs = (pairs, breedCoefficient) => {
    let coefficient = breedCoefficient || 1;
    let breedPairs = [];

    if (pairs) {
      pairs.forEach(pair => {
        let breedPairPrice = (pair.price * coefficient).toFixed(2);
        breedPairs.push({ weight: pair.weight.toString(), price: breedPairPrice.toString(), key: pair.key.toString() });
      })
    }

    return breedPairs;
  };

  render() {
    const { formData, dispatch, hasList, hasEdit, hasShow, hasCreate, options, ...rest } = this.props;
    const { activeTab, status, openPopup } = this.state;

    return (
      <Card>
        <Tabs
          value={activeTab}
          onChange={this.handleOpenPopup}
        >
          <Tab label={t('order_details.tab_order')} />
          {options.currentUser.role !== 'Owner' && this.props.location.state === undefined ? <Tab label={t('order_details.tab_clinic_order')} /> : null}
        </Tabs>
        {activeTab === 0 && status !== '' &&
          <NormalOrderForm
            formData={formData}
            generateWeightPriceChoice={this.generateWeightPriceChoice}
            dispatch={dispatch}
            convertPairsToString={this.convertPairsToString}
            calculatePairs={this.calculatePairs}
            status={status}
            user={options.currentUser}
            {...rest} />
        }
        {activeTab === 1 && options.currentUser.role !== 'Owner' && this.props.location.state === undefined && status !== '' &&
          <ClinicOrderForm
            formData={formData}
            generateWeightPriceChoice={this.generateWeightPriceChoice}
            dispatch={dispatch}
            convertPairsToString={this.convertPairsToString}
            calculatePairs={this.calculatePairs}
            status={status}
            user={options.currentUser}
            {...rest} />
        }
        <Dialog
          open={openPopup}
          onClose={this.handleStay}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{t("order_details.unfinished_order_title")}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t('order_details.unfinished_order_message')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleStay} color="secondary">
              {t('no')}
            </Button>
            <Button onClick={this.handleChangeTab} color="primary" autoFocus>
              {t('yes')}
            </Button>
          </DialogActions>
        </Dialog>
      </Card>
    );
  }
}

const mapFormStateToProps = state => {
  if (state.form[REDUX_FORM_NAME]) {
    return { formData: state.form[REDUX_FORM_NAME].values }
  }
  return { formData: {} }
};

export default connect(mapFormStateToProps)(OrderCreate);
