import React, { Fragment, Component } from 'react';
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  FunctionField,
  Button
} from 'react-admin';
import { t } from 'i18next';
import ImageEye from '@material-ui/icons/RemoveRedEye';
import ContentCopy from '@material-ui/icons/ContentCopy';

import CopyRecipeFilter from './CopyRecipeFilter';
import ChipWithAvatar from '../components/fields/ChipWithAvatar';
import apolloClient from '../initApollo';
import {
  GET_ALL_AGE_GROUPS,
  GET_MATERIALS,
  GET_RECIPE,
  GET_UNITS
} from '../customQueries';
import RecipeShowDialog from '../components/Recipe/RecipeShowDialog';

class CopyRecipe extends Component {
  constructor() {
    super();

    this.state = {
      showDialog: false,
      recipeData: null,
      materials: [],
      units: [],
      ageGroupCats: [],
      ageGroupDogs: [],
    }
  }

  componentDidMount() {
    apolloClient.query({ query: GET_UNITS }).then(response => {
      if (response.loading) return;
      this.setState({ units: response.data.units });
    });

    apolloClient.query({ query: GET_MATERIALS }).then(response => {
      if (response.loading) return;
      this.setState({ materials: response.data.materials });
    });

    apolloClient.query({ query: GET_ALL_AGE_GROUPS }).then(response => {
      if (response.loading) return;
      this.setState({
        ageGroupCats: response.data.ageGroupCats,
        ageGroupDogs: response.data.ageGroupDogs,
      });
    });
  }

  handleShowRecipe = (e, id) => {
    e.stopPropagation();

    apolloClient.query({ query: GET_RECIPE, variables: { id }, fetchPolicy: 'no-cache' })
      .then(response => {
        if (response.loading) return;

        this.setState({
          recipeData: response.data.recipe,
          showDialog: true
        });
      });
  };

  handleCopyRecipe = (e, id) => {
    const { location: { state: { order } }, history } = this.props;

    e.stopPropagation();

    apolloClient.query({ query: GET_RECIPE, variables: { id }, fetchPolicy: 'no-cache' })
      .then(response => {
        if (response.loading) return;

        let copiedRecipe = response.data.recipe;
        const data = {
          materialQuantities: copiedRecipe.materialQuantities,
          moistureDetails: copiedRecipe.moistureDetails,
          energy: copiedRecipe.energy,
          nutrients: copiedRecipe.nutrients,
          totalPrice: copiedRecipe.totalPrice,
          order: { id: order },
          previousRecipeId: id
        };

        history.push({ pathname: '/Recipe/create', search: `?orderId=${order}`, state: data });
      })
  };

  handleCloseClick = () => {
    this.setState({ showDialog: false })
  };

  render() {
    const { showDialog, recipeData, ageGroupDogs, ageGroupCats, materials, units } = this.state;
    const { location: { state: { filters } }, location, match } = this.props;

    return (
      <Fragment>
        <List
          resource='Order'
          basePath='/Order'
          hasCreate={false}
          hasEdit={false}
          hasList={true}
          hasShow={false}
          filters={<CopyRecipeFilter animaltype={filters.animalType} />}
          filter={filters}
          sort={{ field: 'dateCreated', order: 'DESC' }}
          location={location}
          match={match}
          bulkActionButtons={false}
          exporter={false}
          title={t('order_details.title_list')}
          className='gridWithSearchableFilter'
        >
          <Datagrid>
            <TextField source='idNumber' label={t('number')} />
            <ReferenceField label={t('clinic_name')} reference='Clinic' source='clinic.id' allowEmpty={true} sortable={false} linkType={false}>
              <TextField source='name' />
            </ReferenceField>
            <ReferenceField label={t('vet')} reference='Vet' source='vet.id' allowEmpty={true} sortable={false} linkType={false}>
              <TextField source='name' />
            </ReferenceField>
            <ReferenceField label={t('owner')} reference='Owner' source='owner.id' allowEmpty={true} sortable={false} linkType={false}>
              <TextField source='name' />
            </ReferenceField>
            <ReferenceField label={t('animal')} reference='Animal' source='animal.id' allowEmpty={true} sortable={false} linkType={false}>
              <ChipWithAvatar blankTarget={true}/>
            </ReferenceField>
            <ReferenceField label={t('status')} reference='Status' source='status.id' allowEmpty={true} sortable={false} linkType={false}>
              <TextField source='nameBg' />
            </ReferenceField>
            <FunctionField
              label={t('recipe')}
              sortable={false}
              render={({ recipe }) => {
                return recipe ?
                  <Fragment>
                    <Button label='ra.action.show' onClick={e => this.handleShowRecipe(e, recipe.id)}>
                      <ImageEye />
                    </Button>
                    <Button label={t('copy')} onClick={e => this.handleCopyRecipe(e, recipe.id)}>
                      <ContentCopy />
                    </Button>
                  </Fragment>
                : null
              }}
            />
          </Datagrid>
        </List>
        <RecipeShowDialog
          isOpen={showDialog}
          handleClose={this.handleCloseClick}
          cancelLabel='ra.action.back'
          recipeData={recipeData}
          ageGroupDogs={ageGroupDogs}
          ageGroupCats={ageGroupCats}
          allMaterials={materials}
          units={units}
        />
      </Fragment>
    );
  }
}

export default CopyRecipe;
