import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  NumberField,
  Filter,
  TextInput
} from 'react-admin';
import { t } from 'i18next';

import { CustomBulkActions } from '../CustomBulkActions';
import { CustomListActions } from '../CustomListActions';

const NutrientGroupFilter = props => (
  <Filter {...props}>
    <TextInput label={t('name')} source='name_contains' alwaysOn/>
  </Filter>
);

export const NutrientGroupList = props => (
  <List
    {...props}
    bulkActionButtons={<CustomBulkActions />}
    actions={<CustomListActions />}
    title={t('nutrient_groups.title_list')}
    filters={<NutrientGroupFilter/>}
  >
    <Datagrid rowClick='edit'>
      <TextField source='name' label={t('name')} />
      <ReferenceField label={t('nutrient')} reference='Nutrient' source='nutrient.id' allowEmpty={true} sortable={false}>
        <TextField source='name'/>
      </ReferenceField>
      <NumberField source='uiValue' label={t('ui_in_mg')} options={{ maximumFractionDigits: 20 }}/>
    </Datagrid>
  </List>
);
