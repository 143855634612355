import { t } from 'i18next';

export const isRequired = (sign = true) => {
  return Object.assign(value => {
    return (!value || !value.toString().replace(/\s/g, '').length) ? t('required_field') : undefined;
  }, {
    isRequired: sign
  });
};

export const validateAlwaysFail = () => {
  return Object.assign(() => {
    return t('required_field');
  });
};

export const isTextNumbers = value => {
  if (!value) return undefined;

  if (!value.match(/^[a-zA-Z0-9]+$/)) {
    return t('only letters');
  }

  return undefined;
};

export const isNaturalNumber = value => {
  if (!Number.isInteger(value) || Math.sign(value) !== 1) {
    return t('natural_number');
  }

  return undefined;
};

export const isNaturalNumberOrNull = value => {
  if (value && (!Number.isInteger(value) || Math.sign(value) !== 1)) return t('natural_number');

  return undefined;
};

export const validateLatCoordinate = value => {
  const regex = new RegExp(/^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/);
  if (value && !regex.test(value)) {
    return t('require_geo_latitude');
  }

  return undefined;
};

export const validateLngCoordinate = value => {
  const regex = new RegExp(/^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/);
  if (value && !regex.test(value)) {
    return t('require_geo_longitude');
  }

  return undefined;
};

export const isValidPassword = password => {
  const hasNumberRegex = /\d/;
  const hasSpecialCharRegex = /\W/;
  const hasUpperLetterRegex  = /[A-Z]/;
  const hasLowerLetterRegex  = /[a-z]/;

  return (
    password.length >= 8 &&
    hasNumberRegex.test(password) &&
    hasSpecialCharRegex.test(password) &&
    hasUpperLetterRegex.test(password) &&
    hasLowerLetterRegex.test(password)
  );
}