import React, { Component } from 'react';
import { addField } from 'ra-core';
import Select, { components } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown } from '@fortawesome/free-solid-svg-icons';
import { REDUX_FORM_NAME } from 'react-admin';
import { connect } from 'react-redux';
import { t } from 'i18next';

const styles = theme => ({
  input: {
    minWidth: theme.spacing.unit * 20,
    cursor: 'pointer'
  }
});

const DropdownIndicator = props => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <FontAwesomeIcon style={{color: 'rgba(0, 0, 0, 0.54)'}} icon={faSortDown}/>
      </components.DropdownIndicator>
    )
  );
};

const filterOptions = (candidate, input) => {
  let candidateLabel = candidate.label.toLowerCase().trim();
  let inputValue = input.toLowerCase();

  if (input) {
    if(input.length <= 1){
      return true
    }
    return candidateLabel.includes(inputValue);
  }
  return true;
};

export class CustomSearchableMultiSelect extends Component {
  constructor(props){
    super(props);

    this.state = {
      isRequired: props.isRequired ? ' *' :  '',
      selected: []
    };
  }

  handleChange = (eventOrValue, {action}) => {
    let recordIds = [];
    if(eventOrValue === null){
      eventOrValue = []
    }

    if (action === 'clear' || eventOrValue.length < 1) {
      this.setState({selected: []});
      recordIds = [];
    } else {
      if (eventOrValue.length > 1) {
        eventOrValue.forEach(value => {
          this.setState({selected: [...this.state.selected, value]});
          recordIds.push(value.id);
        });
      } else {
        this.setState({selected: [...this.state.selected, eventOrValue[0]]});
        recordIds.push(eventOrValue[0].id);
      }
    }

    this.props.input.onChange();

    // HACK: For some reason, redux-form does not consider this input touched without calling onBlur manually
    this.props.input.onBlur(recordIds);
  };

  render() {
    const { choices, record, label, labelsource } = this.props;

    return (
      <Select
        value={record || this.state.selected}
        isMulti
        isClearable={true}
        noOptionsMessage={() => t('no_results_found')}
        getOptionValue={option => option.id}
        getOptionLabel={option => option[labelsource || 'name']}
        filterOption={filterOptions}
        placeholder={label + this.state.isRequired}
        onChange={this.handleChange}
        options={choices}
        className="react-select-container"
        theme={theme => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: '#3f51b5',
            primary25: '#f5f5f5',
          },
        })}
        styles={{
          menu: () => ({ ...styles, zindex: 100000}),
          container: () => ({ ...styles, width: '256px'}),
          valueContainer: () => ({ ...styles, padding: 0}),
          menuList: () => ({
            overflowY: 'auto',
            maxHeight: 200,
            border: 'none',
            boxShadow: '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)'}),
          indicatorSeparator: base => ({...base, display: 'none'}),
          multiValueLabel: base => ({...base, padding: 4}),
          option: base => ({...base, cursor: 'pointer'}),
        }}
        components={{DropdownIndicator}}
      />
    );
  }
}

const mapFormStateToProps = ( state, props ) => {
  let resource = props.obj;
  let form = state.form[REDUX_FORM_NAME];
  let record = [];

  if(form && form.values && resource) {
    if(form.values.hasOwnProperty(props.id)) {
      let selectedValues = form.values[props.id];
      let resourceObj = state.admin.resources[resource].data;

      for (let key in resourceObj){
        if (selectedValues.includes(key)) {
          record.push(resourceObj[key]);
        }
      }
    }
  }

  return { record };
};

export default addField(connect(mapFormStateToProps, {})(CustomSearchableMultiSelect));