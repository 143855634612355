import React from 'react';
import {
  List,
  Datagrid,
  TextField,
} from 'react-admin';
import { t } from 'i18next';

import { CustomBulkActions } from '../CustomBulkActions';
import { CustomListActions } from '../CustomListActions';

export const NutrientList = props => (
  <List {...props} bulkActionButtons={<CustomBulkActions />} actions={<CustomListActions />} title={t('nutrients.title_list')}>
    <Datagrid rowClick="edit">
      <TextField source="name" label={t('name')} />
    </Datagrid>
  </List>
);
