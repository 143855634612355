import React, { Fragment } from 'react';
import { SimpleForm, NumberInput, ReferenceField, TextField } from 'react-admin';
import { t } from 'i18next';

import { isRequired } from '../../utils/validation';

const validateRequired = [isRequired()];

function redirect(record) {
  if(record.clinic){
    return `/Clinic/${record.clinic.id}`;
  }

  return `/Clinic/${record.clinicId}`;
}

export const ConsultingFeePercentageClinicForm = props => (
  <Fragment>
    <SimpleForm {...props} redirect={redirect(props.record)} defaultValue={{ clinic: { id: props.record.clinic || props.record.clinicId }}}>
      <ReferenceField label={t('clinic_name')} reference="Clinic" source={props.record.clinic ? "clinic.id" : "clinicId"} allowEmpty={true} sortable={false}>
        <TextField source="name"/>
      </ReferenceField>
      <NumberInput source="weeklyTurnoverTo" label={t('consulting_fee_details.weekly_turnover_to')} validate={validateRequired}/>
      <NumberInput source="consultingFeePercentage" label={t('consulting_fee_details.consulting_fee_percentage')} validate={validateRequired}/>
    </SimpleForm>
  </Fragment>
);