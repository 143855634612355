import React from 'react';
import { Edit } from 'react-admin';
import { t } from 'i18next';

import EditFormTitle from '../EditFormTitle';
import { NutrientGroupForm } from './NutrientGroupForm';
import { CustomEditToolbar } from '../CustomEditToolbar';

export const NutrientGroupEdit = props => (
  <Edit {...props} title={<EditFormTitle formName={t("nutrient_group")} />}>
    <NutrientGroupForm toolbar={<CustomEditToolbar user={props.options.currentUser.role}/>} />
  </Edit>
);
