import { t } from 'i18next';
import { CREATE } from 'react-admin';
import apolloClient from './../initApollo';
import {
  UPDATE_ORDER_HASRECIPE,
} from './../customQueries';

export const customCrudCreate = (resource, data, basePath, createHistory, redirect = false) => ({
  type: 'RA/CRUD_CREATE',
  payload: { data },
  meta: {
    resource,
    fetch: CREATE,
    onSuccess: {
      redirectTo: redirect,
      basePath,
      callback: () => {
        if (resource === 'Recipe') {
          const id = data.orderId || data.order.id;
          apolloClient.mutate({ mutation: UPDATE_ORDER_HASRECIPE, variables: { id, hasRecipe: 'Yes' } });
        }
      }
    },
    onFailure: {
      notification: {
        body: t('ra.notification.http_error'),
        level: 'warning',
      },
    }
  }
});
