import React, { Component } from 'react';
import {
  TextInput,
  NumberInput,
  SelectInput,
  BooleanInput,
} from 'react-admin';
import CustomDateInput from '../fields/CustomDateInput';
import { t } from 'i18next';
import { withStyles } from '@material-ui/core';
// import apolloClient from "../../initApollo";
// import {GET_MIX_BREED_ID} from "../../customQueries";


import {
  Divider,
} from '@material-ui/core';

const styles = theme => ({
  dateMinWidth: {
    minWidth: 216
  },
  dailyWalkMinWidth: {
    minWidth: 396
  }
});

class VIPDataForm extends Component {
  VIPDataStyle = {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 280
  };

  // constructor(props) {
  //   super(props);
  //
  // }

  render() {
    const { classes } = this.props;
    const { userRole } = this.props;

    const isDisabled = (userRole === 'Owner');

    return (
      <div style={this.VIPDataStyle}>
        <h5>{t('general_section')}</h5>
        <NumberInput disabled={isDisabled} source='withersHeight' label={t('withers_height')} />
        <TextInput disabled={isDisabled} source='coatType' label={t('coat_type')} />
        <TextInput disabled={isDisabled} source='hairStructure' label={t('hair_structure')} />
        <TextInput disabled={isDisabled} source='coatColor' label={t('coat_color')} />
        <TextInput disabled={isDisabled} source='skinCondition' label={t('skin_condition')} />

        <Divider/>

        <h5>{t('digestive_system_condition_section')}</h5>
        <TextInput disabled={isDisabled} source='teeth' label={t('teeth')} />
        <TextInput disabled={isDisabled} source='gums' label={t('gums')} />
        <TextInput disabled={isDisabled} source='tongueAndMucousMembranes' label={t('tongue_and_mucous_membranes')} />
        <TextInput disabled={isDisabled} source='esophagus' label={t('esophagus')} />
        <TextInput disabled={isDisabled} source='stomach' label={t('stomach')} />
        <TextInput disabled={isDisabled} source='intestines' label={t('intestines')} />
        <SelectInput
          source='excrementConsistency'
          label={t('excrement_consistency')}
          disabled={isDisabled}
          choices={[
            { id: 'None', name: '' },
            { id: 'SolidAndFriable', name: t('solid_and_friable') },
            { id: 'SolidAndShaped', name: t('solid_and_shaped') },
            { id: 'Shaped', name: t('shaped') },
            { id: 'SoftAndShaped', name: t('soft_and_shaped') },
            { id: 'SoftAndUnshaped', name: t('soft_and_unshaped') },
            { id: 'Liquid', name: t('liquid') },
          ]}
        />

        <Divider/>

        <h5>{t('activity_section')}</h5>
        <SelectInput
          source='livingPlace'
          label={t('living_place')}
          disabled={isDisabled}
          choices={[
            { id: 'None', name: '' },
            { id: 'HomeInside', name: t('home_inside') },
            { id: 'HomeOutside', name: t('home_outside') },
          ]}
        />
        <NumberInput disabled={isDisabled} source='dailyWalkDuration' label={t('daily_walk_duration')} className={classes.dailyWalkMinWidth} />
        <SelectInput
          source='walkIntensity'
          label={t('walk_intensity')}
          disabled={isDisabled}
          choices={[
            { id: 'None', name: '' },
            { id: 'Low', name: t('low_intensity') },
            { id: 'Medium', name: t('medium_intensity') },
            { id: 'High', name: t('high_intensity') },
          ]}
        />
        <SelectInput
          source='hunt'
          label={t('hunt')}
          disabled={isDisabled}
          choices={[
            { id: 'None', name: '' },
            { id: 'Underground', name: t('underground') },
            { id: 'Birds', name: t('birds') },
            { id: 'Chasing', name: t('chasing') },
          ]}
        />
        <SelectInput
          source='sport'
          label={t('sport')}
          disabled={isDisabled}
          choices={[
            { id: 'None', name: '' },
            { id: 'IPO', name: t('IPO') },
            { id: 'Agility', name: t('agility') },
            { id: 'Other', name: t('other') },
          ]}
        />
        <TextInput disabled={isDisabled} source='otherSport' label={t('other_sport')} />
        <BooleanInput disabled={isDisabled} source='workUsage' label={t('work_usage')} />
        <TextInput disabled={isDisabled} source='workType' label={t('work_type')} />

        <Divider/>

        <h5>{t('feeding_section')}</h5>
        <TextInput disabled={isDisabled} source='foodBrand' label={t('food_brand')} />
        <TextInput disabled={isDisabled} source='homemadeFood' label={t('homemade_food')} />
        <SelectInput
          source='appetite'
          label={t('appetite')}
          disabled={isDisabled}
          choices={[
            { id: 'None', name: '' },
            { id: 'Low', name: t('low_appetite') },
            { id: 'Medium', name: t('medium_appetite') },
            { id: 'High', name: t('high_appetite') },
          ]}
        />
        <TextInput disabled={isDisabled} source='preferredTaste' label={t('preferred_taste')} />
        <TextInput disabled={isDisabled} source='suspectedFoodAllergy' label={t('suspected_food_allergy')} />
        <TextInput disabled={isDisabled} source='identifiedFoodAllergy' label={t('identified_food_allergy')} />
        <TextInput disabled={isDisabled} source='jointsAndTiesCondition' label={t('joints_and_ties_condition')} />

        <Divider/>

        <h5>{t('breeding_section')}</h5>
        <BooleanInput disabled={isDisabled} source='breeding' label={t('breeding')} />
        <CustomDateInput disabled={isDisabled} source='fertilizationDate' label={t('fertilization_date')} maxDate={new Date()} />
        <CustomDateInput disabled={isDisabled} source='givingBirthDate' label={t('giving_birth_date')} maxDate={new Date()} className={classes.dateMinWidth} />

      </div>
    )
  }
}

export default withStyles(styles)(VIPDataForm);
