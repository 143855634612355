import React, { Component } from 'react';
import { Edit } from 'react-admin';

import { t } from 'i18next';

import { CustomEditToolbar } from '../CustomEditToolbar';
import { AdminForm } from './AdminForm';
import EditFormTitle from '../EditFormTitle';

class AdminEdit extends Component {

  render() {
    const { history, options } = this.props;

    return (
      <Edit
        title={<EditFormTitle formName={t('admin')} />}
        {...this.props}
      >
        <AdminForm redirect="list" toolbar={<CustomEditToolbar history={history} user={options.currentUser.role} useremail={options.currentUser.email}/>} />
      </Edit>
    );
  }
}

export default AdminEdit;