import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { connect } from 'react-redux';
import { t } from 'i18next';
import DeleteFileButtonWithConfirmation from './DeleteFileButtonWithConfirmation';
import { refreshView as refreshViewAction } from 'ra-core';
import { DELETE_FILES_OF_ANIMAL } from '../customQueries';
import apolloClient from '../initApollo';
import { customCrudCreate } from './customCrudCreate';

class CustomFileInputPreview extends Component {
  deleteFile = ({resource, id, files, removedFile}) => {
    const { createHistory, basePath, refreshView, user } = this.props;

    apolloClient.mutate({mutation: DELETE_FILES_OF_ANIMAL(resource), variables: { id: id, filesIds: [removedFile.id] }})
    .then(async response => {
      if (response.loading) return;

      const history = {
        user,
        date: new Date(),
        animal: removedFile.animal.id,
        data: {
          files: {
            oldValue: { id: removedFile.id, name: removedFile.name, url: removedFile.url },
            newValue: null
          }
        }
      };

      createHistory('AnimalHistory', history, basePath, null);
      refreshView();
    });
  };

  formatDate = date => {
    const datetime = new Date(date);

    const pad = '00';

    const yyyy = datetime.getFullYear().toString();
    const MM = (datetime.getMonth() + 1).toString();
    const dd = datetime.getDate().toString();

    const HH = datetime.getHours().toString();
    const ii = datetime.getMinutes().toString();
    const ss = datetime.getSeconds().toString();

    return `${(pad + dd).slice(-2)}/${(pad + MM).slice(-2)}/${yyyy} ${HH}:${(pad + ii).slice(-2)}:${(pad + ss).slice(-2)}`;
  };

  render() {
    const { classes = {}, data, ids } = this.props;
    return data ? (
      Object.values(data).map((item, index) => {
        let files = [];
        ids.forEach(id => {
          if(id !== item.id){
            files.push(id);
          }
        });
        return (<React.Fragment key={index}>
          <ListItem>
            <label htmlFor="icon-button-file">
              <IconButton color="primary" className={classes.button} href={item.url} target="_blank">
                <CloudDownloadIcon/>
              </IconButton>
            </label>
            <ListItemText primary={item.name} secondary={this.formatDate(item.dateCreated)}/>
            <DeleteFileButtonWithConfirmation
              resource='Animal'
              id={item.animal.id}
              files={files}
              removedFile={item}
              callback={this.deleteFile.bind(this)}
              label={t('delete_file_button.delete_file')}
              message={t('delete_file_confirmation.confirm_message')}
            />
          </ListItem>
        </React.Fragment>);
      })
    ) : null;
  }
}

CustomFileInputPreview.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
};

export default connect(undefined, { refreshView: refreshViewAction, createHistory: customCrudCreate })(CustomFileInputPreview);