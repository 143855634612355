import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faObjectGroup } from '@fortawesome/free-solid-svg-icons';

import { AgeGroupCatList } from '../components/AgeGroupCat/AgeGroupCatList';
import { AgeGroupCatShow } from '../components/AgeGroupCat/AgeGroupCatShow';
import { AgeGroupCatCreate } from '../components/AgeGroupCat/AgeGroupCatCreate';
import { AgeGroupCatEdit } from '../components/AgeGroupCat/AgeGroupCatEdit';

export const AgeGroupCat = {
  icon: () => <FontAwesomeIcon style={{marginLeft: '5px'}} icon={faObjectGroup}/>,
  list: AgeGroupCatList,
  show: AgeGroupCatShow,
  create: AgeGroupCatCreate,
  edit: AgeGroupCatEdit
};