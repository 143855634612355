import React, { Fragment } from 'react';
import { SimpleForm, NumberInput, SelectInput, ArrayInput, SimpleFormIterator, FormDataConsumer } from 'react-admin';
import { t } from 'i18next';
import { isRequired } from '../../utils/validation';

const validateRequired = [isRequired()];

export const AgeGroupDogForm = props => (
  <SimpleForm {...props} redirect='list'>
    <SelectInput
      source="sizeClass"
      label={t('size_class')}
      choices={[
        { id: 'Mini', name: t('size_classes.mini') },
        { id: 'Small', name: t('size_classes.small') },
        { id: 'Medium', name: t('size_classes.medium') },
        { id: 'Large', name: t('size_classes.large') },
        { id: 'Giant', name: t('size_classes.giant') }
      ]}
      validate={validateRequired}
    />
    <SelectInput
      source="ageGroup"
      label={t('age_group.age_group')}
      choices={[
        { id: 'Puppy', name: t('age_group.puppy') },
        { id: 'Junior', name: t('age_group.junior') },
        { id: 'Adult', name: t('age_group.adult') },
        { id: 'Mature', name: t('age_group.mature') },
        { id: 'YoungAdult', name: t('age_group.youngadult') },
        { id: 'MatureAdult', name: t('age_group.matureadult') },
        { id: 'Senior', name: t('age_group.senior') },
        { id: 'Geriatric', name: t('age_group.geriatric') }
      ]}
      validate={validateRequired}
    />
    <NumberInput source="months" label={t('age_in_months')} validate={validateRequired}/>

    <ArrayInput source="priceByWeightGeneral" label={t('age_group.price_by_weight_general')} validate={validateRequired}>
      <SimpleFormIterator>
        <FormDataConsumer>
          {({id, formData, getSource}) => {
            const index = id.match(/\d+/) ? id.match(/\d+/)[0] : 0;
            if (formData && formData.priceByWeightGeneral && formData.priceByWeightGeneral[index] && formData.priceByWeightGeneral[index].weight && formData.priceByWeightGeneral[index].price) {
              formData.priceByWeightGeneral[index].key = `${formData.priceByWeightGeneral[index].weight}${formData.priceByWeightGeneral[index].price}${new Date().getTime()}`
            }
            return (
              <Fragment>
                <NumberInput source={getSource('weight')} label={t('age_group.weight')} validate={validateRequired}/>
                <NumberInput source={getSource('price')} label={t('age_group.price')} validate={validateRequired}/>
              </Fragment>
            );
          }}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>

    <ArrayInput source="priceByWeight" label={t('age_group.price_by_weight')} validate={validateRequired}>
      <SimpleFormIterator>
        <FormDataConsumer>
          {({id, formData, getSource}) => {
            const index = id.match(/\d+/) ? id.match(/\d+/)[0] : 0;
            if (formData && formData.priceByWeight && formData.priceByWeight[index] && formData.priceByWeight[index].weight && formData.priceByWeight[index].price) {
              formData.priceByWeight[index].key = `${formData.priceByWeight[index].weight}${formData.priceByWeight[index].price}${new Date().getTime()}`
            }
            return (
              <Fragment>
                <NumberInput source={getSource('weight')} label={t('age_group.weight')} validate={validateRequired}/>
                <NumberInput source={getSource('price')} label={t('age_group.price')} validate={validateRequired}/>
              </Fragment>
            );
          }}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
    <ArrayInput source="priceByWeightVIP" label={t('age_group.price_by_weight_VIP')}>
      <SimpleFormIterator>
        <FormDataConsumer>
          {({id, formData, getSource}) => {
            const index = id.match(/\d+/) ? id.match(/\d+/)[0] : 0;
            if (formData && formData.priceByWeightVIP && formData.priceByWeight[index] && formData.priceByWeightVIP[index].weight && formData.priceByWeightVIP[index].price) {
              formData.priceByWeightVIP[index].key = `${formData.priceByWeightVIP[index].weight}${formData.priceByWeightVIP[index].price}${new Date().getTime()}`
            }
            return (
              <Fragment>
                <NumberInput source={getSource('weight')} label={t('age_group.weight')} validate={validateRequired}/>
                <NumberInput source={getSource('price')} label={t('age_group.price')} validate={validateRequired}/>
              </Fragment>
            );
          }}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  </SimpleForm>
);
