import React from 'react';
import { t } from 'i18next';
import {
  TableHead,
  TableRow,
  TableCell,
} from '@material-ui/core';

function IngredientsHeader(props) {
  const { totalPrice } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell>{t('material')}</TableCell>
        <TableCell>{t('quantity')}</TableCell>
        <TableCell>{t('unit')}</TableCell>
        <TableCell>{t('percent_per_kg')}</TableCell>
        <TableCell>{t('material_details.moisture_percent')}</TableCell>
        <TableCell>{t('price')} ({totalPrice}{t('lv')})</TableCell>
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  )
}

export default IngredientsHeader;