import React, { Component } from 'react';
import { Edit } from 'react-admin';

import { t } from 'i18next';

import { CustomEditToolbar } from '../CustomEditToolbar';
import EditFormTitle from '../EditFormTitle';
import { ProductionManagerForm } from './ProductionManagerForm';

class ProductionManagerEdit extends Component {

  render() {
    const { history, options } = this.props;

    return (
      <Edit
        title={<EditFormTitle formName={t('productionmanager')} />}
        {...this.props}
      >
        <ProductionManagerForm redirect="list" toolbar={<CustomEditToolbar history={history} user={options.currentUser.role} />} />
      </Edit>
    );
  }
}

export default ProductionManagerEdit;