import React from 'react';
import { Edit } from 'react-admin';
import { t } from 'i18next';

import { CustomEditToolbar } from '../CustomEditToolbar';
import EditFormTitle from '../EditFormTitle';
import { MunicipalityForm } from './MunicipalityForm';

export const MunicipalityEdit = props => (
  <Edit {...props} title={<EditFormTitle formName={t("municipality")} />}>
    <MunicipalityForm toolbar={<CustomEditToolbar user={props.options.currentUser.role}/>}/>
  </Edit>
);
