import React, {Component} from 'react';
import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  ReferenceField,
  ReferenceArrayField,
  BooleanField,
  Datagrid, ReferenceManyField, SingleFieldList,
} from 'react-admin';


import {
  Divider,
} from '@material-ui/core';

import {t} from 'i18next';

import apolloClient from '../../initApollo';
import {GET_CONSULTING_FEE_VET_PERCENT} from '../../customQueries';
import EditFormTitle from '../EditFormTitle';
import VetConsultingFee from './VetConsultingFee';
import ChipWithAvatar from '../fields/ChipWithAvatar';
import CustomDateField from '../fields/CustomDateField';

// import AnimalTypeAvatar from '../fields/AnimalTypeAvatar';

class VetShow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      percentagesCount: 0
    }
  }

  componentDidMount() {
    apolloClient.query({query: GET_CONSULTING_FEE_VET_PERCENT, variables: {vetId: this.props.id}})
      .then(response => {
        if (response.loading) return;

        const percentagesCount = response.data.consultingFeePercentageVets.length;
        this.setState({percentagesCount});
      })
  }

  render() {
    return (
      <Show {...this.props} title={<EditFormTitle formName={t("vet")}/>}>
        <TabbedShowLayout>
          <Tab label={t("summary_tab")}>
            <TextField source="name" label={t("name")}/>
            <BooleanField source="active" label={t('active')}/>
            <TextField source="email" label={t('email')}/>
            <TextField source="phoneNumber" label={t('phone_number')}/>
            <ReferenceField label={t("clinic_name")} reference="Clinic" source="clinic.id" allowEmpty={true}
                            linkType={this.props.options.currentUser.role === 'Vet' ? false : 'show'} sortable={false}>
              <TextField source="name"/>
            </ReferenceField>
          </Tab>

          <Tab label={t("addresses_tab")}>
            <ReferenceField label={t("city")} reference="City" source="city.id" allowEmpty={true} linkType={false}>
              <TextField source="name"/>
            </ReferenceField>
            <TextField source='street' label={t('street')}/>
            <TextField source='streetNumber' label={t('street_number')}/>
            <TextField source='buildingNumber' label={t('building_number')}/>
            <TextField source='district' label={t('district')}/>
            <TextField source='entrance' label={t('entrance')}/>
            <TextField source='floor' label={t('floor')}/>
            <TextField source='apartmentNumber' label={t('apartment_number')}/>
            <TextField source="postcode" label={t('postcode')}/>
          </Tab>

          <Tab label={t("orders")}>
            <ReferenceArrayField addLabel={false} reference="Order" source="ordersIds">
              <Datagrid rowClick="show">
                <TextField source="idNumber" label={t("number")} sortable={false}/>
                <ReferenceField label={t("animal")} reference="Animal" source="animal.id" allowEmpty={true}
                                linkType="show" sortable={false}>
                  <ChipWithAvatar/>
                </ReferenceField>
                <CustomDateField source="dateCreated" label={t('date_created')} sortable={false}/>
                <ReferenceField label={t('status')} reference='Status' source='status.id' allowEmpty={true}
                                sortable={false} linkType={false}>
                  <TextField source='nameBg'/>
                </ReferenceField>
              </Datagrid>
            </ReferenceArrayField>
          </Tab>

          <Tab label={t("vets.owners")}>
            <ReferenceArrayField addLabel={false} reference="Owner" source="ownersIds">
              <Datagrid rowClick="show">
                <TextField source='name' label={t("name")} sortable={false}/>
                <TextField source='email' label={t("email")} sortable={false}/>

                <ReferenceManyField label={t('owners.pets')} reference="Animal" target="owner.id" allowEmpty={true}
                                    sortable={false}>
                  <SingleFieldList linkType="show">
                    <ChipWithAvatar/>
                  </SingleFieldList>
                </ReferenceManyField>

                {/*<ReferenceManyField label={t('owners.orders')} reference="Order" target="owner.id" allowEmpty={true} sortable={false}>*/}
                {/*  <SingleFieldList linkType="show">*/}
                {/*    <Chip key={record.id}*/}
                {/*          label={record.idNumber + ' (' + record.price + ' лв)'} component={Link}*/}
                {/*          to={{pathname: `/Order/${record.id}/show`}} clickable/>*/}
                {/*  </SingleFieldList>*/}
                {/*</ReferenceManyField>*/}

              </Datagrid>
            </ReferenceArrayField>
          </Tab>

          {this.state.percentagesCount === 0 && this.props.options.currentUser.role === 'Vet' ? null :
            <Tab label={t("consulting_fee")}>

              {this.props.options.currentUser.role !== 'Vet' ?
                <ReferenceArrayField label={t("consulting_fee_details.consulting_fee_percentage_vet")}
                                     reference="ConsultingFeePercentageVet" source="consultingFeePercentageVetsIds">
                  <Datagrid>
                    <TextField source="weeklyTurnoverTo" label={t("consulting_fee_details.weekly_turnover_to")}
                               sortable={false}/>
                    <TextField source="consultingFeePercentage"
                               label={t("consulting_fee_details.consulting_fee_percentage")} sortable={false}/>
                  </Datagrid>
                </ReferenceArrayField>
                : null}

              <Divider/>

              <VetConsultingFee user={this.props.options.currentUser}/>
            </Tab>
          }
        </TabbedShowLayout>
      </Show>
    )
  }
}

export default VetShow;