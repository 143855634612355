import React, {Fragment} from 'react';
import CustomSearchableAsyncSelect from './CustomSearchableAsyncSelect';
import apolloClient from '../initApollo';
import {
    GET_ALL_CLINICS,
    GET_ALL_ACTIVE_CLINICS,
    GET_ALL_VETS_BY_CLINIC,
    GET_ALL_VETS,
    GET_ALL_ACTIVE_VETS,
    GET_ALL_OWNERS,
    GET_ALL_ACTIVE_OWNERS,
    GET_ALL_OWNERS_BY_VET,
    GET_ALL_OWNERS_BY_CLINIC,
    GET_ALL_VETS_BY_CLINIC_EMAIL,
    GET_ALL_OWNERS_BY_VET_EMAIL,
    GET_ALL_ANIMALS_BY_OWNER,
    GET_ALL_ANIMALS_WITH_NAME,
    GET_ALL_TASTES,
} from "../customQueries";
import {t} from 'i18next';
import {isRequired} from '../utils/validation';

const validateRequired = [isRequired()];

class SearchableSelect extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            initialOptions: [],
            inputValue: null,
        };
    }

    getQueryData = () => {
        const {obj, filter, resource} = this.props;
        switch (obj) {

            case 'Clinic':
                if (resource === 'Vet') {
                    return {queryName: GET_ALL_ACTIVE_CLINICS, obj: 'clinics'};
                }
                return {queryName: GET_ALL_CLINICS, obj: 'clinics'};

            case 'Vet':
                if (filter) {
                    if (filter.clinic && filter.clinic !== undefined) {
                        if (filter.clinic.id !== undefined) {
                            return {queryName: GET_ALL_VETS_BY_CLINIC, obj: 'vets', vars: {clinicId: filter.clinic.id}}
                        } else if (filter.clinic.email !== undefined) {
                            return {
                                queryName: GET_ALL_VETS_BY_CLINIC_EMAIL,
                                obj: 'vets',
                                vars: {clinic: filter.clinic.email}
                            }
                        }
                    }
                    return {queryName: GET_ALL_VETS_BY_CLINIC, obj: 'vets', vars: {clinicId: null}}
                }

                if (resource === 'Owner') {
                    return {queryName: GET_ALL_ACTIVE_VETS, obj: 'vets'};
                }

                return {queryName: GET_ALL_VETS, obj: 'vets'};

            case 'Owner':
                if (filter) {
                    if (filter.vet && filter.vet !== undefined) {
                        if (filter.vet.id !== undefined) {
                            return {queryName: GET_ALL_OWNERS_BY_VET, obj: 'owners', vars: {vetId: filter.vet.id}};
                        } else if (filter.vet.email !== undefined) {
                            return {
                                queryName: GET_ALL_OWNERS_BY_VET_EMAIL,
                                obj: 'owners',
                                vars: {vet: filter.vet.email}
                            };
                        } else if (filter.vet.clinic !== undefined) {
                            return {
                                queryName: GET_ALL_OWNERS_BY_CLINIC,
                                obj: 'owners',
                                vars: {clinic: filter.vet.clinic.email}
                            };
                        }
                    }
                    return {queryName: GET_ALL_OWNERS_BY_VET, obj: 'owners', vars: {vetId: null}};
                }

                if (resource === 'Animal') {
                    return {queryName: GET_ALL_ACTIVE_OWNERS, obj: 'owners'};
                }

                return {queryName: GET_ALL_OWNERS, obj: 'owners'};

            case 'Animal':
                if (filter) {
                    if (filter.owner && filter.owner !== undefined) {
                        if (filter.owner.id !== undefined) {
                            return {
                                queryName: GET_ALL_ANIMALS_BY_OWNER,
                                obj: 'animals',
                                vars: {ownerId: filter.owner.id}
                            };
                        }
                    }
                }

                return {queryName: GET_ALL_ANIMALS_WITH_NAME, obj: 'animals'};

            case 'Taste':
                return {queryName: GET_ALL_TASTES, obj: 'tastes'};

            default:
                return;
        }
    };

    componentDidMount() {
        this.getFilteredData();
    }

    getFilteredData = inputValue => {
        const {queryName, obj, vars} = this.getQueryData();
        const variables = {name: inputValue !== undefined ? inputValue : '', ...vars};

        return apolloClient.query({query: queryName, variables, fetchPolicy: 'no-cache'})
            .then(response => {
                if (response.loading) return;
                let data = response.data[obj];

                data.sort((a, b) => {
                    if (a.name.trim() < b.name.trim()) {
                        return -1;
                    }
                    if (a.name.trim() > b.name.trim()) {
                        return 1;
                    }
                    return 0;
                });

                this.setState({
                    initialOptions: data,
                    inputValue,
                });
                return data;
            });
    };

    handleChange = (input) => {
        this.props.input.onChange(input);

        // HACK: For some reason, redux-form does not consider this input touched without calling onBlur manually
        this.props.input.onBlur();
    };

    render() {
        const {obj, input, label, source, data, isRequired, disabled, defaultValue} = this.props;

        return (
            <Fragment>
                <CustomSearchableAsyncSelect
                    value={this.state.inputValue}
                    isClearable={true}
                    isDisabled={disabled}
                    defaultValue={defaultValue}
                    defaultOptions
                    loadOptions={this.getFilteredData}
                    noOptionsMessage={() => t('no_results_found')}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    getFilteredData={this.getFilteredData}
                    placeholder={`${label} ${isRequired ? '*' : ''}`}
                    onChange={this.handleChange}
                    onValueChanged={this.props.onValueChanged}
                    validate={isRequired ? validateRequired : null}
                    name={input.name}
                    source={source}
                    data={data}
                    obj={obj}
                    theme={theme => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            primary: '#3f51b5',
                            neutral70: '#fff',
                            primary25: '#f5f5f5',
                        },
                    })}
                />
            </Fragment>
        )
    };
}

export {SearchableSelect};
