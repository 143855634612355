import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faObjectUngroup } from '@fortawesome/free-solid-svg-icons';

import { AgeGroupDogList } from '../components/AgeGroupDog/AgeGroupDogList';
import { AgeGroupDogShow } from '../components/AgeGroupDog/AgeGroupDogShow';
import { AgeGroupDogCreate } from '../components/AgeGroupDog/AgeGroupDogCreate';
import { AgeGroupDogEdit } from '../components/AgeGroupDog/AgeGroupDogEdit';

export const AgeGroupDog = {
  icon: () => <FontAwesomeIcon style={{marginLeft: '5px'}} icon={faObjectUngroup}/>,
  list: AgeGroupDogList,
  show: AgeGroupDogShow,
  create: AgeGroupDogCreate,
  edit: AgeGroupDogEdit
};