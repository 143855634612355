import React from 'react';
import { Edit } from 'react-admin';

import { t } from 'i18next';

import EditFormTitle from '../EditFormTitle';
import { VetForm } from './VetForm';
import { CustomEditToolbar } from '../CustomEditToolbar';

export const VetEdit = props => (
  <Edit {...props} title={<EditFormTitle formName={t("vet")} />} className="gridWithSearchableFilter">
    <VetForm toolbar={<CustomEditToolbar history={props.history} user={props.options.currentUser.role}/>} user={props.options.currentUser}/>
  </Edit>
);
