import React from 'react';
import {
  Filter,
  TextInput,
  ReferenceInput,
  BooleanInput
} from 'react-admin';

import { t } from 'i18next';
import LocationSelect from '../LocationSelect';

export const VetFilter = (props) => (
  <Filter {...props}>
    {props.user.role !== 'Vet' ? <TextInput label={t("name")} source="name_contains" alwaysOn /> : null}
    <BooleanInput label={t("active")} source="active" />
    <ReferenceInput
      label={t('city')}
      reference="City"
      source="city.id"
      perPage={30}
      sort={{ field: 'name', order: 'ASC' }}>
      <LocationSelect />
    </ReferenceInput>
    <TextInput label={t('street')} source='street_contains'/>
    {props.user.role !== 'Clinic' ? <TextInput label={t("clinic_name")} source="clinic.name_contains" /> : null}
  </Filter>
);
