import React, {Component} from 'react';
import {
  REDUX_FORM_NAME,
  SelectInput,
  ReferenceInput,
  SimpleForm,
  Edit,
  ArrayInput,
  LongTextInput,
  SimpleFormIterator,
  FormDataConsumer,
  TextInput,
} from 'react-admin';
import {t} from 'i18next';
import {connect} from 'react-redux';

import apolloClient from '../../initApollo';
import {GET_STATUS_ID_BY_NAME} from '../../customQueries';
import EditFormTitle from '../EditFormTitle';
import {CustomEditToolbar} from '../CustomEditToolbar';
import {isRequired} from '../../utils/validation/index';
import {withStyles} from '@material-ui/core';
import {SearchableSelect} from "../SearchableSelect";
import LocationSelect from "../LocationSelect";

const validateRequired = [isRequired()];

const styles = theme => ({
  root: {
    minWidth: 185
  },
});

class OrderEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      statusPayed: '',
      statusCanceled: ''
    }
  }

  componentDidMount() {
    apolloClient.query({
      query: GET_STATUS_ID_BY_NAME,
      variables: {name: process.env.REACT_APP_STATUS_PAYED_ID},
      fetchPolicy: 'no-cache'
    })
      .then(response => {
        if (response.loading) return;
        this.setState({statusPayed: response.data.statuses.pop() || ''})
      });

    apolloClient.query({
      query: GET_STATUS_ID_BY_NAME,
      variables: {name: process.env.REACT_APP_STATUS_CANCELED_ID},
      fetchPolicy: 'no-cache'
    })
      .then(response => {
        if (response.loading) return;
        this.setState({statusCanceled: response.data.statuses.pop() || ''})
      });
  }

  render() {
    const {options, classes} = this.props;
    const {statusPayed, statusCanceled} = this.state;
    const user = options.currentUser.role;
    const redirect = user === 'Admin' || user === 'ProductionManager' ? 'list' : 'show';

    return (
      <Edit {...this.props} title={<EditFormTitle formName={t('order')}/>}>
        <SimpleForm toolbar={<CustomEditToolbar user={user}/>} redirect={redirect}>
          <FormDataConsumer>
            {({formData, ...rest}) => (
              <div>
                {(user === 'Admin' || user === 'ProductionManager') && rest && rest.record && rest.record.status && rest.record.status.id && statusPayed !== '' && statusCanceled !== '' && rest.record.status.id !== statusPayed.id && rest.record.status.id !== statusCanceled.id ?
                  <div>
                    <div>
                      <ReferenceInput
                        label={t('status')}
                        reference='Status'
                        resource='Status'
                        source='status.id'
                        validate={validateRequired}
                        filter={rest && rest.record && rest.record.status && rest.record.status.id ? {OR: [{statusToWorkflows_some: {statusFrom: {id: rest.record.status.id}}}, {id: rest.record.status.id}]} : null}
                      >
                        <SelectInput optionText='nameBg'/>
                      </ReferenceInput>
                    </div>
                    <div>
                      <ReferenceInput label={t('taste')} classes={{input: classes.root}} reference='Taste'
                                      resource='Taste' source='taste.id'>
                        <SearchableSelect obj="Taste"/>
                      </ReferenceInput>
                    </div>
                    <div>
                      <TextInput
                        source="testerCode"
                        label={t('order_details.tester_code')}
                      />
                    </div>
                  </div>
                  : null}
                {user === 'ProductionManager' ? null :
                  <ArrayInput source="comments" label={t('comments')} fullWidth>
                    <SimpleFormIterator
                      disableRemove={user !== 'Admin' || (rest && rest.record && rest.record.status && statusPayed !== '' && statusCanceled !== '' && (rest.record.status.id === statusPayed.id || rest.record.status.id === statusCanceled.id))}>
                      <FormDataConsumer>
                        {({id, formData, getSource}) => {
                          const index = id.match(/\d+/) ? id.match(/\d+/)[0] : 0;
                          return (
                            <LongTextInput
                              source={getSource('comment')}
                              label={t('comment')}
                              validate={validateRequired}
                              resettable
                              onChange={() => formData.comments[index].user = user}
                              disabled={!!(formData && formData.comments && formData.comments[index] && formData.comments[index].comment && formData.comments[index].locked)}
                            />
                          );
                        }}
                      </FormDataConsumer>
                    </SimpleFormIterator>
                  </ArrayInput>}

                {(user === 'Admin' || user === 'ProductionManager') ?
                  <div>
                    <div>
                      <TextInput source="ownerName" label={t('owner_name')}/>
                    </div>
                    <div>
                      <ReferenceInput
                        label={t('city')}
                        reference="City"
                        resource="City"
                        source="city.id"
                        perPage={30}
                        filter={{mainProvinceCity: true}}
                        sort={{field: 'name', order: 'ASC'}}
                      >
                        <LocationSelect/>
                      </ReferenceInput>
                    </div>
                    <div>
                      <TextInput source="district" label={t('district')}/>
                    </div>
                    <div>
                      <TextInput source='street' label={t('street')}/>
                    </div>
                    <div>
                      <TextInput source='streetNumber' label={t('street_number')}/>
                    </div>
                    <div>
                      <TextInput source='buildingNumber' label={t('building_number')}/>
                    </div>
                    <div>
                      <TextInput source='entrance' label={t('entrance')}/>
                    </div>
                    <div>
                      <TextInput source='floor' label={t('floor')}/>
                    </div>
                    <div>
                      <TextInput source='apartmentNumber' label={t('apartment_number')}/>
                    </div>
                    <div>
                      <TextInput source="postcode" label={t('postcode')}/>
                    </div>
                    <div>
                      <TextInput source="phoneNumber" label={t('phone_number')}/>
                    </div>
                  </div>
                  : null
                }

                {(user === 'Admin' || user === 'ProductionManager') ?
                  <ArrayInput source='notes' label={t('notes')} fullWidth>
                    <SimpleFormIterator
                      disableRemove={user !== 'Admin' || (rest && rest.record && rest.record.status && statusPayed !== '' && statusCanceled !== '' && (rest.record.status.id === statusPayed.id || rest.record.status.id === statusCanceled.id))}>
                      <FormDataConsumer>
                        {({id, formData, getSource}) => {
                          const index = id.match(/\d+/) ? id.match(/\d+/)[0] : 0;
                          return (
                            <LongTextInput
                              source={getSource('note')}
                              label={t('order_details.note')}
                              validate={validateRequired}
                              resettable
                              onChange={() => {
                                formData.notes[index].user = user
                              }}
                              disabled={!!(formData && formData.notes && formData.notes[index] && formData.notes[index].note && formData.notes[index].locked)}
                            />
                          );
                        }}
                      </FormDataConsumer>
                    </SimpleFormIterator>
                  </ArrayInput>
                  : null}
                {(user === 'Admin' || user === 'Owner') ?
                  <ArrayInput source='ownerNotes' label={t('owner_notes')} fullWidth>
                    <SimpleFormIterator
                      disableRemove={user !== 'Admin' || (rest && rest.record && rest.record.status && statusPayed !== '' && statusCanceled !== '' && (rest.record.status.id === statusPayed.id || rest.record.status.id === statusCanceled.id))}>
                      <FormDataConsumer>
                        {({id, formData, getSource}) => {
                          const index = id.match(/\d+/) ? id.match(/\d+/)[0] : 0;
                          return (
                            <LongTextInput
                              source={getSource('ownerNote')}
                              label={t('order_details.owner_note')}
                              validate={validateRequired}
                              resettable
                              onChange={() => formData.ownerNotes[index].user = user}
                              disabled={!!(formData && formData.ownerNotes && formData.ownerNotes[index] && formData.ownerNotes[index].ownerNote && formData.ownerNotes[index].locked)}
                            />
                          );
                        }}
                      </FormDataConsumer>
                    </SimpleFormIterator>
                  </ArrayInput>
                  : null}
                {(user === 'Admin' || user === 'Vet') ?
                  <ArrayInput source='vetNotes' label={t('vet_notes')} fullWidth>
                    <SimpleFormIterator
                      disableRemove={user !== 'Admin' || (rest && rest.record && rest.record.status && statusPayed !== '' && statusCanceled !== '' && (rest.record.status.id === statusPayed.id || rest.record.status.id === statusCanceled.id))}>
                      <FormDataConsumer>
                        {({id, formData, getSource}) => {
                          const index = id.match(/\d+/) ? id.match(/\d+/)[0] : 0;
                          return (
                            <LongTextInput
                              source={getSource('vetNote')}
                              label={t('order_details.vet_note')}
                              validate={validateRequired}
                              resettable
                              onChange={() => formData.vetNotes[index].user = user}
                              disabled={!!(formData && formData.vetNotes && formData.vetNotes[index] && formData.vetNotes[index].vetNote && formData.vetNotes[index].locked)}
                            />
                          );
                        }}
                      </FormDataConsumer>
                    </SimpleFormIterator>
                  </ArrayInput>
                  : null}
              </div>
            )}
          </FormDataConsumer>
        </SimpleForm>
      </Edit>
    );
  }
}

const mapFormStateToProps = state => {
  let record = {};
  let form = state.form[REDUX_FORM_NAME];
  if (form) {
    record.comments = (form.initial && form.initial.comments) ? form.initial.comments : null;
    record.notes = (form.initial && form.initial.notes) ? form.initial.notes : null;
    record.ownerNotes = (form.initial && form.initial.ownerNotes) ? form.initial.ownerNotes : null;
    record.vetNotes = (form.initial && form.initial.vetNotes) ? form.initial.vetNotes : null;

    Object.keys(record).forEach(key => {
      if (record[key]) record[key].forEach(item => item.locked = true);
    });
  }

  return {record};
};

export default connect(mapFormStateToProps, {})(withStyles(styles)(OrderEdit));
