import React from 'react';
import { t } from 'i18next';
import TextField from '@material-ui/core/TextField';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';

export const Comments = props => {
  return props.record.comments && (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{t('user')}</TableCell>
          <TableCell>{t('comment')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          props.record.comments.map((commentDetails, index) => {
            return (
              <TableRow key={index}>
                <TableCell>{t(`${commentDetails.user.toLowerCase()}`)}</TableCell>
                <TableCell><TextField fullWidth={true} InputProps={{multiline: true, disableUnderline: true, readOnly: true}} value={commentDetails.comment}/></TableCell>
              </TableRow>
            );
          })
        }
      </TableBody>
    </Table>
  );
};