import React, { Component, Fragment } from 'react';
import { t } from 'i18next';
import { minValue, maxValue, NumberInput, required } from 'react-admin';
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withStyles } from '@material-ui/core';

import CustomDateInput from '../fields/CustomDateInput'
import { isNaturalNumberOrNull } from '../../utils/validation/index';

const styles = {
  input: {
    width: '200px'
  }
};

class AdditionalSettings extends Component {
  render = () => {
    const { classes, user } = this.props;
    return (
      <Fragment>
        <h1>{t('recipes.additional_settings')}</h1>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>{t('recipes.high_quality_animal_protein')}</TableCell>
              <TableCell>
                <NumberInput
                  className={classes.input}
                  label=""
                  source="highQualityAnimalProtein"
                  validate={[required(), minValue(1), maxValue(100)]}
                  options={{ InputProps: { endAdornment: <InputAdornment position="end">%</InputAdornment> }}}
                  disabled={user && user !== 'Admin'}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('recipes.weight_per_bowl')}</TableCell>
              <TableCell>
                <NumberInput
                  className={classes.input}
                  label=""
                  source="weightPerBowl"
                  validate={[isNaturalNumberOrNull]}
                  options={{InputProps: {endAdornment: <InputAdornment position="end">g</InputAdornment>}}}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('recipes.best_before')} *</TableCell>
              <TableCell><CustomDateInput source='bestBefore' validate={[required()]} minDate={new Date()} disabled={user && user !== 'Admin'} /></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Fragment>
    );
  };
}

export default withStyles(styles)(AdditionalSettings);
