import React, { Component } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import { isEqual, clone } from 'lodash';
import { withStyles } from '@material-ui/core';
import { t } from 'i18next';
import { REDUX_FORM_NAME, NumberInput, minValue, maxValue } from 'react-admin';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';

import { isRequired } from '../../utils/validation';

const styles = theme => ({
  input: {
    marginTop: '0px',
    marginBottom: '20px',
    width: '100px',
    '& input': {
      textAlign: 'right',
    }
  },
  errorField: {
    color: theme.palette.error.main
  },
});

class Moisture extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dryMatterError: false,
      totalMoisture: props.moistureDetails ? props.moistureDetails.totalMoisture : 0,
      targetMoisture: props.moistureDetails ? props.moistureDetails.targetMoisture : 120,
      moistureToRemove: props.moistureDetails ? props.moistureDetails.moistureToRemove : 0,
      totalDryMatter: props.moistureDetails ? props.moistureDetails.totalDryMatter : 0,
      targetDryMatter: props.moistureDetails ? props.moistureDetails.targetDryMatter : 880,
    };
  }

  componentDidUpdate(prevProps, state, snapshot) {
    const prevFields = clone(prevProps.materials);
    const currentFields = clone(this.props.materials);

    let isChanged = !isEqual(prevFields, currentFields);

    if (isChanged) {
      let totalMoisture = 0;
      let totalDryMatter = 0;

      currentFields.forEach((field) => {
        let { moisture, dryMatter } = this.calculateMoisture(field);

        totalMoisture += moisture;
        totalDryMatter += dryMatter;
      });

      totalMoisture = Number(totalMoisture.toFixed(2));
      totalDryMatter = Number(totalDryMatter.toFixed(2));

      let moistureToRemove = totalMoisture - this.state.targetMoisture;
      moistureToRemove = Number(moistureToRemove.toFixed(2));

      this.setState({
        totalMoisture: totalMoisture,
        totalDryMatter: totalDryMatter,
        moistureToRemove: moistureToRemove
      }, () => this.checkDryMatter());
    }
  }

  calculateMoisture = (field) => {
    if (!field.materialId || !field.quantity || !field.unitId) {
      return { moisture: 0, dryMatter: 0 };
    }

    let material = this.props.allMaterials.filter(material => material.id === field.materialId).pop();
    let unit = this.props.allUnits.filter(unit => unit.id === field.unitId).pop();

    let percentPerGram = this.calculatePerGram(unit);

    let moisturePercent = material.moisture / 100;
    let moisture = moisturePercent * field.quantity * percentPerGram;

    let dryMatterPercent = (100 - material.moisture) / 100;
    let dryMatter = dryMatterPercent * field.quantity * percentPerGram;

    return { moisture: moisture, dryMatter: dryMatter };
  };

  calculatePerGram = (unit) => {
    if (unit.name === 'kg') {
      return 1000;
    } else {
      let coefficientPerGram = unit.coefficientPerKilogram / 1000;
      return 1 / coefficientPerGram;
    }
  };

  handleMoistureChange = (preventDefault, targetMoisture, oldValue, source) => {
    if (targetMoisture > 300 || targetMoisture < 30) {
      return;
    }

    const GramsPerKg = 1000;
    const targetDryMatter = GramsPerKg - parseInt(targetMoisture);

    let moistureToRemove = this.state.totalMoisture - parseInt(targetMoisture);
    moistureToRemove = Number(moistureToRemove.toFixed(2));

    this.setState({
      targetMoisture: parseInt(targetMoisture),
      targetDryMatter: targetDryMatter,
      moistureToRemove: moistureToRemove,
    }, () => this.checkDryMatter());
  };

  checkDryMatter = () => {
    this.setState({
      dryMatterError: false
    });

    const minDryMatter = this.state.targetDryMatter - 2;
    const maxDryMatter = this.state.targetDryMatter + 2;
    const totalDryMatter = this.state.totalDryMatter;

    if (totalDryMatter < minDryMatter || totalDryMatter > maxDryMatter) {
      this.setState({
        dryMatterError: true
      });
    }

    this.storeData();
  };

  storeData = () => {
    let moistureDetails = {
      totalMoisture: this.state.totalMoisture,
      targetMoisture: this.state.targetMoisture,
      moistureToRemove: this.state.moistureToRemove,
      totalDryMatter: this.state.totalDryMatter,
      targetDryMatter: this.state.targetDryMatter
    };

    this.props.callback(moistureDetails);
    this.props.change(REDUX_FORM_NAME, 'moistureDetails', moistureDetails);
  };

  render() {
    const { classes, user } = this.props;

    return (this.props.moistureDetails || this.state.totalMoisture !== 0) ? (
      <div>
        <h1>{t('moisture')}</h1>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>{t('moisture_details.target_moisture')}*</TableCell>
              <TableCell>
                <NumberInput
                  source="moistureDetails.targetMoisture"
                  label=""
                  className={classes.input}
                  validate={[isRequired(false), minValue(30), maxValue(300)]}
                  onChange={this.handleMoistureChange}
                  options={{
                    InputProps: {
                      endAdornment: <InputAdornment position="end">g</InputAdornment>
                    }
                  }}
                  disabled={user && user !== 'Admin'}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('moisture_details.total_moisture')}</TableCell>
              <TableCell>
                <NumberInput
                  source="moistureDetails.totalMoisture"
                  label=""
                  className={classes.input}
                  options={{
                    InputProps: {
                      endAdornment: <InputAdornment position="end">g</InputAdornment>,
                      readOnly: true,
                      disableUnderline: true,
                    }
                  }}
                  value={this.state.totalMoisture}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('moisture_details.moisture_to_remove')}</TableCell>
              <TableCell>
                <NumberInput
                  source="moistureDetails.moistureToRemove"
                  label=""
                  className={classes.input}
                  options={{
                    InputProps: {
                      endAdornment: <InputAdornment position="end">g</InputAdornment>,
                      readOnly: true,
                      disableUnderline: true,
                    }
                  }}
                  value={this.state.moistureToRemove}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('moisture_details.total_dry_matter')}</TableCell>
              <TableCell>
                <NumberInput
                  source="moistureDetails.totalDryMatter"
                  label=""
                  className={classes.input}
                  validate={[minValue(this.state.targetDryMatter - 2), maxValue(this.state.targetDryMatter + 2)]}
                  options={{
                    InputProps: {
                      endAdornment: <InputAdornment position="end">g</InputAdornment>,
                      readOnly: true,
                      disableUnderline: true,
                      className: this.state.dryMatterError ? classes.errorField : null
                    }
                  }}
                  value={this.state.totalDryMatter}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('moisture_details.target_dry_matter')}</TableCell>
              <TableCell>
                <NumberInput
                  source="moistureDetails.targetDryMatter"
                  label=""
                  className={classes.input}
                  options={{
                    InputProps: {
                      endAdornment: <InputAdornment position="end">g</InputAdornment>,
                      readOnly: true,
                      disableUnderline: true,
                    }
                  }}
                  value={this.state.targetDryMatter}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    ) : null;
  }
}

Moisture.propTypes = {
  moistureDetails: PropTypes.object,
  materials: PropTypes.array,
  change: PropTypes.func,
  callback: PropTypes.func,
};

export default withStyles(styles)(Moisture);
