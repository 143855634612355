import React, {Component} from 'react';
import {GET_ORDERS_BY_CLINIC} from '../../customQueries';
import apolloClient from '../../initApollo';
import {t} from 'i18next';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import {Link} from 'react-admin';
import {formatDate} from '../../utils/Helper';
import {withStyles} from '@material-ui/core';

const styles = theme => ({
  chip: {
    margin: 2
  },
});

class ClinicConsultingFee extends Component {
  constructor(props) {
    super(props);

    this.state = {
      orders: [],
      consultingFees: []
    }
  }

  componentWillMount() {
    apolloClient.query({
      query: GET_ORDERS_BY_CLINIC,
      variables: {clinicId: this.props.record.id},
      fetchPolicy: 'network-only'
    }).then(response => {
      this.setState({orders: response.data.orders}, () => this.calculate())
    });
  }

  calculate = () => {
    let ordersData = [];
    this.state.orders && this.state.orders.forEach(order => {
      if (order.startOfWeek) {
        if (order.startOfWeek in ordersData) {
          ordersData[order.startOfWeek].push(order)
        } else {
          ordersData[order.startOfWeek] = [order];
        }
      }
    });

    let consultingFees = [];
    Object.entries(ordersData).forEach(([startOfWeek, weekData]) => {
      if (!(startOfWeek in consultingFees))
        consultingFees[startOfWeek] = [];

      // console.log(weekData);

      consultingFees[startOfWeek].startOfWeek = startOfWeek;
      consultingFees[startOfWeek].weeklyTurnoverClinic = 0;
      consultingFees[startOfWeek].orders = [];
      consultingFees[startOfWeek].vets = [];
      consultingFees[startOfWeek].consultingFeeClinic = weekData[0].consultingFeeClinic || 0;
      consultingFees[startOfWeek].consultingPercentageClinic = weekData[0].consultingPercentageClinic || 0;

      weekData.forEach(data => {
        consultingFees[startOfWeek].weekNumber = data.weekNumber;
        consultingFees[startOfWeek].weeklyTurnoverClinic += data.price;
        consultingFees[startOfWeek].orders.push({id: data.id, idNumber: data.idNumber, price: data.price});
        if (!consultingFees[startOfWeek].vets[data.vet.id]) {
          // we assume that the orders are sorted reversed and the first order keeps the real vet fee;
          // the first added record for this vet should be the proper vet summed fee
          consultingFees[startOfWeek].vets[data.vet.id] = {
            ...data.vet,
            consultingFeeVetForThisWeek: data.consultingFeeVet || 0,
            consultingPercentageVetThisWeek: data.consultingPercentageVet || 0,
            weeklyTurnoverVet: 0
          }
        }
        consultingFees[startOfWeek].vets[data.vet.id].weeklyTurnoverVet += data.price
      });

      // console.log(consultingFees[startOfWeek]);

    });

    this.setState({consultingFees})
  };

  render() {
    const {classes} = this.props;
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('consulting_fee_details.week')}</TableCell>
            <TableCell>{t('consulting_fee_details.weekly_turnover_clinic')}</TableCell>
            <TableCell>{t('consulting_fee_details.consulting_fee_clinic')}</TableCell>
            <TableCell>{t('consulting_fee_details.consulting_fee_clinic_percentage')}</TableCell>
            <TableCell>{t('main_menu.vets')}</TableCell>
            <TableCell>{t('orders')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            Object.values(this.state.consultingFees).map(consultingFee => {
              return (
                <TableRow key={consultingFee.startOfWeek}>
                  <TableCell>#{consultingFee.weekNumber} {t('from')} {formatDate(consultingFee.startOfWeek)}</TableCell>
                  <TableCell>{consultingFee.weeklyTurnoverClinic.toFixed(2)}</TableCell>
                  <TableCell>{consultingFee.consultingFeeClinic.toFixed(2)}</TableCell>
                  <TableCell>{consultingFee.consultingPercentageClinic.toFixed(1)}%</TableCell>
                  <TableCell>
                    {Object.values(consultingFee.vets).map(vet => {
                      return <Chip key={vet.id}
                                   label={vet.name + " (ОБ: " + vet.weeklyTurnoverVet.toFixed(2) + " лв,  КВ "
                                   + vet.consultingPercentageVetThisWeek.toFixed(1) + "%: "
                                   + vet.consultingFeeVetForThisWeek.toFixed(2) + " лв)"}
                                   component={Link} to={{pathname: `/Vet/${vet.id}/show`}}
                                   clickable/>
                    })}
                  </TableCell>
                  <TableCell>
                    {consultingFee.orders.map(order => {
                      return <Chip key={order.id} className={classes.chip}
                                   label={order.idNumber + ' (' + order.price + ' лв)'} component={Link}
                                   to={{pathname: `/Order/${order.id}/show`}} clickable/>
                    })}
                  </TableCell>
                </TableRow>
              );
            })
          }
        </TableBody>
      </Table>
    );
  }
}

export default withStyles(styles)(ClinicConsultingFee);
