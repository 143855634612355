import React from 'react';
import { Edit } from 'react-admin';
import { t } from 'i18next';

import EditFormTitle from '../../components/EditFormTitle';
import { CustomEditToolbar } from '../CustomEditToolbar';
import { IngredientForm } from './IngredientForm';

export const IngredientEdit = props => {
  return (
    <Edit {...props} title={<EditFormTitle formName={t('ingredients')} />}>
      <IngredientForm toolbar={<CustomEditToolbar user={props.options.currentUser.role}/>}/>
    </Edit>
  )
};