import React from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/lib/Async';
import { components } from 'react-select';
import debounce from 'debounce-promise';
import { addField } from 'ra-core';
import { t } from 'i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown } from '@fortawesome/free-solid-svg-icons';
import { REDUX_FORM_NAME } from 'react-admin';
import { connect } from 'react-redux';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ConfirmationDialog from './ConfirmationDialog';

const DropdownIndicator = props => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <FontAwesomeIcon style={{color: 'rgba(0, 0, 0, 0.54)'}} icon={faSortDown}/>
      </components.DropdownIndicator>
    )
  );
};

const IndicatorSeparator = () => {
  return '';
};

class CustomSearchableSelectInput extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      value: props.initialValue,
      selected: props.initialValue,
      options: props.initialOptions,
      showDialog: false,
      dialogMessage: null
    };

    const wait = 100; // milliseconds
    const loadOptions = inputValue => this.promiseOptions(inputValue, props.getFilteredData);
    this.debouncedLoadOptions = debounce(loadOptions, wait);
  }

  promiseOptions = (inputValue, getFilteredData) =>
    new Promise(resolve =>
      setTimeout(() => {
        resolve(getFilteredData(inputValue));
      }, 200)
    );

  handleChange = (optionValues) => {

    let value = optionValues
      ? optionValues.id
      : '';

    if(this.props.data !== undefined && this.props.data.filter(material => material.materialId === value).length !== 0) {
      value = null;
      optionValues = null;
    }

    this.props.input.onChange(value)
    this.props.onValueChanged && this.props.onValueChanged(value)

    // HACK: For some reason, redux-form does not consider this input touched without calling onBlur manually
    this.props.input.onBlur();
    this.setState({ value: optionValues, selected: optionValues });
  };

  handleOnBlur = () => {
    const { obj, record } = this.props;

    if(record && obj === 'Owner'){
      if (!record.active) {
        this.setState({
          showDialog: true,
          dialogMessage: t('owners.inactive_owner_confirm')
        });
      }
    } else if (record && obj === 'Vet') {
      if (!record.active) {
        this.setState({
          showDialog: true,
          dialogMessage: t('vets.inactive_vet_pop_up')
        });
      }
    } else if (record && obj === 'Clinic') {
      if (!record.active) {
        this.setState({
          showDialog:true,
          dialogMessage: t('clinics.inactive_clinic_pop_up')
        });
      }
    }

    if (this.state.options && this.state.options.length === 1) {
      this.setState({ value: this.state.options })
    } else {
      this.setState({ value: this.state.selected });
    }
  };

  handleOnFocus = () => { this.setState({ value: null }) };

  handleCancel = () => {
    this.setState({ showDialog: false });
    window.location.reload();
  };

  handleConfirm = () => {
    this.setState({ showDialog: false });
  };

  render() {
    let { placeholder, getOptionLabel, getOptionValue, formatGroupLabel, record, input, meta, materialWidth, disabled } = this.props;

    const hasValue = input.value !== '' && input.value !== undefined && input.value !== null;
    const hasError = !hasValue && meta.submitFailed;

    return (
      <React.Fragment>
        <ConfirmationDialog
          isOpen={this.state.showDialog}
          message={this.state.dialogMessage}
          handleAction={this.handleConfirm}
          label={t('continue')}
          icon={<ArrowForwardIcon />}
          handleClose={this.handleCancel}
          cancelLabel='ra.action.cancel'
          title={false}
        />
        <AsyncSelect
          value={record || this.state.value}
          isDisabled={disabled}
          noOptionsMessage={() => t('no_results_found')}
          loadingMessage={() => t('loading_select')}
          defaultOptions={this.state.options}
          getOptionLabel={getOptionLabel || ''}
          getOptionValue={getOptionValue || ''}
          filterOption={() => (true)}
          loadOptions={inputValue => this.debouncedLoadOptions(inputValue)}
          formatGroupLabel={formatGroupLabel}
          placeholder={t(placeholder || 'search_input_field')}
          onChange={this.handleChange}
          onBlur={this.handleOnBlur}
          onFocus={this.handleOnFocus}
          className={!hasError ? "react-select-container" : "react-select-container-error"}
          onInputChange={inputValue => (inputValue.length <= 1 ? inputValue = '' : inputValue)}
          styles={
            {
              placeholder: (base) => ({
                ...base,
                color: hasError ? 'rgba(244, 67, 54, 1)' : 'rgba(0, 0, 0, 0.54)'
              }),
              option: (provided, state) => ({
                ...provided,
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                cursor: 'pointer'
              }),
              menuList: (base) => ({
                ...base,
                cursor: 'pointer',
                maxHeight: 120
              }),
              menu: (base) => ({
                ...base,
                maxWidth: materialWidth || 256,
              }),
              valueContainer: (base) => ({
                ...base,
                padding: 0,
                width: materialWidth || 180
              }),
              dropdownIndicator: (base) => ({
                ...base,
                cursor: 'pointer'
              }),
              clearIndicator: (base) => ({
                ...base,
                cursor: 'pointer'
              }),
              control: (base, state) => {
                const border = '1px solid rgba(0, 0, 0, 0.42)';
                const borderHover = '2px solid rgba(0, 0, 0, 0.87)';
                const borderFocus = '2px solid #303f9f';

                return {
                  ...base,
                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                  outline: 0,
                  border: 0,
                  boxShadow: 0,
                  borderRadius: 0,
                  borderBottom: state.isFocused ? borderFocus : border,
                  marginTop: 32,
                  marginBottom: 8,
                  maxWidth: materialWidth || 256,
                  ':hover': {
                    border: state.isFocused ? 0 : 0,
                    borderBottom: state.isFocused ? borderFocus : borderHover,
                    cursor: 'text'
                  }
                }
              }
            }
          }
         theme={theme => ({
           ...theme,
           colors: {
             ...theme.colors,
             primary: '#3f51b5',
             neutral70: '#fff',
             primary25: '#f5f5f5',
           },
         })}
          components={{DropdownIndicator, IndicatorSeparator}}
          {...this.props}
        />
        {hasError ? <p className="required-field-error">{t('required_field')}</p> : null}
      </React.Fragment>
    );
  }
}

CustomSearchableSelectInput.propTypes = {
  placeholder: PropTypes.string,
  className: PropTypes.string,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  formatGroupLabel: PropTypes.func,
  getFilteredData: PropTypes.func,
  initialValue: PropTypes.object,
  initialOptions: PropTypes.arrayOf(PropTypes.object),
  source: PropTypes.string.isRequired,
  data: PropTypes.array,
};

const mapFormStateToProps = ( state, props ) => {
  let resource = props.obj;
  let resourceId = null;
  let record = null;
  let form = state.form[REDUX_FORM_NAME];

  if(form && resource) {
    resourceId = form.values && form.values[resource.toLowerCase()] ? form.values[resource.toLowerCase()].id : null;
    record = resourceId ? state.admin.resources[resource].data[resourceId] : null;
  }

  return { record };
};

export default addField(connect(mapFormStateToProps, {})(CustomSearchableSelectInput));
