import React from 'react';
import { Create } from 'react-admin';
import { t } from 'i18next';
import CustomCreateToolbar from '../CustomCreateToolbar';
import { IngredientForm } from './IngredientForm';

export const IngredientCreate = props => {
  return (
    <Create {...props} title={t('ingredient_details.title_create')}>
      <IngredientForm toolbar={<CustomCreateToolbar user={props.options.currentUser.role}/>}/>
    </Create>
  )
};