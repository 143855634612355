import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { REDUX_FORM_NAME } from 'react-admin';
import { isEqual, remove, isEmpty, clone } from 'lodash';
import { GET_NUTRIENT_INFO } from '../../customQueries';
import client from '../../initApollo';
import { withStyles } from '@material-ui/core';

import { PercentToUnit, calculateUi } from '../PercentToUnit';
import { sortNutrientGroups, sortNutrientGroupsValues } from '../sortGroups';

const styles = {
  div: {
    width: '50%',
    marginRight: '50px'
  },
  tableCellRight: {
    textAlign: 'right'
  }
};

class Nutrients extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = { nutrients: props.nutrients || [] };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.fields === null) {
      return;
    }

    const prevFields = clone(prevProps.fields);
    const currentFields = clone(this.props.fields);

    // remove empty rows and rows without material
    remove(prevFields, function(field) {
      return isEmpty(field) || !field.materialId;
    });

    remove(currentFields, function(field) {
      return isEmpty(field) || !field.materialId;
    });

    let isChanged = !isEqual(prevFields, currentFields);

    if (isChanged) {
      this.calculateNutrients(currentFields);
    }
  }

  calculateNutrients = fields => {
    const materials = this.getMaterials(fields);
    const ids = this.getNutrientIds(materials);
    // fetch nutrients' names and ui values
    client
      .query({
        query: GET_NUTRIENT_INFO,
        variables: {
          nutrientIds: ids.nutrient,
          nutrientGroupIds: ids.nutrientGroup,
          nutrientElementIds: ids.nutrientElement
        },
        fetchPolicy: 'network-only'
      })
      .then(response => {
        if (response.loading) return;

        let groups = [];
        let nutrients = response.data.filterNutrients;
        let { percents, withoutPercent, withPercent } = this.calculateNutrientPercent(fields, materials);
        // add percent data to the nutrients
        nutrients.forEach(nutrient => {
          nutrient.percent = percents[nutrient.id];

          nutrient.nutrientGroups.forEach(nutrientGroup => {
            let uiSubtotal = 0;
            nutrientGroup.percent = percents[nutrientGroup.id];

            nutrientGroup.nutrientElements.forEach(nutrientElement => {
              nutrientElement.percent = percents[nutrientElement.id];

              if (uiSubtotal !== null && nutrientElement.uiValue) {
                uiSubtotal += calculateUi(nutrientElement.uiValue, nutrientElement.percent);
              } else {
                uiSubtotal = null;
              }
            });

            if (uiSubtotal !== null) nutrientGroup.uiSubtotal = uiSubtotal;
            if (withoutPercent.includes(nutrientGroup.id)) nutrientGroup.noPercent = true;
            if (withPercent.includes(nutrientGroup.id)) nutrientGroup.withPercent = true;
          });

          groups.push(nutrient.name);
        });

        const sortIndexes = sortNutrientGroups(groups);
        let sortedNutrients = sortNutrientGroupsValues(nutrients, sortIndexes);

        this.props.callback(sortedNutrients);
        this.props.change(REDUX_FORM_NAME, 'nutrients', sortedNutrients);

        if (this._isMounted) {
          this.setState({ nutrients: sortedNutrients });
        }
      });
  };

  getMaterials = fields => {
    let materials = [];
    this.props.materials.forEach(material => {
      fields.forEach(field => {
        if (field.materialId === material.id) {
          return materials.push(material);
        }
      })
    });

    return materials;
  };

  getNutrientIds = materials => {
    let ids = { nutrient: [], nutrientGroup: [], nutrientElement: [] };

    materials.forEach(material => {
      if (!material.nutrients) return;

      material.nutrients.forEach(nutrient => {
        if (nutrient.nutrientId && !ids.nutrient.find(id => id === nutrient.nutrientId)) {
          ids.nutrient.push(nutrient.nutrientId);
        }

        if (nutrient.nutrientGroupId && !ids.nutrientGroup.find(id => id === nutrient.nutrientGroupId)) {
          ids.nutrientGroup.push(nutrient.nutrientGroupId);
        }

        if (nutrient.nutrientElementId && !ids.nutrientElement.find(id => id === nutrient.nutrientElementId)) {
          ids.nutrientElement.push(nutrient.nutrientElementId);
        }
      });
    });

    return ids;
  };

  calculateNutrientPercent = (fields, materials) => {
    let percents = {};
    let withoutPercent = [];
    let withPercent = [];

    fields.forEach(field => {
      // calculate percent when quantity and unit are filled-in
      if (field.unitId && field.quantity) {
        const unit = this.props.units.find(unit => field.unitId === unit.id);

        materials.forEach(material => {
          let lockedNutrients = [];
          let lockedNutrientGroups = [];

          // calculate percent when the material has nutrient data
          if (material.nutrients && field.materialId === material.id) {
            let sortedNutrients = this.sortNutrients(material.nutrients);

            sortedNutrients.forEach(nutrient => {
              // calculate percent when the nutrient has percent data
              let nutrientId = nutrient.nutrientId;
              let nutrientGroupId = nutrient.nutrientGroupId;
              let nutrientElementId = nutrient.nutrientElementId;
              if (!nutrient.percent || !nutrientId) return;

              percents[nutrientId] = percents[nutrientId] || 0;
              percents[nutrientGroupId] = percents[nutrientGroupId] || 0;
              percents[nutrientElementId] = percents[nutrientElementId] || 0;

              if (!lockedNutrients.includes(nutrientId)) {
                percents[nutrientId] += this.calculatePercentPerKilogram(
                  field.quantity,
                  nutrient.percent,
                  unit.coefficientPerKilogram
                );
              }
              if (!lockedNutrientGroups.includes(nutrientGroupId)) {
                if (nutrientElementId) {
                    //withoutPercents is for nutrient groups which do not have a value in the material, but should be included because they are part of the tree...
                    withoutPercent.push(nutrientGroupId)
                  // }
                } else {
                  percents[nutrientGroupId] += this.calculatePercentPerKilogram(
                    field.quantity,
                    nutrient.percent,
                    unit.coefficientPerKilogram
                  );
                  withPercent.push(nutrientGroupId);
                }
              }
              percents[nutrientElementId] += this.calculatePercentPerKilogram(
                field.quantity,
                nutrient.percent,
                unit.coefficientPerKilogram
              );

              if (nutrientGroupId === null) {
                lockedNutrients.push(nutrientId);
              }
              if (nutrientGroupId !== null && nutrientElementId === null) {
                lockedNutrientGroups.push(nutrientGroupId);
              }
            });
          }
        })
      }
    });

    return { percents, withoutPercent, withPercent };
  };

  calculatePercentPerKilogram = (quantity, percent, coefficient) => {
    return (quantity * percent) / coefficient;
  };

  sortNutrients = (unsorted) => {
    let sorted = [];

    let nutrients = unsorted.filter(nutrient => nutrient.nutrientGroupId === null);
    let nutrientGroups = unsorted.filter(nutrient => (nutrient.nutrientGroupId !== null && nutrient.nutrientElementId === null));
    let nutrientElements = unsorted.filter(nutrient => (nutrient.nutrientGroupId !== null && nutrient.nutrientElementId !== null && nutrient.nutrientElementId !== null));

    return sorted.concat(nutrients).concat(nutrientGroups).concat(nutrientElements);
  };

  render() {
    const { nutrients } = this.state;
    const { classes, units } = this.props;

    return nutrients.length ? (
      <div className={classes.div}>
        <h1>{t('main_menu.nutrients')}</h1>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('nutrient')}</TableCell>
              <TableCell classes={{ root: classes.tableCellRight }}>{t('quantity')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {nutrients.map((nutrient, index) => (
              <Fragment key={index}>
                <TableRow>
                  <TableCell>{nutrient.name}</TableCell>
                  <TableCell classes={{ root: classes.tableCellRight }}>
                    {
                      nutrient.name.includes("Витамини") !== true ?
                        <PercentToUnit
                          percent={nutrient.percent}
                          uiValue={nutrient.uiValue || null}
                          units={units}
                        />
                        : null
                    }
                  </TableCell>
                </TableRow>
                {nutrient.nutrientGroups.map((nutrientGroup, idx2) => (
                  <Fragment key={idx2}>
                    <TableRow>
                      <TableCell>-> {nutrientGroup.name}</TableCell>
                      <TableCell classes={{ root: classes.tableCellRight }}>
                        <PercentToUnit
                          percent={nutrientGroup.percent}
                          uiValue={nutrientGroup.uiValue || null}
                          uiSubtotal={nutrientGroup.uiSubtotal || 0}
                          noPercent={nutrientGroup.noPercent || false}
                          withPercent={nutrientGroup.withPercent || false}
                          units={units}
                        />
                      </TableCell>
                    </TableRow>
                    {nutrientGroup.nutrientElements.map((nutrientElement, idx3) => (
                      <TableRow key={idx3}>
                        <TableCell>-> -> {nutrientElement.name}</TableCell>
                        <TableCell classes={{ root: classes.tableCellRight }}>
                          <PercentToUnit
                            percent={nutrientElement.percent}
                            uiValue={nutrientElement.uiValue || null}
                            units={units}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </Fragment>
                ))}
              </Fragment>
            ))}
          </TableBody>
        </Table>
      </div>
    ) : null;
  }
}

Nutrients.propTypes = {
  fields: PropTypes.array,
  nutrients: PropTypes.array,
  change: PropTypes.func,
  units: PropTypes.array.isRequired,
  materials: PropTypes.array.isRequired,
  callback: PropTypes.func
};

export default withStyles(styles)(Nutrients);
