import PropTypes from 'prop-types';

const defaultUnit = '%';
const uiUnit = 'UI';

const PercentToUnit = ({ percent, units, uiValue = null, uiSubtotal = 0, rounding = 3, noPercent = false, withPercent = false }) => {
  if ((!percent && !uiSubtotal) || !units) {
    return '';
  }

  const nutrientUnit = null;
  const result = percentToUnitObject(percent, units, nutrientUnit, uiValue, uiSubtotal, rounding, noPercent, withPercent);

  return result.value + ' ' + result.unit;
};

const percentToUnitObject = (percent, units, nutrientUnit = null, uiValue = null, uiSubtotal = 0, rounding = 3, noPercent = false, withPercent = false) => {
  if ((!percent && !uiSubtotal) || !units) {
    return {
      value: '',
      unit: ''
    };
  }

  if (nutrientUnit) {
    let roundingNumber = getRounding(nutrientUnit);
    let result = { unit: nutrientUnit };

    if (nutrientUnit === '%') {
        result.value = percent.toFixed(roundingNumber);
        return result;
    }

    units.forEach(unit => {
      if (unit.name === nutrientUnit) {
        let value = percent / 100 * unit.coefficientPerKilogram;

        result.value = value.toFixed(roundingNumber);
      }
    });

    return result;
  }

  if (withPercent && noPercent) {
    return {
      value: (calculateUi(uiValue, percent) + uiSubtotal).toFixed(0),
      unit: uiUnit,
    };
  }

  if (noPercent && uiSubtotal) {
    return {
      value: uiSubtotal.toFixed(0),
      unit: uiUnit,
    }
  }

  if (uiValue && uiSubtotal) {
    return {
      value: calculateUi(uiValue, percent).toFixed(0),
      unit: uiUnit,
    }
  }

  if (uiValue || uiSubtotal) {
    return {
      value: (calculateUi(uiValue, percent) + uiSubtotal).toFixed(0),
      unit: uiUnit,
    };
  }

  return percent < 0.001 ? convertToBestUnit(percent, units, rounding)
    : {
      value: percent.toFixed(rounding),
      unit: defaultUnit
    };
};

const convertToBestUnit = (percent, units, rounding) => {
  units = units.sort(compareUnitCoefficients);  // sort units descending by coefficient
  let firstUnit = true;

  for (let unit of units) {
    if (unit.name === "ppm") continue;
    if (unit.coefficientPerKilogram === 0) continue;

    let converted = percent / 100 * unit.coefficientPerKilogram;
    converted = converted.toFixed(firstUnit ? 0 : rounding);

    if (converted <= 999 && Number(converted) !== 0) return { value: converted, unit: unit.name };

    firstUnit = false;
  }

  return { value: percent.toFixed(rounding), unit: defaultUnit };
};

const compareUnitCoefficients = (a, b) => {
  if (a.coefficientPerKilogram > b.coefficientPerKilogram) return -1;
  if (a.coefficientPerKilogram < b.coefficientPerKilogram) return 1;
  return 0;
};

const calculateUi = (uiValue, percent) => {
  let uiResult = 0;
  if (!uiValue || !percent) return uiResult;

  uiResult = uiValue * 10000 * percent;

  if (uiResult > 0.0000001 && uiResult < 1) return 1;
  return uiResult;
};

const getRounding = unit => {
  if (unit === 'UI' || unit === 'μg') {
    return 0;
  }

  return 3;
};

PercentToUnit.propTypes = {
  percent: PropTypes.number,
  units: PropTypes.array,
  uiValue: PropTypes.number,
  rounding: PropTypes.number,
};

export { PercentToUnit, percentToUnitObject, compareUnitCoefficients, calculateUi, getRounding };
