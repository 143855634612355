import React, { Fragment } from 'react';
import {
  SimpleForm,
  TextInput,
  maxLength
} from 'react-admin';
import { t } from 'i18next';

import { isRequired } from '../../utils/validation';

const validateRequired = [isRequired()];
const validateLength = [isRequired(), maxLength(2)];

export const CountryForm = props => (
  <Fragment>
    <SimpleForm {...props} redirect="list">
      <TextInput source="name" label={t("name")} validate={validateRequired}/>
      <TextInput source="isoCode" label={t("countries.iso_code_column")} validate={validateLength}/>
    </SimpleForm>
  </Fragment>
);
