import { MunicipalityCreate } from '../components/Municipality/MunicipalityCreate';
import { MunicipalityEdit } from '../components/Municipality/MunicipalityEdit';
import { MunicipalityList } from '../components/Municipality/MunicipalityList';
import { MunicipalityShow } from '../components/Municipality/MunicipalityShow';

export const Municipalities = {
  create: MunicipalityCreate,
  edit: MunicipalityEdit,
  list: MunicipalityList,
  show: MunicipalityShow,
};
