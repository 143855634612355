import React from 'react';
import {
  SimpleForm,
  TextInput,
  NumberInput
} from 'react-admin';

import { t } from 'i18next';

import { isRequired } from '../../utils/validation';
const validateRequired = [isRequired()];

export const UnitForm = props => (
  <SimpleForm {...props} redirect="list">
    <TextInput source="name" label={t('name')} validate={validateRequired}/>
    <NumberInput source="coefficientPerKilogram" label={t('units.coefficient_per_kilogram_column')} validate={validateRequired}/>
  </SimpleForm>
);