import React, {Component} from 'react';
import ClientOAuth2 from 'client-oauth2';
import LoadingIndicator from '../components/LoadingIndicator';

const cognitoOauth2 = new ClientOAuth2({
  clientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
  accessTokenUri: process.env.REACT_APP_COGNITO_CLIENT_ENDPOINT + '/token',
  authorizationUri: process.env.REACT_APP_COGNITO_CLIENT_ENDPOINT + '/login',
  redirectUri: window.location.origin + '?authenticate',
  scopes: ['openid', 'profile', 'email', 'aws.cognito.signin.user.admin']
})

const redirectOauth = () => {
  window.location = cognitoOauth2.code.getUri()
}

const redirectHome = () => {
  window.location = '/'
}

const saveToken = token => {
  sessionStorage.token_data = JSON.stringify({
    data: token.data,
    expires_at: token.expires.getTime()
  })
}

const saveRefreshedToken = token => {
  const prevData = JSON.parse(sessionStorage.token_data);
  sessionStorage.token_data = JSON.stringify({
    data: {
      ...prevData.data,
      access_token: token.data.access_token,
      id_token: token.data.id_token,
    },
    expires_at: token.expires.getTime()
  })
}

const loadToken = () => {
  const data = JSON.parse(sessionStorage.token_data);
  return new ClientOAuth2.Token(cognitoOauth2, {
    ...data.data,
    expires_in: Math.floor((data.expires_at - new Date().getTime()) / 1000)
  })
}

const requireToken = () => {
  return new Promise(resolve => {
    // Redirected from AWS
    if (window.location.search.indexOf('?authenticate') === 0) {
      // Get token from URL
      cognitoOauth2.code.getToken(window.location.href)
        .then(function (token) {
          // Save the token data in the session storage
          // It will be automatically deleted on browser close
          saveToken(token)
          redirectHome()
        })
    } else if (sessionStorage.token_data) {
      const token = loadToken()
      if (token.expired()) {
        let params = new URLSearchParams({
          'grant_type': 'refresh_token',
          'client_id': process.env.REACT_APP_COGNITO_CLIENT_ID,
          'refresh_token': token.data.refresh_token,
        })
        fetch(process.env.REACT_APP_COGNITO_CLIENT_ENDPOINT + '/oauth2/token', {
          method: 'POST',
          body: params,
        })
          .then(response => {
            if (response.status !== 200) throw new Error('Invalid status returned when refreshing token');
            return response.json();
          })
          .then(data => {
            const newToken = new ClientOAuth2.Token(cognitoOauth2, data);
            saveRefreshedToken(newToken);
            resolve(newToken);
          })
          .catch(() => {
            redirectOauth();
          })
      } else {
        resolve(token)
      }
    } else {
      redirectOauth()
    }
  })
}

const getToken = () => {
  return requireToken().then(token => {
    return token.data.id_token
  })
}

const logout = () => {
  delete sessionStorage.token_data;
  window.location = cognitoOauth2.code.getUri().replace('/login', '/logout')
}

class AuthUtils extends Component {
  constructor() {
    super();

    this.state = {
      authorized: false
    }
  }

  componentWillMount() {
    getToken().then(() => {
      this.setState({authorized: true})
    })
  }

  render() {
    if (this.state.authorized) return this.props.children;

    return this.props.loadingIndicator || <LoadingIndicator/>;
  }
}

export {AuthUtils, logout};
