import AccountBox from '@material-ui/icons/AccountBox';
import ProductionManagerCreate from '../components/ProductionManager/ProductionManagerCreate';
import ProductionManagerEdit from '../components/ProductionManager/ProductionManagerEdit';
import { ProductionManagerList } from '../components/ProductionManager/ProductionManagerList';
import { ProductionManagerShow } from '../components/ProductionManager/ProductionManagerShow';

export const ProductionManagers = {
  icon: AccountBox,
  list: ProductionManagerList,
  edit: ProductionManagerEdit,
  show: ProductionManagerShow,
  create: ProductionManagerCreate
};