import React from 'react';
import {
  List,
  Datagrid,
  BooleanField,
  ReferenceField,
  TextField
} from 'react-admin';
import { t } from 'i18next';

import CustomDateField from '../fields/CustomDateField';

export const FoodRunningOutNotificationList = () => {
  return (
    <List
      resource='FoodRunningOutNotification'
      basePath='/FoodRunningOutNotification'
      location={{}}
      match={{}}
      hasCreate={false}
      hasEdit={false}
      hasShow={false}
      hasList={true}
      sort={{ field: 'runningOutDate', order: 'ASC' }}
      filter={{ seen: false }}
      bulkActionButtons={false}
      actions={null}
      title={t('food_running_out.food_running_out')}
    >
      <Datagrid rowClick='edit'>
        <ReferenceField label={t('order')} reference='Order' source='order.id' allowEmpty={true} sortable={false} linkType='show'>
          <TextField source='idNumber'/>
        </ReferenceField>
        <ReferenceField label={t('animal')} reference='Animal' source='animal.id' allowEmpty={true} sortable={false} linkType='show'>
          <TextField source='name'/>
        </ReferenceField>
        <ReferenceField label={t('owner')} reference='Owner' source='owner.id' allowEmpty={true} sortable={false} linkType='show'>
          <TextField source='name'/>
        </ReferenceField>
        <ReferenceField label={t('vet')} reference='Vet' source='vet.id' allowEmpty={true} sortable={false} linkType='show'>
          <TextField source='name'/>
        </ReferenceField>
        <CustomDateField source='runningOutDate' label={t('food_running_out.running_out_date')}/>
        <BooleanField source='seen' label={t('food_running_out.seen')} sortable={false}/>
      </Datagrid>
    </List>
  );
};