import React from 'react';
import { Route } from 'react-router-dom';
import { Redirect } from 'react-router';
import LabelSettings from './pages/LabelSettings';
import CopyRecipe from './pages/CopyRecipe';
import DigitalLabel from './pages/DigitalLabel';
import CountryImportData from './pages/CountryImportData';
import Profile from './pages/Profile';
import ScanDigitalLabel from './pages/ScanDigitalLabel';

export default [
  <Route exact path='/Recipe/labelSettings/:recipeId' component={LabelSettings} />,
  <Route exact path='/copyRecipe' render={({ location, match, history }) => location.state ? <CopyRecipe location={location} match={match} history={history} /> : <Redirect to='/Order' />} />,
  <Route path='/digitalLabel/:digitalLabelId' render={props => <DigitalLabel {...props} />} noLayout />,
  <Route exact path='/Profile' component={Profile} />,
  <Route path='/scanDigitalLabel' render={props => <ScanDigitalLabel {...props} />} />,

  /**
   * Use this route if country data (Provinces, Municipalities and cities) should be imported
   */
  <Route exact path='/country_import_data' component={CountryImportData} />,
];
