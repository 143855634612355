import { t } from 'i18next';
import { UPDATE } from 'react-admin';
import { isEmpty } from 'lodash';

export const customCrudUpdate = (data, previousData, resource, basePath, changedData, createHistory, redirectTo, user) => ({
  type: 'RA/CRUD_UPDATE',
  payload: { id: previousData.id, data, previousData },
  meta: {
    resource,
    fetch: UPDATE,
    onSuccess: {
      //redirect on success of recipe edit returns `Invariant Violation: Maximum update depth exceeded.`
      redirectTo: resource === 'Recipe' ? false : redirectTo,
      basePath,
      notification: {
        body: t('ra.notification.updated'),
        level: "info",
        messageArgs: {
          smart_count: 1,
        },
      },
      callback: async() => {
        if((resource === 'Animal' || resource === 'Order') && changedData && !isEmpty(changedData)) {
          const history = {
            user,
            date: new Date(),
            data: changedData
          };

          if(resource === 'Animal') {
            history.animal = previousData.id;
            createHistory('AnimalHistory', history, basePath, null);
          }

          if(resource === 'Order') {
            history.order = previousData.id;
            createHistory('OrderHistory', history, basePath, null);
          }
        }
      }
    },
    onFailure: {
      notification: {
        body: t('ra.notification.http_error'),
        level: 'warning',
      },
    }
  }
});