import React from 'react';
import { t } from 'i18next';
import { Create } from 'react-admin';
import { ProvinceForm } from './ProvinceForm';
import CustomCreateToolbar from '../CustomCreateToolbar';

export const ProvinceCreate = props => (
  <Create {...props} title={t("provinces.title_create")}>
    <ProvinceForm toolbar={<CustomCreateToolbar user={props.options.currentUser.role}/>}/>
  </Create>
);
