import React from 'react';
import { Edit } from 'react-admin';
import { t } from 'i18next';

import EditFormTitle from '../EditFormTitle';
import { NutrientElementForm } from './NutrientElementForm';
import { CustomEditToolbar } from '../CustomEditToolbar';

export const NutrientElementEdit = props => (
  <Edit {...props} title={<EditFormTitle formName={t("nutrient_element")} />}>
    <NutrientElementForm toolbar={<CustomEditToolbar user={props.options.currentUser.role}/>} />
  </Edit>
);
