import React from 'react';
import { Create } from 'react-admin';
import { t } from 'i18next';

import CustomCreateToolbar from '../CustomCreateToolbar';
import { StatusesWorkflowForm } from './StatusesWorkflowForm';

export const StatusesWorkflowCreate = props => (
  <Create {...props} title={t('statuses_workflow.create_title')}>
    <StatusesWorkflowForm toolbar={<CustomCreateToolbar user={props.options.currentUser.role}/>}/>
  </Create>
);