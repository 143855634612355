import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';

class CustomTextField extends Component {
  render() {
    return <TextField
      {...this.props}
    />
  }
}

export default CustomTextField;
