import React, { Fragment, Component } from 'react';
import { Button } from 'react-admin';
import ConfirmationDialog from './ConfirmationDialog';
import PropTypes from 'prop-types';
import ActionDelete from '@material-ui/icons/Delete';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  button: {
    color: '#f44336',
  },
};

class DeleteFileButtonWithConfirmation extends Component {
  state = {
    showDialog: false
  };

  handleClick = () => {
    this.setState({ showDialog: true });
  };

  handleCloseClick = () => {
    this.setState({ showDialog: false });
  };

  handleDelete = () => {
    this.setState({ showDialog: false });
    this.props.callback(this.props);
  };

  render() {
    const { label, classes, icon, message } = this.props;

    return (
      <Fragment>
        <Button
          onClick={this.handleClick}
          label={label}
          className={classes.button}
        >
          {icon}
        </Button>
        <ConfirmationDialog
          isOpen={this.state.showDialog}
          message={message}
          handleAction={this.handleDelete}
          label={label}
          classes={classes}
          icon={icon}
          handleClose={this.handleCloseClick}
          cancelLabel='ra.action.cancel'
          title={true}
        />
      </Fragment>
    );
  }
}

DeleteFileButtonWithConfirmation.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  label: PropTypes.string,
  record: PropTypes.object,
  resource: PropTypes.string.isRequired,
  icon: PropTypes.element,
  callback: PropTypes.func,
  message: PropTypes.string,
  source: PropTypes.string,
  id: PropTypes.string,
  files: PropTypes.array,
  removedFile: PropTypes.object
};

DeleteFileButtonWithConfirmation.defaultProps = {
  label: 'ra.action.delete',
  icon: <ActionDelete />,
};

export default compose(
  withStyles(styles)
)(DeleteFileButtonWithConfirmation);