import React, { Fragment } from 'react';
import {
  TabbedForm,
  TextInput,
  ReferenceInput,
  BooleanInput,
  FormTab,
  ReferenceArrayField,
  Datagrid,
  TextField,
  FunctionField,
  Button,
  Link,
  ReferenceManyField,
  ReferenceField,
  email
} from 'react-admin';
import { t } from 'i18next';
import ContentAdd from '@material-ui/icons/Add';

import { isRequired, validateLngCoordinate, validateLatCoordinate } from '../../utils/validation';
import LocationSelect from '../LocationSelect';

const validateRequired = [isRequired()];
const validateEmail = [isRequired(), email()];

export const ClinicForm = props => (
  <Fragment>
    <TabbedForm {...props} redirect="list">
      <FormTab label={t('summary_tab')}>
        <TextInput source="name" label={t("name")} validate={validateRequired}/>
        { props.record && props.record.id ?
          props.user.role === 'Admin' ? <BooleanInput source="active" label={t('clinics.active')}/> : null
        : <BooleanInput source="active" label={t('clinics.active')}/>}
        <TextInput source="phoneNumber" label={t('phone_number')} validate={validateRequired}/>
        <TextInput source="email" label={t('email')} validate={validateEmail}/>
      </FormTab>
      <FormTab label={t('addresses_tab')}>
        <ReferenceInput
          label={t('city')}
          reference="City"
          source="city.id"
          validate={validateRequired}
          perPage={30}
          filter={{mainProvinceCity: true}}
          sort={{ field: 'name', order: 'ASC' }}
        >
          {
            props.record.id ?
              <LocationSelect parentId={props.record.id} parentIdName='clinicsIds'/>
              :<LocationSelect/>
          }
        </ReferenceInput>
        <TextInput source='street' label={t('street')}/>
        <TextInput source='streetNumber' label={t('street_number')}/>
        <TextInput source='buildingNumber' label={t('building_number')}/>
        <TextInput source='district' label={t('district')}/>
        <TextInput source='entrance' label={t('entrance')}/>
        <TextInput source='floor' label={t('floor')}/>
        <TextInput source='apartmentNumber' label={t('apartment_number')}/>
        <TextInput source="postcode" label={t('postcode')} validate={validateRequired}/>
        <TextInput label={t("clinics.latitude")} source="lat" validate={validateLatCoordinate}/>
        <TextInput label={t("clinics.longitude")} source="lng" validate={validateLngCoordinate}/>
      </FormTab>
      { props.record && props.record.id ?
        <FormTab label={t("consulting_fee")}>
          <ReferenceArrayField label={t("consulting_fee_details.consulting_fee_percentage_clinic")} reference="ConsultingFeePercentageClinic" source="consultingFeePercentageClinicsIds">
            <Datagrid rowClick={props.user.role === 'Admin' ? 'edit' : null}>
              <TextField source="weeklyTurnoverTo" label={t("consulting_fee_details.weekly_turnover_to")} sortable={false}/>
              <TextField source="consultingFeePercentage" label={t("consulting_fee_details.consulting_fee_percentage")} sortable={false}/>
            </Datagrid>
          </ReferenceArrayField>
          { props.user.role === 'Admin' ?
            <FunctionField
              label={t('consulting_fee_details.consulting_fee_percentage_create_clinic')}
              render={() => {
                return <Button component={Link} to={{pathname: '/ConsultingFeePercentageClinic/create', search: `?clinicId=${props.record.id}`}} label='ra.action.create'><ContentAdd/></Button>;
              }}
            />
          : null }
          <ReferenceManyField label={t('consulting_fee_details.consulting_fee_percentage_vet')} reference="ConsultingFeePercentageVet" target="clinic">
            <Datagrid rowClick="edit">
              <ReferenceField label={t('vet')} reference="Vet" source="vet.id" allowEmpty={true} linkType="show" sortable={false}>
                <TextField source="name"/>
              </ReferenceField>
              <TextField source="weeklyTurnoverTo" label={t('consulting_fee_details.weekly_turnover_to')} sortable={false}/>
              <TextField source="consultingFeePercentage" label={t('consulting_fee_details.consulting_fee_percentage')} sortable={false}/>
            </Datagrid>
          </ReferenceManyField>
          <FunctionField
            label={t('consulting_fee_details.consulting_fee_percentage_create_vet')}
            render={() => {
              return <Button component={Link} to={{pathname: '/ConsultingFeePercentageVet/create', search: `?clinicId=${props.record.id}&canEditVet=true`}} label='ra.action.create'><ContentAdd/></Button>;
            }}
          />
        </FormTab>
      : null }
    </TabbedForm>
  </Fragment>
);
