import React from 'react';
import { Edit } from 'react-admin';
import { t } from 'i18next';

import EditFormTitle from '../EditFormTitle';
import { ClinicForm } from './ClinicForm';
import { CustomEditToolbar } from '../CustomEditToolbar';

export const ClinicEdit = props => (
  <Edit {...props} title={<EditFormTitle formName={t("clinic_name")} />}>
    <ClinicForm toolbar={<CustomEditToolbar history={props.history} user={props.options.currentUser.role}/>} user={props.options.currentUser}/>
  </Edit>
);
