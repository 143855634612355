import React from 'react';
import {
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput
} from 'react-admin';
import { t } from 'i18next';

import { isRequired } from '../../utils/validation';

const validateRequired = [isRequired()];

export const MunicipalityForm = props => (
  <SimpleForm {...props} redirect="list">
    <TextInput label={t("name")} source="name" validate={validateRequired}/>
    <ReferenceInput label={t("province")} reference="Province" source="province.id" validate={validateRequired} perPage={30}>
      <SelectInput source="name"/>
    </ReferenceInput>
    <TextInput label={t("code_column")} source="code" validate={validateRequired}/>
  </SimpleForm>
);
