module.exports = {
  ra: {
    action: {
      add_filter: 'Добави филтър',
      add: 'Добави',
      back: 'Обратно',
      bulk_actions: 'Избран е 1 елемент |||| Избрани са %{smart_count} елемента',
      cancel: 'Отмени',
      clear_input_value: 'Изчисти',
      clone: 'Клонирай',
      confirm: 'Потвърди',
      create: 'Създай',
      delete: 'Изтрий',
      edit: 'Редактирай',
      export: 'Експортирай',
      list: 'Списък',
      refresh: 'Обновяване',
      remove_filter: 'Премахни филтъра',
      remove: 'Премахни',
      save: 'Запази',
      search: 'Търсене',
      show: 'Покажи',
      sort: 'Сортирай',
      undo: 'Отмени',
    },
    boolean: {
      true: 'Да',
      false: 'Не',
    },
    page: {
      create: 'Създаване на %{name}',
      dashboard: 'Начало',
      edit: '%{name} #%{id}',
      error: 'Something went wrong',
      list: 'Списък на %{name}',
      loading: 'Зареждане',
      not_found: 'Not Found',
      show: '%{name} #%{id}',
    },
    input: {
      file: {
        upload_several:
          'Пуснете избраните за качване файлове тук или кликнете, за да изберете файл.',
        upload_single: 'Пуснете избраният за качване файл тук или кликнете, за да изберете файл.',
      },
      image: {
        upload_several:
          'Пуснете избраните за качване снимки тук или кликнете, за да изберете снимка.',
        upload_single:
          'Пуснете избраната за качване снимка тук или кликнете, за да изберете снимка.',
      },
      references: {
        all_missing: 'Търсените данни не бяха открити.',
        many_missing:
          'Част от търсената информация не е налична.',
        single_missing:
          'Търсената информация не е налична.',
      },
    },
    message: {
      about: 'Относно',
      are_you_sure: 'Сигурни ли сте?',
      bulk_delete_content:
        'Are you sure you want to delete this %{name}? |||| Are you sure you want to delete these %{smart_count} items?',
      bulk_delete_title:
        'Delete %{name} |||| Delete %{smart_count} %{name} items',
      delete_content: 'Are you sure you want to delete this item?',
      delete_title: 'Delete %{name} #%{id}',
      details: 'Детайли',
      error:
        'Заявката не може да бъде изпълнена, заради клиентска грешка.',
      invalid_form: 'Данните са невалидни. Моля проверете грешките.',
      loading: 'Страницата се зарежда, моля изчакайте',
      no: 'Не',
      not_found:
        'Въведеното URL е грешно или последваният линк е невалиден.',
      yes: 'Да',
    },
    navigation: {
      no_results: 'Не са намерени резултати.',
      no_more_results:
        'The page number %{page} is out of boundaries. Try the previous page.',
      page_out_of_boundaries: 'Page number %{page} out of boundaries',
      page_out_from_end: 'Cannot go after last page',
      page_out_from_begin: 'Cannot go before page 1',
      page_range_info: '%{offsetBegin}-%{offsetEnd} от %{total}',
      page_rows_per_page: 'Редове на страница:',
      next: 'Следваща',
      prev: 'Предишна',
    },
    auth: {
      user_menu: 'Профил',
      username: 'Потребителско име',
      password: 'Парола',
      sign_in: 'Вход',
      sign_in_error: 'Не е открит потребител с тези данни, моля опитайте отново!',
      logout: 'Изход',
    },
    notification: {
      updated: 'Елементът е променен |||| %{smart_count} елемента са променени',
      created: 'Елементът е създаден',
      deleted: 'Елементът е изтрит |||| %{smart_count} елемента са изтрити',
      bad_item: 'Невалиден елемент',
      item_doesnt_exist: 'Елементът не съществува.',
      http_error: 'Грешка в комуникацията със сървъра.',
      data_provider_error:
        'dataProvider error. Check the console for details.',
      canceled: 'Действието е отменено.',
      logged_out: 'Сесията изтече, моля свържете се наново.'
    },
    validation: {
      required: 'Задължително поле',
      minLength: 'Трябва да бъде поне %{min} символа',
      maxLength: 'Трябва да бъде %{max} символа или по-малко',
      minValue: 'Трябва да бъде поне %{min}',
      maxValue: 'Трябва да бъде %{max} или по-малко',
      number: 'Трябва да е число',
      email: 'Трябва да е валиден имейл',
      oneOf: 'Трябва да бъде едно от изброените: %{options}',
      regex: 'Трябва да отговаря на специфичен формат(regexp): %{pattern}',
    },
  },
};
