import React from 'react';
import {
  ShowController,
  ShowView,
  SimpleShowLayout,
  TextField,
  NumberField,
  ArrayField,
  Datagrid,
  ReferenceField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
} from 'react-admin';
import { t } from 'i18next';

import EditFormTitle from '../EditFormTitle';

const unitSources = ["percent", "mg", "μg", "UI", "ppm"];

export const MaterialShow = props => (
  <ShowController {...props}>
  {controllerProps =>
    <ShowView {...props} {...controllerProps} title={<EditFormTitle formName={t('material')} />}>
      <SimpleShowLayout>
        <TextField source="name" label={t("name")} />
        <NumberField source="moisture" label={t("material_details.moisture_percent")} />
        <NumberField source="price" label={t("material_details.price_unit")}/>

        {controllerProps.record && controllerProps.record.ingredientsIds && controllerProps.record.ingredientsIds.length ?
          (
            <ReferenceArrayField label={t('ingredients')} reference="Ingredient" source="ingredientsIds">
                <SingleFieldList>
                  <ChipField source="name" />
                </SingleFieldList>
            </ReferenceArrayField>
          ) : null
        }

        {controllerProps.record && controllerProps.record.nutrients && controllerProps.record.nutrients.length ?
          (
            <ArrayField label={t("material_details.nutrient_content")} source="nutrients" className="horizontal-form">
              <Datagrid>
                <ReferenceField label={t("nutrient")} reference="Nutrient" source="nutrientId" linkType={false} allowEmpty={true}>
                  <TextField source="name" />
                </ReferenceField>
                <ReferenceField label={t("nutrient_group")} reference="NutrientGroup" source="nutrientGroupId" linkType={false} allowEmpty={true}>
                  <TextField source="name" />
                </ReferenceField>
                <ReferenceField label={t("nutrient_element")} reference="NutrientElement" source="nutrientElementId" linkType={false} allowEmpty={true}>
                  <TextField source="name" />
                </ReferenceField>
                {unitSources.map((sourceName, index) => (
                  <NumberField key={index} source={sourceName} label={t(`material_details.${sourceName}`)} />
                  ))}
              </Datagrid>
            </ArrayField>
          ) : null
        }

      </SimpleShowLayout>
    </ShowView>
  }
  </ShowController>
);
