import React from 'react';
import { Edit } from 'react-admin';
import { t } from 'i18next';

import EditFormTitle from '../EditFormTitle';
import { CustomEditToolbar } from '../CustomEditToolbar';
import CityForm from './CityForm';

export const CityEdit = props => (
  <Edit {...props} title={<EditFormTitle formName={t('city')}/>} >
    <CityForm redirect="list" toolbar={<CustomEditToolbar user={props.options.currentUser.role}/>} />
  </Edit>
);
