import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  TextInput,
  ReferenceField,
  SingleFieldList,
  ReferenceManyField,
  Filter,
  Link,
  FunctionField,
  Button,
  ReferenceInput,
  BooleanField,
  BooleanInput,
  ReferenceFieldController
} from 'react-admin';

import {CustomListActions} from '../CustomListActions';
import ChipWithAvatar from '../fields/ChipWithAvatar';
import ContentAdd from '@material-ui/icons/Add';

import {t} from 'i18next';
import LocationSelect from '../LocationSelect';
import {SearchableSelect} from "../SearchableSelect";
import {ResendTempEmailButton} from "../ResendTempEmailButton";

const OwnerFilter = (props) => (
  <Filter {...props}>
    {props.user !== 'Owner' ? <TextInput label={t('name')} source="name_contains" alwaysOn/> : null}
    <BooleanInput label={t("active")} source="active"/>
    <TextInput label={t('phone_number')} source="phoneNumber_contains"/>
    <TextInput label={t('email')} source="email_contains"/>
    <ReferenceInput
      label={t('city')}
      reference="City"
      source="city.id"
      perPage={30}
      sort={{field: 'name', order: 'ASC'}}>
      <LocationSelect/>
    </ReferenceInput>

    <ReferenceInput
      // alwaysOn
      label={t('vet')}
      reference="Vet"
      source="vet.id"
      sort={{field: 'name', order: 'ASC'}}
    >
      <SearchableSelect
        obj="Vet"
        // filter={filterVetByRole(options.currentUser)}
      />
    </ReferenceInput>

    <TextInput label={t('street')} source='street_contains'/>
    <TextInput label={t('postcode')} source="postcode"/>
  </Filter>
);

const filterByRole = ({role, email}) => {
  switch (role) {
    case 'Owner':
      return {email};
    case 'Vet':
      return {vet: {email}};
    case 'Clinic':
      return {vet: {clinic: {email}}};
    default:
      return {}
  }
};

export const OwnerList = props => (
  <List
    {...props}
    filters={<OwnerFilter user={props.options.currentUser.role}/>}
    bulkActionButtons={false}
    actions={props.options.currentUser.role !== 'Owner' ? <CustomListActions user={props.options.currentUser}/> : null}
    title={t('owners.title_list')}
    filter={filterByRole(props.options.currentUser)}
  >
    <Datagrid rowClick="show">
      <BooleanField source="active" label={t('active')}/>
      <TextField source="name" label={t('name')}/>

      <ReferenceField label={t('vet')} reference="Vet" source="vet.id" allowEmpty={true} sortable={false}>
        <TextField source="name"/>
      </ReferenceField>

      <ReferenceFieldController label="Clinic" reference="Vet" source="vet.id" linkType={false}>
        {
          ({referenceRecord, ...props}) => (
            <ReferenceField basePath="/Clinic" resource="Vet" reference="Clinic" source="clinic.id" record={referenceRecord || {}}
                            linkType="show">
              <TextField source="name"/>
            </ReferenceField>
          )
        }
      </ReferenceFieldController>


      <TextField source="phoneNumber" label={t('phone_number')}/>
      <TextField source="email" label={t('email')}/>

      <ReferenceField label={t('city')} reference="City" source="city.id" allowEmpty={true} sortable={false}
                      linkType={false}>
        <TextField source="name"/>
      </ReferenceField>


      <TextField source='street' label={t('street')}/>
      <TextField label={t('postcode')} source="postcode"/>

      <ReferenceManyField label={t('owners.pets')} reference="Animal" target="owner.id" allowEmpty={true}
                          sortable={false}>
        <SingleFieldList linkType="show">
          <ChipWithAvatar/>
        </SingleFieldList>
      </ReferenceManyField>

      <FunctionField
        render={({animal, id, active}) => {
          return (
            <Button
              component={Link}
              to={{pathname: '/Animal/create', state: {owner: id, redirect: '/Owner'}}}
              label={t('animals.title_create')}
              onClick={e => e.stopPropagation()}
              disabled={!active}
            >
              <ContentAdd/>
            </Button>
          );
        }}
      />

      <ResendTempEmailButton
        email={props.email}
        active={props.active}
      />

    </Datagrid>
  </List>
);
