import React from 'react';
import {
  Create,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  FormDataConsumer,
  NumberInput,
  RadioButtonGroupInput,
  ArrayInput,
  SimpleFormIterator,
  LongTextInput,
  TextInput,
  REDUX_FORM_NAME
} from 'react-admin';
import { withStyles } from '@material-ui/core';
import { change } from 'redux-form';
import { t } from 'i18next';
import Typography from '@material-ui/core/Typography';
import { SearchableSelect } from './../SearchableSelect';
import BreedSelect from '../BreedSelect';

import CustomCreateToolbar from '../CustomCreateToolbar';
import { isRequired } from '../../utils/validation';
import { getNormalPackageDetails, getBreedPackageDetails } from '../calculateAnimalDetails';
import { isNaturalNumber } from '../../utils/validation/index';
import { GET_SIZE_CLASS_BY_BREED, GET_AGE_GROUP_NAME_BY_ANIMAL_TYPE, GET_CLINIC_ID_OF_VET } from '../../customQueries';
import apolloClient from '../../initApollo';

const validateRequired = [isRequired()];
const validateNumber = [isRequired(), isNaturalNumber];

const styles = theme => ({
  ageGroupField: {
    minWidth: 180
  }
});

class ClinicOrderForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      breedCoefficient: null,
      priceByWeightClinic: null,
      clinic: null
    }
  }

  componentDidMount() {
    const { user } = this.props;
    this._isMounted = true;

    if (user.role === 'Vet') {
      let vetId = user.systemId;

      apolloClient.query({ query: GET_CLINIC_ID_OF_VET, variables: { vetId }, fetchPolicy: 'no-cache' })
        .then(response => {
          if (response.loading) return;

          this.setState({ clinic: response.data.vet.clinic.id });
        })
    }
  }

  componentDidUpdate(prevProps) {
    const { formData, dispatch } = this.props;
    let prevAgeGroup = prevProps.formData.ageGroup;
    let curAgeGroup = formData.ageGroup;
    let isAgeGroupChanged = prevAgeGroup !== curAgeGroup;

    if (prevProps.formData.breed) {
      let prevBreed = prevProps.formData.breed;
      let curBreed = formData.breed;
      let isBreedChanged = prevBreed && curBreed && prevBreed.id && curBreed.id && prevBreed.id !== curBreed.id;

      if (isBreedChanged) {
        dispatch(change(REDUX_FORM_NAME, 'price', null));
        dispatch(change(REDUX_FORM_NAME, 'weightPricePair', null));
        dispatch(change(REDUX_FORM_NAME, 'sizeClass', null));
        dispatch(change(REDUX_FORM_NAME, 'ageGroup', null));
        dispatch(change(REDUX_FORM_NAME, 'ageGroupName', null));
        this.getClinicWeightChoices(formData.orderType);
      }
    }

    if (formData.breed) {
      const breedId = formData.breed.id;
      const prevBreedId = prevProps.formData.breed ? prevProps.formData.breed.id : null;

      if (breedId !== prevBreedId) {
        this.setSizeClassByBreed(formData.breed.id);
      }
    }

    if (formData.ageGroup && isAgeGroupChanged) {
      this.getClinicWeightChoices(formData.orderType);
      this.setAgeGroupName(formData.ageGroup, formData.animalType);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  setAgeGroupName = async (id, animalType) => {
    const query = GET_AGE_GROUP_NAME_BY_ANIMAL_TYPE(animalType === 'Dog' ? 'Dogs' : 'Cats');
    const ageGroupName = await apolloClient.query({ query, variables: { id }, fetchPolicy: 'no-cache' }).then(response => {
      if (response.loading) return;
      const data = response.data.ageGroups.pop();

      return data.ageGroup;
    });

    this.props.dispatch(change(REDUX_FORM_NAME, 'ageGroupName', ageGroupName));
  }

  setSizeClassByBreed = async (id) => {
    const sizeClass = await apolloClient.query({ query: GET_SIZE_CLASS_BY_BREED, variables: { id }, fetchPolicy: 'no-cache' }).then(response => {
      if (response.loading) return;
      const data = response.data.breeds.pop();

      return data.sizeClass;
    });

    this.props.dispatch(change(REDUX_FORM_NAME, 'sizeClass', sizeClass));
  }

  getClinicWeightChoices = orderType => {
    if (orderType === 'ByBreed') {
      this.getPackagesByBreed();
    } else {
      this.getNormalPackages();
    }
  }

  getPackagesByBreed = () => {
    const { formData } = this.props;

    if (formData.animalType && formData.ageGroup && formData.breed && formData.orderType === 'ByBreed') {
      getBreedPackageDetails(formData.animalType, formData.breed, formData.ageGroup)
        .then(res => {
          if (res && res.priceByWeight) {
            if (!formData.sizeClass && res.sizeClass) {
              this.props.dispatch(change(REDUX_FORM_NAME, 'sizeClass', res.sizeClass))
            }
            return this.setState({
              priceByWeightClinic: res.priceByWeight,
              breedCoefficient: res.priceCoefficient
            });
          }

          return this.setState({ priceByWeightClinic: [], breedCoefficient: null });
        })
        .catch(err => { });
    }
  }

  getNormalPackages = () => {
    const { formData } = this.props;

    if (formData.animalType && formData.sizeClass && formData.ageGroup) {
      return getNormalPackageDetails(formData.animalType, formData.sizeClass, formData.ageGroup)
        .then(res => {
          if (res && res.priceByWeightGeneral) {
            return this.setState({ priceByWeightClinic: res.priceByWeightGeneral });
          }

          return this.setState({ priceByWeightClinic: [] });
        });
    }

    return this.setState({ priceByWeightClinic: [] });
  }

  getSizeClass = (animalType) => {
    if (animalType === 'Dog') {
      return ([
        { id: 'Mini', name: t('size_classes.mini') },
        { id: 'Small', name: t('size_classes.small') },
        { id: 'Medium', name: t('size_classes.medium') },
        { id: 'Large', name: t('size_classes.large') },
        { id: 'Giant', name: t('size_classes.giant') }
      ]);
    }

    if (animalType === 'Cat') {
      return ([
        { id: 'Medium', name: t('size_classes.medium') },
      ]);
    }

    return [];
  }

  getAgeGroupFilter = () => {
    const { formData } = this.props;
    if (formData.animalType === 'Dog' && formData.sizeClass) {
      return { sizeClass: formData.sizeClass }
    }

    return null;
  }

  render() {
    const { classes, formData, generateWeightPriceChoice, dispatch, convertPairsToString, calculatePairs, status, user, ...rest } = this.props;
    const previousState = this.props.location.state || null;
    const ageGroupFilter = this.getAgeGroupFilter();

    return (
      <Create {...rest} redirect="list" title={t('order_details.title_create')}>
        <SimpleForm
          toolbar={<CustomCreateToolbar user={user.role} />}
          redirect="list"
          defaultValue={{ status: { id: status.id }, dateCreated: new Date() }}>
          <React.Fragment>
            <div>
              <SelectInput
                style={{ width: 256 }}
                source="animalType"
                label={t('pet_type')}
                choices={[
                  { id: 'Dog', name: t('dog') },
                  { id: 'Cat', name: t('cat') },
                ]}
                validate={validateRequired}
                onChange={() => {
                  formData.breed = {};
                  formData.weight = null;
                  formData.key = null;
                  formData.price = null;
                  formData.weightPricePair = null;
                  formData.ageGroup = null;
                  formData.ageGroupName = null;
                  formData.sizeClass = null;
                }}
              />
            </div>
            <div>
              <SelectInput
                source="orderType"
                label={t('order_details.order_type')}
                choices={[
                  { id: 'Normal', name: t('order_details.normal') },
                  { id: 'ByBreed', name: t('order_details.by_breed') },
                ]}
                onChange={() => {
                  formData.breed = {};
                  formData.weight = null;
                  formData.key = null;
                  formData.price = null;
                  formData.weightPricePair = null;
                  formData.ageGroup = null;
                  formData.ageGroupName = null;
                  formData.sizeClass = null;
                }}
                validate={validateRequired}
              />
            </div>
            <div>
              <ReferenceInput
                label={t('clinic_name')}
                reference="Clinic"
                source="clinic.id"
                validate={validateRequired}
                onChange={() => { formData.vet = null }}
                disabled={!!previousState || user.role === 'Vet' || user.role === 'Clinic'}
                defaultValue={previousState ? previousState.data.clinic.id : user.role === 'Vet' ? this.state.clinic : user.role === 'Clinic' ? user.systemId : undefined}
                {...rest}
              >
                <SearchableSelect obj="Clinic" />
              </ReferenceInput>
            </div>
            <div>
              <ReferenceInput
                key={formData.clinic ? formData.clinic.id : null}
                label={t('vet')}
                reference="Vet"
                source="vet.id"
                validate={validateRequired}
                filter={{ clinic: (formData.clinic ? formData.clinic : null) }}
                disabled={!!previousState || user.role === 'Vet'}
                defaultValue={previousState ? previousState.data.vet : user.role === 'Vet' ? user.systemId : undefined}
                {...rest}
              >
                <SearchableSelect obj="Vet" filter={{ clinic: (formData.clinic || null) }} optionText="name" />
              </ReferenceInput>
            </div>
            <div>
              {(formData.orderType && formData.orderType === 'Normal' && formData.animalType) &&
                <SelectInput
                  source="sizeClass"
                  label={t('size_class')}
                  choices={this.getSizeClass(formData.animalType)}
                  validate={validateRequired}
                  onChange={() => {
                    formData.ageGroup = null;
                    formData.ageGroupName = null;
                  }}
                />
              }
            </div>
            <div>
              {(formData.animalType && formData.orderType && formData.orderType === 'ByBreed') &&
                <ReferenceInput
                  key={formData.animalType}
                  label={t('breed')}
                  source="breed.id"
                  reference="Breed"
                  validate={validateRequired}
                  sort={{ field: 'breedNameBg', order: 'ASC' }}
                  filter={{ animalType: formData.animalType }}
                  resource={rest.resource}
                  onChange={() => {
                    formData.weight = null;
                    formData.price = null;
                    formData.key = null;
                    formData.weightPricePair = null;
                    formData.ageGroup = null;
                    formData.ageGroupName = null;
                  }}
                >
                  <BreedSelect animalType={formData.animalType} containsMix={false} />
                </ReferenceInput>
              }
            </div>
            {formData.sizeClass &&
              <div>
                <ReferenceInput
                  label={t('age_group.age_group')}
                  reference={formData.animalType === 'Dog' ? 'AgeGroupDog' : 'AgeGroupCat'}
                  source='ageGroup'
                  validate={validateRequired}
                  filter={ageGroupFilter}
                  classes={{ input: classes.ageGroupField }}
                  {...rest}>
                  <SelectInput
                    optionText="ageGroup"
                    validate={validateRequired}
                  />
                </ReferenceInput>
              </div>
            }

            <div>
              <ReferenceInput
                style={{ width: 256 }}
                label={t('taste')}
                reference='Taste'
                source='taste.id'
                {...rest}
              >
                <SearchableSelect obj="Taste" />
              </ReferenceInput>
            </div>

            <div>
              <TextInput
                source="testerCode"
                label={t('order_details.tester_code')}
              />
            </div>

            {(formData.animalType && (formData.sizeClass || formData.breed) && formData.ageGroup) &&
              <div>
                <RadioButtonGroupInput
                  label={t('age_group.price_weight_pair')}
                  source='key'
                  choices={
                    formData.orderType === 'ByBreed' ?
                      calculatePairs(this.state.priceByWeightClinic, this.state.breedCoefficient)
                      :
                      convertPairsToString(this.state.priceByWeightClinic)
                  }
                  optionValue='key'
                  optionText={generateWeightPriceChoice}
                  translateChoice={false}
                  validate={validateRequired}
                  onChange={(x, value) => {
                    let packagePrice;
                    formData.weightPricePair = this.state.priceByWeightClinic.find(v => v.key.toString() === value);
                    formData.weight = formData && formData.weightPricePair && formData.weightPricePair.weight ? formData.weightPricePair.weight.toString() : null;

                    if (formData.orderType === 'ByBreed') {
                      packagePrice = formData.weightPricePair['weight'] * (formData.weightPricePair['price'] * (this.state.breedCoefficient || 1))
                    } else {
                      packagePrice = formData.weightPricePair['weight'] * formData.weightPricePair['price']
                    }
                    formData.price = packagePrice * formData.quantity;
                  }}
                />
              </div>
            }
            <div>
              <NumberInput
                source="quantity"
                label={t('order_details.quantity')}
                validate={validateNumber}
                defaultValue={1}
                inputProps={{ min: 1 }}
                onChange={(x, value) => {
                  formData.price = formData.weightPricePair ? value * formData.weightPricePair.weight * formData.weightPricePair.price * (this.state.breedCoefficient || 1) : null;
                }}
              />
            </div>
            {formData.price ?
              <React.Fragment>
                <Typography variant="headline">{t('order_details.price')}: {formData.price.toFixed(2)} {t('lv')}</Typography>
              </React.Fragment>
              : null}
            <ArrayInput source="comments" label={t('comments')}>
              <SimpleFormIterator>
                <FormDataConsumer>
                  {({ id, getSource }) => {
                    const index = id.match(/\d+/) ? id.match(/\d+/)[0] : 0;
                    return (
                      <LongTextInput
                        source={getSource('comment')}
                        label={t('comment')}
                        validate={isRequired()}
                        resettable
                        onChange={() => formData.comments[index].user = user}
                      />
                    );
                  }}
                </FormDataConsumer>
              </SimpleFormIterator>
            </ArrayInput>
          </React.Fragment>
        </SimpleForm>
      </Create>
    );
  }
}

export default withStyles(styles)(ClinicOrderForm);
