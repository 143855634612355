import PermIdentity from '@material-ui/icons/PermIdentity';
import AdminCreate from '../components/Admin/AdminCreate';
import AdminEdit from '../components/Admin/AdminEdit';
import { AdminList } from '../components/Admin/AdminList';
import { AdminShow } from '../components/Admin/AdminShow';

export const Admins = {
  icon: PermIdentity,
  list: AdminList,
  edit: AdminEdit,
  show: AdminShow,
  create: AdminCreate
};