import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  NumberField
} from 'react-admin';
import { t } from 'i18next';
import { connect } from 'react-redux';
import { change } from 'redux-form';

import { AnimalFilter } from './AnimalFilter';
import { CustomListActions } from '../CustomListActions';
import TranslationField from '../fields/TranslationField';
import CustomDateField from '../fields/CustomDateField';

const filterByRole = ({ role, email }) => {
  switch (role) {
    case 'Owner':
      return { owner: { email } };
    case 'Vet':
      return { owner: { vet: { email } } };
    case 'Clinic':
      return { owner: { vet: { clinic: { email } } } };
    default:
      return {}
  }
};

class AnimalList extends React.Component {
  componentDidMount() {
    this.props.resetWeightFilter()
  }

  render() {
    const { resetWeightFilter, ...rest } = this.props;
    return (
      <List {...rest}
        filters={<AnimalFilter user={rest.options.currentUser} />}
        bulkActionButtons={false}
        actions={<CustomListActions user={rest.options.currentUser} />}
        title={t('animals.title_list')}
        filter={filterByRole(rest.options.currentUser)}
      >
        <Datagrid rowClick="show">
          <TranslationField source="animalType" label={t('animal_type')} />
          <CustomDateField source="birthDate" label={t('birth_date')} />
          <TextField source="name" label={t('name')} />
          <ReferenceField label={t('owner')} reference="Owner" source="owner.id" allowEmpty={true} linkType="show" sortable={false}>
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField label={t('breed')} reference="Breed" source="breed.id" linkType={false} allowEmpty={true} sortable={false}>
            <TextField source="breedNameBg" />
          </ReferenceField>
          <NumberField source="weight" label={t('weight')} />
        </Datagrid>
      </List>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    resetWeightFilter: () => {
      dispatch(change('filterForm', 'weight_gte', null));
      dispatch(change('filterForm', 'weight_lte', null))
    }
  }
};

const ConnectedAnimalList = connect(undefined, mapDispatchToProps)(AnimalList);
export { ConnectedAnimalList as AnimalList };
