import React, {Component} from 'react';

import {
  Grommet,
  Box,
  Grid,
  Heading,
  Card,
  CardBody,
  Text,
  Paragraph,
  Image,
  Meter,
  DataTable,
  CardHeader
} from 'grommet';

import {grommet} from 'grommet/themes';

import {t} from 'i18next';
import {GET_LABEL_CONTENT} from '../customQueries';
import apolloClient from '../initApollo';
import {isEmpty} from 'lodash';


// // Two responsive grids
// //    - First one with a known number of elements
// //    - Second one with an unknown number of elements
//
// // set custom breakpoints so we can see the changes
// const customBreakpoints = deepMerge(grommet, {
//   global: {
//     breakpoints: {
//       small: {
//         value: 600,
//       },
//       medium: {
//         value: 900,
//       },
//       large: {
//         value: 3000,
//       },
//     },
//   },
// });

// columns, rows and areas are for Grid with a known number of contents / boxes.

// // If the size is small, we only see 1 column
// // If the size is medium, we only see 2 columns
// // If the size is either large or xlarge, we see 3 columns
// const columns = {
//   small: ['auto'],
//   medium: ['auto', 'auto'],
//   large: ['auto', 'auto', 'auto'],
//   xlarge: ['auto', 'auto', 'auto'],
// };
//
// // If the size is small, we have 3 rows
// // If the size is medium, we have 2 rows
// // If the size is large or xlarge, we have 1 row
// const rows = {
//   small: ['xsmall', 'xsmall', 'xsmall'],
//   medium: ['xsmall', 'xsmall'],
//   large: ['xsmall'],
//   xlarge: ['xsmall'],
// };

// Set the different areas you need for every size
// const fixedGridAreas = {
//   small: [
//     {name: 'header', start: [0, 0], end: [0, 0]},
//     {name: 'test', start: [0, 1], end: [0, 1]},
//     {name: 'test1', start: [0, 2], end: [0, 2]},
//   ],
//   medium: [
//     {name: 'header', start: [0, 0], end: [1, 0]},
//     {name: 'test', start: [0, 1], end: [0, 1]},
//     {name: 'test1', start: [1, 1], end: [1, 1]},
//   ],
//   large: [
//     {name: 'header', start: [0, 0], end: [0, 0]},
//     {name: 'test', start: [1, 0], end: [1, 0]},
//     {name: 'test1', start: [2, 0], end: [2, 0]},
//   ],
//   xlarge: [
//     {name: 'header', start: [0, 0], end: [0, 0]},
//     {name: 'test', start: [1, 0], end: [1, 0]},
//     {name: 'test1', start: [2, 0], end: [2, 0]},
//   ],
// };


// const columns = ['large'];
//
// const rows = ['small', 'small', 'small', 'small', 'small', 'auto'];


const getFixedGridAreas = (labelContent) => {

  // console.log(labelContent)

  let ar = [
    'dog_info',
    'package',
    'best_before',
    'daily_dose',
    'important',
    'protein',
  ];

  // add the food content groups
  labelContent.foodContentGroups.map((rec, index) => {
    ar.push('food_content_gr_' + index)
    return 0
  })

  // add the rest
  //
  ar.push('analysis')
  ar.push('notes')

  // console.log(ar)

  // each element need to be an array
  return Array.from(ar, a => [a])
}


const AppBar = (props) => (
  <Box
    tag='header'
    direction='row'
    align='center'
    justify='between'
    background='neutral-2'
    pad={{left: 'medium', right: 'small', vertical: 'small'}}
    elevation='medium'
    style={{zIndex: '1'}}
    {...props}
  />
);


class DigitalLabel extends Component {

  state = {
    spacing: '16',
  };

  constructor(props) {
    super(props);

    this.state = {
      digitalLabelId: props.match.params.digitalLabelId,
      labelContent: [],
      recipe: null,
      dailyDosesInPack: null,
      animalName: null
    };
  };

  componentDidMount() {
    this.getLabelContent();
  }

  getLabelContent = () => {
    apolloClient.query({
      query: GET_LABEL_CONTENT,
      variables: {digitalLabelId: this.state.digitalLabelId},
      fetchPolicy: 'no-cache'
    })
      .then(response => {
        if (response.loading) return;

        this.setState({
          labelContent: response.data.recipe.labelContent,
          recipe: response.data.recipe,
          dailyDosesInPack: this.calcDailyDosesInPack(response.data.recipe),
          animalName: response.data.recipe.order.animal.name
        });
      });
  };

  // for now not calculated and stored in the database - as a temp solition, we will calculate it here
  calcDailyDosesInPack = (recipe) => {
    if (!recipe) return null

    const dailyDoseGr = parseFloat(recipe.dailyDose)
    const weightKg = recipe.order && parseFloat(recipe.order.weight)

    if (!weightKg) return null;

    const weightGr = weightKg * 1000;

    const numDays = Math.round(weightGr / dailyDoseGr);

    return numDays
  }

  render = () => {

    const {labelContent, dailyDosesInPack, animalName} = this.state;

    // console.log(recipe && recipe.dailyDose)
    // console.log('Label content')
    // console.log(labelContent)

    const cardBackground = 'light-1';

    let key = 1;

    return (
      labelContent.length !== 0 && (
        <Grommet theme={grommet}>
          <Box fill>

            <AppBar>
              <Heading level='3' margin='none'>Поръчка #{labelContent.orderNumber}</Heading>
            </AppBar>

            <Grid
              rows={['auto']}
              columns={['auto']}
              gap="small"
              areas={getFixedGridAreas(labelContent)}
              margin="medium"
            >
              <Card background="accent-4" gridArea="dog_info" pad="small" gap="small" key={key++}>

                <CardHeader>
                  <strong>Домашен любимец</strong>
                </CardHeader>

                <CardBody height="small">

                  <Box
                    pad="small"
                  >
                    <Grid
                      columns={{
                        count: 2,
                        size: ['1/3', '2/3'],
                      }}
                      gap="small"
                    >

                      <Box pad={{horizontal: "medium"}} responsive={false}>
                        <Heading level="4" margin={{vertical: "medium"}}>
                          {animalName}
                        </Heading>
                        <Paragraph margin={{top: "none"}}>
                          {labelContent.breed}
                        </Paragraph>
                        <Paragraph margin={{top: "none"}}>
                          {labelContent.category}
                        </Paragraph>
                      </Box>

                      <Box align={'end'} height="small" width="small">
                        <Image fit="cover" src={labelContent.dogImageUrl}/>
                      </Box>

                    </Grid>
                  </Box>
                </CardBody>

              </Card>

              <Card background={cardBackground} gridArea="best_before" pad="small" gap="small" key={key++}>
                <CardHeader>
                  <strong>Срок на годност</strong>
                </CardHeader>
                <CardBody>
                  <Paragraph fill={true}>
                    {labelContent.bestBefore}
                  </Paragraph>
                </CardBody>
              </Card>

              <Card background={cardBackground} gridArea="package" pad="small" gap="small" key={key++}>
                <CardHeader>
                  <strong>Разфасовка</strong>
                </CardHeader>
                <CardBody>
                  <Paragraph fill={true}>
                    {labelContent.productWeight}
                  </Paragraph>
                </CardBody>
              </Card>

              <Card background={cardBackground} gridArea="daily_dose" pad="small" gap="small" key={key++}>
                <CardHeader>
                  <strong>Дозировка</strong>
                </CardHeader>
                <CardBody>
                  <Paragraph fill={true}>
                    {labelContent.dailyDose}
                  </Paragraph>
                  {dailyDosesInPack &&
                    <Paragraph fill={true}>
                      Храната в този пакет е достатъчна за {dailyDosesInPack} дни.
                    </Paragraph>
                  }
                  <Paragraph fill={true} size={'small'}>
                    {t('label_settings.indicative_doses_notes')}
                  </Paragraph>
                </CardBody>
              </Card>

              <Card background={cardBackground} gridArea="important" pad="small" gap="small" key={key++}>
                <CardHeader>
                  <strong>ВАЖНО!</strong>
                </CardHeader>
                <CardBody>
                  <Paragraph fill={true}>
                    {t('label_settings.must_have_water')}
                  </Paragraph>
                </CardBody>
              </Card>

              <Card background={cardBackground} gridArea="protein" pad="small" gap="small" key={key++}>
                <CardHeader>
                  <strong>Висококачествен животински протеин</strong>
                </CardHeader>
                <CardBody pad="small">
                  <Grid
                    columns={['small', 'auto']}
                    gap="small"
                  >
                    <Box height='small'>
                      <Meter
                        values={[{
                          value: labelContent.formulaPercentage,
                          label: '',
                          onClick: () => {
                          }
                        }]}
                        type='circle'
                        thickness='large'
                        background='light-5'
                      />
                    </Box>
                    <Box align={'start'}>
                      <Text>
                        {labelContent.formulaPercentage}%
                      </Text>
                    </Box>
                  </Grid>
                </CardBody>
              </Card>

              {
                labelContent.foodContentGroups.map((gr, index) =>
                  <Card background={cardBackground} gridArea={'food_content_gr_' + index} pad="small" gap="small"
                        key={key++}>
                    <CardHeader>
                      <strong>{gr.foodGroupType}</strong>
                    </CardHeader>
                    <CardBody>
                      <Paragraph fill={true}>
                        {gr.foodGroupList.join(', ')}
                      </Paragraph>
                    </CardBody>
                  </Card>
                )
              }

              <Card background={cardBackground} gridArea="analysis" key={key++}>
                <CardBody pad="small">

                  {labelContent.analysisData.map((analysisGroup, index) => (

                    <Box key={index}>
                      <Heading level={'4'}>{analysisGroup.analysisGroupType}</Heading>
                      <DataTable
                        background={["white", "light-2"]}
                        columns={[
                          {
                            property: 'itemName',
                            header: <Text>Елемент</Text>,
                            // primary: true,
                          },
                          {
                            property: 'itemValue',
                            header: <Text>Стойност</Text>,
                          },
                        ]}
                        data={analysisGroup.analysisGroupList.filter(element => !isEmpty(element.itemValue.trim()))}
                      />
                    </Box>

                  ))}

                </CardBody>
              </Card>

              <Card background={cardBackground} gridArea="notes" pad="small" gap="small" key={key++}>
                <CardHeader>
                  <strong>Забележки</strong>
                </CardHeader>
                <CardBody>
                  <Paragraph fill={true}>
                    {t('label_settings.label_1star_explanation')}
                  </Paragraph>
                  <Paragraph fill={true}>
                    {t('label_settings.label_2stars_explanation')}
                  </Paragraph>
                </CardBody>
              </Card>

            </Grid>
          </Box>
        </Grommet>
      )
    )
  }
}

export default DigitalLabel;
