import React from 'react';
import {
  SimpleForm,
  NumberInput,
  ReferenceInput,
  SelectInput
} from 'react-admin';
import { t } from 'i18next';

import RecipeSimpleFormIterator from './RecipeSimpleFormIterator';
import RecipeArrayInput from './RecipeArrayInput';
import { MaterialSelect } from '../MaterialSelect';
import { isRequired } from '../../utils/validation';

const validateRequired = [isRequired()];

export const RecipeForm = ({ showWarningMessage, ...props }) => {
  let { animalType, order, user, data, ...rest } = props;

  if (!order) return null;

  const { animal } = order;

  if (!animalType && !animal) {
    animalType = order.animalType
  }

  const disabled = props.record && props.record.id && user.role !== 'Admin';

  return (
    <SimpleForm {...rest} redirect="/Order" defaultValue={data || {order: {id: order.id}}}>
      <RecipeArrayInput source="materialQuantities" className="horizontal-form" animal={animalType || animal.animalType}>
        <RecipeSimpleFormIterator
          disableAdd={disabled}
          disableRemove={disabled}
          user={user.role}
          animalType={animalType || animal.animalType}
          order={order}
          showWarningMessage={showWarningMessage}
          data={data}
        >
          <ReferenceInput label={t('material')} reference="Material" source="materialId">
            <MaterialSelect isDisabled={disabled} />
          </ReferenceInput>
          <NumberInput source="quantity" label={t('quantity')} disabled={disabled} validate={validateRequired}/>
          <ReferenceInput label={t('unit')} reference="Unit" source="unitId" disabled={disabled} validate={validateRequired} fullWidth>
            <SelectInput name="name" />
          </ReferenceInput>
        </RecipeSimpleFormIterator>
      </RecipeArrayInput>
    </SimpleForm>
  );
};
