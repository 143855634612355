import React from 'react';
import {
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  NumberInput
} from 'react-admin';
import { t } from 'i18next';

import { isRequired } from '../../utils/validation';

const validateRequired = [isRequired()];

export const NutrientGroupForm = props => (
  <SimpleForm {...props} redirect="list">
    <TextInput source="name" label={t("name")} validate={validateRequired}/>
    <ReferenceInput label={t("nutrient")} reference="Nutrient" source="nutrient.id">
      <SelectInput optionText="name"/>
    </ReferenceInput>
    <NumberInput source='uiValue' label={t('ui_in_mg')}/>
  </SimpleForm>
);