import React from 'react';
import {
  TextInput,
  BooleanInput,
  email,
  SimpleForm
} from 'react-admin';

import { t } from 'i18next';
import { isRequired } from '../../utils/validation';

const validateRequired = [isRequired()];
const validateEmail = [isRequired(), email()];

export const AdminForm = props => (
  <SimpleForm {...props} >
    <TextInput source="name" label={t('name')} validate={validateRequired} />
    {((props.record.id && props.record.email !== props.toolbar.props.useremail) || Object.keys(props.record).length === 0) &&
      <BooleanInput source="active" label={t('active')}/>
    }
    <BooleanInput source="notifications" label={t('notifications')} />
    <TextInput source="phoneNumber" label={t('phone_number')} validate={validateRequired} />
    <TextInput source="email" label={t('email')} validate={validateEmail} />
  </SimpleForm>
);