import React from 'react';

import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarked } from '@fortawesome/free-solid-svg-icons';

export const MapField = ({source, record = {}}) => {
  if (!record.lat || !record.lng) {
    return '';
  }

  const mapUrl = 'https://www.google.com/maps?q=' + record.lat + ',' + record.lng;

  return <a href={mapUrl} target="_blank" rel="noopener noreferrer" onClick={(e) => { e.stopPropagation(); }}>
    <FontAwesomeIcon icon={faMapMarked} size="lg"/>
  </a>
};

MapField.propTypes = {
  addLabel: PropTypes.bool,
  record: PropTypes.object
};

MapField.defaultProps = {
  addLabel: false
};