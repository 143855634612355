import { StatusesWorkflowCreate } from '../components/StatusesWorkflow/StatusesWorkflowCreate';
import { StatusesWorkflowList } from '../components/StatusesWorkflow/StatusesWorkflowList';
import { StatusesWorkflowEdit } from '../components/StatusesWorkflow/StatusesWorkflowEdit';

import { Timeline } from '@material-ui/icons';

export const StatusesWorkflow = {
  list: StatusesWorkflowList,
  create: StatusesWorkflowCreate,
  edit: StatusesWorkflowEdit,
  icon: Timeline
};