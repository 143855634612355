import React from 'react';
import { Show, SimpleShowLayout, TextField, NumberField, ArrayField, Datagrid } from 'react-admin';
import { t } from 'i18next';

import EditFormTitle from '../EditFormTitle';

export const AgeGroupDogShow = props => (
  <Show {...props} title={<EditFormTitle formName={t('age_group.dog_title')}/>}>
    <SimpleShowLayout>
      <TextField source="sizeClass" label={t('size_class')}/>
      <TextField source="ageGroup" label={t('age_group.age_group')}/>
      <NumberField source="months" label={t('age_in_months')}/>
      <ArrayField source="priceByWeightGeneral" label={t('age_group.price_by_weight_general')}>
        <Datagrid>
          <NumberField source="weight" label={t('age_group.weight')} />
          <NumberField source="price" label={t('age_group.price')}/>
        </Datagrid>
      </ArrayField>
      <ArrayField source="priceByWeight" label={t('age_group.price_by_weight')}>
        <Datagrid>
          <NumberField source="weight" label={t('age_group.weight')} />
          <NumberField source="price" label={t('age_group.price')}/>
        </Datagrid>
      </ArrayField>
      <ArrayField source="priceByWeightVIP" label={t('age_group.price_by_weight_VIP')}>
        <Datagrid>
          <NumberField source="weight" label={t('age_group.weight')} />
          <NumberField source="price" label={t('age_group.price')}/>
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);
