export const sortIndexes = (unsorted, orderedGroups) => {
  let sorted = [];
  let groupIndexes = [];

  orderedGroups.forEach(group => {
    groupIndexes.push(unsorted.indexOf(group));
  });

  groupIndexes.forEach(index => {
    if (index >= 0) {
      sorted.push(unsorted[index]);
      delete unsorted[index];
    }
  });

  unsorted.forEach(group => {
    sorted.push(group);
  });

  return sorted;
};

export const sortNutrientGroups = groups => {
  let orderOfGroups = [
    'Протеини',
    'Мазнини',
    'Влага',
    'Въглехидрати',
    'Калций',
    'Фосфор',
    'Пепел',
    'Метаболитна енергия',
    'Витамини',
    'Минерали',
    'Витаминоподобни вещества'
  ];

  return sortIndexes(groups, orderOfGroups);
};

export const sortProductGroups = unsorted => {
  let orderOfGroups = [
    'Животински продукти',
    'Растителни продукти',
    'Биоактивни продукти',
    'Хранителни добавки',
    'Зоотехнически добавки',
    'Сензорни добавки'
  ];

  return sortIndexes(unsorted, orderOfGroups);
};

export const sortNutrientGroupsValues = (unsorted, sortIndexes) => {
  let sortedNutrients = [];

  sortIndexes.forEach(index => {
    let indexedNutrient = unsorted.find(nutrient => nutrient.name === index);
    if (indexedNutrient) {
      if (Array.isArray(indexedNutrient.value)) {
        indexedNutrient.value.forEach(value => {
          sortedNutrients.push(value);
        });
      } else {
        sortedNutrients.push(indexedNutrient);
      }
    }
  });

  return sortedNutrients;
};
