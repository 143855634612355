import React from 'react';
import {
  Show,
  TextField,
  ReferenceField,
  SimpleShowLayout,
  SelectField
} from 'react-admin';
import { t } from 'i18next';

import EditFormTitle from '../EditFormTitle';

export const CityShow = props => (
  <Show {...props} title={<EditFormTitle formName={t('city')} />} >
    <SimpleShowLayout>
      <TextField source="name" label={t('name')}/>
      <ReferenceField label={t('municipality')} reference="Municipality" source="municipality.id" allowEmpty={true}>
        <TextField source="name"/>
      </ReferenceField>
      <SelectField
        label={t('location_type')}
        source="type"
        optionValue="id"
        choices={[
          { id: 'City', name: t('city_select') },
          { id: 'Village', name: t('village_select') },
        ]}
      />
    </SimpleShowLayout>
  </Show>
);
