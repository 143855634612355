import React from 'react';
import { Create } from 'react-admin';
import { t } from 'i18next';
import CustomCreateToolbar from '../CustomCreateToolbar';
import { MunicipalityForm } from './MunicipalityForm';

export const MunicipalityCreate = props => (
  <Create {...props} title={t("municipalities.title_create")}>
    <MunicipalityForm toolbar={<CustomCreateToolbar user={props.options.currentUser.role}/>}/>
  </Create>
);
