import { AUTH_GET_PERMISSIONS } from 'react-admin';
import client from '../initApollo';
import { GET_CURRENT_USER } from '../customQueries';

export default (type) => {
  if (type === AUTH_GET_PERMISSIONS) {
    return client.query({query: GET_CURRENT_USER}).then(response => {
      if (response.loading) return;
      return response.data.currentUser.role;
    })
  }
};