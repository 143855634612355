import React from 'react';
import ExitIcon from '@material-ui/icons/PowerSettingsNew';
import Person from '@material-ui/icons/Person';
import {t} from 'i18next';
import {logout} from '../utils/AuthUtils';
import {AppBar, UserMenu, MenuItemLink, Layout, Link} from 'react-admin';
import Typography from '@material-ui/core/Typography'
import {withStyles} from '@material-ui/core/styles'

import {Tooltip} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton'
import BackIcon from '@material-ui/icons/CropFree'

const CustomUserMenu = props => (
  <UserMenu {...props} logout={<LogoutButton/>}>
    <MenuItemLink
      to="/Profile"
      primaryText={t('profile.title')}
      leftIcon={<Person/>}
    />
  </UserMenu>
);

const LogoutButton = props => (
  <MenuItemLink
    to='/'
    primaryText={t('logout')}
    leftIcon={< ExitIcon/>}
    onClick={() => logout()}/>
);

const styles = {
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  spacer: {
    flex: 1,
  },
}

const CustomAppBar = withStyles(styles)(({classes, ...props}) =>

  <AppBar {...props} userMenu={<CustomUserMenu/>}>

    <Typography
      variant="title"
      color="inherit"
      className={classes.title}
      id="react-admin-title"
    />

    <span className={classes.spacer}/>
    <IconButton
      component={Link}
      color='inherit'
      onClick={e => e.stopPropagation()}
      to={{pathname: `/ScanDigitalLabel`}}
    >
      <Tooltip title={t('scan_digital_label')}>
        <BackIcon/>
      </Tooltip>
    </IconButton>
  </AppBar>
)

const CustomLayout = props => <Layout {...props} appBar={CustomAppBar}/>;

export {CustomLayout};