import React from 'react';
import {
  Show,
  TextField,
  BooleanField,
  SimpleShowLayout
} from 'react-admin';

import { t } from 'i18next';

import EditFormTitle from '../EditFormTitle';

export const AdminShow = props => (
  <Show {...props} title={<EditFormTitle formName={t('admin')} />}>
    <SimpleShowLayout redirect="list">
      <TextField source="name" label={t('name')} />
      <BooleanField source="active" label={t('active')} />
      <BooleanField source="notifications" label={t('notifications')} />
      <TextField source="phoneNumber" label={t('phone_number')} />
      <TextField source="email" label={t('email')} />
    </SimpleShowLayout>
  </Show>
);