import React from 'react';
import {
  SimpleForm,
  TextInput
} from 'react-admin';
import { t } from 'i18next';

import { isRequired } from '../../utils/validation';

const validateRequired = [isRequired()];

export const NutrientForm = props => (
  <SimpleForm {...props} redirect="list">
    <TextInput source="name" label={t('name')} validate={validateRequired}/>
  </SimpleForm>
);
