import React, { Component } from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  FormDataConsumer,
  LongTextInput,
  maxLength
} from 'react-admin';
import { t } from 'i18next';
import CustomCreateToolbar from '../CustomCreateToolbar';
import CustomDateInput from '../fields/CustomDateInput';
import BreedSelect from '../BreedSelect';
import { SearchableSelect } from './../SearchableSelect';
import apolloClient from '../../initApollo';
import { GET_MIX_BREED_ID } from '../../customQueries';

import { isRequired, isTextNumbers } from '../../utils/validation';

const validateRequired = [isRequired()];
const validateComment = [maxLength(150)];
const validateSerial = [isTextNumbers];

const redirect = state => {
  if(state === undefined){
    return 'edit';
  }

  return state.redirect;
};

const filterOwnersByRole = props => {
  const { location, options } = props;
  if (location.state !== undefined) {
    return { id: location.state.owner }
  }

  if (options.currentUser.role === 'Owner') {
    return { email: options.currentUser.email };
  } else if (options.currentUser.role === 'Vet') {
    return { vet: { email: options.currentUser.email } };
  } else if (options.currentUser.role === 'Clinic') {
    return { vet: { clinic: { email: options.currentUser.email } } };
  }

  return null;
};

const getDefaultOwnerValue = props => {
  const { location, options } = props;
  if (location.state !== undefined) {
    return location.state.owner;
  }

  if (options.currentUser.role === 'Owner') {
    return options.currentUser.systemId;
  }

  return undefined;
};

class AnimalCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      breedMix: ''
    }
  }

  componentDidMount() {
    apolloClient.query({ query: GET_MIX_BREED_ID, fetchPolicy: 'no-cache' })
      .then(response => {
        if (response.loading) return;
        this.setState({breedMix: response.data.breeds.pop() || ''})
      });
  }

  render () {
    const { options, location } = this.props;
    const { breedMix } = this.state;

    return (
      <Create {...this.props} title={t('animals.title_create')}>
        <SimpleForm toolbar={<CustomCreateToolbar user={options.currentUser.role}/>} redirect={redirect(location.state)}>
          <FormDataConsumer>
            {({formData, ...rest}) =>
              <SelectInput
                source="animalType"
                label={t('animal_type')}
                choices={[
                  { id: 'Dog', name: t('dog') },
                  { id: 'Cat', name: t('cat') },
                ]}
                validate={validateRequired}
                onChange={() => formData.breed = ''}
              />
            }
          </FormDataConsumer>

          <CustomDateInput source="birthDate" label={t('birth_date')} validate={validateRequired} maxDate={new Date()}/>
          <TextInput source="name" label={t('name')} validate={validateRequired} />

          <SelectInput
            source="gender"
            label={t('gender')}
            choices={[
              { id: 'male', name: t('male') },
              { id: 'female', name: t('female') },
            ]}
            validate={validateRequired}
          />

          <ReferenceInput
            label={t('owner')}
            source="owner.id"
            reference="Owner"
            validate={validateRequired}
          >
            <SearchableSelect
              obj="Owner"
              defaultValue={getDefaultOwnerValue(this.props)}
              disabled={location.state !== undefined || options.currentUser.role === 'Owner'}
              filter={filterOwnersByRole(this.props)}
            />
          </ReferenceInput>

          <FormDataConsumer>
            {({ formData, ...rest }) => formData.animalType &&
              <ReferenceInput
                key={ formData.animalType }
                label={t('breed')}
                source="breed.id"
                reference="Breed"
                validate={validateRequired}
                perPage={400}
                sort={{ field: 'breedNameBg', order: 'ASC' }}
                filter={{ animalType: formData.animalType }}
                resource={rest.resource}
              >
                <BreedSelect animalType={ formData.animalType }/>
              </ReferenceInput>
            }
          </FormDataConsumer>

          <FormDataConsumer>
            {({ formData, ...rest }) =>

              formData.hasOwnProperty('breed') && breedMix !== '' && (
                formData.breed.id === breedMix.id ? (
                  <div>
                    <div>
                      <ReferenceInput
                        key="father"
                        label={t('breed_father')}
                        source="breedFather.id"
                        reference="Breed"
                        validate={validateRequired}
                        perPage={400}
                        filter={{ animalType: formData.animalType }}
                        sort={{field: 'breedNameBg', order: 'ASC'}}
                        {...rest}
                      >
                        <BreedSelect animalType={ formData.animalType } name="BreedFather"/>
                      </ReferenceInput>
                    </div>
                    <div>
                      <ReferenceInput
                        key="mother"
                        label={t('breed_mother')}
                        source="breedMother.id"
                        reference="Breed"
                        validate={validateRequired}
                        perPage={400}
                        filter={{ animalType: formData.animalType }}
                        sort={{field: 'breedNameBg', order: 'ASC'}}
                        {...rest}
                      >
                        <BreedSelect animalType={ formData.animalType } name="BreedMother"/>
                      </ReferenceInput>
                    </div>
                  </div>
                ) : null
              )
            }
          </FormDataConsumer>

          <NumberInput source="weight" label={t('weight')} validate={validateRequired} />

          <TextInput source="chipNumber" label={t('chip_number')} validate={validateSerial} parse={value => (value !== '') ? value : null} />
          <TextInput source="tattooNumber" label={t('tattoo_number')} validate={validateSerial} parse={value => (value !== '') ? value : null} />

          <SelectInput
            source="castrated"
            label={t('castrated')}
            choices={[
              { id: 'yes', name: t('yes') },
              { id: 'no', name: t('no') }
            ]}
            validate={validateRequired}
          />

          <LongTextInput source="comment" label={t('comment')} resettable validate={validateComment}/>
        </SimpleForm>
      </Create>
    )
  }
}

export default AnimalCreate;
