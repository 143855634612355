import React from 'react';
import { SimpleForm, NumberInput } from 'react-admin';
import { t } from 'i18next';

import { isRequired } from '../../utils/validation';

const validateRequired = [isRequired()];

export const SizeClassMixForm = props => (
  <SimpleForm {...props} redirect='list'>
    <NumberInput source="months" label={t('age_in_months')} validate={validateRequired}/>
    <NumberInput source="mini" label={t('size_class_mix.mini_kg')} validate={validateRequired}/>
    <NumberInput source="small" label={t('size_class_mix.small_kg')} validate={validateRequired}/>
    <NumberInput source="medium" label={t('size_class_mix.medium_kg')} validate={validateRequired}/>
    <NumberInput source="large" label={t('size_class_mix.large_kg')} validate={validateRequired}/>
    <NumberInput source="giant" label={t('size_class_mix.giant_kg')} validate={validateRequired}/>
  </SimpleForm>
);