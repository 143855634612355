import React from 'react';
import {
  Filter,
  TextInput,
  BooleanInput
} from 'react-admin';
import { t } from 'i18next';

export const CityFilter = (props) => (
  <Filter {...props}>
    <TextInput label={t("name")} source="name_contains" alwaysOn/>
    <BooleanInput label={t("city_is_main_province")} source="mainProvinceCity" alwaysOn/>
  </Filter>
);
