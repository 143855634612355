import React from 'react';
import { Create } from 'react-admin';
import { t } from 'i18next';

import CustomCreateToolbar from '../CustomCreateToolbar';
import { AgeGroupCatForm } from './AgeGroupCatForm';

export const AgeGroupCatCreate = props => (
  <Create {...props} title={t('age_group.cat_title_create')}>
    <AgeGroupCatForm toolbar={<CustomCreateToolbar user={props.options.currentUser.role}/>}/>
  </Create>
);