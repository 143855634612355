import React from 'react';
import PropTypes from 'prop-types';
import {Chip, Avatar} from '@material-ui/core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCat, faDog} from '@fortawesome/free-solid-svg-icons';
import {Link} from 'react-admin';

const ChipWithAvatar = ({record = {}, blankTarget = false}) => {
  if (record === null || record === undefined) return '';
  if (record.hasOwnProperty('animalType') === false) return '';

  if (blankTarget) {
    return (
      <Chip
        component={Link}
        to={{pathname: `/Animal/${record.id}/show`}}
        target='_blank'
        style={{marginLeft: 'auto'}}
        avatar={<Avatar><FontAwesomeIcon icon={record.animalType === 'Dog' ? faDog : faCat}/></Avatar>}
        label={record.name}
        // variant={"outlined"}
        // size={"small"}
        // color={"primary"}
        clickable
      />
    )
  } else {
    return (
      <Chip
        avatar={<Avatar><FontAwesomeIcon icon={record.animalType === 'Dog' ? faDog : faCat}/></Avatar>}
        label={record.name}
        // variant={"outlined"}
        // size={"small"}
        // color={"primary"}
        clickable
      />
    )
  }

};

ChipWithAvatar.propTypes = {
  record: PropTypes.object,
  blankTarget: PropTypes.bool,
  basePath: PropTypes.string,
  resource: PropTypes.string,
  allowEmpty: PropTypes.bool,
  className: PropTypes.string,
  translateChoice: PropTypes.bool,
  onClick: PropTypes.func
};

export default ChipWithAvatar;