import React, {Fragment, Component} from 'react';
import {
  Show,
  TextField,
  ReferenceField,
  NumberField,
  SelectField,
  Link,
  Button,
  FunctionField,
  TabbedShowLayout,
  Tab,
  Toolbar
} from 'react-admin';
import {t} from 'i18next';

import apolloClient from '../../initApollo';
import {GET_RECIPE_LABEL_LINKS_BY_ORDER} from '../../customQueries';

import ContentAdd from '@material-ui/icons/Add';
import LabelIcon from '@material-ui/icons/Label';
import ListIcon from '@material-ui/icons/List';

import {Comments} from './Comments';
import {Notes, OwnerNotes, VetNotes} from './Notes';

import {
  Divider,
} from '@material-ui/core';

import EditFormTitle from '../EditFormTitle';
import ChipWithAvatar from '../fields/ChipWithAvatar';
import DateField from '../fields/CustomDateField';
import OrderHistory from './OrderHistory';
import StatusNameTypography from '../fields/StatusNameTypography';

import { withStyles } from '@material-ui/core/styles';
import FrontLabelIcon from '@material-ui/icons/Description';
import BackLabelIcon from '@material-ui/icons/RestorePage';
import DigitalLabelIcon from '@material-ui/icons/LineWeight';

const styles = theme => ({
  button: {
    marginRight: theme.spacing.unit,
  },
  buttonIcon: {
    marginRight: theme.spacing.unit,
  },
});

const AddNoteButton = ({id, label}) => (
  <FunctionField addLabel={false} render={() => {
    return <Button component={Link} to={{pathname: `/Order/${id}`}} label={label}><ContentAdd/></Button>
  }}/>
);

class OrderShow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      recipeLabelLinks: null
    }
  }

  componentDidMount() {
    apolloClient.query({query: GET_RECIPE_LABEL_LINKS_BY_ORDER, variables: {id: this.props.id}})
      .then(response => {
        if (response.loading) return;
        if(response.data.orders.length) {
          const recipeLabelLinks = response.data.orders[0].recipe;
          this.setState({recipeLabelLinks});
        }
      })
  }

  render() {
    const { recipeLabelLinks } = this.state;

    return (
  <Show {...this.props} title={<EditFormTitle formName={t('order')}/>}>
    <TabbedShowLayout>
      <Tab label={t('data_tab')}>
        <TextField source="idNumber" label={t('number')}/>
        <ReferenceField label={t('status')} reference='Order' source='id' allowEmpty={true} sortable={false}
                        linkType={this.props.options.currentUser.role === 'Admin' || this.props.options.currentUser.role === 'ProductionManager' ? 'edit' : false}>
          <StatusNameTypography user={this.props.options.currentUser.role}/>
        </ReferenceField>
        <DateField source='dateCreated' label={t('date_created')}/>
        <ReferenceField label={t('clinic_name')} reference="Clinic" source="clinic.id" allowEmpty={true}
                        sortable={false}
                        linkType={this.props.options.currentUser.role === 'Admin' || this.props.options.currentUser.role === 'Clinic' ? 'show' : false}>
          <TextField source="name"/>
        </ReferenceField>
        <ReferenceField label={t('vet')} reference="Vet" source="vet.id" allowEmpty={true} sortable={false}
                        linkType={this.props.options.currentUser.role === 'Owner' || this.props.options.currentUser.role === 'ProductionManager' ? false : 'show'}>
          <TextField source="name"/>
        </ReferenceField>
        <ReferenceField label={t('owner')} reference="Owner" source="owner.id" allowEmpty={true} sortable={false}
                        linkType={this.props.options.currentUser.role === 'ProductionManager' ? false : 'show'}>
          <TextField source="name"/>
        </ReferenceField>
        <ReferenceField label={t('animal')} reference="Animal" source="animal.id" allowEmpty={true} sortable={false}
                        linkType={this.props.options.currentUser.role === 'ProductionManager' ? false : 'show'}>
          <ChipWithAvatar/>
        </ReferenceField>
        <ReferenceField label={t('breed')} source="breed.id" reference="Breed" linkType={false} allowEmpty={true}>
          <TextField source="breedNameBg"/>
        </ReferenceField>
        <SelectField
          label={t('age_group.age_group')}
          source="ageGroupName"
          choices={[
            {id: 'Puppy', name: t('age_group.puppy')},
            {id: 'Kitten', name: t('age_group.kitten')},
            {id: 'Junior', name: t('age_group.junior')},
            {id: 'Adult', name: t('age_group.adult')},
            {id: 'Prime', name: t('age_group.prime')},
            {id: 'Mature', name: t('age_group.mature')},
            {id: 'Senior', name: t('age_group.senior')},
            { id: 'YoungAdult', name: t('age_group.youngadult') },
            { id: 'MatureAdult', name: t('age_group.matureadult') },
            {id: 'Geriatric', name: t('age_group.geriatric')}
          ]}
        />
        <SelectField
          label={t('size_class')}
          source="sizeClass"
          choices={[
            {id: 'Mini', name: t('size_classes.mini')},
            {id: 'Small', name: t('size_classes.small')},
            {id: 'Medium', name: t('size_classes.medium')},
            {id: 'Large', name: t('size_classes.large')},
            {id: 'Giant', name: t('size_classes.giant')}
          ]}
        />
        <NumberField source="quantity" label={t('order_details.quantity')}/>
        <TextField source="weight" label={t('age_group.weight')}/>
        <NumberField source="price" label={t('order_details.price')}/>
        <SelectField
          label={t('order_details.order_type')}
          source="orderType"
          choices={[
            {id: 'Normal', name: t('order_details.normal')},
            {id: 'ByBreed', name: t('order_details.by_breed')},
            {id: 'VIP', name: t('order_details.VIP')},
          ]}
        />
        <ReferenceField label={t('taste')} reference='Taste' source='taste.id' allowEmpty={true} sortable={false}
                        linkType={false}>
          <TextField source='name'/>
        </ReferenceField>
        <TextField source="testerCode" label={t('order_details.tester_code')}/>

        <Divider/>

        <TextField source="ownerName" label={t('owner_name')}/>
        <ReferenceField label={t("city")} reference="City" source="city.id" allowEmpty={true} linkType={false}>
          <TextField source="name"/>
        </ReferenceField>
        <TextField source="district" label={t('district')}/>
        <TextField source="street" label={t('street')}/>
        <TextField source="streetNumber" label={t('street_number')}/>
        <TextField source="buildingNumber" label={t('building_number')}/>
        <TextField source="entrance" label={t('entrance')}/>
        <TextField source="floor" label={t('floor')}/>
        <TextField source="apartmentNumber" label={t('apartment_number')}/>
        <TextField source="postcode" label={t('postcode')}/>
        <TextField source="phoneNumber" label={t('phone_number')}/>

        <Divider/>

        {this.props.options.currentUser.role !== 'ProductionManager' ?
          <Comments/>
          : null}
        {this.props.options.currentUser.role !== 'ProductionManager' ?
          <AddNoteButton id={this.props.id} label={t('order_details.add_comment')}/>
          : null}
        {(this.props.options.currentUser.role === 'Admin' || this.props.options.currentUser.role === 'ProductionManager') ?
          <Notes/>
          : null}
        {(this.props.options.currentUser.role === 'Admin' || this.props.options.currentUser.role === 'ProductionManager') ?
          <AddNoteButton id={this.props.id} label={t('order_details.add_note')}/>
          : null}
        {(this.props.options.currentUser.role === 'Admin' || this.props.options.currentUser.role === 'Owner') ?
          <OwnerNotes/>
          : null}
        {(this.props.options.currentUser.role === 'Admin' || this.props.options.currentUser.role === 'Owner') ?
          <AddNoteButton id={this.props.id} label={t('order_details.add_owner_note')}/>
          : null}
        {(this.props.options.currentUser.role === 'Admin' || this.props.options.currentUser.role === 'Vet') ?
          <VetNotes/>
          : null}
        {(this.props.options.currentUser.role === 'Admin' || this.props.options.currentUser.role === 'Vet') ?
          <AddNoteButton id={this.props.id} label={t('order_details.add_vet_note')}/>
          : null}

        <Divider/>

        {(this.props.options.currentUser.role === 'Admin' || this.props.options.currentUser.role === 'ProductionManager') ?
          <FunctionField
            label={t('recipe')}
            sortable={false}
            render={({recipe, id}) => {
              return recipe ?
                <Fragment>
                  <Button
                    component={Link}
                    to={{pathname: `/Recipe/${recipe.id}`}}
                    label={t('recipe')}
                    onClick={e => e.stopPropagation()}>
                    <ListIcon/>
                  </Button>
                  <Button
                    component={Link}
                    to={{pathname: `/Recipe/labelSettings/${recipe.id}`}}
                    label={t('label_settings.label_admin')}
                    onClick={e => e.stopPropagation()}>
                    <LabelIcon/>
                  </Button>
                </Fragment>
                : null
            }}
          />
          : null}

        {!(recipeLabelLinks && ((this.props.options.currentUser.role === 'Admin' || this.props.options.currentUser.role === 'ProductionManager') || recipeLabelLinks.digitalLabelId)) ? null : 
          <Toolbar>
            <Fragment>
              {(this.props.options.currentUser.role === 'Admin' || this.props.options.currentUser.role === 'ProductionManager') ? 
              [
                recipeLabelLinks.frontLabelUrl ?
                  <Button variant='raised' color='default' size='medium'
                    className={this.props.classes.button}
                    onClick={() => window.open(recipeLabelLinks.frontLabelUrl, '_blank')}
                    label={t('label_settings.button_link_front')}>
                    <FrontLabelIcon className={this.props.classes.buttonIcon} />
                  </Button>
                  : null,
                recipeLabelLinks.backLabelUrl ?
                  <Button variant='raised' color='default' size='medium'
                    className={this.props.classes.button}
                    onClick={() => window.open(recipeLabelLinks.backLabelUrl, '_blank')}
                    label={t('label_settings.button_link_back')}>
                    <BackLabelIcon className={this.props.classes.buttonIcon} />
                  </Button>
                  : null
              ]
              : null}
              {recipeLabelLinks.digitalLabelId ?
                <Button variant='raised' color='default' size='medium'
                  className={this.props.classes.button}
                  component={Link}
                  to={{ pathname: `/DigitalLabel/${recipeLabelLinks.digitalLabelId}` }}
                  label={t('label_settings.digital_label')}>
                  <DigitalLabelIcon className={this.props.classes.buttonIcon} />
                </Button>
                : null}
            </Fragment>
          </Toolbar>
        }

      </Tab>

      <Tab label={t('history')}>
        <OrderHistory/>
      </Tab>
    </TabbedShowLayout>
  </Show>
    );
  }
}

export default withStyles(styles)(OrderShow);
