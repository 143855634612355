import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  BooleanField
} from 'react-admin';
import { t } from 'i18next';

import { CustomListActions } from '../CustomListActions';
import { MapField } from '../MapField.js';
import { ClinicFilter } from './ClinicFilter';
import {ResendTempEmailButton} from "../ResendTempEmailButton";

export const ClinicList = props => (
  <List {...props}
    filters={<ClinicFilter user={props.options.currentUser}/>}
    bulkActionButtons={false}
    actions={props.options.currentUser.role !== 'Clinic' ? <CustomListActions user={props.options.currentUser}/> : null}
    title={t("clinics.title_list")}
    filter={props.options.currentUser.role === 'Clinic' ? {email: props.options.currentUser.email} : {}}
  >
    <Datagrid rowClick="show">
      <TextField source="name" label={t("name")} />
      <BooleanField source="active" label={t('clinics.active')}/>
      <TextField source="phoneNumber" label={t('phone_number')} />
      <TextField source="email" label={t('email')}/>
      <ReferenceField label={t("city")} reference="City" source="city.id" allowEmpty={true} sortable={false} linkType={false}>
        <TextField source="name"/>
      </ReferenceField>
      <TextField source="postcode" label={t('postcode')} />
      <TextField source='street' label={t('street')} />
      <MapField label={t("clinics.location")}/>

      <ResendTempEmailButton
        email={props.email}
        active={props.active}
      />

    </Datagrid>
  </List>
);
