import { Flag } from '@material-ui/icons';
import { CountryList } from '../components/Country/CountryList';
import { CountryCreate } from '../components/Country/CountryCreate';
import { CountryEdit } from '../components/Country/CountryEdit';

export const Countries = {
  icon: Flag,
  list: CountryList,
  create: CountryCreate,
  edit: CountryEdit
};
