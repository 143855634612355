import React from 'react';
import { t } from 'i18next';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';

import ConsultingFeeClinicList from './components/ConsultingFeeClinic/ConsultingFeeClinicList';
import { FoodRunningOutNotificationList } from './components/FoodRunningOutNotification/FoodRunningOutNotificationList';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: 0
    };
  }

  handleChange = value => {
    this.setState({ value });
  };

  render() {
    return (
      <Paper>
        <Tabs
          value={this.state.value}
          onChange={(x, value) => this.handleChange(value)}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label={t('consulting_fee_details.consulting_fee_clinic')}/>
          <Tab label={t('food_running_out.food_running_out')}/>
        </Tabs>
        { this.state.value === 0 && <ConsultingFeeClinicList/> }
        { this.state.value === 1 && <FoodRunningOutNotificationList/> }
      </Paper>
    );
  }
}

export default Dashboard;