import React from 'react';
import {
  Filter,
  TextInput,
  ReferenceInput,
  BooleanInput
} from 'react-admin';
import { t } from 'i18next';
import LocationSelect from '../LocationSelect';

export const ClinicFilter = (props) => (
  <Filter {...props}>
    { props.user.role !== 'Clinic' ? <TextInput label={t("name")} source="name_contains" alwaysOn/> : null}
    <BooleanInput label={t("clinics.active")} source="active"/>
    <TextInput label={t('phone_number')} source="phoneNumber_contains"/>
    <TextInput label={t('email')} source="email_contains"/>
    <TextInput label={t('postcode')} source="postcode_contains"/>
    <TextInput label={t('street')} source='street_contains'/>
    <ReferenceInput
      label={t('city')}
      reference="City"
      source="city.id"
      perPage={30}
      sort={{ field: 'name', order: 'ASC' }}>
      <LocationSelect/>
    </ReferenceInput>
  </Filter>
);
