import React, { Fragment } from 'react';
import { startUndoable, crudDelete } from 'ra-core';
import { Button } from 'react-admin';
import ConfirmationDialog from './ConfirmationDialog';
import PropTypes from 'prop-types';
import ActionDelete from '@material-ui/icons/Delete';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { t } from 'i18next';

const styles = {
    button: {
        color: '#f44336',
    },
};

class DeleteButtonWithConfirmation extends React.Component {
    state = {
        showDialog: false
    };

    handleClick = () => {
        this.setState({ showDialog: true });
    };

    handleCloseClick = () => {
        this.setState({ showDialog: false });
    };

    handleDelete = () => {
        this.setState({ showDialog: false });
        const {
            undoable,
            startUndoable,
            resource,
            record,
            basePath,
            redirect,
            dispatchCrudDelete,
        } = this.props;

        if (undoable) {
            startUndoable(crudDelete(resource, record.id, record, basePath, redirect));
        } else {
            dispatchCrudDelete(resource, record.id, record, basePath, redirect);
        }
    };

    render() {
        const { label, classes, icon, record, resource } = this.props;

        var message = t('delete_confirmation.confirm_message_sg');
        var title = true;
        var onlyCancel = false;

        if (resource === 'Owner' && record.animals.length > 0) {
            message = t('owners.owner_has_pet_pop_up');
            title = false;
            onlyCancel = true;
        }

        if (resource.toLowerCase() === "vet" && record.orders.length > 0) {
            onlyCancel = true;
            message = t('vets.vet_has_order_pop_up');
            title = false;
        }

        if (resource === 'Clinic' && record.vets.length > 0) {
            message = t('clinics.has_vets_pop_up');
            title = false;
            onlyCancel = true;
        }

        return (
            <Fragment>
                <Button
                    onClick={this.handleClick}
                    label={label}
                    className={classes.button}
                >
                    {icon}
                </Button>
                <ConfirmationDialog
                    isOpen={this.state.showDialog}
                    message={message}
                    handleAction={this.handleDelete}
                    label={label}
                    classes={classes}
                    icon={icon}
                    handleClose={this.handleCloseClick}
                    cancelLabel='ra.action.cancel'
                    title={title}
                    onlyCancel={onlyCancel}
                />
            </Fragment>
        );
    }
}

DeleteButtonWithConfirmation.propTypes = {
    basePath: PropTypes.string,
    classes: PropTypes.object,
    className: PropTypes.string,
    dispatchCrudDelete: PropTypes.func.isRequired,
    label: PropTypes.string,
    record: PropTypes.object,
    redirect: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.func,
    ]),
    resource: PropTypes.string.isRequired,
    startUndoable: PropTypes.func,
    undoable: PropTypes.bool,
    icon: PropTypes.element,
};

DeleteButtonWithConfirmation.defaultProps = {
    undoable: true,
    redirect: 'list',
    label: 'ra.action.delete',
    icon: <ActionDelete />,
};

export default compose(
    connect(
        null,
        {
            startUndoable,
            dispatchCrudDelete: crudDelete
        }
    ),
    withStyles(styles)
)(DeleteButtonWithConfirmation);