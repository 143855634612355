import React, { Component, Fragment } from 'react';
import { Button } from 'react-admin';
import ContentFilter from '@material-ui/icons/FilterList';
import { t } from 'i18next';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { findDOMNode } from 'react-dom';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';

const styles = {
  filter: {
    marginTop: '15px',
    marginBottom: '20px'
  }
};

class CustomHistoryFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      hiddenFilters: [ 'weight' ],
      displayedFilters: []
    };

    this.handleClickFilterButton = this.handleClickFilterButton.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleClickMenuItem = this.handleClickMenuItem.bind(this);
    this.handleRemoveFilter = this.handleRemoveFilter.bind(this);
  }

  handleClickFilterButton() {
    this.setState({
      open: true,
      anchorEl: findDOMNode(this.button), // eslint-disable-line react/no-find-dom-node
    });
  }

  handleClose() {
    this.setState({ open: false });
  }

  handleClickMenuItem(e) {
    let filterKey = e.target.dataset.key;

    this.setState(prevState => ({
      open: false,
      displayedFilters: [...prevState.displayedFilters, filterKey ],
      hiddenFilters: this.state.hiddenFilters.filter(item => item !== filterKey)
    }));

    this.props.onAddFilter(filterKey);
  }

  handleRemoveFilter = filterKey => () => {
    this.setState(prevState => ({
      displayedFilters: this.state.displayedFilters.filter(item => item !== filterKey),
      hiddenFilters: [...prevState.hiddenFilters, filterKey ],
    }));

    this.props.onRemoveFilter(filterKey);
  };

  render() {
    const { classes } = this.props;
    const { hiddenFilters, displayedFilters } = this.state;

    return (
      <div className={classes.filter}>
        { hiddenFilters.length > 0 && (
          <Fragment>
            <Button
              ref={ node => { this.button = node } }
              label='ra.action.add_filter'
              onClick={this.handleClickFilterButton}
            >
              <ContentFilter/>
            </Button>
            <Menu
              anchorEl={this.state.anchorEl}
              open={this.state.open}
              onClose={this.handleClose}
            >
            { hiddenFilters.map(filterElement => (
              <MenuItem
                key={filterElement}
                data-key={filterElement}
                onClick={this.handleClickMenuItem}
              >
                {t(`${filterElement}`)}
              </MenuItem>
            ))}
            </Menu>
          </Fragment>
        )}
        { displayedFilters.length > 0 && (
          displayedFilters.map(displayedFilter => (
            <Chip
              key={displayedFilter}
              label={t(`${displayedFilter}`)}
              onDelete={this.handleRemoveFilter(displayedFilter)}
            />
          ))
        )}
      </div>
    )
  }
}

CustomHistoryFilter.propTypes = {
  onRemoveFilter: PropTypes.func.isRequired,
  onAddFilter: PropTypes.func.isRequired,
};

export default withStyles(styles)(CustomHistoryFilter);