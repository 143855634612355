import React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  ReferenceField,
} from 'react-admin';
import { t } from 'i18next';

import EditFormTitle from '../EditFormTitle';

export const MunicipalityShow = props => (
  <Show {...props} title={<EditFormTitle formName={t("municipality")} />}>
    <SimpleShowLayout>
      <TextField label={t("name")} source="name"/>
      <ReferenceField label={t("province")} reference="Province" source="province.id" allowEmpty={true}>
        <TextField source="name"/>
      </ReferenceField>
      <TextField label={t("code_column")} source="code"/>
    </SimpleShowLayout>
  </Show>
);
