import React from 'react';
import {
  SimpleForm,
  BooleanInput,
  TextInput,
  ReferenceInput,
  SelectInput
} from 'react-admin';
import { t } from 'i18next';

import { isRequired } from '../../utils/validation';

const validateRequired = [isRequired()];

export const IngredientForm = props => (
    <SimpleForm {...props} redirect="list">
      <BooleanInput source="isHumanUsable" label={t('ingredient_details.human_usable')}/>
      <TextInput source="name" label={t('name')} validate={validateRequired}/>
      <ReferenceInput label={t('product_group')} reference="ProductGroup" source="productGroup.id">
        <SelectInput optionText="name"/>
      </ReferenceInput>
    </SimpleForm>
);