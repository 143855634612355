import React from 'react';
import {
  SimpleForm,
  NumberInput,
  SelectInput,
  required
} from 'react-admin';
import { t } from 'i18next';

import { isRequired } from '../../utils/validation';

const validateRequired = [isRequired()];

export const EnergyRequirementDogForm = props => (
  <SimpleForm {...props} redirect='list'>
    <SelectInput
      source='sizeClass'
      label={t('size_class')}
      choices={[
        { id: 'Mini', name: t('size_classes.mini') },
        { id: 'Small', name: t('size_classes.small') },
        { id: 'Medium', name: t('size_classes.medium') },
        { id: 'Large', name: t('size_classes.large') },
        { id: 'Giant', name: t('size_classes.giant') }
      ]}
      validate={validateRequired}
    />
    <NumberInput source='energy' validate={validateRequired} label={t('energy_requirement_dog.energy')}/>
    <NumberInput source='months' validate={required()} label={t('age_in_months')}/>
    <NumberInput source='days' validate={required()} defaultValue={0} label={t('energy_requirement_dog.days')}/>
  </SimpleForm>
);
