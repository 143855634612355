import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
} from 'react-admin';
import { t } from 'i18next';

import { CustomBulkActions } from '../CustomBulkActions';
import { CustomListActions } from '../CustomListActions';
import {ResendTempEmailButton} from "../ResendTempEmailButton";

export const ProductionManagerList = props => (
  <List
    {...props}
    bulkActionButtons={<CustomBulkActions />}
    actions={<CustomListActions user={props.options.currentUser} />}
    title={t('production_managers.title_list')}
  >
    <Datagrid rowClick="show">
      <TextField source="name" label={t('name')} />
      <BooleanField source="active" label={t('active')} />
      <BooleanField source="notifications" label={t('notifications')} />
      <TextField source="phoneNumber" label={t('phone_number')} />
      <TextField source="email" label={t('email')} />
      <ResendTempEmailButton
        email={props.email}
        active={props.active}
      />
    </Datagrid>
  </List>
);