import React, { Fragment } from 'react';
import apolloClient from '../../initApollo';
import { Button, LinearProgress } from 'react-admin';
import { Link } from 'react-admin';
import md5 from 'md5';
import { withStyles } from '@material-ui/core/styles';
import ConfirmationDialog from '../ConfirmationDialog';
import { percentToUnitObject } from '../PercentToUnit';
import { getAgeGroupWithMonths, createAgeGroupWithMonths } from '../calculateAnimalDetails';
import { sortNutrientGroups, sortProductGroups, sortNutrientGroupsValues } from '../sortGroups';

import FrontLabelIcon from '@material-ui/icons/Description';
import BackLabelIcon from '@material-ui/icons/RestorePage';
import CheckCircle from '@material-ui/icons/CheckCircle';
import LabelIcon from '@material-ui/icons/Label';
import DigitalLabelIcon from '@material-ui/icons/LineWeight';

import {
  SAVE_RECIPE_FIELDS_FOR_LABEL,
  GET_ADDITIONAL_DATA_FOR_LABEL,
  GET_RECIPE_LABEL_LINKS
} from '../../customQueries';
import { formatDate } from '../../utils/Helper';

import { t } from 'i18next';

const styles = theme => ({
  button: {
    marginLeft: theme.spacing.unit,
  },
  buttonIcon: {
    marginRight: theme.spacing.unit,
  },
});

class GenerateLabelButtons extends React.Component {
  allMaterials = {};
  allUnits = [];
  recipe = {};
  ageGroup = {
    name: 'Age group',
    monthsFrom: null,
    monthsTo: null,
  };

  constructor(props) {
    super(props);

    const { recipe } = props;

    this.state = {
      frontLabelUrl: null,
      backLabelUrl: null,
      digitalLabelId: null,
      recordId: recipe.id,
      loading: props.recipe === null,
      showDialog: false,
    };
  };

  getRecipeLabelLinks = id => {
    apolloClient.query({ query: GET_RECIPE_LABEL_LINKS, variables: { id }, fetchPolicy: 'no-cache' })
      .then(response => {
        if (response.loading) return;
        const { frontLabelUrl, backLabelUrl, digitalLabelId } = response.data.recipe;

        this.setState({
          frontLabelUrl,
          backLabelUrl,
          digitalLabelId,
        });
      });
  };

  componentDidMount() {
    this.fetchPrerequisites();
    this.getRecipeLabelLinks(this.state.recordId);
  };

  fetchPrerequisites = () => {
    this.setState({ loading: true });
    const { recipe } = this.props;

    apolloClient.query({ query: GET_ADDITIONAL_DATA_FOR_LABEL }).then(response => {
      if (response.loading) return;

      return response.data;
    }).then(data => {
      data.materials.forEach(material => {
        this.allMaterials[material.id] = {
          name: material.name,
          ingredients: material.ingredients,
        };
      });

      this.allUnits = data.units;

      if (recipe.order.animal) {
        getAgeGroupWithMonths(recipe.order.animal).then(result => {
          this.ageGroup = result;

          this.setState({ loading: false });
        });
      } else {
        const ageGroup = { id: recipe.order.ageGroup };
        const animalType = recipe.order.animalType;
        const sizeClass = recipe.order.sizeClass;
        createAgeGroupWithMonths(ageGroup, animalType, sizeClass).then(result => {
          this.ageGroup = result;

          this.setState({ loading: false });
        })
      }
    });
  };

  handleClick = () => {
    if (this.state.loading) return;

    if (this.state.frontLabelUrl || this.state.backLabelUrl) {
      this.setState({ showDialog: true });
    } else {
      this.generateLabels();
    }
  };

  handleDialogCloseClick = () => {
    this.setState({ showDialog: false });
  };

  handleDialogConfirmClick = () => {
    this.setState({ showDialog: false });
    this.generateLabels();
  };

  generateLabels = () => {
    const { recipe } = this.props;

    this.setState({
      frontLabelUrl: null,
      backLabelUrl: null,
      loading: true,
    });

    let labelContent = this.generateLabelData(recipe);

    fetch(process.env.REACT_APP_DRIVE_LABEL_SERVICE_URL, {
      method: 'POST',
      redirect: 'follow',
      body: JSON.stringify(labelContent),
    }).then(
      response => response.ok ?
        response.json()
        : {
          success: false,
          error: {
            url: response.url,
            status: response.status,
            statusText: response.statusText
          }
        }
    ).then(responseData => {
      if (responseData.success) {

        const digitalLabelId = this.state.digitalLabelId || md5(recipe.id);

        this.setState({
          frontLabelUrl: responseData.frontLabelUrl,
          backLabelUrl: responseData.backLabelUrl,
          loading: false,
          digitalLabelId: digitalLabelId,
        });

        this.saveLabelLinks(labelContent);
      } else {
        // throw new Error(JSON.stringify(responseData.error));
        console.log(responseData);
      }
    });
  };

  findClosestNumberInCups = (data, number) => {
    return data.find(cup => cup.weightFrom <= number && cup.weightTo >= number);
  };

  generateLabelData = recipe => {

    const digitalLabelId = this.state.digitalLabelId || md5(recipe.id);
    const isVIP = recipe.order.orderType === 'VIP'

    const { hiddenNutrients } = this.props;
    const { nutrients } = this.props.record;
    const { order, materialQuantities, energy, highQualityAnimalProtein, weightPerBowl, bestBefore, dailyDose } = recipe;
    const { animal, taste, weight, ageGroupName, breed } = order;
    const breedPictureName = `picture${ageGroupName}`;
    let breedNameBg = '';
    let breedNameEn = '';
    let calculatedCupsText = '';

    if (animal) {
      breedNameBg = animal.breed.breedNameBg;
      breedNameEn = animal.breed.breedNameEn;
    } else if (order.breed) {
      breedNameBg = order.breed.breedNameBg;
      breedNameEn = order.breed.breedNameEn;
    }

    if (weightPerBowl && dailyDose) {
      const cupData = this.calculateCups(weightPerBowl);

      let largestPortion = cupData.find(d => d.part === 1);
      let fullCups = 0;
      let remainder;
      let remainderText = ' ';

      if (dailyDose >= largestPortion.weight) {
        fullCups = Math.floor(dailyDose / largestPortion.weight);
        let cupText = fullCups >= 2 ? `${t('label_settings.optimus_cups')}` : `${t('label_settings.optimus_cup')}`;

        let part = dailyDose % largestPortion.weight;
        if (part !== 0 && dailyDose > largestPortion.weightTo) {
          remainder = this.findClosestNumberInCups(cupData, part);
          if (remainder.part === '1/1.34') {
            remainderText = ` ${t('and')} 3/4`;
          } else if(remainder.part === 1) {
            fullCups += 1;
          } else {
            remainderText = ` ${t('and')} ${remainder.part}`;
          }
        }

        calculatedCupsText = ` ( ~ ${fullCups}${remainderText} ${cupText})`;
      } else {
        remainder = this.findClosestNumberInCups(cupData, dailyDose);
        if (remainder.part === '1/1.34') {
          calculatedCupsText = ` ( ~ 3/4 ${t('label_settings.optimus_cup')})`;
        } else {
          calculatedCupsText = ` ( ~ ${remainder.part} ${t('label_settings.optimus_cup')})`;
        }
      }
    }

    return {
      orderNumber: order.idNumber,
      digitalLabelId: digitalLabelId,
      mustGenerateQrCode: isVIP ? 'true' : 'false',
      breed: breedNameBg || order.sizeClass,
      category: this.getAgeGroupText(recipe),
      foodContentGroups: this.transformFoodContentData(materialQuantities),
      analysisData: this.transformAnalysisData(nutrients, hiddenNutrients, energy),
      // leaving this as it was in case it's needed again
      // feedingRecommendations: weightPerBowl ? this.calculateCupsInStrings(weightPerBowl) : [],
      feedingRecommendations: [],
      formulaTitle: 'Formula title',
      formulaPercentage: highQualityAnimalProtein,
      breedImageName: `${breedNameEn} ${order.sizeClass}.png`,
      characteristicList: [
        "За висок жизнен тонус и сигурна имунна защита",
        "За стимулиране на мозъчната дейност",
        "За превенция на зъбна плака",
        "За лъскава козина и интензивно оцветяване"
      ],
      dogImageUrl: (animal ? animal.profilePicture : undefined) || (breed ? breed[breedPictureName] : undefined) || '',
      tasteData: this.transformTasteData(taste),
      productWeight: `${weight} ${t('kg')}`,
      bestBefore: bestBefore ? `${t('recipes.best_before')}: ${formatDate(bestBefore)}` : '',
      dailyDose: dailyDose ? `${t('label_settings.daily_dose')}: ${dailyDose.toFixed(0)}${t('g')}${calculatedCupsText.length > 0 ? calculatedCupsText : ''}` : ''
    };
  };

  getAgeGroupText = () => {
    const { name, monthsFrom, monthsTo } = this.ageGroup;

    return `${name} [${t('from')} ${monthsFrom || `2${t('months_without_dot')}`} ${t('to')} ${monthsTo || '∞'}]`;
  };

  transformFoodContentData = materials => {
    let result = [];
    let groups = [];
    let ingredients = [];
    let ingredientIds = [];

    if (materials) {

      materials.forEach(material => {
        const containedIngredients = this.allMaterials[material.materialId]
          ? this.allMaterials[material.materialId].ingredients
          : null;

        if (containedIngredients) {
          containedIngredients.forEach(containedIngredient => {
            const { id, name, productGroup, isHumanUsable } = containedIngredient;

            if (ingredientIds.indexOf(id) < 0) {
              const humanUsableMarker = isHumanUsable ? '**' : '';

              ingredients.push({
                name: name.toLowerCase().trim() + humanUsableMarker,
                group: productGroup.name
              });

              ingredientIds.push(id);
            }

            if (groups.indexOf(productGroup.name) < 0) {
              groups.push(productGroup.name);
            }
          });
        }
      });
    }

    let sortedGroups = sortProductGroups(groups);

    sortedGroups.forEach(foodGroupType => {
      const foodGroupList = ingredients.filter(item => item.group === foodGroupType).map(item => item.name);

      result.push({
        foodGroupType,
        foodGroupList,
      });
    });

    return result;
  };

  transformAnalysisData = (nutrients, hiddenNutrients, energy) => {
    let result = [];
    const groupedNutrients = this.getGroupedNutrients(nutrients, hiddenNutrients, energy);

    for (let groupType in groupedNutrients) {
      result.push({
        analysisGroupType: t(`label_settings.${groupType}`),
        analysisGroupList: [...groupedNutrients[groupType]],
      });
    }

    return result;
  };

  calculateCups = weightPerBowl => {
    let cups = [
      { part: 8 },
      { part: 4 },
      { part: 2 },
      { part: 1.34 },
      { part: 1 },
    ];

    cups.forEach(cup => {
      cup.weight = Math.ceil(weightPerBowl / cup.part);
      cup.part = cup.part === 1 ? cup.part : `1/${cup.part}`;
    });

    return this.calculateCupsWeights(cups);
  };

  calculateCupsWeights = cups => {
    cups.forEach((cup, key) => {
      if (cup.part === 1) {
        cup.weightTo = cup.weight + cups[0].additional;
      } else {
        cup.additional = Math.ceil((cups[key + 1].weight - cup.weight) / 2);
        cup.weightTo = cup.weight + cup.additional;
      }

      if (key === 0) {
        cup.weightFrom = 0;
      } else {
        cup.weightFrom = cups[key - 1].weightTo + 1
      }
    });

    return cups;
  };

  getGroupedNutrients = (nutrientData, hiddenNutrients, energy) => {
    let result = {
      typical_analysis: [],
    };

    const selectedEnergy = this.getSelectedEnergy(energy);
    const { nutrients, vitaminsAndMinerals, groups } = this.processNutrients(nutrientData, hiddenNutrients, selectedEnergy);

    const sortIndexes = sortNutrientGroups(groups);
    const sortedNutrients = sortNutrientGroupsValues(nutrients, sortIndexes);
    const sortedVitaminsAndMinerals = sortNutrientGroupsValues(vitaminsAndMinerals, sortIndexes);

    result.typical_analysis.push(...sortedNutrients);
    if (sortedVitaminsAndMinerals.length > 0) {
      if (sortedNutrients.length % 2) {
        result.typical_analysis.push({
          itemName: ' ',
          itemValue: ' ',
        })
      }

      result.typical_analysis.push({
        itemName: '\n' + t('label_settings.vitamins_and_minerals'),
        itemValue: ' ',
      });
    }

    result.typical_analysis.push(...sortedVitaminsAndMinerals);

    return result;
  };

  processNutrients = (nutrientData, hiddenNutrients, energy) => {
    let vitaminsAndMinerals = [];

    let groups = ['Влага', 'Метаболитна енергия'];
    let elementsAsNutrients = ['Калций', 'Фосфор'];

    let nutrients = [
      { name: 'Влага', value: [{ itemName: t('label_settings.moisture'), itemValue: "12.0" }] },
      { name: 'Метаболитна енергия', value: energy }
    ];

    if (nutrientData.length) {
      const vitaminMineralRegex = new RegExp('витамин|минерал', 'i');

      nutrientData.filter(
        nutrient => (!nutrient || !nutrient.name || !nutrient.percent) ? false : nutrient
      ).forEach(nutrient => {
        let nutrientItems = []

        if (hiddenNutrients.indexOf(nutrient.id) < 0) nutrientItems.push(
          this.createNutrientItem(nutrient)
        );

        nutrient.nutrientGroups.forEach(nutrientGroup => {
          if (hiddenNutrients.indexOf(nutrientGroup.id) < 0) nutrientItems.push(
            this.createNutrientItem(nutrientGroup, ' '.repeat(3))
          );

          nutrientGroup.nutrientElements.forEach(nutrientElement => {
            if (hiddenNutrients.indexOf(nutrientElement.id) < 0) {
              let elementAsNutrient = elementsAsNutrients.find(element => nutrientElement.name.includes(element));
              if (elementAsNutrient !== undefined){
                groups.push(elementAsNutrient);
                nutrients.push({ name: elementAsNutrient, value: [this.createNutrientItem(nutrientElement)] })
              } else {
                nutrientItems.push(this.createNutrientItem(nutrientElement, ' '.repeat(6)));
              }
            }
          });
        });

        if (nutrient.name.search(vitaminMineralRegex) >= 0) {
          vitaminsAndMinerals.push({ name: nutrient.name, value: nutrientItems });
        } else {
          nutrients.push({ name: nutrient.name, value: nutrientItems });
        }

        groups.push(nutrient.name);
      });
    }

    return {
      nutrients,
      vitaminsAndMinerals,
      groups
    };
  };

  createNutrientItem = (nutrient, padding = '') => {
    const { name, percent, uiValue = null, uiSubtotal = 0, unit = null } = nutrient;
    const nutrientPercentToUnit = percentToUnitObject(percent, this.allUnits, unit, uiValue, uiSubtotal);

    return name.includes("Витамини") !== true ? {
      itemName: `${padding}${name} [${nutrientPercentToUnit.unit}]`,
      itemValue: nutrientPercentToUnit.value,
    }
      : {
        itemName: padding + name,
        itemValue: ''
      };
  };

  getSelectedEnergy = energy => {
    let result = [];

    if (energy) {
      for (let energyMethod in energy) {
        for (let unit in energy[energyMethod]) {
          if (energy[energyMethod][unit].selected) {
            result.push({
              itemName: `${t('energy.metabolic_energy')} (${t('energy.calculated')})`,
              itemValue: energy[energyMethod][unit].value + ` ${unit}/kg`,
            });
          }
        };
      };
    }

    return result;
  };

  transformTasteData = taste => {
    if (taste && taste.picture) {
      return {
        tasteImageUrl: taste.picture,
        tasteText: t('label_settings.tastes_like'),
      };
    }

    return {};
  };

  saveLabelLinks = labelContent => {
    const { recipe: { id } } = this.props;
    const { frontLabelUrl, backLabelUrl, digitalLabelId } = this.state;

    const variables = {
      id,
      frontLabelUrl,
      backLabelUrl,
      digitalLabelId,
      labelContent,
      nutrients: this.props.record.nutrients
    };

    apolloClient.mutate({ mutation: SAVE_RECIPE_FIELDS_FOR_LABEL, variables: variables });
  };

  render = () => {
    const { classes } = this.props;
    const { frontLabelUrl, backLabelUrl, loading, digitalLabelId } = this.state;

    return (
      <Fragment>
        {
          <Button
            variant={loading ? 'flat' : 'raised'}
            size='medium'
            color={loading ? 'default' : 'primary'}
            onClick={this.handleClick.bind(this)}
            className={classes.button}
            label={loading ? null : t('label_settings.generate_label_button')}
            disabled={loading}
          >
            {loading ? <LinearProgress /> : <LabelIcon className={classes.buttonIcon} />}
          </Button>
        }
        {
          frontLabelUrl ?
            <Button
              variant='raised'
              color='default'
              size='medium'
              className={classes.button}
              onClick={() => window.open(frontLabelUrl, '_blank')}
              label={t('label_settings.button_link_front')}
            >
              <FrontLabelIcon className={classes.buttonIcon} />
            </Button>
            : null
        }
        {
          backLabelUrl ?
            <Button
              variant='raised'
              color='default'
              size='medium'
              className={classes.button}
              onClick={() => window.open(backLabelUrl, '_blank')}
              label={t('label_settings.button_link_back')}
            >
              <BackLabelIcon className={classes.buttonIcon} />
            </Button>
            : null
        }
        {
          digitalLabelId && !loading ?
            <Button
              variant='raised'
              color='default'
              size='medium'
              className={classes.button}
              component={Link}
              to={{ pathname: `/DigitalLabel/${digitalLabelId}` }}
              label={t('label_settings.digital_label')}
            >
              <DigitalLabelIcon className={classes.buttonIcon} />
            </Button>
            : null
        }
        <ConfirmationDialog
          isOpen={this.state.showDialog}
          handleAction={this.handleDialogConfirmClick}
          handleClose={this.handleDialogCloseClick}
          icon={<CheckCircle className={classes.buttonIcon} />}
          message={t('label_settings.confirm_overwrite_message')}
          label={t('label_settings.confirm_overwrite_label')}
          cancelLabel='ra.action.cancel'
        />
      </Fragment>
    );
  };
};

export default withStyles(styles)(GenerateLabelButtons);
