import React from 'react';
import {
  Show,
  SelectField,
  SimpleShowLayout,
  NumberField
} from 'react-admin';
import { t } from 'i18next';

import EditFormTitle from '../EditFormTitle';

export const EnergyRequirementDogShow = props => (
  <Show {...props} title={<EditFormTitle formName={t('energy.metabolic_energy')} />} >
    <SimpleShowLayout>
      <SelectField
        source='sizeClass'
        label={t('size_class')}
        choices={[
          { id: 'Mini', name: t('size_classes.mini') },
          { id: 'Small', name: t('size_classes.small') },
          { id: 'Medium', name: t('size_classes.medium') },
          { id: 'Large', name: t('size_classes.large') },
          { id: 'Giant', name: t('size_classes.giant') }
        ]}
      />
      <NumberField source='energy' label={t('energy_requirement_dog.energy')}/>
      <NumberField source='months' label={t('age_in_months')}/>
      <NumberField source='days' label={t('energy_requirement_dog.days')}/>
    </SimpleShowLayout>
  </Show>
);
