import React from 'react';
import { Edit } from 'react-admin';

import { t } from 'i18next';

import EditFormTitle from '../EditFormTitle';
import { CustomEditToolbar } from '../CustomEditToolbar';

import { UnitForm } from './UnitForm';

export const UnitEdit = props => (
  <Edit {...props} title={<EditFormTitle formName={t('unit')} />}>
    <UnitForm toolbar={<CustomEditToolbar user={props.options.currentUser.role}/>}/>
  </Edit>
);