import React from 'react';
import { Edit } from 'react-admin';
import { t } from 'i18next';

import EditFormTitle from '../EditFormTitle';
import { CustomEditToolbar } from '../CustomEditToolbar';
import { TasteForm } from './TasteForm';

export const TasteEdit = props => (
  <Edit {...props} title={<EditFormTitle formName={t('taste')} />}>
    <TasteForm toolbar={<CustomEditToolbar user={props.options.currentUser.role}/>}/>
  </Edit>
);