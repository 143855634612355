import React from 'react';
import { SimpleForm, SimpleFormIterator, ReferenceInput, SelectInput, ArrayInput } from 'react-admin';
import { t } from 'i18next';

import { isRequired } from '../../utils/validation';

const validateRequired = [isRequired()];

export const StatusesWorkflowForm = props => (
  <SimpleForm {...props} redirect='list'>
    <ReferenceInput reference='Status' source='statusFrom.id' label={t('statuses_workflow.status_from')} validate={validateRequired}>
      <SelectInput optionText='nameBg'/>
    </ReferenceInput>
    <ReferenceInput reference='Status' source='statusTo.id' label={t('statuses_workflow.status_to')} validate={validateRequired}>
      <SelectInput optionText='nameBg'/>
    </ReferenceInput>
    <ArrayInput source='emailTo' label={t('statuses_workflow.send_emails_to')}>
      <SimpleFormIterator>
        <SelectInput
          source='role'
          label={t('statuses_workflow.role')}
          choices={[
            { id: 'Admin', name: t('statuses_workflow.admin') },
            { id: 'ProductionManager', name: t('statuses_workflow.production_manager') },
            { id: 'Clinic', name: t('statuses_workflow.clinic') },
            { id: 'Vet', name: t('statuses_workflow.vet') },
            { id: 'Owner', name: t('statuses_workflow.owner') },
          ]}
          validate={validateRequired}
        />
        <ReferenceInput reference='EmailTemplate' source='template.id' label={t('statuses_workflow.template')} validate={validateRequired}>
          <SelectInput optionText='name'/>
        </ReferenceInput>
      </SimpleFormIterator>
    </ArrayInput>
  </SimpleForm>
);