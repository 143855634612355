import React from 'react';
import { Create } from 'react-admin';
import CustomCreateToolbar from '../CustomCreateToolbar';
import { t } from 'i18next';

import { UnitForm } from './UnitForm';

export const UnitCreate = props => (
  <Create {...props} title={t('units.title_create')}>
    <UnitForm toolbar={<CustomCreateToolbar user={props.options.currentUser.role}/>}/>
  </Create>
);