import React from 'react';
import { Create } from 'react-admin';
import { t } from 'i18next';

import CustomCreateToolbar from '../CustomCreateToolbar';
import { TasteForm } from './TasteForm';

export const TasteCreate = props => (
  <Create {...props} title={t('tastes.title_create')}>
    <TasteForm toolbar={<CustomCreateToolbar user={props.options.currentUser.role}/>}/>
  </Create>
);