import React, { Component, Fragment } from 'react';
import apolloClient from '../../initApollo';
import { t } from 'i18next';
import { REDUX_FORM_NAME } from 'react-admin';
import {
  TextField,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import {
  GET_ENERGY_REQUIREMENT_DOG,
  GET_EXCHANGE_WEIGHT_BY_WEIGHT
} from '../../customQueries';
import { getAgeInMonthsAndDays } from '../../utils/Helper';

class DailyDose extends Component {

  constructor(props) {
    super(props);

    this.state = {
      dailyDose: 0,
      daysFoodWillLast: 0,
      adjustment: props.adjustment || 1,
      exchangeWeight: null,
      energyNeed: null,
      energyValue: this.getEnergyValue(props.energy) || this.getEnergyValue(props.record.energy),
    };
  };

  componentDidMount = () => {
    this.getExchangeWeight().then(exchangeWeight => {
      if (!exchangeWeight) return null;

      this.getEnergyNeed().then(energyNeed => {
        if (!energyNeed) return null;

        const { adjustment } = this.state;

        this.setState({ energyNeed, exchangeWeight });
        this.updateValues(adjustment);
      });
    });
  };

  componentDidUpdate = () => {
    const newEnergyValue = this.getEnergyValue(this.props.energy);

    if (newEnergyValue && newEnergyValue !== this.state.energyValue) {
      const { adjustment } = this.state;

      this.setState({ energyValue: newEnergyValue }, () => { this.updateValues(adjustment) });
    }
  };

  getExchangeWeight = () => {
    const { sizeClass, animal } = this.props.order;
    let weight = null;
    const query = GET_EXCHANGE_WEIGHT_BY_WEIGHT(sizeClass) || null;

    if (!query) return Promise.resolve(null);
    if (animal) {
      weight = animal.weight;

      return apolloClient.query({ query, variables: { weight }, fetchPolicy: 'no-cache' })
        .then(response => {
          if (response.loading) return;
          let data = response.data[`exchangeWeight${sizeClass}s`];

          if(data && data.length > 0) {
            return data.pop().exchangeWeight;
          }

          return null;
        })
      }
  };

  getEnergyNeed = () => {
    const { sizeClass, animal, dateCreated, animalType } = this.props.order;

    if (animalType !== 'Dog') return null;

    const { birthDate } = animal;
    const { months, days } = getAgeInMonthsAndDays(birthDate, dateCreated);

    return apolloClient.query({ query: GET_ENERGY_REQUIREMENT_DOG, variables: { months, days, sizeClass }, fetchPolicy: 'no-cache' })
      .then(response => {
        if (response.loading) return;
        let data = response.data.energyRequirementDogs;

        if (data && data.length > 0) {
          return data.pop().energy;
        }

        return null;
      });
  };

  handleAdjustmentChange = e => {
    this.updateValues(e.target.value);
  };

  updateValues = (adjustment) => {
    const { exchangeWeight, energyNeed, energyValue } = this.state;
    if (!energyValue) return;

    const dailyDose = this.calculateDose(adjustment, exchangeWeight, energyNeed, energyValue);
    const daysFoodWillLast = this.calculateDays(dailyDose);

    this.setState({
      adjustment,
      dailyDose,
      daysFoodWillLast,
    }, () => this.storeData());
  };

  calculateDose = (adjustment, exchangeWeight, energyNeed, energyValue) => {
    if (!exchangeWeight || !energyNeed || !energyValue) return 0;

    const doseInGrams = (exchangeWeight * energyNeed / energyValue) * 1000;

    return Math.ceil(doseInGrams * adjustment);
  };

  calculateDays = (dailyDose) => {
    if (!dailyDose) return 0;

    const { order: { weight, quantity } } = this.props;

    return Math.floor((weight * quantity * 1000) / dailyDose);
  };

  getEnergyValue = (energy) => {
    if (!energy) return null;

    for (let energyMethod in energy) {
      for (let unit in energy[energyMethod]) {
        if (energy[energyMethod][unit].selected) {
          return energy[energyMethod].kcal.value;
        }
      };
    };

    return null;
  };

  storeData = () => {
    this.props.change(REDUX_FORM_NAME, 'dailyDose', this.state.dailyDose);
    this.props.change(REDUX_FORM_NAME, 'dailyDoseAdjustment', parseFloat(this.state.adjustment));
  };

  render = () => {
    const { energyValue } = this.state;
    if (!energyValue) return null;

    const { dailyDose, daysFoodWillLast, adjustment } = this.state;

    return (
      <Fragment>
        <h1 variant='headline'>
          {t('daily_dose.title')}
        </h1>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                {t('daily_dose.dose_value')}
              </TableCell>
              <TableCell>
                <TextField
                  value={dailyDose}
                  disabled
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {t('daily_dose.days_will_last')}
              </TableCell>
              <TableCell>
                <TextField
                  value={daysFoodWillLast}
                  disabled
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {t('daily_dose.adjustment_coefficient')}
              </TableCell>
              <TableCell>
                <TextField
                  value={adjustment}
                  type='number'
                  inputProps={{ min: '0', step: '0.1' }}
                  onChange={this.handleAdjustmentChange.bind(this)}
                  disabled={this.props.user && this.props.user !== 'Admin'}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Fragment>
    );
  };
}

export { DailyDose };
