import React from 'react';
import { t } from 'i18next';

import CustomSearchableAsyncSelect from './CustomSearchableAsyncSelect';
import apolloClient from '../initApollo';
import { changeElementIndex } from '../utils/Helper';
import { GET_FILTERED_CITIES } from "../customQueries";

import { isRequired } from '../utils/validation';
const validateRequired = [isRequired()];

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};
const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};

const moveOwnerMainCityOnTop = (options, parentId, parentIdName) => {
  const element = options.filter((option, index) =>
    option[parentIdName] && (option[parentIdName].includes(parentId))
  );

  if (Array.isArray(element) && element.length === 0) {
    return options;
  }

  return changeElementIndex(options, ...element, 0)
};

const getLocationsData = (inputValue) => {
  const variables = {name: inputValue};

  return apolloClient.query({ query: GET_FILTERED_CITIES, variables: variables, fetchPolicy: 'no-cache' })
    .then(response => {
      if (response.loading) return;

      return processData(response.data.cities);
    })
};

const getTranslatedLocationType = (locType) => {
  const locTypes = {
    City: t('city_select'),
    Village: t('village_select'),
  };

  if (locTypes.hasOwnProperty(locType)) {
    return locTypes[locType];
  }
  return '';
};

const processData = (locations) => {
  return locations.reduce((locationsArr, location) => {
    let upperLocations = t('province_select') + ' ' + location.municipality.province.name + ', ' + t('municipality_select') + ' ' + location.municipality.name;

    return locationsArr.concat({
      label: upperLocations,
      options: [{ id: location.id, name: getTranslatedLocationType(location.type) + ' ' + location.name }]
    });
    
  }, []);
};

const formatGroupLabel = data => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

const LocationSelect = props => {
  let { choices, parentId, parentIdName, isRequired } = props;

  if (props.hasOwnProperty('parentId')) {
    choices = moveOwnerMainCityOnTop(choices, parentId, parentIdName);
  }

  //choices > 1, because if we use >= on edit page we will see only one result for select options -> the first query on edit page is city->id_in
  return ( choices.length > 1 &&
    <React.Fragment>
      <CustomSearchableAsyncSelect
        isClearable
        initialOptions={choices}
        getOptionLabel={(option)=>option.name}
        getOptionValue={(option)=>option.id}
        getFilteredData={getLocationsData}
        formatGroupLabel={formatGroupLabel}
        placeholder={t('city_search_input_field')}
        validate={isRequired ? validateRequired : null}
        name={props.input.name}
        source={props.source}
        obj="City"
      />
    </React.Fragment>
  );
};

export default LocationSelect;
