import React, { Fragment } from 'react';
import { change } from 'redux-form';
import {
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceArrayInput,
  ArrayInput,
  SimpleFormIterator,
  FormDataConsumer,
  SelectInput,
  ReferenceInput,
  REDUX_FORM_NAME
} from 'react-admin';
import { isEmpty } from 'lodash';
import { t } from 'i18next';
import { withStyles } from '@material-ui/core/styles';

import { isRequired } from '../../utils/validation';

import { GET_UNITS, GET_UI_VALUES } from '../../customQueries';
import client from '../../initApollo';
import CustomSearchableMultiSelect from "./../CustomSearchableMultiSelect";

const styles = {
  unit: {
    display: "block",
    "& div": {width: "100%"}
  },
};

const validateRequired = [isRequired()];
const unitSources = ["percent", "mg", "μg", "UI", "ppm"];

class MaterialFormComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      units: null,
      uiValues: null,
    };
  };

  componentDidMount = () => {
    client.query({ query: GET_UNITS}).then(response => {
      if (response.loading) return;

      this.setState({units: response.data.units});
    });

    client.query({ query: GET_UI_VALUES}).then(response => {
      let uiValues = [];
      uiValues.push(...response.data.nutrientElements, ...response.data.nutrientGroups);

      this.setState({ uiValues });
    });
  };

  handleUnitChange = (value, sourcePrefix, changedInput, dispatch, nutrientItem) => {
    const { units } = this.state;
    const inputs = unitSources.filter(input => input !== changedInput);
    const nutrientItemId = nutrientItem.nutrientElementId || nutrientItem.nutrientGroupId;
    const uiValueRecord = this.state.uiValues.find(item => item.id === nutrientItemId);
    const uiValue = uiValueRecord ? uiValueRecord.uiValue : null;

    inputs.forEach(input => {
      let newValue;

      // calculate the unit value based on the changed percent value
      if (changedInput === 'percent') {
        if (input === 'UI') {
          newValue = uiValue ? value * 10000 * uiValue : 0;
        } else {
          const valueCoefficient = units.find(unit => input === unit.name).coefficientPerKilogram;
          newValue = (value/100) * valueCoefficient;
        }

        // calculate the percent value based on the changed unit value
      } else if (input === 'percent') {
        if (changedInput === 'UI') {
          newValue = uiValue ? value / (10000 * uiValue) : 0;
        } else {
          const inputCoefficient = units.find(unit => changedInput === unit.name).coefficientPerKilogram;
          newValue = (value*100) / inputCoefficient;
        }

      // calculate the unit value based on the changed unit value
      } else {
        const valueCoefficient = units.find(unit => input === unit.name).coefficientPerKilogram;
        const inputCoefficient = units.find(unit => changedInput === unit.name).coefficientPerKilogram;

        if (changedInput === 'UI') {
          newValue = uiValue ? (value * valueCoefficient) / (1000000 * uiValue) : 0;
        } else if (input === 'UI') {
          newValue = uiValue ? (value * 1000000 * uiValue) / inputCoefficient : 0;
        } else {
          newValue = (value*valueCoefficient) / inputCoefficient;
        }
      }

      dispatch(change(REDUX_FORM_NAME, `${sourcePrefix}.${input}`, newValue));
    });
  };

  getNutrientsFilter = (index, formData, name) => {
    if(!isEmpty(formData) && !isEmpty(formData['nutrients']) && !isEmpty(formData['nutrients'][index])) {
      const nutrientData = formData['nutrients'][index];
      return {[name]: {id: nutrientData[name + 'Id']}};
    }

    return null;
  };

  render = () => {
    if (!this.state.units) return null;
    const { classes, ...rest } = this.props;

    return (
      <SimpleForm {...rest} redirect="list">
        <TextInput source="name" label={t("name")} validate={validateRequired}/>
        <NumberInput source="moisture" label={t("material_details.moisture_percent")} validate={validateRequired}/>
        <NumberInput source="price" label={t("material_details.price_unit")} validate={validateRequired}/>
        <ReferenceArrayInput label={t("ingredients")} reference="Ingredient" source="ingredientsIds" perPage={1000}>
          <CustomSearchableMultiSelect obj="Ingredient"/>
        </ReferenceArrayInput>
        <ArrayInput label={t("material_details.nutrient_content")} source="nutrients" className="horizontal-form">
          <SimpleFormIterator>
            <FormDataConsumer>
              {({ id, formData, getSource, dispatch, className }) => {
                const index = id.match(/\d+/) ? id.match(/\d+/)[0] : 0;

                // add width: 264 to each select input to prevent going back up when selecting nutrient
                return (
                  <Fragment>
                    <div>
                      <ReferenceInput
                        className={className}
                        reference='Nutrient'
                        label={t('nutrient')}
                        resource='Nutrient'
                        source={getSource('nutrientId')}
                        onChange={() => {
                            formData.nutrients[index].nutrientGroupId = null;
                            formData.nutrients[index].nutrientElementId = null
                        }}
                      >
                        <SelectInput optionText='name' optionValue='id' style={{width: 264}}/>
                      </ReferenceInput>
                    </div>
                    <div>
                      <ReferenceInput
                        key={formData.nutrients && formData.nutrients[index] && formData.nutrients[index].nutrientId}
                        className={className}
                        label={t('nutrient_group')}
                        resource='NutrientGroup'
                        reference='NutrientGroup'
                        source={getSource('nutrientGroupId')}
                        onChange={() => formData.nutrients[index].nutrientElementId = null}
                        filter={this.getNutrientsFilter(index, formData, 'nutrient')}
                      >
                        <SelectInput optionText='name' optionValue='id' style={{width: 264}}/>
                      </ReferenceInput>
                    </div>
                    <div>
                      <ReferenceInput
                        key={formData.nutrients && formData.nutrients[index] && formData.nutrients[index].nutrientGroupId}
                        className={className}
                        label={t('nutrient_element')}
                        resource='NutrientElement'
                        reference='NutrientElement'
                        source={getSource('nutrientElementId')}
                        perPage={100}
                        filter={this.getNutrientsFilter(index, formData, 'nutrientGroup')}
                      >
                        <SelectInput optionText='name' optionValue='id' style={{width: 264}}/>
                      </ReferenceInput>
                    </div>
                    {unitSources.map((sourceName, unitIndex) => (
                      <NumberInput key={unitIndex}
                        className={classes.unit}
                        source={getSource(sourceName)}
                        label={t(`material_details.${sourceName}`)}
                        onChange={(el, value) => this.handleUnitChange(value, id, sourceName, dispatch, formData.nutrients[index])}
                      />
                    ))}
                  </Fragment>
                )
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    );
  };
}

export const MaterialForm = withStyles(styles)(MaterialFormComponent);
