import React from 'react';
import { Edit } from 'react-admin';
import { t } from 'i18next';

import { CustomEditToolbar } from '../CustomEditToolbar';
import { ConsultingFeePercentageVetForm } from './ConsultingFeePercentageVetForm';

export const ConsultingFeePercentageVetEdit = props => (
  <Edit {...props} title={t('consulting_fee_details.consulting_fee_percentage_vet')}>
    <ConsultingFeePercentageVetForm toolbar={<CustomEditToolbar user={props.options.currentUser.role}/>}/>
  </Edit>
);