import React, {Component} from 'react';
import {GET_ORDERS_BY_VET, GET_ORDERS_BY_VET_USER} from '../../customQueries';
import apolloClient from '../../initApollo';
import {t} from 'i18next';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import {Link} from 'react-admin';
import {formatDate} from '../../utils/Helper';

class VetConsultingFee extends Component {
  constructor(props) {
    super(props);

    this.state = {
      orders: [],
      consultingFees: []
    }
  }

  componentWillMount() {

    const query = this.props.user && this.props.user.role === 'Vet' ? GET_ORDERS_BY_VET_USER : GET_ORDERS_BY_VET;

    apolloClient.query({
      query: query,
      variables: {vetId: this.props.record.id},
      fetchPolicy: 'no-cache'
    }).then(response => {
      this.setState({orders: response.data.orders}, () => this.calculate())
    });
  }

  calculate = () => {
    let ordersData = [];
    this.state.orders && this.state.orders.forEach(order => {
      if (order.startOfWeek) {
        if (order.startOfWeek in ordersData) {
          ordersData[order.startOfWeek].push(order)
        } else {
          ordersData[order.startOfWeek] = [order];
        }
      }
    });

    const isVetUser = this.props.user && this.props.user.role === 'Vet'

    let consultingFees = [];
    Object.entries(ordersData).forEach(([startOfWeek, weekData]) => {
      if (!(startOfWeek in consultingFees)) consultingFees[startOfWeek] = [];

      consultingFees[startOfWeek].startOfWeek = startOfWeek;
      consultingFees[startOfWeek].weeklyTurnoverVet = 0;
      consultingFees[startOfWeek].orders = [];
      consultingFees[startOfWeek].consultingFeeVet = weekData[0].consultingFeeVet || 0;
      consultingFees[startOfWeek].consultingPercentageVet = weekData[0].consultingPercentageVet || 0;

      if (!isVetUser) {
        consultingFees[startOfWeek].consultingPercentageClinic = weekData[0].consultingPercentageClinic || 0;
      }

      weekData.forEach(data => {
        consultingFees[startOfWeek].weekNumber = data.weekNumber;
        consultingFees[startOfWeek].weeklyTurnoverVet += data.price;
        consultingFees[startOfWeek].orders.push({id: data.id, idNumber: data.idNumber, price: data.price});
      });

      if (!isVetUser) {
        consultingFees[startOfWeek].partialConsultingFeeClinic = consultingFees[startOfWeek].weeklyTurnoverVet -
          (consultingFees[startOfWeek].weeklyTurnoverVet / (1 + consultingFees[startOfWeek].consultingPercentageClinic / 100))
      }
    });

    this.setState({consultingFees})
  };

  render() {
    const isVetUser = this.props.user && this.props.user.role === 'Vet'

    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('consulting_fee_details.week_number')}</TableCell>
            <TableCell>{t('consulting_fee_details.weekly_turnover')}</TableCell>

            {!isVetUser && <TableCell>{t('consulting_fee_details.consulting_fee_clinic_percentage')}</TableCell>}
            {!isVetUser && <TableCell>{t('consulting_fee_details.consulting_fee_clinic_partial')}</TableCell>}
            {!isVetUser && <TableCell>{t('consulting_fee_details.consulting_fee_percentage')}</TableCell>}

            <TableCell>{t('consulting_fee_details.consulting_fee')}</TableCell>
            <TableCell>{t('orders')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            Object.values(this.state.consultingFees).map(consultingFee => {
              return (
                <TableRow key={consultingFee.startOfWeek}>
                  <TableCell>#{consultingFee.weekNumber} {t('from')} {formatDate(consultingFee.startOfWeek)}</TableCell>
                  <TableCell>{consultingFee.weeklyTurnoverVet.toFixed(2)}</TableCell>

                  {!isVetUser && <TableCell>{consultingFee.consultingPercentageClinic.toFixed(1)}%</TableCell>}
                  {!isVetUser && <TableCell>{consultingFee.partialConsultingFeeClinic.toFixed(2)} лв</TableCell>}
                  {!isVetUser && <TableCell>{consultingFee.consultingPercentageVet.toFixed(1)}%</TableCell>}

                  <TableCell>{consultingFee.consultingFeeVet.toFixed(2)} лв</TableCell>
                  <TableCell>
                    {consultingFee.orders.map(order => {
                      return <Chip key={order.id} label={order.idNumber + ' (' + order.price + ' лв.)'} component={Link}
                                   to={{pathname: `/Order/${order.id}/show`}} clickable/>
                    })}
                  </TableCell>
                </TableRow>
              );
            })
          }
        </TableBody>
      </Table>
    );
  }
}

export default VetConsultingFee;