import { TurnedIn } from '@material-ui/icons';
import { ProductGroupCreate } from '../components/ProductGroup/ProductGroupCreate';
import { ProductGroupEdit } from '../components/ProductGroup/ProductGroupEdit';
import { ProductGroupList } from '../components/ProductGroup/ProductGroupList';

export const ProductGroups = {
  icon: TurnedIn,
  create: ProductGroupCreate,
  edit: ProductGroupEdit,
  list: ProductGroupList,
};
