import React, { Component } from 'react';
import { t } from 'i18next';

import CustomSearchableAsyncSelect from './CustomSearchableAsyncSelect';
import apolloClient from '../initApollo';
import { GET_FILTERED_MATERIALS, GET_PRODUCT_GROUPS, GET_MATERIALS } from "../customQueries";
import { isEmpty } from 'lodash';

import { isRequired } from '../utils/validation';

const validateRequired = [isRequired()];

class MaterialSelect extends Component {

  constructor(props) {
    super(props);

    this.state = {
      groupedMaterials: []
    };
  }

  componentDidMount() {
    apolloClient.query({ query: GET_MATERIALS })
      .then(response => {
        if (response.loading) return;

        this.getGroupedMaterials(response.data.materials);
      });
  }

  getFilteredMaterials = (inputValue) => {
    const variables = { name: inputValue };

    return apolloClient.query({ query: GET_FILTERED_MATERIALS, variables: variables })
      .then(response => {
        if (response.loading) return;

        return this.getGroupedMaterials(response.data.materials);
      });
  };

  getGroupedMaterials = (materials) => {
    return apolloClient.query({ query: GET_PRODUCT_GROUPS })
      .then(response => {
        if (response.loading) return;

        let mixes = { label: t('material_details.mix'), options: [] };
        let groupedMaterials = [];

        response.data.productGroups.forEach((productGroup) => {
          groupedMaterials.push({ id: productGroup.id, label: productGroup.name, options: [] });
        });

        materials.forEach((material) => {
          if (material.ingredients.length > 1) {
            mixes.options.push(material);
          }

          if (material.ingredients.length === 1) {
            groupedMaterials.forEach((groupedMaterial) => {
              if (groupedMaterial.id === material.ingredients[0].productGroup.id) {
                groupedMaterial.options.push(material);
              }
            })
          }
        });

        groupedMaterials = [mixes].concat(groupedMaterials);
        groupedMaterials.forEach((groupedMaterial) => {
          groupedMaterial.options.sort((element, next) => element.name.localeCompare(next.name));
        });

        this.setState({ groupedMaterials: groupedMaterials });

        return groupedMaterials;
      });
  };

  getInitialValue = (value, materials) => {
    let initialValue = null;
    materials.forEach((material) => {
      if (material.id === value) {
        initialValue = material;
      }
    });

    return initialValue;
  };

  render() {
    let { choices, input, isDisabled } = this.props;

    const initialValue = isEmpty(input.value) ? null : this.getInitialValue(input.value, choices);

    return (
      this.state.groupedMaterials.length !== 0 &&
      <React.Fragment>
        <CustomSearchableAsyncSelect
          materialWidth={480}
          isDisabled={isDisabled}
          isClearable
          initialValue={initialValue}
          initialOptions={this.state.groupedMaterials}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          getFilteredData={this.getFilteredMaterials}
          validate={validateRequired}
          placeholder={t('select')}
          name={this.props.input.name}
          source={this.props.source}
          data={this.props.data}
          obj="Material"
        />
      </React.Fragment>
    );
  };
}

export { MaterialSelect };
