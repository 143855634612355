import {
  GET_TOTAL_DATA,
  GET_TOTAL_NODES_COUNT,
  GET_TOTAL_ORDERS_BY_BREED,
  GET_TOTAL_REVENUE,
  GET_TOTAL_NEW_CLIENTS,
  GET_TOTAL_ORDERS_PREV_AND_CUR_MONTH,
  GET_TOTAL_CLINIC_ORDERS,
  GET_TOTAL_REVENUE_PREV_AND_CUR_MONTH,
  GET_ORDER_COUNTS,
  GET_ANIMAL_DATA,
  GET_PRODUCTION,
  GET_MATERIAL_QUANTITY,
  GET_TOTAL_ORDERS_DATA
} from '../customQueries';
import apolloClient from '../initApollo';
import { t } from 'i18next';

// Get first and last days of the current month
const getMonthStartAndEnd = (date) => {
  date = date || new Date();
  const y = date.getFullYear();
  const m = date.getMonth();
  const minDate = new Date(y, m, 1);
  let maxDate = new Date(y, m + 1, 0);
  maxDate.setHours(23, 59, 59, 999);

  return { minDate, maxDate };
}

// Get difference between 2 dates in months
const getMonthDatesDiff = (d2, d1) => {
  // set dates to 1st day of the month
  const startDate = new Date(d1.setDate(1));
  // set dates to last day of the month
  const endDate = new Date(d2.getFullYear(), d2.getMonth() + 1, 0);
  let diff = (endDate.getTime() - startDate.getTime()) / 1000;
  diff /= (60 * 60 * 24 * 7 * 4);
  let result = Math.abs(Math.round(diff));

  if (result === 0) return 1;

  return result;
}

const getPercentageDiff = (avgVal, curVal) => {
  if (curVal === 0 && avgVal === 0) {
    return 0;
  }

  if (curVal === 0) {
    return -100;
  }

  if (avgVal === 0) {
    return 100;
  }

  return (curVal / avgVal * 100 - 100).toFixed(2);
}

export const calculateTotalOrdersPerMonth = async => {
  const dates = getMonthStartAndEnd();
  const minDate = dates.minDate;
  const maxDate = dates.maxDate;

  const res = apolloClient.query({ query: GET_TOTAL_ORDERS_DATA, fetchPolicy: 'no-cache' }).then(response => {
    if (response.loading) return { primaryValue: '', secondaryValue: [] };

    const orders = response.data.orders;
    const totalCount = orders.length;
    const firstOrderDate = new Date(orders[0].dateCreated);
    const monthsFromStart = getMonthDatesDiff(new Date(), new Date(firstOrderDate));
    const avgPerMonth = totalCount / monthsFromStart;
    let countCurMonth = 0;

    for (const order of orders) {
      if (new Date(order.dateCreated) >= minDate && new Date(order.dateCreated) <= maxDate) {
        countCurMonth++
      }
    }

    const percDiff = getPercentageDiff(avgPerMonth, countCurMonth);
    const secondaryValue = [
      `${percDiff}% ${t('statistics.this_month')}`,
      `(${t('statistics.based_on_avg')})`
    ];

    return { primaryValue: totalCount, secondaryValue }
  });

  return res;
}

export const calculateTotalsPerMonth = async (type) => {
  let query;
  const dates = getMonthStartAndEnd();
  const minDate = dates.minDate;
  const maxDate = dates.maxDate;

  const variables = {
    minDate,
    maxDate,
    type,
  };

  switch (type) {
    case 'Owner':
      query = GET_TOTAL_NODES_COUNT('owners');
      break;
    case 'Vet':
      query = GET_TOTAL_NODES_COUNT('vets');
      break;
    default:
      break;
  }

  const count = await apolloClient.query({ query, variables, fetchPolicy: 'no-cache' }).then(response => {
    if (response.loading) return 0;

    return response.data.entities.length;
  });

  const res = await apolloClient.query({ query: GET_TOTAL_DATA, variables, fetchPolicy: 'no-cache' }).then(response => {
    if (response.loading) return { primaryValue: '', secondaryValue: [] };

    const curMonthData = response.data.historyData;
    const data = response.data.firstDate.pop();
    const firstDate = data && data.date ? data.date : new Date();
    let curMonthCount = 0;

    for (const d of curMonthData) {
      if (Object.entries(d.oldData).length === 0 && d.oldData.constructor === Object) {
        curMonthCount++;
      }
    }

    const allMonthsCount = getMonthDatesDiff(new Date(), new Date(firstDate));
    const avgPerMonth = count / allMonthsCount;
    const percentageDiff = getPercentageDiff(avgPerMonth, curMonthCount);
    const secondaryValue = [
      `${percentageDiff}% ${t('statistics.this_month')}`,
      `(${t('statistics.based_on_avg')})`
    ];

    return {
      primaryValue: count,
      secondaryValue
    }
  });

  return res;
}

export const calculateTotalRevenueStatistics = async () => {
  const dates = getMonthStartAndEnd();
  const variables = {
    minDate: dates.minDate,
    maxDate: dates.maxDate,
  };
  const res = await apolloClient.query({ query: GET_TOTAL_REVENUE, variables, fetchPolicy: 'no-cache' }).then(response => {
    if (response.loading) return { primaryValue: '', secondaryValue: [] };

    let totalRevenue = 0;
    let totalRevenueCurMonth = 0;
    const allPrices = response.data.allPrices;
    const data = response.data.firstOrderDate.pop();
    const firstOrderDate = data.dateCreated;
    const allPricesCurMonth = response.data.allPricesCurMonth;
    const monthsCount = getMonthDatesDiff(new Date(), new Date(firstOrderDate));

    for (const p of allPrices) {
      totalRevenue += p.price;
    }

    for (const p of allPricesCurMonth) {
      totalRevenueCurMonth += p.price;
    }

    const avgRevenuePerMonth = totalRevenue / monthsCount;
    const percentageDiff = getPercentageDiff(avgRevenuePerMonth, totalRevenueCurMonth);
    const secondaryValue = [
      `${percentageDiff}% ${t('statistics.this_month')}`,
      `(${t('statistics.based_on_avg')})`
    ];

    return { primaryValue: totalRevenue.toFixed(2), secondaryValue }
  });

  return res;
}

export const calculateTotalOrdersByBreed = async () => {
  const dates = getMonthStartAndEnd();
  const variables = {
    minDate: dates.minDate,
    maxDate: dates.maxDate,
  };
  const res = await apolloClient.query({ query: GET_TOTAL_ORDERS_BY_BREED, variables, fetchPolicy: 'no-cache' }).then(response => {
    if (response.loading) return { primaryValue: '', secondaryValue: [] };

    const allOrdersCount = response.data.allOrders.length;
    const data = response.data.firstOrderDate.pop();
    const firstOrderDate = data && data.dateCreated ? data.dateCreated : new Date();
    const ordersCurMonth = response.data.ordersCurMonth.length;
    const monthsCount = getMonthDatesDiff(new Date(), new Date(firstOrderDate));
    const avgOrdersPerMonth = allOrdersCount / monthsCount;
    const percentageDiff = getPercentageDiff(avgOrdersPerMonth, ordersCurMonth);
    const secondaryValue = [
      `${percentageDiff}% ${t('statistics.this_month')}`,
      `(${t('statistics.based_on_avg')})`
    ];

    return { primaryValue: allOrdersCount, secondaryValue };
  });

  return res;
};

export const calculateNewOwnersCurrentMonth = async () => {
  const dates = getMonthStartAndEnd();
  const prevDates = getMonthStartAndEnd(new Date((new Date()).setMonth((new Date()).getMonth() - 1)));
  const variables = {
    minDateCur: dates.minDate,
    maxDateCur: dates.maxDate,
    minDatePrev: prevDates.minDate,
    maxDatePrev: prevDates.maxDate,
  };

  const res = await apolloClient.query({ query: GET_TOTAL_NEW_CLIENTS, variables, fetchPolicy: 'no-cache' }).then(response => {
    if (response.loading) return { primaryValue: '', secondaryValue: [] };

    const allCurMonth = response.data.allCurMonth;
    const allPrevMonth = response.data.allPrevMonth;
    let ownersCurMonth = 0;
    let ownersPrevMonth = 0;

    for (const o of allCurMonth) {
      if (Object.entries(o.oldData).length === 0 && o.oldData.constructor === Object) {
        ownersCurMonth++;
      }
    }

    for (const o of allPrevMonth) {
      if (Object.entries(o.oldData).length === 0 && o.oldData.constructor === Object) {
        ownersPrevMonth++;
      }
    }

    const percentageDiff = getPercentageDiff(ownersPrevMonth, ownersCurMonth);
    const secondaryValue = [
      `${percentageDiff}% ${t('statistics.this_month')}`,
      `(${t('statistics.based_on_last_mt')})`
    ];

    return { primaryValue: ownersCurMonth, secondaryValue }
  });

  return res;
};

export const calculateTotalOrdersPrevMonthStatistics = async () => {
  const dates = getMonthStartAndEnd();
  const prevDates = getMonthStartAndEnd(new Date((new Date()).setMonth((new Date()).getMonth() - 1)));
  const variables = {
    minDateCur: dates.minDate,
    maxDateCur: dates.maxDate,
    minDatePrev: prevDates.minDate,
    maxDatePrev: prevDates.maxDate,
  };
  const res = await apolloClient.query({ query: GET_TOTAL_ORDERS_PREV_AND_CUR_MONTH, variables, fetchPolicy: 'no-cache' }).then(response => {
    if (response.loading) return { primaryValue: '', secondaryValue: [] };

    const totalOrdersPrevMonth = response.data.prevMonthOrderCount.length;
    const totalOrdersCurMonth = response.data.curMonthOrderCount.length;
    const percentageDiff = getPercentageDiff(totalOrdersPrevMonth, totalOrdersCurMonth);
    const secondaryValue = [
      `${percentageDiff}% ${t('statistics.this_month')}`,
      `(${t('statistics.based_on_last_mt')})`
    ];

    return { primaryValue: totalOrdersPrevMonth, secondaryValue };
  });

  return res;
};

export const calculateTotalClinicOrdersStatistics = async () => {
  const dates = getMonthStartAndEnd();
  const variables = {
    minDate: dates.minDate,
    maxDate: dates.maxDate,
  };
  const res = await apolloClient.query({ query: GET_TOTAL_CLINIC_ORDERS, variables, fetchPolicy: 'no-cache' }).then(response => {
    if (response.loading) return { primaryValue: '', secondaryValue: [] };

    const totalOrders = response.data.totalOrders.length;
    const data = response.data.firstOrderDate.pop();
    const firstOrderDate = data.dateCreated;
    const totalOrdersCurMonth = response.data.totalOrdersCurMonth.length;
    const monthsCount = getMonthDatesDiff(new Date(), new Date(firstOrderDate));
    const avgOrdersPerMonth = totalOrders / monthsCount;
    const percentageDiff = getPercentageDiff(avgOrdersPerMonth, totalOrdersCurMonth);
    const secondaryValue = [
      `${percentageDiff}% ${t('statistics.this_month')}`,
      `(${t('statistics.based_on_avg')})`
    ];

    return { primaryValue: totalOrders, secondaryValue };
  });

  return res;
}

export const calculatePrevMonthRevenueStatistics = async () => {
  const dates = getMonthStartAndEnd();
  const prevDates = getMonthStartAndEnd(new Date((new Date()).setMonth((new Date()).getMonth() - 1)));
  const variables = {
    minDateCur: dates.minDate,
    maxDateCur: dates.maxDate,
    minDatePrev: prevDates.minDate,
    maxDatePrev: prevDates.maxDate,
  };
  const res = await apolloClient.query({ query: GET_TOTAL_REVENUE_PREV_AND_CUR_MONTH, variables, fetchPolicy: 'no-cache' }).then(response => {
    if (response.loading) return { primaryValue: '', secondaryValue: [] };

    const prevMonthPrices = response.data.prevMonthPrices;
    const curMonthPrices = response.data.curMonthPrices;
    let prevMonthRevenue = 0;
    let curMonthRevenue = 0;

    for (const p of prevMonthPrices) {
      prevMonthRevenue += p.price;
    }

    for (const p of curMonthPrices) {
      curMonthRevenue += p.price;
    }

    const percentageDiff = getPercentageDiff(prevMonthRevenue, curMonthRevenue);
    const secondaryValue = [
      `${percentageDiff}% ${t('statistics.this_month')}`,
      `(${t('statistics.based_on_last_mt')})`
    ];

    return { primaryValue: prevMonthRevenue, secondaryValue };
  });

  return res;
}

export const calculateRevenueByAnimalTypeStatistics = async () => {
  const dates = getMonthStartAndEnd();
  const prevDates = getMonthStartAndEnd(new Date((new Date()).setMonth((new Date()).getMonth() - 1)));
  const variables = {
    minDateCur: dates.minDate,
    maxDateCur: dates.maxDate,
    minDatePrev: prevDates.minDate,
    maxDatePrev: prevDates.maxDate,
  };

  const res = await apolloClient.query({ query: GET_ORDER_COUNTS, variables, fetchPolicy: 'no-cache' }).then(response => {
    if (response.loading) return { primaryValue: '', secondaryValue: [] };

    const allDogOrders = response.data.allDogOrders.length;
    const allCatOrders = response.data.allCatOrders.length;
    const allDogOrdersCurMonth = response.data.allDogOrdersCurMonth.length;
    const allCatOrdersCurMonth = response.data.allCatOrdersCurMonth.length;
    const allDogOrdersPrevMonth = response.data.allDogOrdersPrevMonth.length;
    const allCatOrdersPrevMonth = response.data.allCatOrdersPrevMonth.length;

    const percentageDiffDog = getPercentageDiff(allDogOrdersPrevMonth, allDogOrdersCurMonth);
    const percentageDiffCat = getPercentageDiff(allCatOrdersPrevMonth, allCatOrdersCurMonth);

    const secondaryValue = [
      `${percentageDiffDog}% / ${percentageDiffCat}% ${t('statistics.this_month')}`,
      `(${t('statistics.based_on_last_mt')})`
    ]

    return { primaryValue: `${allDogOrders} / ${allCatOrders}`, secondaryValue };
  });

  return res;
}

export const calculateRevenueDogCatStatistics = async () => {
  const dates = getMonthStartAndEnd();
  const prevDates = getMonthStartAndEnd(new Date((new Date()).setMonth((new Date()).getMonth() - 1)));
  const curMonthStart = dates.minDate;
  const curMonthEnd = dates.maxDate;
  const prevMonthStart = prevDates.minDate;
  const prevMonthEnd = prevDates.maxDate;

  const res = await apolloClient.query({ query: GET_ANIMAL_DATA, fetchPolicy: 'no-cache' }).then(response => {
    if (response.loading) return { primaryValue: '', secondaryValue: [] };

    const orders = response.data.orders;
    let revDog = 0;
    let revCat = 0;
    let revDogCurMonth = 0;
    let revCatCurMonth = 0;
    let revDogPrevMonth = 0;
    let revCatPrevMonth = 0;

    for (const order of orders) {
      const date = new Date(order.dateCreated);

      if (order.animalType === 'Dog') {
        const revs = calcRevenue(revDogCurMonth, revDogPrevMonth, date, order.price, curMonthStart, curMonthEnd, prevMonthStart, prevMonthEnd);
        revDog += order.price;
        revDogCurMonth = revs.revCur;
        revDogPrevMonth = revs.revPrev;
      } else {
        const revs = calcRevenue(revCatCurMonth, revCatPrevMonth, date, order.price, curMonthStart, curMonthEnd, prevMonthStart, prevMonthEnd);
        revCat += order.price;
        revCatCurMonth = revs.revCur;
        revCatPrevMonth = revs.revPrev;
      }
    }

    const percentageDiffDog = getPercentageDiff(revDogPrevMonth, revDogCurMonth);
    const percentageDiffCat = getPercentageDiff(revCatPrevMonth, revCatCurMonth);
    const secondaryValue = [
      `${percentageDiffDog}% / ${percentageDiffCat}% ${t('statistics.this_month')}`,
      `(${t('statistics.based_on_last_mt')})`
    ];

    return { primaryValue: `${revDog.toFixed(2)} / ${revCat.toFixed(2)}`, secondaryValue };
  });

  return res;
}

export const calculateRevenueBySizeClassStatistics = async () => {
  const dates = getMonthStartAndEnd();
  const prevDates = getMonthStartAndEnd(new Date((new Date()).setMonth((new Date()).getMonth() - 1)));
  const curMonthStart = dates.minDate;
  const curMonthEnd = dates.maxDate;
  const prevMonthStart = prevDates.minDate;
  const prevMonthEnd = prevDates.maxDate;

  const res = await apolloClient.query({ query: GET_ANIMAL_DATA, fetchPolicy: 'no-cache' }).then(response => {
    if (response.loading) return { primaryValue: '', secondaryValue: [] };

    const orders = response.data.orders;
    let mini = 0;
    let miniCur = 0;
    let miniPrev = 0;
    let small = 0;
    let smallCur = 0;
    let smallPrev = 0;
    let mediumDog = 0;
    let mediumCat = 0;
    let mediumCurDog = 0;
    let mediumCurCat = 0;
    let mediumPrevDog = 0;
    let mediumPrevCat = 0;
    let large = 0;
    let largeCur = 0;
    let largePrev = 0;
    let giant = 0;
    let giantCur = 0;
    let giantPrev = 0;

    for (const order of orders) {
      const date = new Date(order.dateCreated);
      let revs;

      switch (order.sizeClass) {
        case 'Mini':
          revs = calcRevenue(miniCur, miniPrev, date, order.price, curMonthStart, curMonthEnd, prevMonthStart, prevMonthEnd);
          mini += order.price;
          miniCur = revs.revCur;
          miniPrev = revs.revPrev;
          break;
        case 'Small':
          revs = calcRevenue(smallCur, smallPrev, date, order.price, curMonthStart, curMonthEnd, prevMonthStart, prevMonthEnd);
          small += order.price;
          smallCur = revs.revCur;
          smallPrev = revs.revPrev;
          break;
        case 'Medium':
          if (order.animalType === 'Dog') {
            revs = calcRevenue(mediumCurDog, mediumPrevDog, date, order.price, curMonthStart, curMonthEnd, prevMonthStart, prevMonthEnd);
            mediumDog += order.price;
            mediumCurDog = revs.revCur;
            mediumPrevDog = revs.revPrev;
          } else {
            revs = calcRevenue(mediumCurCat, mediumPrevCat, date, order.price, curMonthStart, curMonthEnd, prevMonthStart, prevMonthEnd);
            mediumCat += order.price;
            mediumCurCat = revs.revCur;
            mediumPrevCat = revs.revPrev;
          }
          break;
        case 'Large':
          revs = calcRevenue(largeCur, largePrev, date, order.price, curMonthStart, curMonthEnd, prevMonthStart, prevMonthEnd);
          large += order.price;
          largeCur = revs.revCur;
          largePrev = revs.revPrev;
          break;
        case 'Giant':
          revs = calcRevenue(giantCur, giantPrev, date, order.price, curMonthStart, curMonthEnd, prevMonthStart, prevMonthEnd);
          giant += order.price;
          giantCur = revs.revCur;
          giantPrev = revs.revPrev;
          break;
        default:
          break;
      }
    }

    const percentageDiffMini = getPercentageDiff(miniPrev, miniCur);
    const percentageDiffSmall = getPercentageDiff(smallPrev, smallCur);
    const percentageDiffMediumDog = getPercentageDiff(mediumPrevDog, mediumCurDog);
    const percentageDiffMediumCat = getPercentageDiff(mediumPrevCat, mediumCurCat);
    const percentageDiffLarge = getPercentageDiff(largePrev, largeCur);
    const percentageDiffGiant = getPercentageDiff(giantPrev, giantCur);

    const primaryArr = [
      `Mini: ${mini.toFixed(2)}`,
      `Small: ${small.toFixed(2)}`,
      `Medium ${t('dogs')}: ${mediumDog.toFixed(2)}`,
      `Medium ${t('cats')}: ${mediumCat.toFixed(2)}`,
      `Large: ${large.toFixed(2)}`,
      `Giant: ${giant.toFixed(2)}`,
    ];

    const secondaryValue = [
      `Mini: ${percentageDiffMini} %`,
      `Small: ${percentageDiffSmall} %`,
      `Medium ${t('dogs')}: ${percentageDiffMediumDog} %`,
      `Medium ${t('cats')}: ${percentageDiffMediumCat} %`,
      `Large: ${percentageDiffLarge} %`,
      `Giant: ${percentageDiffGiant} %`,
      `${t('statistics.this_month')}`,
      `(${t('statistics.based_on_last_mt')})`
    ];

    return { primaryArr, secondaryValue };
  });

  return res;
}

export const calculateProductionStatistics = async (minDate, maxDate) => {
  const res = await apolloClient.query({ query: GET_PRODUCTION, variables: { minDate, maxDate }, fetchPolicy: 'no-cache' }).then(response => {
    if (response.loading) return { primaryValue: '', secondaryValue: [] };

    const orders = response.data.orders;
    let total = 0;

    for (const o of orders) {
      total += o.weight * o.quantity;
    }

    const secondaryValue = [
      t('statistics.in_kg'),
      `(${t('statistics.based_on_time_filter')})`,
    ];

    return { primaryValue: total, secondaryValue };
  });

  return res;
}

const calcRevenue = (revCur, revPrev, date, price, curMonthStart, curMonthEnd, prevMonthStart, prevMonthEnd) => {
  if (date >= curMonthStart && date <= curMonthEnd) {
    revCur += price;
  } else if (date >= prevMonthStart && date <= prevMonthEnd) {
    revPrev += price;
  }

  return { revCur, revPrev };
}

export const calculateMaterialQuantities = async (minDate, maxDate) => {
  const res = await apolloClient.query({ query: GET_MATERIAL_QUANTITY, variables: { minDate, maxDate }, fetchPolicy: 'no-cache' }).then(response => {
    if (response.loading) return { primaryValue: '', secondaryValue: [] };

    const units = response.data.units;
    const orders = response.data.orders;
    let quantity = 0;

    for (const order of orders) {
      const recipe = order.recipe;
      if (recipe && recipe.materialQuantities) {
        const mq = recipe.materialQuantities;
        for (const m of mq) {
          const unit = units.find(u => u.id === m.unitId);
          if (unit) {
            const coef = unit.coefficientPerKilogram;
            if (coef !== 0) {
              quantity += m.quantity / coef;
            }
          }
        }
      }
    }

    const secondaryValue = [
      t('statistics.in_kg'),
      `(${t('statistics.based_on_time_filter')})`,
    ];

    return { primaryValue: quantity.toFixed(3), secondaryValue }
  });

  return res;
}