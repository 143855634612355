import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
} from 'react-admin';
import { t } from 'i18next';

import { CustomBulkActions } from '../CustomBulkActions';
import { CustomListActions } from '../CustomListActions';

export const MunicipalityList = props => (
  <List {...props} title={t("municipalities.title_list")} bulkActionButtons={<CustomBulkActions />} actions={<CustomListActions />}>
    <Datagrid rowClick="show">
      <TextField label={t("name")} source="name"/>
      <ReferenceField label={t("province")} reference="Province" source="province.id" allowEmpty={true} sortable={false}>
        <TextField source="name"/>
      </ReferenceField>
      <TextField label={t("code_column")} source="code"/>
    </Datagrid>
  </List>
);
