import React, { Component } from 'react';
import {
  ShowController,
  ShowView,
  TextField,
  ReferenceField,
  NumberField,
  ReferenceManyField,
  TabbedShowLayout,
  Tab,
  RichTextField,
  ImageField,
  Labeled,
  BooleanField,
  SelectField,
} from 'react-admin';
import { t } from 'i18next';

import EditFormTitle from '../EditFormTitle';
import CustomFileInputPreview from '../CustomFileInputPreview';
import TranslationField from '../fields/TranslationField';
import CustomDateField from '../fields/CustomDateField';
import AnimalHistory from './AnimalHistory';
import AnimalFoodPrices from './AnimalFoodPrices';
import apolloClient from '../../initApollo';
import { GET_MIX_BREED_ID } from '../../customQueries';

class AnimalShow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      breedMix: ''
    }
  }

  componentDidMount() {
    apolloClient.query({ query: GET_MIX_BREED_ID, fetchPolicy: 'no-cache' })
      .then(response => {
        if (response.loading) return;
        this.setState({breedMix: response.data.breeds.pop() || ''})
      });
  }

  render() {
    const { breedMix } = this.state;

    return (
      <ShowController {...this.props}>
        {controllerProps =>
          <ShowView {...this.props} {...controllerProps} title={<EditFormTitle formName={t('animal')} />}>
            <TabbedShowLayout redirect="list">
              <Tab label={t('summary_tab')}>
                <TranslationField source="animalType" label={t('animal_type')}/>
                <CustomDateField source="birthDate" label={t('birth_date')} />
                <TextField source="name" label={t('name')}/>
                <TranslationField source="gender" label={t('gender')}/>
                <ReferenceField label={t('owner')} source="owner.id" reference="Owner" allowEmpty={true} linkType="show">
                  <TextField source="name"/>
                </ReferenceField>
                <ReferenceField label={t('breed')} source="breed.id" reference="Breed" linkType={false} allowEmpty={true}>
                  <TextField source="breedNameBg"/>
                </ReferenceField>

                {controllerProps.record && breedMix !== '' && controllerProps.record.breed.id === breedMix.id && (
                  <ReferenceField
                    key="father"
                    label={t('breed_father')}
                    source="breedFather.id"
                    reference="Breed"
                    linkType={false}
                    allowEmpty={true}
                  >
                    <TextField source="breedNameBg"/>
                  </ReferenceField>
                )}

                {controllerProps.record && breedMix !== '' && controllerProps.record.breed.id === breedMix.id && (
                  <ReferenceField
                    key="mother"
                    label={t('breed_mother')}
                    source="breedMother.id"
                    reference="Breed"
                    linkType={false}
                    allowEmpty={true}
                  >
                    <TextField source="breedNameBg"/>
                  </ReferenceField>
                )}

                <NumberField source="weight" label={t('weight')}/>

                <TextField source="chipNumber" label={t('chip_number')}/>
                <TextField source="tattooNumber" label={t('tattoo_number')}/>

                <TranslationField source="castrated" label={t('castrated')}/>

                <RichTextField source="comment" label={t('comment')}/>
              </Tab>

              <Tab label='VIP'>
                <h5>{t('general_section')}</h5>
                <NumberField source='withersHeight' label={t('withers_height')} />
                <TextField source='coatType' label={t('coat_type')} />
                <TextField source='hairStructure' label={t('hair_structure')} />
                <TextField source='coatColor' label={t('coat_color')} />
                <TextField source='skinCondition' label={t('skin_condition')} />

                <h5>{t('digestive_system_condition_section')}</h5>
                <TextField source='teeth' label={t('teeth')} />
                <TextField source='gums' label={t('gums')} />
                <TextField source='tongueAndMucousMembranes' label={t('tongue_and_mucous_membranes')} />
                <TextField source='esophagus' label={t('esophagus')} />
                <TextField source='stomach' label={t('stomach')} />
                <TextField source='intestines' label={t('intestines')} />
                <SelectField
                  source='excrementConsistency'
                  label={t('excrement_consistency')}
                  choices={[
                    { id: 'SolidAndFriable', name: t('solid_and_friable') },
                    { id: 'SolidAndShaped', name: t('solid_and_shaped') },
                    { id: 'Shaped', name: t('shaped') },
                    { id: 'SoftAndShaped', name: t('soft_and_shaped') },
                    { id: 'SoftAndUnshaped', name: t('soft_and_unshaped') },
                    { id: 'Liquid', name: t('liquid') },
                  ]}
                />

                <h5>{t('activity_section')}</h5>
                <SelectField
                  source='livingPlace'
                  label={t('living_place')}
                  choices={[
                    { id: 'HomeInside', name: t('home_inside') },
                    { id: 'HomeOutside', name: t('home_outside') },
                  ]}
                />
                <NumberField source='dailyWalkDuration' label={t('daily_walk_duration')} />
                <SelectField
                  source='walkIntensity'
                  label={t('walk_intensity')}
                  choices={[
                    { id: 'Low', name: t('low_intensity') },
                    { id: 'Medium', name: t('medium_intensity') },
                    { id: 'High', name: t('high_intensity') },
                  ]}
                />
                <SelectField
                  source='hunt'
                  label={t('hunt')}
                  choices={[
                    { id: 'Underground', name: t('underground') },
                    { id: 'Birds', name: t('birds') },
                    { id: 'Chasing', name: t('chasing') },
                  ]}
                />
                <SelectField
                  source='sport'
                  label={t('sport')}
                  choices={[
                    { id: 'IPO', name: t('IPO') },
                    { id: 'Agility', name: t('agility') },
                    { id: 'Other', name: t('other') },
                  ]}
                />
                <TextField source='otherSport' label={t('other_sport')} />
                <BooleanField source='workUsage' label={t('work_usage')} />
                <TextField source='workType' label={t('work_type')} />

                <h5>{t('feeding_section')}</h5>
                <TextField source='foodBrand' label={t('food_brand')} />
                <TextField source='homemadeFood' label={t('homemade_food')} />
                <TextField source='appetite' label={t('appetite')} />
                <TextField source='preferredTaste' label={t('preferred_taste')} />
                <TextField source='suspectedFoodAllergy' label={t('suspected_food_allergy')} />
                <TextField source='identifiedFoodAllergy' label={t('identified_food_allergy')} />
                <TextField source='jointsAndTiesCondition' label={t('joints_and_ties_condition')} />

                <h5>{t('breeding_section')}</h5>
                <BooleanField source='breeding' label={t('breeding')} />
                <CustomDateField source='fertilizationDate' label={t('fertilization_date')}/>
                <CustomDateField source='givingBirthDate' label={t('giving_birth_date')}/>
              </Tab>

              <Tab label={t('profile_picture')}>
                <Labeled label={t('profile_picture')}>
                  <ImageField source="profilePicture"/>
                </Labeled>
              </Tab>

              <Tab label={t('files')}>
                <ReferenceManyField label={t('files')} reference="File" target="animal">
                  <CustomFileInputPreview user={this.props.options.currentUser.role}/>
                </ReferenceManyField>
              </Tab>

              <Tab label={t('food_price')}>
                <AnimalFoodPrices/>
              </Tab>

              <Tab label={t('history')}>
                {/*
                  One animal could have many records in AnimalHistory table.
                  Custom component is created because there is no React Admin field,
                  which will display the JSON data for many records from the DB.
                  ReferenceManyField/ReferenceArrayField will call the custom component for every record
                  of change of the animal -> will display n(for each record) tables, and we do not want that.
                */}
                <AnimalHistory/>
              </Tab>
              {controllerProps.record && controllerProps.record.animalType === "Dog" ?
                (<Tab label={t('encyclopedia')}>
                  {controllerProps.record && breedMix !== '' && controllerProps.record.breed.id !== breedMix.id && (
                    <ReferenceField label={t('breed')} source="breed.id" reference="Breed" linkType={false} allowEmpty={true}>
                      <RichTextField source="encyclopedia"/>
                    </ReferenceField>
                  )}
                  {controllerProps.record && breedMix !== '' && controllerProps.record.breed.id === breedMix.id
                  && controllerProps.record.breedFather && controllerProps.record.breedFather.id !== breedMix.id && (
                    <ReferenceField label={t('breed_father')} source="breedFather.id" reference="Breed" linkType={false} allowEmpty={true}>
                      <RichTextField source="encyclopedia"/>
                    </ReferenceField>
                  )}
                  {controllerProps.record && breedMix !== '' && controllerProps.record.breed.id === breedMix.id
                  && controllerProps.record.breedMother && controllerProps.record.breedMother.id !== breedMix.id && (
                    <ReferenceField label={t('breed_mother')} source="breedMother.id" reference="Breed" linkType={false} allowEmpty={true}>
                      <RichTextField source="encyclopedia"/>
                    </ReferenceField>
                  )}
                </Tab>)
              : null}
            </TabbedShowLayout>
          </ShowView>
        }
      </ShowController>
    )
  }
}

export default AnimalShow;