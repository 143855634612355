import React from 'react';
import { Create } from 'react-admin';
import { t } from 'i18next';

import CustomCreateToolbar from '../CustomCreateToolbar';
import { ExchangeWeightForm } from './ExchangeWeightForm';

export const ExchangeWeightCreate = props => (
  <Create {...props} redirect='list' title={t('exchange_weight.title_create')}>
    <ExchangeWeightForm toolbar={<CustomCreateToolbar user={props.options.currentUser.role}/>}/>
  </Create>
);
