import React from 'react';
import { Create } from 'react-admin';
import { t } from 'i18next';
import CustomCreateToolbar from '../CustomCreateToolbar';
import { NutrientForm } from './NutrientForm';

export const NutrientCreate = props => (
  <Create {...props} title={t('nutrients.title_create')}>
    <NutrientForm toolbar={<CustomCreateToolbar user={props.options.currentUser.role}/>}/>
  </Create>
);
