import React from 'react';
import Card from '@material-ui/core/Card';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CustomTextField from '../fields/CustomTextField';
import StatisticsCard from './StatisticsCard';
import { t } from 'i18next';
import {
  calculateTotalsPerMonth,
  calculateTotalRevenueStatistics,
  calculateTotalOrdersByBreed,
  calculateNewOwnersCurrentMonth,
  calculateTotalOrdersPrevMonthStatistics,
  calculateTotalClinicOrdersStatistics,
  calculatePrevMonthRevenueStatistics,
  calculateRevenueByAnimalTypeStatistics,
  calculateRevenueDogCatStatistics,
  calculateRevenueBySizeClassStatistics,
  calculateProductionStatistics,
  calculateMaterialQuantities,
  calculateTotalOrdersPerMonth
} from '../../utils/StatisticsHelper';

const styles = {
  flex: {
    flexGrow: 1,
  },
  grid: {
    display: 'flex',
    flexDirection: 'column'
  },
  gridRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  gridItem: {
    flex: 0.2,
    margin: 4
  },
  datePickerField: {
    marginRight: 8,
  }
};

class StatisticsList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dateFrom: this.getStartOfMonth(new Date()),
      dateTo: this.getStartOfMonth(new Date().setMonth(new Date().getMonth() + 1)),
    }
  }

  getStartOfMonth = (date) => {
    date = new Date(date).setDate(1);
    date = new Date(date).setHours(0, 0, 0, 0);

    return new Date(date);
  }

  render() {
    const { classes } = this.props;

    return (
      <Card>
        <Toolbar>
          <Typography variant="title" color="inherit" className={classes.flex}>
            {t('statistics.title')}
          </Typography>
          <DatePicker
            customInput={
              <CustomTextField
                label={t('from')}
                value={this.state.dateFrom}
                inputProps={{ readOnly: true }}
                classes={{ root: classes.datePickerField }}
              />
            }
            onChange={date => this.setState({ dateFrom: this.getStartOfMonth(date) })}
            dateFormat="MMM yyyy"
            selected={this.state.dateFrom}
            maxDate={new Date()}
            showMonthYearPicker
          />
          <DatePicker
            customInput={
              <CustomTextField
                label={t('to')}
                value={this.state.dateTo}
                inputProps={{ readOnly: true }}
              />
            }
            onChange={date => this.setState({ dateTo: this.getStartOfMonth(date) })}
            dateFormat="MMM yyyy"
            selected={this.state.dateTo}
            minDate={this.state.dateFrom}
            maxDate={this.getStartOfMonth(new Date().setMonth(new Date().getMonth() + 1))}
            showMonthYearPicker
          />
        </Toolbar>
        <CardContent>
          <div className={classes.grid}>
            <div className={classes.gridRow}>
              <div className={classes.gridItem}>
                <StatisticsCard
                  title={t('statistics.total_owners')}
                  getData={() => calculateTotalsPerMonth('Owner')} />
              </div>
              <div className={classes.gridItem}>
                <StatisticsCard
                  title={t('statistics.total_orders')}
                  getData={calculateTotalOrdersPerMonth} />
              </div>
              <div className={classes.gridItem}>
                <StatisticsCard
                  title={t('statistics.total_vets')}
                  getData={() => calculateTotalsPerMonth('Vet')} />
              </div>
              <div className={classes.gridItem}>
                <StatisticsCard
                  title={t('statistics.total_revenue')}
                  getData={calculateTotalRevenueStatistics} />
              </div>
              <div className={classes.gridItem}>
                <StatisticsCard
                  title={t('statistics.orders_by_breed')}
                  getData={calculateTotalOrdersByBreed} />
              </div>
            </div>
            <div className={classes.gridRow}>
              <div className={classes.gridItem}>
                <StatisticsCard
                  title={t('statistics.new_clients_current_mt')}
                  getData={calculateNewOwnersCurrentMonth} />
              </div>
              <div className={classes.gridItem}>
                <StatisticsCard
                  title={t('statistics.orders_last_mt')}
                  getData={calculateTotalOrdersPrevMonthStatistics} />
              </div>
              <div className={classes.gridItem}>
                <StatisticsCard
                  title={t('statistics.vet_orders')}
                  getData={calculateTotalClinicOrdersStatistics} />
              </div>
              <div className={classes.gridItem}>
                <StatisticsCard
                  title={t('statistics.revenue_last_mt')}
                  getData={calculatePrevMonthRevenueStatistics} />
              </div>
              <div className={classes.gridItem}>
                <StatisticsCard
                  title={t('statistics.total_ingredient_value')}
                  getData={calculateMaterialQuantities}
                  dateFrom={this.state.dateFrom}
                  dateTo={this.state.dateTo} />
              </div>
            </div>
            <div className={classes.gridRow}>
              <div className={classes.gridItem}></div>
              <div className={classes.gridItem}>
                <StatisticsCard
                  title={t('statistics.orders_by_animal_type')}
                  getData={calculateRevenueByAnimalTypeStatistics} />
              </div>
              <div className={classes.gridItem}></div>
              <div className={classes.gridItem}>
                <StatisticsCard
                  title={t('statistics.revenue_by_animal_type')}
                  getData={calculateRevenueDogCatStatistics} />
              </div>
              <div className={classes.gridItem}>
                <StatisticsCard
                  title={t('statistics.total_production')}
                  getData={calculateProductionStatistics}
                  dateFrom={this.state.dateFrom}
                  dateTo={this.state.dateTo} />
              </div>
            </div>
            <div className={classes.gridRow}>
              <div className={classes.gridItem}></div>
              <div className={classes.gridItem}></div>
              <div className={classes.gridItem}></div>
              <div className={classes.gridItem}>
                <StatisticsCard
                  title={t('statistics.rev_by_size_class')}
                  getData={calculateRevenueBySizeClassStatistics} />
              </div>
              <div className={classes.gridItem}></div>
            </div>
          </div>
        </CardContent>
      </Card >
    );
  }
}

export default withStyles(styles)(StatisticsList);