import React, { Children, cloneElement } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { FormInput } from 'react-admin';
import {
  TableBody,
  TableRow,
  TableCell,
  IconButton,
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { t } from 'i18next';

function IngredientsBody(props) {
  const {
    materialQuantities,
    fields,
    ids,
    children,
    basePath,
    record,
    source,
    resource,
    disableRemove,
    removeField,
  } = props;

  const records = get(record, source);

  return (
    <TableBody>
      {fields.map((member, index) => (
        <TableRow key={ids[index]}>
          {Children.map(children, (input, index2) => {

            return (
              <TableCell>
                <FormInput
                  basePath={input.props.basePath || basePath}
                  input={cloneElement(input, {
                    source: input.props.source ? `${member}.${input.props.source}` : member,
                    index: input.props.source ? undefined : index2,
                    label: input.props.label || input.props.source,
                    data: materialQuantities,
                  })}
                  record={(records && records[index]) || {}}
                  resource={resource}
                />
              </TableCell>
            )
          })}
          <TableCell>{materialQuantities[index] && materialQuantities[index].percentPerKg ? `${parseFloat(materialQuantities[index].percentPerKg.toFixed(3))}%` : ''}</TableCell>
          <TableCell>{materialQuantities[index] && materialQuantities[index].moisture}</TableCell>
          <TableCell>{materialQuantities[index] && materialQuantities[index].price ? `${materialQuantities[index].price} ${t('lv')}` : ''}</TableCell>
          {!disableRemove && (
            <TableCell>
              <IconButton color="secondary" onClick={removeField(index)}>
                <Delete />
              </IconButton>
            </TableCell>
          )}
        </TableRow>
      ))}
    </TableBody>
  )
}

IngredientsBody.propTypes = {
  materialQuantities: PropTypes.array,
  fields: PropTypes.object,
  ids: PropTypes.array,
  children: PropTypes.node,
  basePath: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string,
  resource: PropTypes.string,
  disableRemove: PropTypes.bool,
  removeField: PropTypes.func,
};

export default IngredientsBody;