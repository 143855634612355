import React, { Component, Fragment } from 'react';
import { GET_ANIMAL_HISTORY_RECORDS, GET_ALL_OWNERS, GET_ALL_BREEDS } from '../../customQueries';
import apolloClient from '../../initApollo';
import { t } from 'i18next';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CustomHistoryFilter from '../CustomHistoryFilter';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

import { formatDate, translateProp, translatePropValue, calculateAge, getObjectProperty, formatDateTime } from '../../utils/Helper';

const styles = {
  image: {
    width: 'auto',
    height: '100px'
  },
  noResultsDiv: {
    marginTop: '10px'
  },
  filterDiv: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  select: {
    marginTop: '10px',
    marginLeft: '10px',
    display: 'inline-table'
  }
};

class AnimalHistory extends Component {
  constructor(props){
    super(props);

    this.state = {
      owners: [],
      breeds: [],
      filters: [],
      records: [],
      allRecords: [],
      filteredRecords: [],
      user: '',
      loading: true
    };

    this.handleRemoveFilter = this.handleRemoveFilter.bind(this);
    this.handleAddFilter = this.handleAddFilter.bind(this);
    this.filterByUser = this.filterByUser.bind(this);
  }

  componentWillMount() {
    const breeds = apolloClient.query({query: GET_ALL_BREEDS}).then(response => {
      this.setState({ breeds: response.data.breeds })
    });

    const owners = apolloClient.query({query: GET_ALL_OWNERS}).then(response => {
      this.setState({ owners: response.data.owners })
    });

    Promise.all([breeds, owners]).then(() => {
      this.formatHistory(this.props.record.id)
    });
  }

  formatHistory = (animalId) => {
    apolloClient.query({query: GET_ANIMAL_HISTORY_RECORDS, variables: { animalId }, fetchPolicy: 'no-cache'}).then(response => {
      //sort by date of create
      let historyRecords = response.data.animalHistories;
      let data = [];

      historyRecords.forEach(record => {
        Object.entries(record.data).forEach(([property, values]) => {
          let oldValue = values.oldValue;
          let newValue = values.newValue;

          let dateOfBirth = this.props.record.birthDate;
          if(property === 'birthDate' || property === 'fertilizationDate' || property === 'givingBirthDate') {
            dateOfBirth = newValue;

            oldValue = oldValue ? formatDate(oldValue) : null;
            newValue = newValue ? formatDate(newValue) : null;
          }

          // we need animalType in lowercase to translate it in the current language
          if(property === 'animalType') {
            oldValue = oldValue !== null ? oldValue.toLowerCase() : oldValue;
            newValue = newValue !== null ? newValue.toLowerCase() : newValue;
          }

          if(oldValue !== null && typeof oldValue === 'object') {
            // for some properties we want to show name or something else instead of id
            // to be sure we display the current name we need to make a query to get this property
            oldValue = this.getValue(property, oldValue);
          }

          if(newValue !== null && typeof newValue === 'object') {
            // for some properties we want to show name or something else instead of id
            // to be sure we display the current name we need to make a query to get this property
            newValue = this.getValue(property, newValue);
          }

          let age = calculateAge(dateOfBirth, record.date);

          data.push({
            date: formatDateTime(record.date),
            user: record.user,
            age: `${age.years}${t('years')} ${t('and')} ${age.months}${t('months')}`,
            property: property,
            oldValue: oldValue,
            newValue: newValue
          });
        })
      });

      this.setState({
        loading: false,
        allRecords: data,
        records: data
      })
    });
  };

  getValue = (key, value) => {
    switch(key) {
      case 'owner':
        return getObjectProperty(value, this.state.owners, 'name');
      case 'breed':
      case 'breedMother':
      case 'breedFather':
        return getObjectProperty(value, this.state.breeds, 'breedNameBg');
      default:
        return value;
    }
  };

  getStyledProp = (prop, value) => {
    const { classes } = this.props;
    switch(prop) {
      case 'files':
        return value ? <a href={value.url}>{value.name}</a> : t(`${value}`);
      case 'profilePicture':
        return value ? <img src={value} alt={translateProp(prop)} className={classes.image}/> : t(`${value}`);
      case 'animalType':
      case 'appetite':
      case 'excrementConsistency':
      case 'hunt':
      case 'livingPlace':
      case 'sport':
      case 'walkIntensity':
      case 'workUsage':
      case 'breeding':
      case 'gender':
      case 'castrated':
        return typeof value === 'string' ? t(`${translatePropValue(prop, value)}`) : t(`${value}`);
      default:
        return value || t(`${value}`);
    }
  };

  filterByUser(e) {
    let value = e.target.value;

    let items = this.getActiveRecords();
    let records = this.filterByUserValue(items, value);

    this.setState({
      records: records,
      user: value
    });
  }

  handleRemoveFilter(filter) {
    const { user, filters } = this.state;

    let filterValues = filters.filter(property => property !== filter);
    this.setState({ filters: filterValues }, () => {

      let items = this.getActiveRecords();

      if(user) {
        items = this.filterByUserValue(items, user);
      }

      this.setState({ records: items });
    });
  }

  handleAddFilter(filter) {
    const { allRecords, records } = this.state;

    this.setFilter(filter);

    let filteredRecords = this.getFilteredByRow(allRecords, filter);
    let recordsValues = this.getFilteredByRow(records, filter);

    this.setState({
      records: recordsValues,
      filteredRecords: filteredRecords
    })
  }

  setFilter = (filter) => {
    let filterValues = this.state.filters;
    filterValues.push(filter);

    this.setState({ filters: filterValues });
  };

  getActiveRecords = () => {
    const { filters, allRecords, filteredRecords } = this.state;

    let hasRowFilter = false;
    if(Object.keys(filters).length !== 0) {
      hasRowFilter = true;
    }

    return hasRowFilter ? filteredRecords : allRecords;
  };

  getFilteredByRow = (data, filter) => {
    return Object.values(data).filter(record => record.property === filter);
  };

  filterByUserValue = (items, value) => {
    return items.filter(record => record.user.toString().includes(value));
  };

  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <div className={classes.filterDiv}>
          <CustomHistoryFilter
            onRemoveFilter={this.handleRemoveFilter}
            onAddFilter={this.handleAddFilter}
          />
          <div>
            <InputLabel>{t('user')}</InputLabel>
            <Select value={this.state.user} onChange={this.filterByUser} className={classes.select}>
              <MenuItem value=''></MenuItem>
              <MenuItem value='Admin'>{t('admin')}</MenuItem>
              <MenuItem value='ProductionManager'>{t('productionmanager')}</MenuItem>
              <MenuItem value='Clinic'>{t('clinic')}</MenuItem>
              <MenuItem value='Vet'>{t('vet')}</MenuItem>
              <MenuItem value='Owner'>{t('owner')}</MenuItem>
            </Select>
          </div>
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('property')}</TableCell>
              <TableCell>{t('old_value')}</TableCell>
              <TableCell>{t('new_value')}</TableCell>
              <TableCell>{t('date_of_change')}</TableCell>
              <TableCell>{t('age')}</TableCell>
              <TableCell>{t('user')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(this.state.records).map(([key, value]) => {
              const translated_property = translateProp(value.property);
              return (
                <TableRow key={key}>
                  <TableCell>{t(`${translated_property}`)}</TableCell>
                  <TableCell>
                    {this.getStyledProp(value.property, value.oldValue)}
                  </TableCell>
                  <TableCell>
                    {this.getStyledProp(value.property, value.newValue)}
                  </TableCell>
                  <TableCell>{value.date}</TableCell>
                  <TableCell>{value.age}</TableCell>
                  <TableCell>{t(`${value.user.toLowerCase()}`)}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {!this.state.loading && this.state.records.length === 0 ? <div className={classes.noResultsDiv}>{t('no_results_found')}</div> : null }
      </Fragment>
    );
  }
}

export default withStyles(styles)(AnimalHistory);
