import React, { Fragment } from 'react';
import { SimpleForm, NumberInput, ReferenceField, TextField, ReferenceInput, SelectInput } from 'react-admin';
import { t } from 'i18next';

import { isRequired } from '../../utils/validation';

const validateRequired = [isRequired()];

function redirect(record) {
  if(record.canEditVet) {
    return `/Clinic/${record.clinicId}`;
  }

  if(record.vet){
    return `/Vet/${record.vet.id}`;
  }

  return `/Vet/${record.vetId}`;
}

export const ConsultingFeePercentageVetForm = props => (
  <Fragment>
    <SimpleForm {...props} redirect={redirect(props.record)} defaultValue={{ vet: {id: props.record.vet || props.record.vetId}, clinic: { id: props.record.clinic || props.record.clinicId }}}>
      { props.record && props.record.canEditVet === undefined ?
        <ReferenceField label={t('vet')} reference="Vet" source={props.record.vet ? "vet.id" : "vetId"} allowEmpty={true} sortable={false}>
          <TextField source="name"/>
        </ReferenceField>
      : <ReferenceInput label={t('vet')} reference="Vet" source="vet.id" validate={validateRequired} filter={{ clinic: {id: props.record.clinicId }, active: true }}>
          <SelectInput name="name"/>
        </ReferenceInput>
      }
      <NumberInput source="weeklyTurnoverTo" label={t('consulting_fee_details.weekly_turnover_to')} validate={validateRequired}/>
      <NumberInput source="consultingFeePercentage" label={t('consulting_fee_details.consulting_fee_percentage')} validate={validateRequired}/>
    </SimpleForm>
  </Fragment>
);