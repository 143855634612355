import React, {Fragment, Component} from 'react';
import {
  Edit,
  TabbedForm,
  FormTab,
  FormDataConsumer,
  SelectInput,
  TextInput,
  NumberInput,
  LongTextInput,
  ReferenceInput,
  ReferenceManyField,
  maxLength
} from 'react-admin';
import {t} from 'i18next';

import EditFormTitle from '../EditFormTitle';
import {CustomEditToolbar} from '../CustomEditToolbar';
import CustomFileInput from '../CustomFileInput';
import CustomFileInputPreview from '../CustomFileInputPreview';
import CustomDateInput from '../fields/CustomDateInput';
import CustomImageInput from '../CustomImageInput';
import VIPDataForm from './VIPForm';
import BreedSelect from '../BreedSelect';
import {SearchableSelect} from './../SearchableSelect';
import apolloClient from '../../initApollo';
import {GET_MIX_BREED_ID} from '../../customQueries';

import {isRequired, isTextNumbers} from '../../utils/validation';

const validateRequired = [isRequired()];
const validateSerial = [isTextNumbers];
const validateComment = [maxLength(150)];

export const redirect = (props) => {
  if (props.location.state && props.location.state.dialog && props.location.state.owner) {
    return `/Owner/${props.location.state.owner}/show/2`;
  }

  return 'list';
};

class AnimalEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      breedMix: ''
    }
  }

  componentDidMount() {
    apolloClient.query({query: GET_MIX_BREED_ID, fetchPolicy: 'no-cache'})
      .then(response => {
        if (response.loading) return;
        this.setState({breedMix: response.data.breeds.pop() || ''})
      });
  }

  render() {
    const {options} = this.props;
    const {breedMix} = this.state;

    const isDisabledForOwner = options.currentUser.role === 'Owner';
    const isDisabledForAllExceptAdmin = options.currentUser.role !== 'Admin';


    return (
      <Edit {...this.props} title={<EditFormTitle formName={t('animal')}/>}>
        <TabbedForm redirect={redirect(this.props)} toolbar={<CustomEditToolbar user={options.currentUser.role}/>}>
          <FormTab label={t('summary_tab')}>
            <FormDataConsumer>
              {({formData, ...rest}) =>
                <SelectInput
                  source="animalType"
                  label={t('animal_type')}
                  disabled={isDisabledForAllExceptAdmin}
                  choices={[
                    {id: 'Dog', name: t('dog')},
                    {id: 'Cat', name: t('cat')}
                  ]}
                  validate={validateRequired}
                  onChange={() => {
                    formData.breed = '';
                    formData.breedFather = {id: null};
                    formData.breedMother = {id: null};
                  }}
                />
              }
            </FormDataConsumer>
            <CustomDateInput source="birthDate" label={t('birth_date')} validate={validateRequired} disabled={isDisabledForAllExceptAdmin}
                             maxDate={new Date()}/>
            <TextInput disabled={isDisabledForAllExceptAdmin} source="name" label={t('name')}
                       validate={validateRequired}/>

            <SelectInput
              source="gender"
              disabled={isDisabledForAllExceptAdmin}
              label={t('gender')}
              choices={[
                {id: 'male', name: t('male')},
                {id: 'female', name: t('female')},
              ]}
              validation={validateRequired}
            />

            {options.currentUser.role === 'Admin' ?
              <ReferenceInput label={t('owner')} source="owner.id" reference="Owner" validate={validateRequired}>
                <SearchableSelect obj="Owner" />
              </ReferenceInput>
              : null}

            <FormDataConsumer>
              {({formData, ...rest}) =>
                <ReferenceInput
                  key={formData.animalType}
                  label={t('breed')}
                  source="breed.id"
                  reference="Breed"
                  validate={validateRequired}
                  perPage={400}
                  sort={{field: 'breedNameBg', order: 'ASC'}}
                  filter={{animalType: formData.animalType}}
                  onchange={() => {
                    formData.breedFather = null;
                    formData.breedMother = null;
                  }}
                  {...rest}
                >
                  <BreedSelect disabled={isDisabledForOwner} animalType={formData.animalType}/>
                </ReferenceInput>
              }
            </FormDataConsumer>

            <FormDataConsumer>
              {({formData, ...rest}) =>
                breedMix !== '' && formData.breed.id === breedMix.id ? (
                  <Fragment>
                    <div>
                      <ReferenceInput
                        key={formData.breed.id}
                        label={t('breed_father')}
                        source="breedFather.id"
                        reference="Breed"
                        validate={validateRequired}
                        perPage={400}
                        filter={{animalType: formData.animalType}}
                        sort={{field: 'breedNameBg', order: 'ASC'}}
                        {...rest}
                      >
                        <BreedSelect disabled={isDisabledForOwner} animalType={formData.animalType} name="BreedFather"/>
                      </ReferenceInput>
                    </div>
                    <div>
                      <ReferenceInput
                        key={formData.breed.id}
                        label={t('breed_mother')}
                        source="breedMother.id"
                        reference="Breed"
                        validate={validateRequired}
                        perPage={400}
                        filter={{animalType: formData.animalType}}
                        sort={{field: 'breedNameBg', order: 'ASC'}}
                        {...rest}
                      >
                        <BreedSelect disabled={isDisabledForOwner} animalType={formData.animalType} name="BreedMother"/>
                      </ReferenceInput>
                    </div>
                  </Fragment>
                ) : null
              }
            </FormDataConsumer>

            <NumberInput source="weight" label={t('weight')} validate={validateRequired}/>


            <TextInput disabled={isDisabledForOwner} source="chipNumber" label={t('chip_number')} validate={validateSerial}
                       parse={value => (value !== '') ? value : null}/>
            <TextInput disabled={isDisabledForOwner} source="tattooNumber" label={t('tattoo_number')} validate={validateSerial}
                       parse={value => (value !== '') ? value : null}/>

            <SelectInput
              source="castrated"
              label={t('castrated')}
              choices={[
                {id: 'yes', name: t('yes')},
                {id: 'no', name: t('no')}
              ]}
              validate={validateRequired}
            />

            <LongTextInput source="comment" label={t('comment')} resettable validate={validateComment}/>
          </FormTab>

          <FormTab label='VIP'>
            <VIPDataForm userRole={options.currentUser.role}/>
          </FormTab>

          <FormTab label={t('profile_picture')}>
            <CustomImageInput
              source="profilePicture"
              label={t('profile_picture')}
              accept='image/jpeg, image/png'
              maxSize={20 * (2 ** 20)} //20MB in bytes
              user={options.currentUser.role}
            />
          </FormTab>

          <FormTab label={t('files')}>
            <CustomFileInput
              accept="application/pdf, application/x-rar-compressed, application/octet-stream, application/zip,
                      application/octet-stream, application/x-zip-compressed, multipart/x-zip"
              maxSize={2e+8} //200 MB in bytes; 200 MB - file size limit for Graph.cool
              {...this.props}
            />
            <ReferenceManyField label={t('files')} reference="File" target="animal">
              <CustomFileInputPreview user={options.currentUser.role}/>
            </ReferenceManyField>
          </FormTab>
        </TabbedForm>
      </Edit>
    )
  }
}

export default AnimalEdit;