import React from 'react';
import Chip from '@material-ui/core/Chip';
import { t } from 'i18next';
import PropTypes from 'prop-types';

const PriceByWeight = ({ record, field }) => {
  if(record && record[field]) {
    return record[field].map((pair, index) => {
      return <Chip key={index} label={`${pair.weight} ${t('kg')} - ${pair.price.toFixed(2)} ${t('lv')}`}/>
    })
  }

  return null;
};

PriceByWeight.propTypes = {
  record: PropTypes.object,
  field: PropTypes.string,
  basePath: PropTypes.string,
  resource: PropTypes.string,
  label: PropTypes.string
};

export default PriceByWeight;