import React from 'react';
import { SimpleForm, TextInput } from 'react-admin';
import { t } from 'i18next';

import TasteImageInput from './TasteImageInput';
import { isRequired } from '../../utils/validation';

const validateRequired = [isRequired()];

export const TasteForm = props => (
  <SimpleForm {...props} redirect='list'>
    <TextInput source='name' label={t('name')} validate={validateRequired}/>
    <TasteImageInput
      source="picture"
      label={t('tastes.picture')}
      accept='image/jpeg, image/png'
      maxSize={5*(2**20)} //5MB in bytes
    />
  </SimpleForm>
);