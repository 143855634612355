import React, { Component } from 'react';
import { Button, REDUX_FORM_NAME } from 'react-admin';
import ContentSave from '@material-ui/icons/Save';
import { showNotification } from 'ra-core';
import { connect } from 'react-redux';
import { t } from 'i18next';
import apolloClient from './../initApollo';
import { CREATE_OWNER_NODE, CREATE_VET_NODE, CREATE_CLINIC_NODE, CREATE_ADMIN_OR_MANAGER_NODE } from '../customQueries';

class CustomCreateButton extends Component {
  handleClick = () => {
    const {
      invalid,
      showNotification,
      handleSubmitWithRedirect,
      redirect,
      resource,
      formData,
    } = this.props;

    if (invalid) {
      showNotification('ra.message.invalid_form', 'warning');
      handleSubmitWithRedirect(redirect)();
    } else {
      apolloClient.mutate({ mutation: this.getQueryName(resource), variables: this.getData(resource, formData) })
        .then(response => {
          if (response.loading) return;
          this.props.history.push(`/${resource}`);
        })
        .catch(error => {
          const graphQLError = error.graphQLErrors ? error.graphQLErrors.pop() : null;

          if (graphQLError) {
            graphQLError.code === 3010 ? showNotification(t('unique_constraint_message'), 'warning') : showNotification(graphQLError.message, 'warning');
          } else {
            throw Error(error);
          }
        })
    }
  };

  getQueryName = resource => {
    switch (resource) {
      case 'Owner':
        return CREATE_OWNER_NODE;
      case 'Vet':
        return CREATE_VET_NODE;
      case 'Clinic':
        return CREATE_CLINIC_NODE;
      case 'Admin':
      case 'ProductionManager':
        return CREATE_ADMIN_OR_MANAGER_NODE(resource);
      default:
        throw Error('Resource not found!');
    }
  };

  getData = (resource, form) => {
    switch (resource) {
      case 'Owner':
        return this.getOwnerData(form);
      case 'Vet':
        return this.getVetData(form);
      case 'Clinic':
        return this.getClinicData(form);
      case 'Admin':
      case 'ProductionManager':
        return this.getAdminOrManagerData(form);
      default:
        throw Error('Resource not found!');
    }
  };

  getOwnerData = form => {
    return {
      active: form.active,
      email: form.email,
      name: form.name,
      city: form.city.id,
      street: form.street,
      streetNumber: form.streetNumber,
      buildingNumber: form.buildingNumber,
      district: form.district,
      entrance: form.entrance,
      floor: form.floor,
      apartmentNumber: form.apartmentNumber,
      country: form.countryId,
      postcode: form.postcode,
      phoneNumber: form.phoneNumber,
      vet: form.vet.id
    }
  };

  getVetData = form => {
    return {
      active: form.active,
      email: form.email,
      name: form.name,
      phoneNumber: form.phoneNumber,
      postcode: form.postcode,
      street: form.street,
      streetNumber: form.streetNumber,
      buildingNumber: form.buildingNumber,
      district: form.district,
      entrance: form.entrance,
      floor: form.floor,
      apartmentNumber: form.apartmentNumber,
      city: form.city.id,
      clinic: form.clinic.id
    }
  };

  getClinicData = form => {
    return {
      active: form.active,
      email: form.email,
      name: form.name,
      lat: form.lat,
      lng: form.lng,
      phoneNumber: form.phoneNumber,
      postcode: form.postcode,
      street: form.street,
      streetNumber: form.streetNumber,
      buildingNumber: form.buildingNumber,
      district: form.district,
      entrance: form.entrance,
      floor: form.floor,
      apartmentNumber: form.apartmentNumber,
      city: form.city.id
    }
  };

  getAdminOrManagerData = form => {
    return {
      active: form.active,
      notifications: form.notifications,
      email: form.email,
      name: form.name,
      phoneNumber: form.phoneNumber,
    }
  }

  render() {
    return (
      <Button
        label='ra.action.save'
        onClick={this.handleClick}
        color='primary'
        variant='raised'
        size="medium"
      >
        <ContentSave />
      </Button>
    );
  }
}

const mapFormStateToProps = state => {
  let formData = null;
  let form = state.form[REDUX_FORM_NAME];
  if (form) {
    formData = form.values || null
  }

  return { formData };
};

export default connect(mapFormStateToProps, { showNotification })(CustomCreateButton);