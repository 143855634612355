import React, { Fragment } from 'react';
import { startUndoable, crudDeleteMany } from 'ra-core';
import { Button } from 'react-admin';
import ConfirmationDialog from './ConfirmationDialog';
import PropTypes from 'prop-types';
import ActionDelete from '@material-ui/icons/Delete';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { t } from 'i18next';

const styles = {
    button: {
        color: '#f44336'
    },
};

class BulkDeleteButtonWithConfirmation extends React.Component {
    state = {
        showDialog: false
    };

    handleClick = () => {
        this.setState({ showDialog: true });
    };

    handleCloseClick = () => {
        this.setState({ showDialog: false });
    };

    handleDelete = () => {
        this.setState({ showDialog: false });
        const {
            undoable,
            startUndoable,
            resource,
            selectedIds,
            basePath,
            dispatchCrudDeleteMany,
        } = this.props;

        if (undoable) {
            startUndoable(crudDeleteMany(resource, selectedIds, basePath));
        } else {
            dispatchCrudDeleteMany(resource, selectedIds, basePath);
        }
    };

    render() {
        const { label, classes, icon } = this.props;
        return (
            <Fragment>
                <Button
                    onClick={this.handleClick}
                    label={label}
                    className={classes.button}
                >
                    {icon}
                </Button>
                <ConfirmationDialog
                    isOpen={this.state.showDialog}
                    message={t('delete_confirmation.confirm_message_pl')}
                    handleAction={this.handleDelete}
                    label={label}
                    classes={classes}
                    icon={icon}
                    handleClose={this.handleCloseClick}
                    cancelLabel='ra.action.cancel'
                    title={true}
                />
            </Fragment>
        );
    }
}

BulkDeleteButtonWithConfirmation.propTypes = {
    basePath: PropTypes.string,
    classes: PropTypes.object,
    dispatchCrudDeleteMany: PropTypes.func.isRequired,
    label: PropTypes.string,
    selectedIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    resource: PropTypes.string.isRequired,
    startUndoable: PropTypes.func,
    undoable: PropTypes.bool,
    icon: PropTypes.element,
};

BulkDeleteButtonWithConfirmation.defaultProps = {
    undoable: true,
    label: 'ra.action.delete',
    icon: <ActionDelete />,
};

export default compose(
    connect(
        null,
        {
            startUndoable,
            dispatchCrudDeleteMany: crudDeleteMany,
        }
    ),
    withStyles(styles)
)(BulkDeleteButtonWithConfirmation);