import React, { createElement } from 'react';
import { connect } from 'react-redux';
import { MenuItemLink, getResources } from 'react-admin';
import { t } from 'i18next';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Settings from '@material-ui/icons/Settings';
import ViewList from '@material-ui/icons/ViewList';
import Dashboard from '@material-ui/icons/Dashboard';
import { withStyles } from '@material-ui/core/styles';

const mainItems = ['Statistics', 'Clinic', 'Vet', 'Owner', 'Animal', 'Breed', 'Order', 'Material'];
const toSkip = ['Recipe'];

const styles = {
  root: {
    width: 'auto',
    fontSize: '1rem',
    boxSizing: 'content-box',
    fontWeight: 400,
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    lineHeight: '1.5em',
    paddingLeft: 16,
    textOverflow: 'ellipsis',
    paddingRight: 16,
    color: 'rgba(0, 0, 0, 0.54)',
  },
  menuItemRoot: {
    overflow: 'inherit',
    whiteSpace: 'normal',
    height: 'auto',
    lineHeight: '1.1em',
    alignItems: 'center',
  },
  listItem: {
    paddingLeft: '1rem',
  },
  listItemText: {
    paddingLeft: 2,
    fontSize: '1rem',
  },
  listItemTextPrimary: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  sidebarIsOpen: {
    paddingLeft: 24,
    transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
  },
  sidebarIsClosed: {
    paddingLeft: 0,
    transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
  },
};

class SideMenu extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  renderListItems() {
    const { classes, resources, onMenuClick } = this.props;
    let main = [];
    let settings = [];

    for (const item of resources) {
      if (item.options && item.options.label) {
        if (!toSkip.includes(item.name)) {
          if (mainItems.includes(item.name)) {
            main.push(
              <MenuItemLink
                key={item.name}
                to={`/${item.name}`}
                primaryText={item.options.label}
                onClick={onMenuClick}
                leftIcon={item.icon ? createElement(item.icon) : <ViewList />}
                classes={{ root: classes.menuItemRoot }}
              />
            );
          } else {
            settings.push(
              <MenuItemLink
                key={item.name}
                to={`/${item.name}`}
                primaryText={item.options.label}
                onClick={onMenuClick}
                leftIcon={item.icon ? createElement(item.icon) : <ViewList />}
                classes={{ root: classes.menuItemRoot }}
              />
            );
          }
        }
      }
    }

    return { main, settings };
  }

  render() {
    const { classes, onMenuClick } = this.props;
    const { isOpen } = this.state;
    const listItems = this.renderListItems();

    return (
      <div>
        {/* {samo admin} */}
        {(window.currentUser.role && window.currentUser.role === 'Admin') &&
          <MenuItemLink
            key='home'
            to='/'
            primaryText={t('main_menu.dashboard')}
            onClick={onMenuClick}
            leftIcon={<Dashboard />}
          />
        }
        {listItems.main}
        {listItems.settings.length > 0 &&
          <React.Fragment>
            <ListItem
              dense
              button
              onClick={() => this.setState({ isOpen: !isOpen })}
              className={classes.listItem}
              classes={{ root: classes.root }}
            >
              <ListItemIcon>{<Settings />}</ListItemIcon>
              <ListItemText
                inset
                primary={t('main_menu.settings')}
                className={classes.listItemText}
                classes={{ primary: classes.listItemTextPrimary }}
              />
              {isOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
              <List
                dense
                component="div"
                disablePadding
                className={
                  isOpen
                    ? classes.sidebarIsOpen
                    : classes.sidebarIsClosed
                }
              >
                {listItems.settings}
              </List>
            </Collapse>
          </React.Fragment>
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  resources: getResources(state),
});

export default withStyles(styles)(connect(mapStateToProps)(SideMenu));