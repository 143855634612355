import React from 'react';
import { Edit } from 'react-admin';
import { t } from 'i18next';

import { CustomEditToolbar } from '../CustomEditToolbar';
import EditFormTitle from '../EditFormTitle';
import { AgeGroupDogForm } from './AgeGroupDogForm';

export const AgeGroupDogEdit = props => (
  <Edit {...props} title={<EditFormTitle formName={t('age_group.dog_title')}/>}>
    <AgeGroupDogForm toolbar={<CustomEditToolbar user={props.options.currentUser.role}/>}/>
  </Edit>
);
