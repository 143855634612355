import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  Filter,
  TextInput
} from 'react-admin';
import { t } from 'i18next';

import { CustomBulkActions } from '../CustomBulkActions';
import { CustomListActions } from '../CustomListActions';

const MaterialFilter = props => (
  <Filter {...props}>
    <TextInput label={t('name')} source='name_contains' alwaysOn/>
  </Filter>
);

export const MaterialList = props => (
  <List
    {...props}
    bulkActionButtons={<CustomBulkActions />}
    actions={<CustomListActions />}
    title={t('material_details.title_list')}
    filters={<MaterialFilter/>}
  >
    <Datagrid rowClick='show'>
      <TextField source='name' label={t('name')} />
      <NumberField source='moisture' label={t('material_details.moisture_percent')}/>
      <NumberField source='price' label={t('material_details.price_unit')} />
    </Datagrid>
  </List>
);
