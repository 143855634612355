import React, { Component } from 'react';
import { NumberInput } from 'react-admin';
import { t } from 'i18next';
import { withRouter } from 'react-router';

class NumberBetweenInput extends Component {
  styles = {
    row: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      maxWidth: 160,
    },
    field: {
      marginRight: 15
    }
  };

  componentWillUnmount() {
    const { setFilters, filterValues, source, history } = this.props;
    const pathname = history.location.pathname;

    if (pathname.includes('/Animal') && !pathname.includes('/show')) {
      if (setFilters && typeof setFilters === 'function') {
        if (filterValues) {
          let newValues = Object.assign({}, filterValues);

          delete newValues[`${source}_gte`];
          delete newValues[`${source}_lte`];

          setFilters(newValues);
        }
      }
    }
  }

  render() {
    const { source } = this.props;

    return (
      <span style={this.styles.row}>
        <NumberInput
          style={this.styles.field}
          source={`${source}_gte`}
          label={t('weight_from')}
        />
        <NumberInput
          source={`${source}_lte`}
          label={t('weight_to')}
        />
      </span>
    );
  }
}

NumberBetweenInput.defaultProps = {
  addLabel: true,
  label: 'weight',
};

export default withRouter(NumberBetweenInput);
