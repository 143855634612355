import React from 'react';
import { List, Datagrid, NumberField, TextField } from 'react-admin';
import { t } from 'i18next';

import { CustomBulkActions } from '../CustomBulkActions';
import { CustomListActions } from '../CustomListActions';
import PriceByWeight from '../fields/PriceByWeight';

export const AgeGroupCatList = props => (
  <List {...props}
    sort={{ field: 'months', order: 'ASC' }}
    bulkActionButtons={<CustomBulkActions />}
    actions={<CustomListActions />}
    title={t('age_group.cat_title_list')}
  >
    <Datagrid rowClick='show'>
      <TextField source='ageGroup' label={t('age_group.age_group')}/>
      <NumberField source='months' label={t('age_in_months')}/>
      <PriceByWeight label={t('age_group.price_by_weight_general')} field='priceByWeightGeneral'/>
      <PriceByWeight label={t('age_group.price_by_weight')} field='priceByWeight'/>
      <PriceByWeight label={t('age_group.price_by_weight_VIP')} field='priceByWeightVIP'/>
    </Datagrid>
  </List>
);