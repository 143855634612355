import React from 'react';
import { t } from 'i18next';
import { Edit } from 'react-admin';

import EditFormTitle from '../EditFormTitle';
import { MaterialForm } from './MaterialForm';
import { CustomEditToolbar } from '../CustomEditToolbar';

export const MaterialEdit = props => (
  <Edit {...props} title={<EditFormTitle formName={t("material")} />}>
    <MaterialForm toolbar={<CustomEditToolbar user={props.options.currentUser.role} />} />
  </Edit>
);
