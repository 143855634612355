import React from 'react';
import { t } from 'i18next';
import { Edit } from 'react-admin';
import EditFormTitle from '../EditFormTitle';
import { ProvinceForm } from './ProvinceForm';
import { CustomEditToolbar } from '../CustomEditToolbar';

export const ProvinceEdit = props => (
  <Edit {...props} title={<EditFormTitle formName={t('province')} />}>
    <ProvinceForm toolbar={<CustomEditToolbar user={props.options.currentUser.role}/>}/>
  </Edit>
);
