import React from 'react';
import {
  Filter,
  TextInput,
  SelectInput
} from 'react-admin';
import { t } from 'i18next';

export const OrderFilter = props => (
  <Filter {...props}>
    <TextInput label={t('number')} source="idNumber_contains" />
    <TextInput label={t('animal')} source="animal.name_contains" />
    {props.user.role !== 'Owner' ? <TextInput label={t('owner')} source="owner.name_contains" /> : null}
    {props.user.role !== 'Vet' && props.user.role !== 'Owner' ? <TextInput label={t('vet')} source="vet.name_contains" /> : null}
    {props.user.role !== 'Clinic' && props.user.role !== 'Vet' && props.user.role !== 'Owner'
      ? <TextInput label={t('clinic_name')} source="clinic.name_contains" />
      : null}
    {props.user.role !== 'Clinic' && props.user.role !== 'Vet' && props.user.role !== 'Owner' ?
      <SelectInput
        label={t('recipe')}
        source='hasRecipe'
        choices={[
          { id: 'yes', name: t('with_recipe') },
          { id: 'no', name: t('without_recipe') },
        ]}
      />
      : null}
  </Filter>
);