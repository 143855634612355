import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
  NumberField,
  BooleanField,
  SelectInput,
  BooleanInput
} from 'react-admin';
import {t} from 'i18next';

import {CustomListActions} from '../CustomListActions';
import TranslationField from '../fields/TranslationField';

const BreedFilter = props => (
  <Filter {...props}>
    <TextInput label={t('name_bg')} source="breedNameBg_contains" alwaysOn/>
    <TextInput label={t('name_en')} source="breedNameEn_contains" alwaysOn/>
    <SelectInput
      label={t('animal_type')}
      source='animalType'
      choices={[
        {id: 'Dog', name: t('dog')},
        {id: 'Cat', name: t('cat')},
      ]}
    />
    <SelectInput
      label={t('size_class')}
      source='sizeClass'
      choices={[
        { id: 'Mini', name: t('size_classes.mini') },
        { id: 'Small', name: t('size_classes.small') },
        { id: 'Medium', name: t('size_classes.medium') },
        { id: 'Large', name: t('size_classes.large') },
        { id: 'Giant', name: t('size_classes.giant') }
      ]}
    />
    <BooleanInput label={t("breeds.supported_by_optimus")} source="supportedByOptimus" />
  </Filter>
);

export const BreedList = props => (
  <List {...props}
        filters={<BreedFilter/>}
        bulkActionButtons={false}
        actions={<CustomListActions user={props.options.currentUser}/>}
        title={t('breeds.title_list')}
  >
    <Datagrid rowClick="show">
      <TranslationField source="animalType" label={t('animal_type')}/>
      <TextField source="breedNameBg" label={t('name_bg')}/>
      <TextField source="breedNameEn" label={t('name_en')}/>
      <TextField source="sizeClass" label={t('size_class')}/>
      <BooleanField source="supportedByOptimus" label={t('breeds.supported_by_optimus')}/>
      {props.options.currentUser.role === 'Admin' ?
        <NumberField source="priceCoefficient" label={t('breeds.price_coefficient')}
                     options={{maximumFractionDigits: 12}}/> : null}
    </Datagrid>
  </List>
);
