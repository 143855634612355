import React from 'react';
import { Edit } from 'react-admin';
import { t } from 'i18next';

import EditFormTitle from '../EditFormTitle';
import { NutrientForm } from './NutrientForm';
import { CustomEditToolbar } from '../CustomEditToolbar';

export const NutrientEdit = props => (
  <Edit {...props} title={<EditFormTitle formName={t('nutrient')} />}>
    <NutrientForm toolbar={<CustomEditToolbar user={props.options.currentUser.role}/>} />
  </Edit>
);
