import React from 'react';
import { t } from 'i18next';
import {
  SimpleForm,
  TextInput
} from 'react-admin';

import { isRequired } from '../../utils/validation';

const validateRequired = [isRequired()];

export const ProvinceForm = props => (
  <SimpleForm {...props} redirect="list">
    <TextInput label={t("name")} source="name" validate={validateRequired}/>
    <TextInput label={t("code_column")} source="code" validate={validateRequired}/>
  </SimpleForm>
);
