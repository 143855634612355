import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from "apollo-cache-inmemory";
import { WebSocketLink } from 'apollo-link-ws';
import { ApolloLink, split } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';
import { setContext } from 'apollo-link-context';

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_PRISMA_URL
});

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_PRISMA_SUBSCRIPTIONS_URL,
  options: {
    reconnect: true
  }
});

const terminatingLink = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return (
      kind === 'OperationDefinition' && operation === 'subscription'
    );
  },
  wsLink,
  httpLink
);

const authLink = setContext((_, { headers }) => {
  const token = sessionStorage.getItem('token_data')
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  }
});

const link = ApolloLink.from([terminatingLink]);
const mainLink = authLink.concat(link);

const client = new ApolloClient({
  link: mainLink,
  cache: new InMemoryCache()
});

export default client;