import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { t } from 'i18next';

const TranslationField  = ({ source, record = {} }) => {
  const value = record[source];
  if (!value) return '';

  return <Typography>{t(value.toLowerCase())}</Typography>;
};

TranslationField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  label: PropTypes.string,
  addLabel: PropTypes.bool,
  basePath: PropTypes.string,
  resource: PropTypes.string
};

TranslationField.defaultProps = {
  addLabel: true
};

export default TranslationField;