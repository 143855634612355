import React from 'react';
import { Show, NumberField, SimpleShowLayout } from 'react-admin';
import { t } from 'i18next';

export const SizeClassMixShow = props => (
  <Show {...props} title={t('size_class_mix.title')}>
    <SimpleShowLayout>
      <NumberField source="months" label={t('age_in_months')}/>
      <NumberField source="mini" label={t('size_class_mix.mini_kg')}/>
      <NumberField source="small" label={t('size_class_mix.small_kg')}/>
      <NumberField source="medium" label={t('size_class_mix.medium_kg')}/>
      <NumberField source="large" label={t('size_class_mix.large_kg')}/>
      <NumberField source="giant" label={t('size_class_mix.giant_kg')}/>
    </SimpleShowLayout>
  </Show>
);
