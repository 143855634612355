import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { addField } from 'ra-core';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CustomTextField from './CustomTextField';
// import {isRequired} from "../../utils/validation";

class CustomDateInput extends Component {
  constructor() {
    super();
    this.onChange = this.onChange.bind(this)
  }

  onChange = date => {
    this.props.input.onChange(date);
    this.props.input.onBlur();
  };

  render() {
    const { input, label, meta, disabled, minDate, maxDate, className, position, isRequired } = this.props;

    return (
      <DatePicker
        customInput={
          <CustomTextField
            label={label + (isRequired ? ' *' : '')}
            error={meta.touched && !!meta.error}
            helperText={meta.touched && meta.error}
            inputProps={{ readOnly: true }}

          />
        }
        popperPlacement={position || "top-end"}
        popperModifiers={{
          offset: {
            enabled: true,
            offset: "5px, 10px"
          },
          preventOverflow: {
            enabled: true,
            escapeWithReference: false,
            boundariesElement: "viewport"
          }
        }}
        className={className}
        dateFormat='dd/MM/yyyy'
        selected={input.value ? new Date(input.value) : null}
        onChange={this.onChange}
        minDate={minDate}
        maxDate={maxDate}
        showMonthDropdown
        showYearDropdown
        dropdownMode='select'
        disabled={disabled}
        isClearable={!disabled}
      />
    );
  }
}

CustomDateInput.propTypes = {
  label: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.object,
  disabled: PropTypes.bool,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  basePath: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  isRequired: PropTypes.bool,
  record: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string,
  allowEmpty: PropTypes.bool,
  component: PropTypes.func,
  position: PropTypes.string,
};

export default addField(CustomDateInput);
