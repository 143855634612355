import React from 'react';
import { Create } from 'react-admin';
import { t } from 'i18next';

import CustomCreateToolbar from '../CustomCreateToolbar';
import { ConsultingFeePercentageClinicForm } from './ConsultingFeePercentageClinicForm';

export const ConsultingFeePercentageClinicCreate = props => (
  <Create {...props} title={t('consulting_fee_details.consulting_fee_percentage_create_clinic')}>
    <ConsultingFeePercentageClinicForm toolbar={<CustomCreateToolbar user={props.options.currentUser.role}/>}/>
  </Create>
);