import React, { Component, Fragment } from 'react';
import { Labeled, REDUX_FORM_NAME, TextInput } from 'react-admin';
import Dropzone from 'react-dropzone';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { t } from 'i18next';

import DeleteFileButtonWithConfirmation from '../DeleteFileButtonWithConfirmation';
import { validateAlwaysFail } from '../../utils/validation';
import { UPLOAD_FILE_TO_S3 } from "../../customQueries";
import apolloClient from '../../initApollo';

const validateRequired = [validateAlwaysFail()];

const styles = {
  dropZone: {
    background: '#efefef',
    cursor: 'pointer',
    padding: '1rem',
    marginTop: 15,
    textAlign: 'center',
    color: '#999',
  },
  preview: {
    margin: '0.5rem',
    'max-height': '10rem',
    display: 'block',
  },
  hiddenInput: {
    display: 'none'
  },
  redText: {
    color: '#ff0000'
  }
};

class TasteImageInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      picture: null
    }
  }

  uploadFile = (acceptedFile, fileContent) => {
    apolloClient.mutate({
      mutation: UPLOAD_FILE_TO_S3,
      variables: { fileName: acceptedFile.name, fileSize: acceptedFile.size, fileType: acceptedFile.type, fileContent }
    }).then(response => {
      if (response.loading) return;

      const { url } = response.data.uploadToS3;

      if(url !== '') {
        this.setState({ picture: url })
      }
    });
  };

  onDrop = acceptedFiles => {
    acceptedFiles.forEach((acceptedFile) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        this.uploadFile(acceptedFile, btoa(reader.result));
      };
      reader.readAsBinaryString(acceptedFile);
    });
  };

  hideImage = () => {
    this.props.formData.picture = null;
    this.setState({ picture: null });
  };

  render() {
    const {
      accept,
      classes,
      maxSize,
      label,
      formData,
      source,
      resource
    } = this.props;

    if (this.state && this.state.picture && formData) {
      formData.picture = this.state.picture;
    }

    const hasStatePicture = !!(this.state && this.state.picture);
    const hasFormDataPicture = !!(formData && formData.picture);
    const required = !(hasStatePicture || hasFormDataPicture);
    const showImage = hasStatePicture || hasFormDataPicture;
    const showError = !!(!hasStatePicture && !hasFormDataPicture && this.props.pictureError);

    return (
      <Fragment>
        <Labeled label={label}>
          <Dropzone
            onDrop={this.onDrop}
            accept={accept}
            maxSize={maxSize}
            multiple={false}
          >
            {({ getRootProps, getInputProps }) => {
              return (
                <div className={classes.dropZone} {...getRootProps()}>
                  <input {...getInputProps()}/>
                  <p>{t('image_input.drop_image_or_click')}</p>
                </div>
              )
            }}
          </Dropzone>
        </Labeled>
        { showError ? <p className={classes.redText}>{t('tastes.picture_required')}</p> : null}
        <TextInput source={source} className={classes.hiddenInput} validate={required ? validateRequired : null}/>
        { showImage ?
          <Fragment>
            <img src={hasStatePicture ? this.state.picture : hasFormDataPicture ? formData.picture : null} alt={label} className={classes.preview}/>
            <DeleteFileButtonWithConfirmation
              resource={resource}
              callback={this.hideImage.bind(this)}
              label={t('delete_image_button.delete_image')}
              message={t('delete_image_confirmation.confirm_message')}
            />
          </Fragment>
        : null}
      </Fragment>
    );
  }
}

TasteImageInput.propTypes = {
  source: PropTypes.string,
  label: PropTypes.string,
  accept: PropTypes.string,
  maxSize: PropTypes.number
};

const mapFormStateToProps = state => {
  let formData = null;
  let pictureError = false;
  let form = state.form[REDUX_FORM_NAME];
  if (form) {
    formData = form.values;
    pictureError = !!(form.syncErrors && form.syncErrors.picture && form.submitFailed);
  }

  return { formData, pictureError };
};

export default compose(connect(mapFormStateToProps, {}), withStyles(styles))(TasteImageInput);