import React from 'react';
import { Edit, SimpleForm, BooleanInput } from 'react-admin';
import { t } from 'i18next';

import EditFormTitle from '../EditFormTitle';
import { CustomEditToolbar } from '../CustomEditToolbar';

export const FoodRunningOutNotificationEdit = props => (
  <Edit {...props} title={<EditFormTitle formName={t('food_running_out.food_running_out')}/>}>
    <SimpleForm redirect='/' toolbar={<CustomEditToolbar/>}>
      <BooleanInput source='seen' label={t('food_running_out.seen')} defaultValue={false}/>
    </SimpleForm>
  </Edit>
);