import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStream } from '@fortawesome/free-solid-svg-icons';

import { BreedList } from '../components/Breed/BreedList';
import { BreedShow } from '../components/Breed/BreedShow';
import { BreedCreate } from '../components/Breed/BreedCreate';
import { BreedEdit } from '../components/Breed/BreedEdit';

export const Breeds = {
  icon: () => <FontAwesomeIcon style={{marginLeft: '5px', marginRight: '3px'}} icon={faStream}/>,
  list: BreedList,
  show: BreedShow,
  create: BreedCreate,
  edit: BreedEdit
};