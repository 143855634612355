import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Button, Link } from 'react-admin';
import IconCancel from '@material-ui/icons/Cancel';
import IconEdit from '@material-ui/icons/Edit';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

class ConfirmationDialog extends Component {
  render() {
    const {
      isOpen,
      message,
      handleAction,
      label,
      classes,
      icon,
      handleClose,
      cancelLabel,
      title,
      animalEditTitle,
      animalContent,
      animalProp,
      animalPropValue,
      buttonLink,
      pathname,
      onlyCancel
    } = this.props;
    let { state } = this.props;

    state = {
      ...state,
      prevPath: window.location.hash
    }

    return (
      <Dialog open={isOpen}>
        {title ? <DialogTitle>{t('delete_confirmation.dialog_title')}</DialogTitle> : null}
        <DialogContent>
          <div>
            <Typography variant="headline">{message}</Typography>
            {animalContent ? Object.entries(animalContent).map(([key, value]) => {
              if (key === '__typename' || key === 'id') return null;

              if (!value) return null;

              const allowedKeys = ['animalType', 'breed', 'name', 'gender', 'birthDate', 'tattooNumber', 'chipNumber', 'castrated', 'weight'];

              if (allowedKeys.indexOf(key) === -1) return null;

              let primaryText = t(`${animalProp(key)}`);
              let secondaryText = value ? animalPropValue(key, value) : t('null');

              return (
                <ListItem key={key}>
                  <ListItemText primary={primaryText} secondary={secondaryText} />
                </ListItem>
              );
            }) : null}
          </div>
        </DialogContent>
        <DialogActions>
          {buttonLink ?
            <Button
              label={label}
              onClick={e => e.stopPropagation()}
              component={Link}
              to={{ pathname, state }}
            >
              {icon}
            </Button>
            : !onlyCancel && (<Button
              onClick={handleAction}
              label={label}
              className={classes ? classes.button : null}
            >
              {icon}
            </Button>)
          }
          {animalContent && (
            <Button
              label={animalEditTitle}
              onClick={e => e.stopPropagation()}
              component={Link}
              to={{ pathname: `/Animal/${animalContent.id}`, state: { dialog: true, owner: animalContent.owner.id } }}
            >
              <IconEdit />
            </Button>)}
          <Button label={cancelLabel} onClick={handleClose}><IconCancel /></Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ConfirmationDialog.propTypes = {
  isOpen: PropTypes.bool,
  message: PropTypes.string,
  handleAction: PropTypes.func,
  label: PropTypes.string,
  classes: PropTypes.object,
  icon: PropTypes.element,
  handleClose: PropTypes.func,
  cancelLabel: PropTypes.string,
  title: PropTypes.bool,
  animalEditTitle: PropTypes.string,
  animalContent: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  animalProp: PropTypes.func,
  animalPropValue: PropTypes.func,
  buttonLink: PropTypes.bool,
  pathname: PropTypes.string,
  state: PropTypes.object
};

ConfirmationDialog.defaultProps = {
  isOpen: false
};

export default ConfirmationDialog;