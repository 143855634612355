import { Toll } from '@material-ui/icons';
import { NutrientElementCreate } from '../components/NutrientElement/NutrientElementCreate';
import { NutrientElementEdit } from '../components/NutrientElement/NutrientElementEdit';
import { NutrientElementList } from '../components/NutrientElement/NutrientElementList';

export const NutrientElements = {
  icon: Toll,
  create: NutrientElementCreate,
  edit: NutrientElementEdit,
  list: NutrientElementList,
};
