import React from 'react';
import { Create } from 'react-admin';
import { t } from 'i18next';
import CustomCreateToolbar from '../CustomCreateToolbar';
import { CountryForm } from './CountryForm';

export const CountryCreate = props => (
  <Create {...props} redirect="list" title={t("countries.title_create")}>
    <CountryForm toolbar={<CustomCreateToolbar user={props.options.currentUser.role}/>}/>
  </Create>
);
