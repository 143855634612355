import React, { Fragment, Component } from 'react';
import {
  SimpleForm,
  TextInput,
  SelectInput,
  ReferenceInput,
  BooleanInput
} from 'react-admin';
import { t } from 'i18next';

import apolloClient from '../../initApollo';
import { GET_BG_COUNTRY } from '../../customQueries';
import { isRequired } from '../../utils/validation';

const validateRequired = [isRequired()];

class CityForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      country: ''
    }
  }

  componentDidMount() {
    apolloClient.query({ query: GET_BG_COUNTRY, fetchPolicy: 'no-cache' })
      .then(response => {
        if (response.loading) return;
        this.setState({country: response.data.countries.pop() || ''})
      });
  }

  render() {
    const { country } = this.state;

    return ( country !== '' &&
      <Fragment>
        <SimpleForm {...this.props} redirect="list" defaultValue={{ country: {id: country.id }}}>
          <TextInput source="name" label={t('name')} validate={validateRequired} />
          <ReferenceInput
            label={t('municipality')}
            reference="Municipality"
            source="municipality.id"
            perPage={265}
            validate={validateRequired}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <SelectInput
            label={t('location_type')}
            source="type"
            choices={[
              { id: 'City', name: t('city_select') },
              { id: 'Village', name: t('village_select') },
            ]}
            validate={validateRequired}
          />
          <BooleanInput label={t("city_is_main_province")} source="mainProvinceCity"/>
        </SimpleForm>
      </Fragment>
    )
  }
}

export default CityForm;