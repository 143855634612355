import React from 'react';
import {
  Filter,
  SelectInput,
  ReferenceInput
} from 'react-admin';
import { changeListParams } from 'ra-core';
import { t } from 'i18next';
import BreedSelect from '../components/BreedSelect';

import { change } from 'redux-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

const fields = ['animal', 'breed', 'sizeClass', 'ageGroupName'];
const copyRecipeFilterFields = ['idNumber', 'hasRecipe', 'owner', 'animal', 'clinic', 'vet'];

class CopyRecipeFilter extends React.Component {
  constructor(props) {
    super(props);

    if (props.setPage && typeof props.setPage === 'function') {
      props.setPage(1)
    }
  }

  componentDidMount() {
    // copyRecipe uses order filters
    // reset them on mount
    this.resetFilters(copyRecipeFilterFields);
  }

  componentWillUnmount() {
    const { location } = this.props.history;

    if (location.pathname.includes('/Order')) {
      this.resetFilters(fields);
    } else {
      this.props.resetFilters();
    }
  }

  resetFilters = fieldsArr => {
    fieldsArr.forEach(f => this.props.change(f));
  }

  render() {
    const { change, resetFilters, staticContext, animaltype, ...rest } = this.props;
    return (
      <Filter {...rest}>
        <SelectInput
          source='animal.gender'
          label={t('gender')}
          choices={[
            { id: 'male', name: t('male') },
            { id: 'female', name: t('female') },
          ]}
        />
        <SelectInput
          source='animal.castrated'
          label={t('castrated')}
          choices={[
            { id: 'no', name: t('no') },
            { id: 'yes', name: t('yes') },
          ]}
        />
        <ReferenceInput
          label={t('breed')}
          source='breed.id'
          reference='Breed'
        >
          <BreedSelect breedCopyOrderFilter animalType={animaltype} />
        </ReferenceInput>
        {animaltype === 'Dog' ?
          <SelectInput
            label={t('size_class')}
            source='sizeClass'
            choices={[
              { id: 'Mini', name: t('size_classes.mini') },
              { id: 'Small', name: t('size_classes.small') },
              { id: 'Medium', name: t('size_classes.medium') },
              { id: 'Large', name: t('size_classes.large') },
              { id: 'Giant', name: t('size_classes.giant') }
            ]}
          />
          : <SelectInput
            label={t('size_class')}
            source='sizeClass'
            choices={[
              { id: 'Medium', name: t('size_classes.medium') }
            ]}
          />
        }
        {animaltype === 'Dog' ?
          <SelectInput
            label={t('age_group.age_group')}
            source='ageGroupName'
            choices={[
              { id: 'Puppy', name: t('age_group.puppy') },
              { id: 'Junior', name: t('age_group.junior') },
              { id: 'Adult', name: t('age_group.adult') },
              { id: 'Mature', name: t('age_group.mature') },
              { id: 'Senior', name: t('age_group.senior') },
              { id: 'YoungAdult', name: t('age_group.youngadult') },
              { id: 'MatureAdult', name: t('age_group.matureadult') },
              { id: 'Geriatric', name: t('age_group.geriatric') }
            ]}
          />
          : <SelectInput
            label={t('age_group.age_group')}
            source='ageGroupName'
            choices={[
              { id: 'Kitten', name: t('age_group.kitten') },
              { id: 'Geriatric', name: t('age_group.geriatric') },
              { id: 'YoungAdult', name: t('age_group.youngadult') },
              { id: 'MatureAdult', name: t('age_group.matureadult') },
              { id: 'Senior', name: t('age_group.senior') },
              { id: 'Mature', name: t('age_group.mature') },
              { id: 'Prime', name: t('age_group.prime') },
              { id: 'Junior', name: t('age_group.junior') },
            ]}
          />
        }
      </Filter>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    // dispatching plain actions
    change: (field) => dispatch(change('filterForm', field, null)),
    resetFilters: () => dispatch(changeListParams('Order', { filter: {} })),
  }
}

export default connect(undefined, mapDispatchToProps)(withRouter(CopyRecipeFilter));
