import { TasteCreate } from '../components/Taste/TasteCreate';
import { TasteList } from '../components/Taste/TasteList';
import { TasteEdit } from '../components/Taste/TasteEdit';

import { RestaurantMenu } from '@material-ui/icons';

export const Tastes = {
  list: TasteList,
  create: TasteCreate,
  edit: TasteEdit,
  icon: RestaurantMenu
};