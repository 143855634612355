import React from 'react';
import { Edit } from 'react-admin';
import { t } from 'i18next';

import { CustomEditToolbar } from '../CustomEditToolbar';
import { ConsultingFeePercentageClinicForm } from './ConsultingFeePercentageClinicForm';

export const ConsultingFeePercentageClinicEdit = props => (
  <Edit {...props} title={t('consulting_fee_details.consulting_fee_percentage_clinic')}>
    <ConsultingFeePercentageClinicForm toolbar={<CustomEditToolbar user={props.options.currentUser.role}/>}/>
  </Edit>
);