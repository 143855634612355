import React from 'react';
import { List, Datagrid, NumberField, TextField, Filter, SelectInput } from 'react-admin';
import { t } from 'i18next';

import { CustomBulkActions } from '../CustomBulkActions';
import { CustomListActions } from '../CustomListActions';
import PriceByWeight from '../fields/PriceByWeight';

const AgeGroupDogFilter = props => (
  <Filter {...props}>
    <SelectInput
      alwaysOn
      label={t('size_class')}
      source='sizeClass'
      choices={[
        { id: 'Mini', name: t('size_classes.mini') },
        { id: 'Small', name: t('size_classes.small') },
        { id: 'Medium', name: t('size_classes.medium') },
        { id: 'Large', name: t('size_classes.large') },
        { id: 'Giant', name: t('size_classes.giant') }
      ]}
    />
    <SelectInput
      alwaysOn
      label={t('age_group.age_group')}
      source='ageGroup'
      choices={[
        { id: 'Puppy', name: t('age_group.puppy') },
        { id: 'Junior', name: t('age_group.junior') },
        { id: 'Adult', name: t('age_group.adult') },
        { id: 'Mature', name: t('age_group.mature') },
        { id: 'Senior', name: t('age_group.senior') },
        { id: 'YoungAdult', name: t('age_group.youngadult') },
        { id: 'MatureAdult', name: t('age_group.matureadult') },
        { id: 'Geriatric', name: t('age_group.geriatric') }
      ]}
    />
  </Filter>
);

export const AgeGroupDogList = props => (
  <List {...props}
    bulkActionButtons={<CustomBulkActions />}
    actions={<CustomListActions />}
    title={t('age_group.dog_title_list')}
    filters={<AgeGroupDogFilter/>}
  >
    <Datagrid rowClick='show'>
      <TextField source='sizeClass' label={t('size_class')}/>
      <TextField source='ageGroup' label={t('age_group.age_group')}/>
      <NumberField source='months' label={t('age_in_months')}/>
      <PriceByWeight label={t('age_group.price_by_weight_general')} field='priceByWeightGeneral'/>
      <PriceByWeight label={t('age_group.price_by_weight')} field='priceByWeight'/>
      <PriceByWeight label={t('age_group.price_by_weight_VIP')} field='priceByWeightVIP'/>
    </Datagrid>
  </List>
);
