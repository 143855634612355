import React, { Component } from 'react';
import { Edit } from 'react-admin';
import { t } from 'i18next';

import EditFormTitle from '../components/EditFormTitle';
import { LabelSettingsToolbar } from '../components/LabelSettingsToolbar';
import { LabelSettingsForm } from '../components/Label/LabelSettingsForm';
import { GET_RECIPE } from '../customQueries';
import apolloClient from '../initApollo';

class LabelSettings extends Component {
  constructor(props) {
    super();

    this.state = {
      recipe: null,
      recipeFormName: t('recipe'),
      hiddenNutrients: [],
    };

    this.createRecipeFormName(props.match.params.recipeId);
  };

  createRecipeFormName = id => {
    apolloClient.query({ query: GET_RECIPE, variables: { id }, fetchPolicy: 'no-cache' })
      .then(response => {
        if (response.loading) return;

        const recipe = response.data.recipe;
        const animal = recipe.order.animal;
        let name = '';
        let breed = null;
        let animalType;
        let recipeFormName = '';
        if (animal) {
          name = animal.name;
          breed = animal.breed.breedNameBg;
          animalType = animal.animalType;
          recipeFormName = t('recipes.title_label_settings', { name, type: t(animalType.toLowerCase()), breed, orderNumber: recipe.order.idNumber });
        } else {
          if (recipe.order.breed) {
            breed = recipe.order.breed.breedNameBg;
          }
          animalType = recipe.order.animalType;
          recipeFormName = t('recipes.title_clinic_order', { type: t(animalType.toLowerCase()), detail: breed || recipe.order.sizeClass, ageGroup: recipe.order.ageGroupName });
        }

        const hiddenNutrients = recipe.hiddenNutrients || [];

        this.setState({
          recipe,
          recipeFormName,
          hiddenNutrients,
        });
      });
  };

  updateHiddenNutrientsState = hiddenNutrients => {
    this.setState({ hiddenNutrients });
  };

  render = () => {
    const { location, match } = this.props;
    const { recipe, recipeFormName, hiddenNutrients } = this.state;

    if (!recipe) return null;

    return (
      <Edit
        resource='Recipe'
        basePath='/Recipe'
        title={<EditFormTitle formName={recipeFormName} />}
        location={location}
        id={match.params.recipeId}
        match={match}
      >
        <LabelSettingsForm
          toolbar={<LabelSettingsToolbar recipe={recipe} hiddenNutrients={hiddenNutrients} />}
          hiddenNutrients={hiddenNutrients}
          updateHiddenNutrientsState={this.updateHiddenNutrientsState.bind(this)}
        />
      </Edit>
    );
  }
}

export default LabelSettings;
