import { AttachFile } from '@material-ui/icons';

import { IngredientList } from '../components/Ingredient/IngredientList';
import { IngredientEdit } from '../components/Ingredient/IngredientEdit';
import { IngredientCreate } from '../components/Ingredient/IngredientCreate';

export const Ingredients = {
  icon: AttachFile,
  list: IngredientList,
  create: IngredientCreate,
  edit: IngredientEdit
};