import React, { Component } from 'react';
import { t } from 'i18next';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';

import { GET_ALL_EXCHANGE_WEIGHT } from '../../customQueries';
import apolloClient from '../../initApollo';
import { ExchangeWeightList } from './ExchangeWeightList';

class ExchangeWeightBySizeClass extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: 0,
      exchangeWeightMini: [],
      exchangeWeightSmall: [],
      exchangeWeightMedium: [],
      exchangeWeightLarge: [],
      exchangeWeightGiant: [],
    };
  }

  componentDidMount() {
    apolloClient.query({ query: GET_ALL_EXCHANGE_WEIGHT('Mini'), fetchPolicy: 'network-only'}).then(response => {
      this.setState({ exchangeWeightMini: response.data.exchangeWeightMinis })
    });

    apolloClient.query({ query: GET_ALL_EXCHANGE_WEIGHT('Small'), fetchPolicy: 'network-only'}).then(response => {
      this.setState({ exchangeWeightSmall: response.data.exchangeWeightSmalls })
    });

    apolloClient.query({ query: GET_ALL_EXCHANGE_WEIGHT('Medium'), fetchPolicy: 'network-only'}).then(response => {
      this.setState({ exchangeWeightMedium: response.data.exchangeWeightMediums })
    });

    apolloClient.query({ query: GET_ALL_EXCHANGE_WEIGHT('Large'), fetchPolicy: 'network-only'}).then(response => {
      this.setState({ exchangeWeightLarge: response.data.exchangeWeightLarges })
    });

    apolloClient.query({ query: GET_ALL_EXCHANGE_WEIGHT('Giant'), fetchPolicy: 'network-only'}).then(response => {
      this.setState({ exchangeWeightGiant: response.data.exchangeWeightGiants })
    });
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { value } = this.state;

    return (
      <div>
        <AppBar position="static" color='inherit'>
          <Tabs variant="fullWidth" value={value} onChange={this.handleChange} centered={true}>
            <Tab label={t('size_classes.mini')}/>
            <Tab label={t('size_classes.small')}/>
            <Tab label={t('size_classes.medium')}/>
            <Tab label={t('size_classes.large')}/>
            <Tab label={t('size_classes.giant')}/>
          </Tabs>
        </AppBar>
        <Paper>
        {value === 0 && <ExchangeWeightList data={this.state.exchangeWeightMini} history={this.props.history} resource='ExchangeWeightMini'/>}
        {value === 1 && <ExchangeWeightList data={this.state.exchangeWeightSmall} history={this.props.history} resource='ExchangeWeightSmall'/>}
        {value === 2 && <ExchangeWeightList data={this.state.exchangeWeightMedium} history={this.props.history} resource='ExchangeWeightMedium'/>}
        {value === 3 && <ExchangeWeightList data={this.state.exchangeWeightLarge} history={this.props.history} resource='ExchangeWeightLarge'/>}
        {value === 4 && <ExchangeWeightList data={this.state.exchangeWeightGiant} history={this.props.history} resource='ExchangeWeightGiant'/>}
        </Paper>
      </div>
    );
  }
}

export default ExchangeWeightBySizeClass;