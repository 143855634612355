import React, { Component } from 'react';
import {
  Edit,
  TextInput,
  TabbedForm,
  FormTab,
  ReferenceInput,
  email,
  Link,
  FunctionField,
  Button,
  Datagrid,
  TextField,
  NumberField,
  ReferenceArrayField,
  BooleanInput
} from 'react-admin';

import { t } from 'i18next';

import ContentAdd from '@material-ui/icons/Add';
import { CustomEditToolbar } from '../CustomEditToolbar';
import LocationSelect from '../LocationSelect';
import EditFormTitle from '../EditFormTitle';
import { SearchableSelect } from './../SearchableSelect';
import apolloClient from '../../initApollo';
import { GET_BG_COUNTRY } from '../../customQueries';

import { isRequired } from '../../utils/validation';

const validateRequired = [isRequired()];
const validateEmail = [isRequired(), email()];

class OwnerEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      country: ''
    }
  }

  componentDidMount() {
    apolloClient.query({ query: GET_BG_COUNTRY, fetchPolicy: 'no-cache' })
      .then(response => {
        if (response.loading) return;
        this.setState({country: response.data.countries.pop() || ''})
      });
  }

  render() {
    const { history, options, id } = this.props;
    const { country } = this.state;
    return ( country !== '' &&
      <Edit
        title={<EditFormTitle formName={t('owner')} />}
        className="gridWithSearchableFilter"
        {...this.props}
      >
        <TabbedForm redirect="list" toolbar={<CustomEditToolbar history={history} user={options.currentUser.role}/>} defaultValue={{ countryId: country.id }}>
          <FormTab label={t('summary_tab')}>
            <TextInput source="name" label={t('name')} validate={validateRequired}/>
            {options.currentUser.role === 'Admin' ? <BooleanInput source="active" label={t('active')}/> : null}
            <TextInput source="phoneNumber" label={t('phone_number')}/>
            <TextInput source="email" label={t('email')} validate={validateEmail}/>
            <ReferenceInput label={t('vet')} reference="Vet" source="vet.id" validate={validateRequired}>
              <SearchableSelect obj="Vet" disabled={options.currentUser.role !== 'Admin'}/>
            </ReferenceInput>
          </FormTab>
          <FormTab label={t('addresses_tab')} basepath="addresses">
            <ReferenceInput
              label={t('city')}
              reference="City"
              source="city.id"
              validate={validateRequired}
              perPage={30}
              filter={{mainProvinceCity: true}}
              sort={{ field: 'name', order: 'ASC' }}
            >
              <LocationSelect parentId={id} parentIdName='ownersIds'/>
            </ReferenceInput>
            <TextInput source='street' label={t('street')}/>
            <TextInput source='streetNumber' label={t('street_number')}/>
            <TextInput source='buildingNumber' label={t('building_number')}/>
            <TextInput source='district' label={t('district')}/>
            <TextInput source='entrance' label={t('entrance')}/>
            <TextInput source='floor' label={t('floor')}/>
            <TextInput source='apartmentNumber' label={t('apartment_number')}/>
            <TextInput source="postcode" label={t('postcode')} validate={validateRequired}/>
          </FormTab>
          <FormTab label={t('owners.pets')}>
            <FunctionField
              label=" "
              render={({animal, id, active}) => {
                return (
                  <Button
                    component={Link}
                    to={{pathname: '/Animal/create', state: {owner: id, redirect: `/Owner/${id}/2`}}}
                    label={t('animals.title_create')}
                    onClick={e => e.stopPropagation()}
                    disabled={!active}
                  >
                    <ContentAdd/>
                  </Button>
                );
              }}
            />
            <ReferenceArrayField label={t('owners.pets')} reference="Animal" source="animalsIds">
              <Datagrid rowClick="show">
                <TextField source="name" label={t('name')}/>
                <TextField source="animalType" label={t('animal_type')}/>
                <NumberField source="weight" label={t('weight')}/>
              </Datagrid>
            </ReferenceArrayField>
          </FormTab>
        </TabbedForm>
      </Edit>
    )
  }
}

export default OwnerEdit;