import React from 'react';
import { List, Datagrid, ReferenceField, ArrayField, SelectField, TextField } from 'react-admin';
import { t } from 'i18next';

import { CustomListActions } from '../CustomListActions';
import { CustomBulkActions } from '../CustomBulkActions';

export const StatusesWorkflowList = props => (
  <List {...props} bulkActionButtons={<CustomBulkActions/>} actions={<CustomListActions/>} title={t('statuses_workflow.workflow')}>
    <Datagrid rowClick='edit'>
      <ReferenceField source='statusFrom.id' reference='Status' label={t('statuses_workflow.status_from')} allowEmpty={true} sortable={false} linkType={false}>
        <TextField source='nameBg'/>
      </ReferenceField>
      <ReferenceField source='statusTo.id' reference='Status' label={t('statuses_workflow.status_to')} allowEmpty={true} sortable={false} linkType={false}>
        <TextField source='nameBg'/>
      </ReferenceField>
      <ArrayField source='emailTo' label={t('statuses_workflow.send_emails_to')}>
        <Datagrid>
          <SelectField
            label={t('statuses_workflow.role')}
            source='role'
            choices={[
              { id: 'Admin', name: t('statuses_workflow.admin') },
              { id: 'ProductionManager', name: t('statuses_workflow.production_manager') },
              { id: 'Clinic', name: t('statuses_workflow.clinic') },
              { id: 'Vet', name: t('statuses_workflow.vet') },
              { id: 'Owner', name: t('statuses_workflow.owner') },
            ]}
          />
          <ReferenceField source='template.id' reference='EmailTemplate' label={t('statuses_workflow.template')} allowEmpty={true} sortable={false}>
            <TextField source='name'/>
          </ReferenceField>
        </Datagrid>
      </ArrayField>
    </Datagrid>
  </List>
);
