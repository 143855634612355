import React from 'react';
import { Create } from 'react-admin';
import { t } from 'i18next';

import CustomCreateToolbar from '../CustomCreateToolbar';
import BreedForm from './BreedForm';

export const BreedCreate = props => (
  <Create {...props} title={t('breeds.title_create')}>
    <BreedForm toolbar={<CustomCreateToolbar user={props.options.currentUser.role}/>}/>
  </Create>
);