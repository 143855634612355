import React from 'react';
import { Create } from 'react-admin';
import { t } from 'i18next';
import CustomCreateToolbar from '../CustomCreateToolbar';
import CityForm from './CityForm';

export const CityCreate = props => (
  <Create {...props} redirect="list" title={t('cities.title_create')}>
    <CityForm toolbar={<CustomCreateToolbar user={props.options.currentUser.role}/>} redirect="list" />
  </Create>
);