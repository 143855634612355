import React, { Component } from 'react';
import { GET_ALL_VETS, GET_OWNER_HISTORY_RECORDS, GET_CITIES_BY_IDS } from '../../customQueries';
import apolloClient from '../../initApollo';
import { t } from 'i18next';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';

import { translateProp, getObjectProperty, formatDateTime } from '../../utils/Helper';

class OwnerHistory extends Component {
  constructor(props){
    super(props);

    this.state = {
      records: [],
      vets: []
    };
  }

  componentWillMount() {
    apolloClient.query({query: GET_ALL_VETS}).then(response => {
      this.setState({ vets: response.data.vets }, () => this.formatHistory(this.props.record.id) )
    });
  }

  formatHistory = (ownerId) => {
    apolloClient.query({query: GET_OWNER_HISTORY_RECORDS, variables: { ownerId }, fetchPolicy: 'no-cache'}).then(response => {
      let historyRecords = response.data.ownerHistories;
      let data = [];
      let citiesIds = [];

      historyRecords.forEach(record => {
        Object.entries(record.data).forEach(([property, values]) => {
          let oldValue = values.oldValue;
          let newValue = values.newValue;

          if(oldValue !== null && typeof oldValue === 'object' && property !== 'city') {
            // for some properties we want to show name or something else instead of id
            // to be sure we display the current name we need to make a query to get this property
            oldValue = this.getValue(property, oldValue);
          }

          if(newValue !== null && typeof newValue === 'object' && property !== 'city') {
            // for some properties we want to show name or something else instead of id
            // to be sure we display the current name we need to make a query to get this property
            newValue = this.getValue(property, newValue);
          }

          if(property === 'city') {
            citiesIds.push(record.data.city.oldValue.id);
            citiesIds.push(record.data.city.newValue.id);
          }

          data.push({
            date: formatDateTime(record.date),
            user: record.user,
            property: property,
            oldValue: oldValue,
            newValue: newValue
          });
        })
      });

      this.addCities(citiesIds, data);
    });
  };

  addCities = (ids, data) => {
    apolloClient.query({query: GET_CITIES_BY_IDS, variables: {ids}, fetchPolicy: 'no-cache'}).then(response => {
      if (response.loading) return;
      let cityNames = response.data.cities;

      data.forEach(record => {
        if(record.property === 'city'){
          cityNames.forEach(city => {
            if(city.id === record.newValue.id){
              record.newValue = city.name;
            }
            if(city.id === record.oldValue.id){
              record.oldValue = city.name;
            }
          });
        }
      });

      this.setState({
        records: data
      });
    });
  };

  getValue = (key, value) => {
    switch(key) {
      case 'vet':
        return getObjectProperty(value, this.state.vets, 'name');
      case 'city':
        return getObjectProperty(value, this.state.cities, 'name');
      default:
        return value;
    }
  };

  render() {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('property')}</TableCell>
            <TableCell>{t('old_value')}</TableCell>
            <TableCell>{t('new_value')}</TableCell>
            <TableCell>{t('date_of_change')}</TableCell>
            <TableCell>{t('user')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            Object.entries(this.state.records).map(([key, value]) => {
              const translated_property = translateProp(value.property);
              return (
                <TableRow key={key}>
                  <TableCell>{t(`${translated_property}`)}</TableCell>
                  <TableCell>{t(`${value.oldValue}`)}</TableCell>
                  <TableCell>{t(`${value.newValue}`)}</TableCell>
                  <TableCell>{value.date}</TableCell>
                  <TableCell>{t(`${value.user.toLowerCase()}`)}</TableCell>
                </TableRow>
              );
            })
          }
        </TableBody>
      </Table>
    );
  }
}

export default OwnerHistory;
