import React, { Component, Fragment } from 'react';
import { t } from 'i18next';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import { Link } from 'react-admin';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { GET_ORDERS_BY_WEEK } from '../../customQueries';
import apolloClient from '../../initApollo';
import { formatDate, getFirstDayOfWeek } from '../../utils/Helper';

const styles = {
  field: {
    marginLeft: '10px'
  },
  chip: {
    margin: 2
  }
};

class ClinicConsultingFeeList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      orders: [],
      consultingFees: [],
      week: ''
    }
  }

  componentWillMount() {
    let startOfWeek = this.calculateStartOfWeek();
    this.setState({ week: startOfWeek });

    apolloClient.query({ query: GET_ORDERS_BY_WEEK, variables: { startOfWeek }, fetchPolicy: 'network-only' }).then(response => {
      this.setState({ orders: response.data.orders }, () => this.calculate())
    });
  }

  calculateStartOfWeek = () => {
    const date = new Date();
    // date.setDate(date.getDate() - 7);

    return getFirstDayOfWeek(date);
  };

  calculate = () => {
    let ordersData = [];
    this.state.orders && this.state.orders.forEach(order => {
      if (order.clinic && order.clinic.id) {
        const clinic = order.clinic.id;
        if (clinic in ordersData) {
          ordersData[clinic].push(order)
        } else {
          ordersData[clinic] = [order];
        }
      }
    });

    let consultingFees = [];
    Object.entries(ordersData).forEach(([clinic, weekData]) => {
      if (!(clinic in consultingFees)) consultingFees[clinic] = [];

      consultingFees[clinic].consultingFeeClinic = weekData[0].consultingFeeClinic || 0;
      consultingFees[clinic].weeklyTurnoverClinic = 0;
      consultingFees[clinic].orders = [];
      consultingFees[clinic].vets = [];
      weekData.forEach((data) => {
        consultingFees[clinic].clinic = data.clinic;
        consultingFees[clinic].weeklyTurnoverClinic += data.price;
        consultingFees[clinic].orders.push({ id: data.id, idNumber: data.idNumber });
        if (!consultingFees[clinic].vets.includes(data.vet)) {
          consultingFees[clinic].vets.push(data.vet)
        }
      });
    });

    this.setState({ consultingFees })
  };

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <TextField
          disabled
          className={classes.field}
          label={t('consulting_fee_details.week_number')}
          defaultValue={`${t('from')} ${formatDate(this.state.week)}`}
        />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('clinic_name')}</TableCell>
              <TableCell>{t('consulting_fee_details.weekly_turnover')}</TableCell>
              <TableCell>{t('consulting_fee_details.consulting_fee')}</TableCell>
              <TableCell>{t('main_menu.vets')}</TableCell>
              <TableCell>{t('orders')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              Object.values(this.state.consultingFees).map(consultingFee => {
                return (
                  <TableRow key={consultingFee.clinic.id}>
                    <TableCell><Chip label={consultingFee.clinic.name} component={Link} to={{ pathname: `/Clinic/${consultingFee.clinic.id}/show` }} clickable /></TableCell>
                    <TableCell>{consultingFee.weeklyTurnoverClinic.toFixed(2)}</TableCell>
                    <TableCell>{consultingFee.consultingFeeClinic.toFixed(2)}</TableCell>
                    <TableCell>
                      {consultingFee.vets.map(vet => {
                        return <Chip key={vet.id} label={vet.name} component={Link} to={{ pathname: `/Vet/${vet.id}/show` }} clickable />
                      })}
                    </TableCell>
                    <TableCell>
                      {consultingFee.orders.map(order => {
                        return <Chip key={order.id} className={classes.chip} label={order.idNumber} component={Link} to={{ pathname: `/Order/${order.id}/show` }} clickable />
                      })}
                    </TableCell>
                  </TableRow>
                );
              })
            }
          </TableBody>
        </Table>
      </Fragment>
    );
  }
}

export default withStyles(styles)(ClinicConsultingFeeList);
